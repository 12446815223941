import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { MdDelete } from 'react-icons/md';
// import StockReport from "./StockReport";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
// import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { FinanceContext } from "../../../../../contexts/Finance";

const AddBankPayment = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { getSupplier, supplierForSearch } = useContext(UserContext);
    // let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
    //finance context
    const {
      loading,
      setLoading,

      //coa3
      coa3, 
      setCoa3,
      coa3ForSearch,
      setCoa3ForSearch,
      getCoaL3,
      setPaginatedCoa3,

      getBPList,

      //pagination
      dataPaginating,
    } = useContext(FinanceContext);
    // console.log("check tran acc==>",bankAccounts);
    // States hook here
    //new item
    // let [newItem, setNewItem] = useState({
    //   branch: null,
    //   supplier: null,
    //   invoice: "",
    //   description: "",
    //   paymentType: "",
    //   items: null,
    //   paid: null,
    //   total: null,
    //   due: null,
    // });

    //Add new row
    const [newRow,setNewRow]=useState([]);
  
    //datepicker
    // const [purchaseOrderDate, setpurchaseOrderDate] = useState(new Date());

    //overflow state
    const [overflowStyle,setOverflowStyle]=useState(false);

    //bankpayment state
    const [bankPayment,setBankPayment]=useState({
      doc_date:new Date(),
      transactionAccount:"",
      supplierName:"",
      remarks:""
    });

    //Totals of rows
    const [totalRowAmount,setTotalRowAmount]=useState(0);
    const [totalRowWhtAmount,setTotalRowWhtAmount]=useState(0);
    const [totalRowTaxAmount,setTotalRowTaxAmount]=useState(0);
    const [totalRowNetAmount,setTotalRowNetAmount]=useState(0);

    //bank transaction accounts
    const [bankAccounts,setBankAccounts]=useState(null);

    const getTransactionAccount = async () => {
     // setLoading(true);
      const url = BASE_URL + "/settings/get-bank-account";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setBankAccounts(res.data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    };
  
    //useEffect == componentDidMount()
    useEffect(() => {
      if(!supplierForSearch){
        getSupplier();
      }
      getTransactionAccount();
    }, []);
  
  
    //post req of food item add
    // const ingredientItemAxios = async () => {
    //   setLoading(true);
    //   let date =
    //     new Date(purchaseOrderDate).getFullYear() +
    //     "-" +
    //     (new Date(purchaseOrderDate).getMonth() + 1) +
    //     "-" +
    //     new Date(purchaseOrderDate).getDate();
       
  
    //   // formData.append("item_amount", handleAmount());
  
    //   //converting items and prices to array
    //   let slugArray = [];
    //   newItem.items.map((newIngredientItem) => {
    //     slugArray.push({
    //       qty: qtyOfItem && qtyOfItem[newIngredientItem?.id],
    //       rate: rateOfItem && rateOfItem[newIngredientItem?.id],
    //       item_id: newIngredientItem?.id,
    //       item_name: newIngredientItem.name,
    //       unit_name: newIngredientItem.unit,
    //       amount: handleGetAmount(newIngredientItem?.id),
    //     });
    //   });
  
    //   let formData = {
    //     supplier_id : newItem.supplier.id,
    //     // document_identity: newItem.invoice,
    //     document_date : date,
    //     remarks : newItem.description,
    //     total_amount : handleGetTotal(),
    //     items : slugArray
    //   }
  
    //   const url = BASE_URL + "/settings/new-purchase-order";
    //    await axios
    //     .post(url, formData, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     })
    //   .then((res) => {
        
    //     handleOnlyPrint();
    //     getIngredientItem();
    //     setIngredientPurchaseHistory(res.data[0])
    //     setIngredientPurchaseHistoryForSearch(res.data[1])
    //     setNewItem({
    //       branch: null,
    //       supplier: null,
    //       invoice: "",
    //       description: "",
    //       paymentType: "",
    //       items: null,
    //       paid: null,
    //       total: null,
    //       due: null,
    //     });
    //     setQtyOfItem(null);
    //     setRateOfItem(null);
    //     setLoading(false);
    //     toast.success(`${_t(t("Purchase Order has been added"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   }).catch((error) =>{
    //     setLoading(false);
    //     toast.error(`${_t(t("Please try again later"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   })
    // };
  
    //send to server
    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   if (
    //     //newItem.branch !== null &&
    //     newItem.supplier !== null &&
    //     newItem.items !== null &&
    //     newItem.purchaseOrderDate !== null
    //   ) {
    //     ingredientItemAxios();
    //   } else {
    //     toast.error(`${_t(t("Please select the supplier"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   }
    // };

    const saveAccount=async(formData)=>{
      setLoading(true);
      //converting items and prices to array
      // let slugArray = [];
      // newItem.items.map((newIngredientItem) => {
      //   slugArray.push({
      //     qty: qtyOfItem && qtyOfItem[newIngredientItem?.id],
      //     rate: rateOfItem && rateOfItem[newIngredientItem?.id],
      //     item_id: newIngredientItem?.id,
      //     item_name: newIngredientItem.name,
      //     unit_name: newIngredientItem.unit,
      //     amount: handleGetAmount(newIngredientItem?.id),
      //   });
      // });
  
      // let formData = {
      //   supplier_id : newItem.supplier.id,
      //   // document_identity: newItem.invoice,
      //   document_date : date,
      //   remarks : newItem.description,
      //   total_amount : handleGetTotal(),
      //   items : slugArray
      // }
  
      const url = BASE_URL + "/settings/create-bank-payment";
       await axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        // console.log("Added response==>",res)
        getBPList();
        // handleOnlyPrint();
        // getIngredientItem();
        // setIngredientPurchaseHistory(res.data[0])
        // setIngredientPurchaseHistoryForSearch(res.data[1])
        // setNewItem({
        //   branch: null,
        //   supplier: null,
        //   invoice: "",
        //   description: "",
        //   paymentType: "",
        //   items: null,
        //   paid: null,
        //   total: null,
        //   due: null,
        // });
        // setQtyOfItem(null);
        // setRateOfItem(null);
        setLoading(false);
        toast.success(`${_t(t("Bank payment has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        history.push("/dashboard/account/transaction/bank-payment")
      }).catch((error) =>{
        setLoading(false);
        // console.log("check error==>",error);
        toast.error(`${_t(t("Please try again later"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
    }
    
    const handleSubmit=(e)=>{
      e.preventDefault();
      if(bankPayment.transactionAccount == undefined || bankPayment.transactionAccount == ""){
        toast.error(`${_t(t("Please select transaction account"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }else{
        if(newRow && newRow.length>0){
          let noFilledRows=newRow.length>0 && newRow.filter((v,i)=> v.account_name == "")
          if(noFilledRows.length>0){
          toast.error(`${_t(t("Please select account name"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          }else{
        const newArr = newRow.map((v,i) => {
          let obj={ 
            coa_account_id:v.account_name.id,
            cheque_date:v.cheque_date.getFullYear() + "-" + (parseInt(v.cheque_date.getMonth()) + 1) + "-" + v.cheque_date.getDate(),
            cheque_no:v.cheque_no,
            amount:v.amount,
            other_tax_percent:isNaN(v.tax_percent) ? 0 : parseFloat(v.tax_percent),
            other_tax_amount:parseFloat(v.tax_amount),
            wht_percent:isNaN(v.wht_percent) ? 0 : parseFloat(v.wht_percent),
            wht_amount:parseFloat(v.wht_amount),
            net_amount:parseFloat(v.net_amount),
            remarks:v.remarks
          }
          return obj;
        })
        let docDate = bankPayment.doc_date.getFullYear() + "-" + (parseInt(bankPayment.doc_date.getMonth()) + 1) + "-" + bankPayment.doc_date.getDate();
        let saveObj={
          document_date:docDate,
          transaction_account_id:bankPayment.transactionAccount.id,
          supplier_id:bankPayment.supplierName ? bankPayment.supplierName.id : "",
          remarks:bankPayment.remarks,
          rows:newArr,
          total_amount:totalRowAmount,
          total_wht_amount:totalRowWhtAmount,
          total_other_tax_amount:totalRowTaxAmount,
          total_net_amount:totalRowNetAmount
        }
        // console.log("check save==>",saveObj);
        saveAccount(saveObj)
          }
        }
        else{
          toast.error(`${_t(t("Please add accounts"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      }
      
    }
    const handleDocDate=(date)=>{
      setBankPayment({
        ...bankPayment,
        doc_date:date
      })
    }

    const handleTransactionAcc=(acc)=>{
        setBankPayment({
          ...bankPayment,
          transactionAccount:acc
        })
    }

    const handleSetSupplier = (supplier) => {
      setBankPayment({
        ...bankPayment,
        supplierName: supplier
      })
    };
    
    const handleRemarks = (remarks) =>{
      setBankPayment({
        ...bankPayment,
        remarks
      })
    }
    
    // const handleDltHandler = (id) => {
    //   let data = newItem.items?.filter(recipe => recipe.id !== id)
    //   setNewItem({
    //     ...newItem,
    //     items:data
    //   });
    //   setQtyOfItem((curr)=>{
    //     let newData = {
    //       ...curr
    //     }
    //     curr && delete curr[id];
    //     return newData
    //   });
  
    //   setRateOfItem((curr)=>{
    //     let newData = {
    //       ...curr
    //     }
    //     curr && delete curr[id];
    //     return newData
    //   });
      
    // }
    const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{      
      setNewRow((pre)=>[...pre,{
        id:rowId,
        account_name:"",
        cheque_date:new Date(),
        cheque_no:"",
        amount:"",
        tax_percent:0,
        tax_amount:"",
        wht_percent:0,
        wht_amount:"",
        net_amount:"",
        remarks:""
      }])
      setRowId(rowId+1);
    }
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
      totalAmount(row);
      totalTaxAmount(row);
      totalWhtAmount(row);
      totalNetAmount(row);
      // setRowId(rowId-1);
      console.log("funct run")
    }

    const handleAccountName=(index,acc)=>{
      newRow[index].account_name=acc  
      setNewRow([...newRow]);
      setOverflowStyle(false)
    }

    const handleChequeDate=(index,date)=>{
      newRow[index].cheque_date=date 
      setNewRow([...newRow]);
    }

    const handleChequeNo=(index,e)=>{
      newRow[index].cheque_no = e.target.value;
      setNewRow([...newRow]);
    }

    //Financial Calculations
    const accCalculation=(row,field,index)=>{
      // console.log("function==>",row,field,index);
      if(field=='amount'){
        let taxAmount=(row[index].amount*row[index].tax_percent)/100;
        // console.log("check calTax==>",(isNaN(taxAmount)))
        row[index].tax_amount= (isNaN(taxAmount) ? 0 : taxAmount.toFixed(2));
        let whtAmount=(row[index].amount*row[index].wht_percent)/100;
        row[index].wht_amount=(isNaN(whtAmount) ? 0 : whtAmount.toFixed(2));
        setNewRow([...row]);
      }else if(field=='tax_percent'){
        let taxAmount=(row[index].amount*row[index].tax_percent)/100;
        row[index].tax_amount= (isNaN(taxAmount) ? 0 : taxAmount.toFixed(2));
        setNewRow([...row]);
      }else{
        let whtAmount=(row[index].amount*row[index].wht_percent)/100;
        row[index].wht_amount=(isNaN(whtAmount) ? 0 : whtAmount.toFixed(2));
        setNewRow([...row]);
      }
      let netAmount=parseFloat(row[index].amount)-parseFloat(row[index].tax_amount)-parseFloat(row[index].wht_amount);
      row[index].net_amount=netAmount.toFixed(2);
      setNewRow([...row]);
      totalAmount(newRow);
      totalTaxAmount(newRow);
      totalWhtAmount(newRow);
      totalNetAmount(newRow);
    }

    const handleAmt=(index,e)=>{
      newRow[index].amount= parseInt(e.target.value)
      setNewRow([...newRow])
      accCalculation(newRow,'amount',index);
    }

    const handleTaxPercent=(index,e)=>{
      newRow[index].tax_percent=parseFloat(e.target.value)
      setNewRow([...newRow])
      accCalculation(newRow,'tax_percent',index);
    }

    const handleWhtPercent=(index,e)=>{
      newRow[index].wht_percent=parseFloat(e.target.value)
      setNewRow([...newRow])
      accCalculation(newRow,'wht_percent',index);
    }

    const handleRowRemarks=(index,e)=>{
      newRow[index].remarks = e.target.value;
      setNewRow([...newRow]);
    }

    console.log("check newRow==>",newRow);
    const totalAmount=(newRow)=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // console.log("check total amount==>",(isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount)));
          // if(item.amount){
            total = total + (isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount));
          // }
        });
      }
      setTotalRowAmount(total);      
      // return total;
    }
    const totalTaxAmount=(newRow)=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // if(item.tax_amount){
            total = total + (isNaN(item.tax_amount) || item.tax_amount=="" ? 0 : parseFloat(item.tax_amount));
          // }
        });
      }
      setTotalRowTaxAmount(total)
      // return total;
    }
    const totalWhtAmount=(newRow)=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // if(item.wht_amount){
            total = total + (isNaN(item.wht_amount) || item.wht_amount=="" ? 0 : parseFloat(item.wht_amount));
          // }
        });
      }
      setTotalRowWhtAmount(total)
      // return total;
    }
    const totalNetAmount=(newRow)=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // if(item.net_amount){
            total = total + (isNaN(item.net_amount) || item.net_amount=="" ? 0 : parseFloat(item.net_amount));
          // }
        });
      }
      setTotalRowNetAmount(total);
      // return total;
    }

    //document date identity and net amount total in get table
    return (
      <>
      <Helmet>
        <title>{_t(t("Add Bank Payment"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <AccountsSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add Bank Payment"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <div className="row">                            
                              <div className="row">
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="invoice"
                                    className="control-label"
                                  >
                                    {_t(t("Document #"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control table-text"
                                    id="invoice"
                                    name="invoice"
                                    // onChange={handleChange}
                                    // value={newItem.invoice}
                                    placeholder="Auto No"
                                  />
                                </div>
                              </div>
  
                              <div className="form-group col-4 text-right mt-2">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={bankPayment.doc_date}
                                    onChange={(date) => handleDocDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Document date"))}
                                    shouldCloseOnSelect={false}
                                    required
                                  />
                                </div>
                              </div>
  
                              <div className="form-group mt-2 col-4">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="transaction"
                                    className="control-label"
                                  >
                                    {_t(t("Transaction Account"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <Select
                                  options={bankAccounts ? bankAccounts : []}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.level3_name}
                                  getOptionValue={(option) => option.id}
                                  value={bankPayment.transactionAccount || ""}
                                  classNamePrefix="select table-text"
                                  onChange={(acc)=>handleTransactionAcc(acc)}
                                  //maxMenuHeight="50px"
                                  placeholder={
                                    _t(t("Please select an account")) + ".."
                                  }
                                  styles={customStyle}
                                  required
                                />
                                </div>
                              </div>                            
                          </div>
                           <div className="row">                           
                              <div className="form-group mt-2 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="item"
                                    className="control-label"
                                  >
                                    {_t(t("Supplier name"))}                                    
                                  </label>
                                </div>
                                <Select
                                  options={supplierForSearch ? supplierForSearch : []}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={bankPayment.supplierName}
                                  classNamePrefix="select table-text"
                                  onChange={(e)=>handleSetSupplier(e)}
                                  isClearable
                                  //maxMenuHeight="50px"
                                  placeholder={
                                    _t(t("Please select a supplier")) + ".."
                                  }
                                  styles={customStyle}                                  
                                />
                              </div>                            
                            <div className="form-group mt-2 col-8">
                            <div className="mb-2">
                              <label
                                htmlFor="description"
                                className="control-label"
                              >
                                {_t(t("Remarks"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control table-text"
                                id="description"
                                name="description"
                                onChange={(e)=>handleRemarks(e.target.value)}
                                value={bankPayment.remarks}
                                placeholder="Short description"
                              />
                            </div>
                           </div>

                           {/* <div className="form-group mt-2 col-8">
                            <div className="mb-2">
                              <button className="btn btn-primary px-2 rounded">
                                Add&nbsp;
                                <span className="t-mr-10">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                </span>
                                
                              </button>
                            </div>
                           </div> */}
                          </div> 
                            {/* {newItem.items !== null && [
                              newItem.items.length > 0 && ( */}
                                <div className={`table-responsive pt-3 mt-2 pr-1 ${overflowStyle ? "add-bank-table" : "overflow-visible"}`
                                } style={{maxHeight:"23rem",overflow: "auto"}}>                              
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">
                                      <tr>
                                        {newRow.length==0 ? <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                            <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>
                                        </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child-bank"
                                          style={{minWidth:"120px"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Account name"))}
                                          </span>
                                        </th>
                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Cheque Date"))}
                                          </span>
                                        </th>
  
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Cheque No"))}
                                          </span>
                                        </th>
  
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Amount"))}
                                          </span>
                                        </th>
  
                                        <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                       >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Tax Percent"))}
                                        </span>
                                       </th>
  
                                        <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Tax amount"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("WHT Percent"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("WHT Amount"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Net Amount"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Remarks"))}
                                        </span>
                                      </th>
                                      {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}                                        
                                      </tr>
                                    </thead>
  
                                    <tbody className="align-middle">
                                      {/* {newItem.items.reverse().map(
                                        (ingredientItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild">
                                                {ingredientItem.name}
                                              </td>
  
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                              <input
                                                type="number"
                                                step="0.1"
                                                min="0"
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                className="form-control text-center table-Input table-text"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                onChange={handleItemQty}
                                                value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                                placeholder="Qty"
                                                required
                                              />
                                            </td>
  
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {ingredientItem.unit}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={handleItemRate}
                                                  value={rateOfItem && rateOfItem[ingredientItem?.id] ? rateOfItem[ingredientItem?.id] : ""}
                                                  placeholder="Price"
                                                  required
                                                 
                                                />
                                              </td>
  
                                              <td className="table-text align-middle text-center">
                                                {formatPrice(
                                                  handleGetSubTotal(
                                                    ingredientItem.id
                                                  )
                                                )}
                                               </td>
  
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"10%"}}>
                                                  <MdDelete className="text-danger" style={{cursor: "pointer", fontSize: "1.3rem"}} onClick={()=>handleDltHandler(ingredientItem.id)}/>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )} */}
                                        {newRow.length > 0 ? newRow.map((v,i)=>{
                                          return <tr
                                            className="align-middle"
                                            key={i}
                                          >
                                            <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                            {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>}&nbsp;
                                            <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>
                                            </td>
                                            <td className="table-text text-capitalize align-middle" style={{minWidth:"250px"}}>
                                              <Select
                                                menuPlacement={newRow.length > 3 && i > 2 ? "top" : "bottom"}
                                                options={coa3ForSearch ? coa3ForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.level3_name}
                                                getOptionValue={(option) => option.id}
                                                value={v.account_name|| ""}
                                                // classNamePrefix="select table-text"
                                                onChange={(e)=>handleAccountName(i,e)}
                                                maxMenuHeight="12.8rem"
                                                placeholder={
                                                  _t(t("Account Name")) + ""
                                                }
                                                isMenuOpen={true}
                                                styles={customStyle}
                                                className="bank-table-input"
                                                required
                                                onFocus={()=>{
                                                  if(newRow && newRow?.length > 0 && newRow?.length < 5){
                                                    setOverflowStyle(true)
                                                  }
                                                }}
                                                onBlur={()=>setOverflowStyle(false)}
                                                onMenuOpen={()=>{
                                                  if(newRow && newRow?.length > 0 && newRow?.length < 5){
                                                    setOverflowStyle(true)
                                                  }
                                                }}
                                                onMenuClose={()=>setOverflowStyle(false)}
                                              />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"120px"}}>
                                              <DatePicker
                                                selected={v.cheque_date}
                                                onChange={(date) => handleChequeDate(i,date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className="form-control table-text"
                                                placeholderText={_t(t("Cheque date"))}
                                                shouldCloseOnSelect={false}
                                                required                                                
                                              />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"130px"}}>
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleChequeNo(i,e)}
                                              value={v.cheque_no}
                                              placeholder="cheque no"                                                                                         
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleAmt(i,e)}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"
                                              required
                                              value={v.amount}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center">
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleTaxPercent(i,e)}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"
                                              value={v.tax_percent || ""}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                            readOnly
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                            //   onChange={handleItemQty}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"
                                              value={v.tax_amount}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center">
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleWhtPercent(i,e)}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"                                              
                                              value={v.wht_percent || ""}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="number"
                                              readOnly
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                            //   onChange={handleItemQty}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"
                                              value={v.wht_amount}
                                              required
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="number"
                                              readOnly
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                            //   onChange={handleItemQty}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"                                              
                                              value={v.net_amount}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="text"                                              
                                              // step="0.1"
                                              // min="0"
                                              //onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleRowRemarks(i,e)}                                              
                                              placeholder="remarks"                                              
                                              value={v.remarks}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>&nbsp;
                                                {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                    <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}
                                               
                                            </td>
                                          </tr>
                                        }):null}
                                    </tbody>
                                    {newRow.length > 0 &&
                                    <tfoot className="align-middle">
                                      <tr>
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          colSpan="3"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th>                                        
  
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          colSpan="1"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                          {_t(t("Total Amount"))}
                                          </span>
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border font-weight-bold"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalRowAmount)}
                                            {/* {totalRowAmount} */}
                                          </span>
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {/* {formatPrice(totalTaxPercent())} */}
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalRowTaxAmount)}
                                          </span>
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {/* {formatPrice(totalWhtPercent())}                                       */}
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalRowWhtAmount)}
                                          </span>
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalRowNetAmount)}
                                          </span>
                                        </th>
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          // colSpan="3"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th> 
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          // colSpan="3"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th> 
                                      </tr>
                                    </tfoot>}
                                  </table>                                  
                                </div>
                              {/* ),
                            ]} */}
  
                            <div className="form-group mt-4 pb-2">
                              <div className="col-12 text-right">
                                <button
                                  className="btn hov-btn px-4 mr-3 rounded"
                                  onClick={()=>(history.push("/dashboard/account/transaction/bank-payment"))}
                                >
                                  <i className="fa fa-arrow-left" aria-hidden="true"></i>  {_t(t("Back"))}
                                </button>
                                <button
                                  className="btn btn-primary px-4 rounded"
                                  type="submit"
                                >
                                  <i className="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
        {/* main body ends */}
        {/* <StockReport
        name = "Purchase Order"
        purchaseDate={purchaseOrderDate}
        newItem={newItem}
        qtyOfItem={qtyOfItem}
        rateOfItem={rateOfItem}
        handleGetTotal={handleGetTotal}
        handleAmount={handleAmount}
        handleTaxAmount={0}
        handleDisAmount={0}
        handleGetSubTotal={handleGetSubTotal}
        handleGetAmount={handleGetAmount}
        stockPrint={componentRef}
        /> */}
    </>
    )
}

export default AddBankPayment;