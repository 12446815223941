import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const PayrollContext = React.createContext();

const PayrollProvider = ({ children }) => {
    // State hooks  here
    //loading
    const [loading, setLoading] = useState(false);
    const [dataPaginating, setDataPaginating] = useState(false);
    //payroll state
    const [department, setDepartment] = useState(null);
    const [departmentForSearch, setDepartmentForSearch] = useState(null);
    const [designations, setDesignations] = useState(null);
    const [designationForSearch, setDesignationForSearch] = useState(null);

    //check if offline helper
    const offline = (url) => {
        return !navigator.onLine && localStorage.getItem(url) != null;
    };

    useEffect(() => {
        //call if authenticated
        if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
        }
    })

    // Helper
    const setDepartments = (res) => {
      setDepartment(res.data[0])
      setDepartmentForSearch(res.data[1]);
      setLoading(false)
    };

    //get dept  // Priority ->
    const getDepartment = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-coa-level1";
      try {
      const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setDepartments(res);
      //   setDataToLocal(url, res);
      } catch (error) {
      setLoading(false)
      }
    };

    //helper
    const setPaginatedDepartments = (res) => {
      setDepartment(res.data[0])
      setDepartmentForSearch(res.data[1]);
      setDataPaginating(false);
    };

    //get paginated dept
    const setPaginatedDepartment = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-coa-level1?page=" + pageNo;
      try {
      const res = await axios
          .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedDepartments(res);
      } catch (error) {
      setDataPaginating(false)
      }
    };

    // Helper
    const setDesignation = (res) => {
      setDesignations(res.data[0])
      setDesignationForSearch(res.data[1]);
      setLoading(false)
    };

    //get dept  // Priority ->
    const getDesignation = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-coa-level1";
      try {
      const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setDesignation(res);
      //   setDataToLocal(url, res);
      } catch (error) {
      setLoading(false)
      }
    };

    //helper
    const setPaginatedDesignations = (res) => {
      setDesignations(res.data[0])
      setDesignationForSearch(res.data[1]);
      setDataPaginating(false);
    };

    //get paginated dept
    const setPaginatedDesignation = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-coa-level1?page=" + pageNo;
      try {
      const res = await axios
        .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedDesignations(res);
      } catch (error) {
        setDataPaginating(false)
      }
    };

  return (
    <PayrollContext.Provider
      value={{
        //loading
        loading,
        setLoading,
        dataPaginating,
        setDataPaginating,

        //employee
        department,
        setDepartment,
        departmentForSearch,
        setDepartmentForSearch,
        setPaginatedDepartment,
        getDepartment,

        designations,
        setDesignations,
        designationForSearch,
        setDesignationForSearch,
        setPaginatedDesignation,
        getDesignation
      }}
    >
      {children}
    </PayrollContext.Provider>
  );
};

export { PayrollContext, PayrollProvider };