import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  checkPermission,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useCustomHooks } from "../../../../functions/Hooks";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ItemCancelModal from "./modal/ItemCancelModal";
import SettledAddReason from "./SettledAddReason";
import OrderPrintView from "./modal/OrderPrintView";
import OrderDetailsView from "./modal/OrderDetailsView";

const Settled = () => {
  const { authUserInfo, managerPasscode } = useContext(UserContext);
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  const {
    //submitted orders
    settledOrders,
    setSettledOrders,
    setPaginatedSettledOrders,
    settledOrdersForSearch,

    //payment-type
    paymentTypeForSearch,
    orderTypeList,
    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  const { roundFormatPrice, taxPercent } = useCustomHooks();

  const { t } = useTranslation();
  //print bills
  const componentRef = useRef();

  // States hook here
  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  const [orderForAction, setOrderForAction] = useState({});

  const [reason, setReason] = useState("");
  const [taxType, setTaxType] = useState("");

  const [deleteReasonModalShowing, setDeleteReasonModalShowing] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
    srb_pos_invoice_no: null,
    srb_pos_invoice_no_for_print: null,
  });

  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });

  const [showAddReasonModal, setShowAddReasonModal] = useState(false);
  const [activebtn , setActivebtn] = useState("");

  const [passcodeApplied, setPasscodeApplied] = useState(false);
  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);

  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  useEffect(()=>{
    const filteredDeliveryTypes = orderTypeList && orderTypeList?.data.filter(
      (order) => order.status === 'On'
    );
    if (filteredDeliveryTypes.length > 0) {
      setAvailableDeliveryTypes(filteredDeliveryTypes);
    }
  },[orderTypeList]);

  useEffect(() => {
    if (!authUserInfo) return
    setPasscodeApplied(
      (authUserInfo.details.user_type === "staff" && (authUserInfo.details.user_passcode || managerPasscode)) ? true : false
    );
  }, [authUserInfo]);

  useEffect(() => {
    if (!generalSettings || !generalSettings?.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);

  //search submitted orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if(settledOrders){
      if (searchInput.length === 0 || searchInput === "all") {
        setSearchedOrder({ ...searchedOrder, searched: false });
      } else {
        let searchedList = settledOrdersForSearch && settledOrdersForSearch?.filter((item) => {
          //token
          let lowerCaseItemToken = item.token && JSON.stringify(item.token?.id)?.toLowerCase();
          let orderNo = item?.order_no && item.order_no.toString();
          
          //customer
          let lowerCaseItemCustomer = item.customer_name && item.customer_name.toLowerCase();
          let customerPhone = item.delivery_phn_no && item.delivery_phn_no;
          //table
          let lowerCaseItemTable = item.table_name && item.table_name.toLowerCase();

          //branch
          let lowerCaseItemBranch = item.branch_name && item.branch_name.toLowerCase();
            //order type
          let lowerCaseItemOrderType = item.order_type_name && item.order_type_name?.toLowerCase();
          let lowerCaseWaiter = item.waiter_name && item.waiter_name.toLowerCase(); 
          let totalPayable = item.total_payable && item.total_payable; 

          return (
            (lowerCaseItemToken && lowerCaseItemToken.includes(searchInput)) ||
            (lowerCaseItemCustomer && lowerCaseItemCustomer.includes(searchInput)) ||
            (lowerCaseItemTable && lowerCaseItemTable.includes(searchInput)) ||
            (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))|| 
            (lowerCaseItemOrderType && lowerCaseItemOrderType.includes(searchInput)) || 
            (orderNo && orderNo.includes(searchInput)) || 
            (customerPhone && customerPhone.includes(searchInput)) ||
            (lowerCaseWaiter && lowerCaseWaiter.includes(searchInput)) ||
            (totalPayable && totalPayable.includes(searchInput))
          );
        });
        setSearchedOrder({
          ...searchedOrder,
          list: searchedList,
          searched: true,
        });
      }
    }
  };

  //print here
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   this.handlePrintAfter();
    // },
  });

  //ready here
  const handleReadyOrder = (id) => {
    let tempSettledOrders = settledOrders;
    let tempSearchedItems = searchedOrder.list;
    const url = BASE_URL + "/settings/settle-order-ready/" + id;
    let theItems = settledOrders.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_ready: 1,
          orderedItems: item.orderedItems.map((eachItem) => {
            return {
              ...eachItem,
              is_cooking: 1,
              is_ready: 1,
            };
          }),
        };
      } else {
        return item;
      }
    });
    if (searchedOrder.list !== null && searchedOrder.list.length > 0) {
      let searchedItems = searchedOrder.list.map((searchedItem) => {
        if (searchedItem.id === id) {
          return {
            ...searchedItem,
            is_ready: 1,
            orderedItems: searchedItem.orderedItems.map((eachorderItem) => {
              return {
                ...eachorderItem,
                is_cooking: 1,
                is_ready: 1,
              };
            }),
          };
        } else {
          return searchedItem;
        }
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedItems,
      });
    }
    setSettledOrders({ ...settledOrders, data: theItems });
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("resresres", res);
      })
      .catch((error) => {
        setSettledOrders(tempSettledOrders);
        setSearchedOrder({
          ...searchedOrder,
          list: tempSearchedItems,
        });
      });
  };

  //srb invoice
  const handleSrbInvoice = () => {
  const url = BASE_URL + "/settings/sync-srb";
  let formData = {};
    axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success("Srb ingtegration has been posted",toastObj);
      }).catch((err)=>{
        toast.error("Please try again",toastObj)
      });
  }
    
  //delete confirmation modal of waiter
  const handleSrbConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h2>{_t(t("Are you sure?"))}</h2>
            <p className="text-center">{_t(t("You want to integrate the orders?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleSrbInvoice();
                  onClose();
                }}
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handlePrint = (item, printType) => {
    console.log("item=====", item, "checkOrderDetails", checkOrderDetails);
    if (printType === "re_print") {
      // const url = BASE_URL + "/settings/settle-order-reprint/" + item.id;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${getCookie()}` },
      //   })
      //   .then((res) => {
      //     console.log("resresres", res);
      //     setCheckOrderDetails({
      //       ...checkOrderDetails,
      //       item: item,
      //       srb_pos_invoice_no: res.data,
      //       srb_pos_invoice_no_for_print: res.data,
      //     });
      //     setTimeout(() => {
      //       handleOnlyPrint();
      //     }, 1000);
      //   });
    } else {
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: item,
        settle: false,
        srb_pos_invoice_no: null,
        srb_pos_invoice_no_for_print:
          item.srb_pos_invoice_no !== ""
            ? item.srb_pos_invoice_no
            : item.fbr_pos_invoice_no,
      });
      setTimeout(() => {
        handleOnlyPrint();
      }, 500);
    }
  };

  const handleRefund = () => {
    let id = orderForAction.orderedItems[0].order_group_id;

    let theItems = settledOrders.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_refund: 1,
          orderedItems: item.orderedItems.map((eachItem) => {
            return {
              ...eachItem,
              is_cooking: 1,
              is_refund: 1,
            };
          }),
        };
      } else {
        return item;
      }
    });
    if (searchedOrder.list !== null && searchedOrder.list.length > 0) {
      let searchedItems = searchedOrder.list.map((searchedItem) => {
        if (searchedItem.id === id) {
          return {
            ...searchedItem,
            is_refund: 1,
            orderedItems: searchedItem.orderedItems.map((eachorderItem) => {
              return {
                ...eachorderItem,
                is_cooking: 1,
                is_refund: 1,
              };
            }),
          };
        } else {
          return searchedItem;
        }
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedItems,
      });
    }
    setSettledOrders({ ...settledOrders, data: theItems });

    const url = BASE_URL + "/settings/settle-order-refund";
    const formData = new FormData();
    formData.append(
      "order_group_id",
      orderForAction.orderedItems[0].order_group_id
    );
    formData.append("reason", reason);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("resresres", res);
        setOrderForAction({});
        setReason("");
        setSearchedOrder({
          list: null,
          searched: false,
        });
      });
  };

  //cancel item pop up empty  
  const handleCancelEmpty = () =>{
    setItemToDelete(null);
    setDeleteReasonModalShowing(false);
  }

  //cancel order confirmation modal
  const handleCancelOrderConfirmation = (orderGroup) => {
    setItemToDelete(null);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to refund this order?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setItemToDelete(orderGroup);
                  setDeleteReasonModalShowing(true);
                  // handleCancelOrder(orderGroup);
                  onClose();
                }}
              >
                {_t(t("Yes, cancel it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //cancel order here
  const handleCancelOrder = (orderGroup, items) => {
    // if (parseInt(orderGroup.is_accepted) === 0) {
    const url = BASE_URL + "/settings/settle-order-refund";
    let formData = {
      id: orderGroup.id,
      items: items,
    };
    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleCancelOrderReasonSubmit = (e, item) => {
    if (passcodeApplied) {
      const enteredPasscode = e.target["passcode"].value;
      if (authUserInfo.details.user_passcode ? (enteredPasscode !== authUserInfo.details.user_passcode) : !managerPasscode.hasOwnProperty(enteredPasscode)) {
        toast.error("Incorrect password! Try again.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        return;
      }
    }
    const reason = e.target["deleteReason"].value;
    const items = item.orderedItems.map((item) => ({
      ...item,
      reason: reason,
      status: e.target[`item-${item.id}-made`].value,
    }));
    setDeleteReasonModalShowing(false);
    handleCancelOrder(item, items);
    e.target["deleteReason"].value = "";
    if (authUserInfo.details.user_passcode || managerPasscode) {
      if (e.target["passcode"]) {
        e.target["passcode"].value = "";
      }
    }
  };

  const handleQuickBook = (item) => {
    const url = BASE_URL + "/settings/create-quickbook-customer";
    setLoading(true);
    const fromData = {
      order_id: item.id
    }
    axios
    .post(url,fromData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      setLoading(false);
      setSettledOrders(res.data[0]);
      setSearchedOrder({
        list: null,
        searched: false,
      });
    })
    .catch((error) => {
      setLoading(false);
    });
  }

  //settle order action list
  const listItemActionRow = (item) =>{
    return(
      <>
        {/* {parseInt(item.is_ready) === 0 ? ( */}
      {authUserInfo.permissions !== null &&
        checkPermission(authUserInfo.permissions, "Settle Order Print") ? 
        <button
        className="btn btn-secondary btn-sm"
        onClick={() => {
          handlePrint(
            item,
            "just_print"
          );
        }}
      >
        <i className="fa fa-print"></i>
      </button>:null}
      {/* ) : (
        ""
      )} */}

      { !searchedOrder.searched && parseInt(item.is_ready) ===
        0 && parseInt(
          item.is_refund
        ) == 0 && parseInt(item.is_foc) === 0 &&(
        <button
          className="btn btn-success btn-sm ml-2"
          title="Served"
          onClick={() => {
            handleReadyOrder(item.id);
          }}
        >
          <i className="fa fa-check text-"></i>
        </button>
      )}

      {/*authUserInfo.permissions !==
        null &&
      checkPermission(
        authUserInfo.permissions,
        "Reprint"
      ) ? (
        parseInt(item.is_ready) ===
          1 && (
          <button
          data-toggle="tooltip"
            className="re-print-btn ml-2"
            title="Settle"
            onClick={() => {
              handlePrint(
                item,
                "re_print"
              );
            }}
          >
            SETTLE
          </button>
        )
      ) : (
        <></>
      )*/}
      {authUserInfo.permissions !==
        null &&
      checkPermission(
        authUserInfo.permissions,
        "Order refund"
      ) && parseInt(item.is_foc) === 0 && parseInt(item.is_refund) === 0 ? (
        <button
          className="btn btn-danger btn-sm ml-2"
          data-toggle="modal"
          title="Order refund"
          data-target="#addReson"
          onClick={() => {
            setOrderForAction(item);
            setShowAddReasonModal(true);
            handleCancelOrderConfirmation(item);
          }}
        >
          <i
            style={{ fontSize: "1rem" }}
            className="fa fa-money"
          ></i>
        </button>
      ) : (
        ""
      )}

      {
        parseInt(getSystemSettings(generalSettings, "qb_enable")) === 1 && item?.qb_status === 0 && (
          <button
            data-toggle="tooltip"
            className="btn btn-primary btn-sm ml-2"
            title="Push to qb"
            onClick={() => handleQuickBook(item)}
          >
            QB
          </button>
        )
      }
      </>
    )
  }

  //settle status list
  const itemListRowStatus = (item) =>{
   return(
     <> 
    {parseInt(item.is_cancelled) ===
      0 ? (
        [
          parseInt(item.is_ready) ===
          0 && parseInt(
            item.is_refund
          ) == 0 && parseInt(item.is_foc) === 0 ? (
            <span
              class="btn btn-transparent btn-secondary xsm-text text-capitalize"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("processing"))}
            </span>
          ) : parseInt(
              item.is_refund
            ) == 0 && parseInt(item.is_foc) == 0 ? (
            <span
              class="btn btn-transparent btn-success xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Ready"))}
            </span>
          ) : 
          parseInt(item.is_foc) === 1 ?
            (
              <span
              class="btn btn-transparent btn-secondary xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Free of cost"))}
            </span>
            )
            :
          (
            <span
              class="btn btn-transparent btn-danger xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Refund"))}
            </span>
          ),
        ]
      ) : (
        <span
          class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
          onClick={() => {
            setCheckOrderDetails({
              ...checkOrderDetails,
              item: item,
              settle: false,
            });
            setReturnMoneyUsd(0);
            setPaidMoney(0);
          }}
          data-toggle="modal"
          data-target="#orderDetails"
        >
          {_t(t("Cancelled"))}
        </span>
      )}
    </>
   ) 
  } 

  const itemListContent = (item,index) => {
   return(
    <>
    <tr
      className="align-middle"
      key={index}
    >
      <th
        scope="row"
        className="table-text text-capitalize align-middle text-center"
      >
        {index +
          1 +
          (settledOrders.current_page -
            1) *
            settledOrders.per_page}
      </th>

      <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
        {"#"+item.token?.id}
      </td>
    
      <td className="table-text text-capitalize align-middle text-center table_child_ordertype">
        {`${item.order_type_name} - ${item?.party_name}`}
      </td>
      <td className="table-text align-middle text-center table_child_ordertype">
        {item.customer_name}
      </td>
      
      <td className="table-text align-middle text-center table_child_ordertype">
      {item.delivery_phn_no ? item.delivery_phn_no : "-"}
      </td>
      
      <td className="table-text align-middle text-center">
        {roundFormatPrice(Number(item.total_payable))}
      </td>

      <td className="table-text align-middle text-center">
        {item.table_name}
      </td>

      <td class="table-text text-capitalize align-middle text-center">
        {itemListRowStatus(item)}
      </td>
      <td className="table-text align-middle text-center table_child_action">
        {listItemActionRow(item)}
      </td>
     
      <td className="table-text align-middle text-center table_child_branch">
        {item.branch_name || "-"}
      </td>

      <td className="table-text align-middle text-center">
        {item.bill_distribution}
      </td>
      <td className="table-text text-capitalize align-middle text-center table_child_token">
        <Moment format="LT">
        {item.created_at}
        </Moment>
      </td>
      <td className="table-text text-capitalize align-middle text-center table_child_token">
        <Moment format="LT">
        {item?.updated_at}
        </Moment>
      </td>
      <td className="table-text text-capitalize align-middle text-center">
        {item?.ref_no ? item.ref_no : "-"}
      </td>
      {
        parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
          <td className="table-text text-capitalize align-middle text-center table_child_token">
            {item.order_no}
          </td>
        )
      }
      <td className="table-text text-capitalize align-middle text-center">
        {item.user_name}
      </td>
    </tr>
    </>
   )
  }
  
  return (
    <>
      <Helmet>
        <title>{_t(t("Settled orders"))}</title>
      </Helmet>

      {/* Refund Modal */}
      {/* {showAddReasonModal ?
        <SettledAddReason
          passcodeApplied={passcodeApplied}
          correctPasscode={authUserInfo.details.user_passcode}
          reason={reason}
          setReason={setReason}
          onSuccess={handleRefund}
          setShow={setShowAddReasonModal}
        />
        : null
      } */}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {checkOrderDetails.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    {/*<div className="col-5 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedOrder.searched
                              ? _t(t("Settled orders"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                       </div>
                      <div className="col-5 t-mb-15">
                      <span className="px-4 t-link fk-breadcrumb__link">
                        Filter By
                      </span>
                    </div>*/}
                    <div className="col-md-4 col-lg-4 t-mb-15 mb-md-0"
                    style={{ borderRight: "1px solid #6c757da6" }}
                    >
                      <ul className="t-list fk-sort align-items-center mr-3">
                         <li className="fk-breadcrumb__list mr-3">
                         <span className="t-link fk-breadcrumb__link text-capitalize">
                         {!searchedOrder.searched
                           ? _t(t("Settled orders"))
                           : _t(t("Search Result"))}
                         </span>
                         </li>
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control form-control--light-1 rounded-left"
                              placeholder={
                                _t(t("Search by token, customer, branch, order type")) +
                                ".."
                              }
                              onChange={handleSearch}
                            />
                          </div>
                          <button className="btn btn-primary rounded-right" type="button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="d-flex px-4 align-items-center gx-2">
                        {
                          availableDeliveryTypes && availableDeliveryTypes?.map((orderType)=>{
                            return(
                              <div className="mr-3">
                              <input
                              className={`submit-filter ${activebtn === orderType.name ? "active-btn" : ""} `}
                              id = {orderType.name}
                              name = {orderType.name}
                              value= {orderType.name}
                              type = "submit"
                              onClick={(e) => {
                                setActivebtn(e.target.name);
                                handleSearch(e)
                              }}
                              />
                            </div>
                            )
                          })
                        }
                        {/*<div className="mr-3">
                          <input
                          className={`submit-filter ${activebtn === "Dine-in" ? "active-btn" : ""} `}
                          id="Dine-in"
                          name="Dine-in"
                          value="Dine-In"
                          type="submit"
                          onClick={(e) => {
                            setActivebtn(e.target.name);
                            handleSearch(e)
                          }}
                          />
                        </div>
                        <div className="mr-3">
                          <input
                          className={`submit-filter ${activebtn === "delivery" ? "active-btn" : ""} `}
                          id="delivery"
                          name="delivery"
                          value="Delivery"
                          type="submit"
                          onClick={(e) =>{ 
                            setActivebtn(e.target.name)
                            handleSearch(e)
                          }}
                          />
                        </div>
                        <div className="mr-3">
                          <input
                          className={`submit-filter ${activebtn === "takeaway" ? "active-btn" : ""} `}
                          id="takeaway"
                          name="takeaway"
                          value="Takeaway"
                          type="submit"
                          onClick={(e) =>{
                            setActivebtn(e.target.name)
                            handleSearch(e)
                          }}
                          />
                          </div>*/}
                        <div className="mr-3">
                          <input
                          className={`submit-filter ${activebtn === "all" ? "active-btn" : ""} `}
                          id="all"
                          name="all"
                          value="All"
                          type="submit"
                          onClick={(e) =>{ 
                            setActivebtn(e.target.name)
                            handleSearch(e)
                          }}
                          />
                        </div>

                        <div className="mr-3">
                          <button onClick={()=> handleSrbConfirmation()} className="btn btn-sm btn-danger rounded" title="Sync if SRB/PRA server is unresponsive">
                            Sync Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <div className="row align-items-center justify-content-end gx-2">
                        <div className="col-6">
                          <NavLink
                            to="/dashboard/pos"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100 rounded"
                          >
                            {_t(t("POS"))}
                          </NavLink>
                        </div>
                        <div className="col-6">
                          <NavLink
                            to="/dashboard/pos/submitted"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100 rounded"
                          >
                            {_t(t("Submitted"))}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-settle" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Token"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Order Type"))}
                            </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>
                              
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                              {_t(t("Customer Phone"))}
                              </th>
                              
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_total"
                              >
                                {_t(t("Total Amount"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_total"
                              >
                                {_t(t("Table"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Payment Type"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Time"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Dispatch Time"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Ref No"))}
                              </th>
                              {
                                  parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                                    >
                                      {_t(t("Doc. No"))}
                                    </th>
                                  )
                                }
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("User Name"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedOrder.searched
                              ? [
                                  settledOrders && [
                                    settledOrders.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      settledOrders.data.map((item, index) => {
                                        return itemListContent(item,index)
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedOrder && [
                                    searchedOrder.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedOrder.list.map((item, index) => {
                                        return itemListContent(item,index)
                                      })
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {checkOrderDetails.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedOrder.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                settledOrders,
                                setPaginatedSettledOrders
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(settledOrders)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedOrder({
                                      ...searchedOrder,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedOrder,
                                    settledOrdersForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>

      {/*made unmade item cancel order*/}
      <ItemCancelModal
        deleteReasonModalShowing={deleteReasonModalShowing}
        handleCancelEmpty={handleCancelEmpty} 
        itemToDelete={itemToDelete}
        passcodeApplied={passcodeApplied}
        handleCancelOrderReasonSubmit={handleCancelOrderReasonSubmit}
      />

      {/* order print */}
      <OrderPrintView 
        componentRef={componentRef}
        checkOrderDetails={checkOrderDetails}
        taxType = {taxType}
        taxPercent = {checkOrderDetails.item && taxPercent(checkOrderDetails.item?.order_bill,checkOrderDetails.item?.vat,checkOrderDetails.item?.discount_amount)}
        isSettle = {{current : 1}}
        fbrInvoiceNum = {checkOrderDetails.item && checkOrderDetails.item.fbr_pos_invoice_no}
        srbInvoiceNum = {checkOrderDetails.item && checkOrderDetails.item.srb_pos_invoice_no}
        paidMoney = {paidMoney}
        redeemPoints = {checkOrderDetails.item && Number(checkOrderDetails.item?.redeem_points)}
      />
      {/* order view*/}
      <OrderDetailsView
        checkOrderDetails = {checkOrderDetails}
        returnMoneyUsd = {returnMoneyUsd}
        paidMoney = {paidMoney}
        setCheckOrderDetails = {setCheckOrderDetails}
        setReturnMoneyUsd = {setReturnMoneyUsd}
        setPaidMoney = {setPaidMoney}
        handleSettleOrder = {null}
        redeemPoints = {checkOrderDetails.item && Number(checkOrderDetails.item?.redeem_points)}
      />
    </>
  );
};

export default Settled;
