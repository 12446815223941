import React, {useContext} from 'react';
import {
    _t,
    customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next"; 
import { UserContext } from '../../../../../contexts/User';
import Select from "react-select";

const RiderModal = ({riderModal, setRiderModal, setDeliveryStatus, deliveryStatus, deliveryStatusOption, setCheckOrderDetails, handleRider, checkOrderDetails}) => {
    const { t } = useTranslation();
    const { deliveryListReqForSearch } = useContext(UserContext);

    const handleSetDeliveryStatus = (status) =>{
        setDeliveryStatus(status)
    }

  return (
    <>
        <div
        className={`modal fade ${
            riderModal !== false ? "showing" : ""
        }`}
        id="addReason"
        aria-hidden="true"
        >
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                 <div className="modal-header align-items-center">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                    <div className = "rider-text">
                     Assign a Rider
                    </div>
                    <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        setCheckOrderDetails({
                        item: null,
                        payment_amount: null,
                        payment_type : null,
                        settle: false,
                        uploading: false,
                        card_number: ""
                        })
                        setRiderModal(false)
                    }}
                    ></button>
                    </div>
                 </div>
                 <div className="modal-body">
                    {/* show form or show saving loading */}
                    <div key="fragment-food-group-1">
                        <form
                         className="border my-2 alert-success rounded-lg"
                         onSubmit={(e)=>handleRider(e)}
                         autoComplete="off"
                         >
                            <div className="table-text ml-2 py-2">
                             {_t(t("Time To Deliver The Order"))}
                            </div>

                            <div className="addons-list__item mx-2 mb-1">
                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    name="time_to_deliver"
                                    placeholder="Time in minutes"
                                    className="form-control lg-text pl-2"
                                    onChange={(e) => {
                                    setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        time_to_deliver: e.target.value,
                                    });
                                    }}
                                    value={checkOrderDetails?.time_to_deliver || ""}
                                    required
                                />
                            </div>

                            <div className="table-text ml-2 py-2">
                             {_t(t("Assign Delivery Man"))}
                            </div>
                            <div className="addons-list__item mx-2 mb-1">
                                <Select
                                    options={deliveryListReqForSearch ? deliveryListReqForSearch : []}
                                    getOptionLabel={(option) => option.name + " (" + option.phn_no + ")"}
                                    getOptionValue={(option) => option.id}
                                    value={checkOrderDetails.delivery_man_id ? deliveryListReqForSearch.find((delivery)=> delivery.id === checkOrderDetails.delivery_man_id) : null}
                                    onChange={(delivery) => {
                                        setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        delivery_man_id: delivery.id,
                                        })
                                    }}
                                    className="table-text"
                                    maxMenuHeight="200px"
                                    placeholder="Please select deliveryman"
                                    styles={customStyle}
                                />
                            </div>

                            <div className="table-text ml-2 py-2">
                             {_t(t("Select Rider Status "))}
                            </div>
                            <div className="addons-list__item mx-2 mb-1">
                                <Select
                                    options={deliveryStatusOption ? deliveryStatusOption : []}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    classNamePrefix="select"
                                    className="table-text"
                                    value={deliveryStatus}
                                    styles={customStyle}
                                    onChange={handleSetDeliveryStatus}
                                    maxMenuHeight="200px"
                                    clearIndicator={null}
                                    placeholder={_t(t("Select status")) + ".."}
                                />
                            </div>
                            <div className="pb-2 pl-2 mt-3 d-flex justify-content-center mb-2">
                                <button
                                    className="btn btn-sm btn-success text-center px-3 text-uppercase"
                                    type="submit"
                                >
                                    {_t(t("Accept"))}
                                </button>
                            </div>
                        </form>
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RiderModal
