import React, { useContext, useState } from "react";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { MdDelete } from 'react-icons/md';

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const BulkRecipeUpdate = () => {
  const { t } = useTranslation();
  let {
    loading,
    setLoading,
    dataPaginating,
  } = useContext(SettingsContext);

  const { ingredientItemForSearch, recipeForSearch } = useContext(RestaurantContext);

  const [recipe, setRecipe] = useState({
    items: null,
    recipeItem: null,
    recipeDetail: [],
  });

  const handleSetType = (item) => {
    setRecipe({ ...recipe, recipeItem: item });
  };

  const handleSetItems = (item) => {
    setRecipe({...recipe, items: item})
  }

  const handleChange = (e, index) => {
    const {value} = e.target;
    let temp = [...recipe.recipeDetail];
    temp[index].ingredient_qty = value
    setRecipe({...recipe, recipeDetail: temp});
  }

  const handleGetRecipe=(e)=>{
    e.preventDefault();
    if (recipe.recipeItem?.length && recipe.items) {
      const { id } = recipe.items;
      const recipes = recipe.recipeItem.map((item)=> item.id)
      let formData = {
        ingredient_id: id,
        recipe_ids: recipes,
      };
      const url = BASE_URL + "/settings/get-recipe-items";
      setLoading(true);
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setRecipe({
            ...recipe,
            recipeDetail: res.data,
          })
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please fill required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recipe.recipeDetail?.length) {
      let formData = {
        recipe: JSON.stringify(recipe.recipeDetail),
      };
      const url = BASE_URL + "/settings/bulk-recipe-update";
      setLoading(true);
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if(res?.data){
            setRecipe({
              items: null,
              recipe: [],
              recipeDetail: [],
            })
            toast.success("recipe has been updated",{
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            })
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please get recipe first"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Bulk Recipe Update"))}</title>
      </Helmet>
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">

                    {/* next page data spin loading */}
                    <div className={`${ loading && "loading"}`}></div>
                    {/* spin loading ends */}
                    {/* Loading effect */}
                    {dataPaginating ? (
                      tableLoading()
                    ) : (
                      <div className="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0 ">
                            <ul className="t-list mb-3 fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Bulk Recipe Update"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                          {/* Form starts here */}
                          <form
                            className="px-4 card py-2 sm-text my-2"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                           <div className="row mt-3">
                              <div className="col-4 form-group">
                                <div className="mb-2">
                                  <label
                                  htmlFor="recipe"
                                  className="control-label"
                                  >
                                    {_t(t("Ingredient Item"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <Select
                                    options={ingredientItemForSearch ? ingredientItemForSearch : []}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={recipe.items}
                                    onChange={(recipe) => handleSetItems(recipe)}
                                    classNamePrefix="ingredient"
                                    placeholder="select ingredient"
                                    styles={customStyle}
                                    menuPosition="fixed"
                                  />
                                </div>
                              </div>
                              <div className="col-6 form-group">
                                <div className="mb-2">
                                  <label
                                  htmlFor="recipe"
                                  className="control-label"
                                  >
                                    {_t(t("Recipe"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <Select
                                    options={recipeForSearch ? recipeForSearch : []}
                                    getOptionLabel={(option) => option.type + " - " + option.item_name}
                                    getOptionValue={(option) => option.id}
                                    value={(recipe.recipeItem && recipe.recipeItem?.length) ? recipe.recipeItem : null}
                                    isMulti
                                    onChange={(recipe) => handleSetType(recipe)}
                                    classNamePrefix="recipe"
                                    placeholder="select recipe"
                                    styles={customStyle}
                                  />
                                </div>
                              </div>
                              <div className="col-2 form-group d-flex align-items-center">
                                <button type="button" className="btn btn-primary btn-sm px-3 mt-3 rounded-sm" onClick={(e)=>handleGetRecipe(e)}> Get Recipe </button>
                              </div>
                            </div> 

                            {
                              recipe.recipeDetail && recipe.recipeDetail?.length ?(
                                <>
                                  <div className="form-group mt-3">
                                    <div className="mb-1">
                                      <label
                                        htmlFor="payment_type"
                                        className="control-label"
                                      >
                                        {_t(t("Recipe Item"))}
                                        <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="table-responsive mt-3">
                                    <table className="table table-bordered table-hover">
                                      <thead className="align-middle">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{width:"30%"}}
                                          >
                                            <span style={{fontWeight:500}}>
                                              {_t(t("Recipe Name"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{width:"30%"}}
                                          >
                                            <span style={{fontWeight:500}}>
                                              {_t(t("Ingredient Item"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{width:"20%"}}
                                          >
                                            <span style={{fontWeight:500}}>

                                            {_t(t("Qty"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{width:"20%"}}
                                          >
                                            <span style={{fontWeight:500}}>

                                            {_t(t("Unit"))}
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="align-middle">
                                        {recipe.recipeDetail && recipe.recipeDetail?.length && (
                                          recipe.recipeDetail.map((item, index) =>{
                                            return(
                                              <tr
                                                className="align-middle table_row_dlt"
                                                style={{width: '100%'}}
                                              >
                                                <td className="table-text text-capitalize align-middle text-center show_dlt" style={{width:"50%"}}>
                                                  {item.recipe_name}
                                                </td>

                                                <td className="table-text text-capitalize align-middle text-center show_dlt" style={{width:"50%"}}>
                                                  {item.ingredient_name}
                                                </td>

                                                <td className="sm-text text-capitalize align-middle text-center" style={{width:"20%"}}>
                                                  <input
                                                    type="number"
                                                    step="0.0001"
                                                    min="0"
                                                    className="form-control text-center sm-text"
                                                    onKeyDown={(evt) =>
                                                      ["e", "E", "+","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                    onWheel={(e) => e.target.blur()}
                                                    id={item.ingredient_id}
                                                    name="ingredient"
                                                    onChange={(e)=>handleChange(e,index)}
                                                    value={item?.ingredient_qty || ""}
                                                    placeholder="Qty"
                                                    required
                                                  />
                                                </td>
                                                <td className="table-text text-capitalize align-middle text-center" style={{width:"20%"}}>
                                                  {item?.ingredient_unit || ""}
                                                </td>
                                              </tr>
                                            )
                                          })
                                          )
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                            
                                  <div className="form-group mt-4 pb-3">
                                    <div className="mr-4 float-right">
                                      <button
                                        className="btn btn-primary px-5 rounded"
                                        type="submit"
                                      >
                                        {_t(t("Save"))}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : ""
                            }

                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BulkRecipeUpdate;
