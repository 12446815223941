import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { useCustomHooks } from "../../../../functions/Hooks";
//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const FocReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { roundFormatPrice, roundOffPrice } = useCustomHooks();

  const inputProps = {
    placeholder: 'To Time',
  };

  const inputProp = {
    placeholder: 'From Time',
  };
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } = useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { authUserInfo } = useContext(UserContext);

  let [newSalesSummaryReport, setNewSalesSummaryReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  // const [sumPrice, setSumPrice] = useState(0);
  // const [sumTotalPrice, setSumTotalPrice] = useState(0);
  const [siteName, setSiteName] = useState("");

  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handleBranch = (branch) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, branch });
    // setBranch(branch);
  };
  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    if (
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
      newSalesSummaryReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesSummaryReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-foc-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newSalesSummaryReport?.branch?.id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewSalesSummaryReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  
  let total_price = 0;
  let grand_total = 0;
  let tax_total = 0;
  let g_tax = 0 ;
  let g_discount = 0
  let g_delCharges = 0;
  let g_serCharges = 0;
  let g_afterDiscount = 0;
  let g_order_tip = 0;
  let g_pos_fee = 0;
  let g_total_guest = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Foc Summary Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Foc Summary Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />
     
      {/* Print Button */}
      <div className="d-none">
        <div>
        {
          /*  
  
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          */}
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null && reportData &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="41" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Foc Summary Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                  ></th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Created Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Token"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Punching Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Party Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Phone No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Address"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Ref No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Payment Type 1"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Payment Type 2"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Card No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Scheme"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Gross After Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount Claimable"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount Unclaimable"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Vat"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Delivery Charges"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Service Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Total"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("POS Service Fee"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Tip"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Total Guest"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("User Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Status"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Reason"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Source"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Notes"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Waiter Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Table Name"))}
                                  </th>
                              </tr>
                              </thead>
                              <tbody className="align-middle">
                                
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let sum = 0;
                                    let g_total = 0;
                                    let discount = 0;
                                    let delCharges = 0;
                                    let serCharges = 0;
                                    let afterDiscount = 0;
                                    let order_tip = 0;
                                    let pos_fee = 0;
                                    let total_guest = 0;
                                    tax_total = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                            sum += parseFloat(item.order_bill);
                                            total_price += parseFloat(
                                              item.order_bill
                                            );
                                            g_total += parseFloat(
                                              item.total_payable
                                            );
                                            grand_total += parseFloat(
                                              item.total_payable
                                            );
                                            tax_total += parseFloat(item.vat);
                                            g_tax += parseFloat(item.vat)
                                            g_discount += parseFloat(item.discount_amount)
                                            g_delCharges += parseFloat(item.delivery_charges);
                                            g_serCharges += item.service_amount ? parseFloat(item.service_amount) : 0;
                                            discount += parseFloat(item.discount_amount);
                                            delCharges += parseFloat(item.delivery_charges);
                                            serCharges = parseFloat(item.service_amount);
                                            afterDiscount += parseFloat(item.gross_after_discount);
                                            g_afterDiscount += parseFloat(item.gross_after_discount);
                                            order_tip += parseFloat(item?.order_tip);
                                            g_order_tip += parseFloat(item?.order_tip);
                                            pos_fee += parseFloat(item?.fee);
                                            g_pos_fee += parseFloat(item?.fee);
                                            total_guest += parseFloat(item?.total_guest);
                                            g_total_guest += parseFloat(item?.total_guest);

                                            return (
                                              <tr>
                                                <td></td>
                                                <td
                                                  scope="row"
                                                >
                                                  {i + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_date}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.created_at}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.token.id}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.punching_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ready_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.branch_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_type_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.party_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                 scope="row"
                                                >
                                                  {item?.delivery_phn_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.customer_address}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.delivery_boy_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ref_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[0]
                                                    ? item.bill_distribution[0].name
                                                    : "-"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[0]
                                                    ? item.bill_distribution[0].value
                                                    : "0"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[1]
                                                    ? item.bill_distribution[1].name
                                                    : "-"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[1]
                                                    ? item.bill_distribution[1].value
                                                    : "0"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.payment_card_number}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.scheme_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_bill}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.discount_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.gross_after_discount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.claimable_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.unclaimable_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.vat}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.delivery_charges}
                                                </td>
                                                <td
                                                scope="row"
                                              >
                                                {item.service_amount}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.total_payable}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.fee}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item?.order_tip}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item?.total_guest}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.user_name}
                                              </td>
                                              <td>
                                              {parseInt(
                                                  item.is_cancelled
                                                ) === 0 ? (
                                                  [
                                                    parseInt(
                                                      item.is_ready
                                                    ) === 1 &&
                                                    parseInt(
                                                      item.is_refund
                                                    ) === 0 && parseInt(item.is_foc) === 0 ? (
                                                      <span>
                                                        {_t(t("Ready"))}
                                                      </span>
                                                    ) : parseInt(
                                                        item.is_refund
                                                      ) === 1 && parseInt(item.is_foc) === 0 ? (
                                                      <span>
                                                        {_t(t("Refund"))}
                                                      </span>
                                                    ) : 
                                                    parseInt(item.is_foc) === 1 ?(
                                                      <span>
                                                      {_t(t("Free of cost"))}
                                                      </span>
                                                    ) :
                                                    (
                                                      <span>
                                                        {_t(t("processing"))}
                                                      </span>
                                                    ),
                                                  ]
                                                ) : (
                                                  <span>
                                                    {_t(t("Cancelled"))}
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.reason ? item.reason : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.order_source ? item.order_source : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item?.note_to_rider ? item.note_to_rider : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.waiter_name ? item.waiter_name : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.table_name}
                                              </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="21"></th>
                                          <th>
                                            Total
                                          </th>
                                          <td>
                                            {roundFormatPrice(sum)}
                                          </td>
                                          <td>{discount}</td>
                                          <td>{afterDiscount}</td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {roundFormatPrice(tax_total)}
                                          </td>
                                          <td>{delCharges}</td>
                                          <td>{serCharges}</td>
                                          <td>
                                            {roundFormatPrice(g_total)}
                                          </td>
                                          <td>{pos_fee}</td>
                                          <td>
                                            {roundFormatPrice(order_tip)}
                                          </td>
                                          <td>
                                            {roundFormatPrice(total_guest)}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                 <>
                                  <tr>
                                    <th colspan="21"></th>
                                    <th>
                                      Grand Total
                                    </th>
                                    <td>
                                      {roundFormatPrice(total_price)}
                                    </td>
                                    <td>{g_discount}</td>
                                    <td>{g_afterDiscount}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{roundFormatPrice(g_tax)}</td>
                                    <td>{g_delCharges}</td>
                                    <td>{g_serCharges}</td>
                                    <td>
                                      {roundFormatPrice(grand_total)}
                                    </td>
                                    <td>{g_pos_fee}</td>
                                    <td>
                                      {roundFormatPrice(g_order_tip)}
                                    </td>
                                    <td>
                                      {roundFormatPrice(g_total_guest)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th colspan="22"></th>                                  
                                    <td>
                                      Gross Amount
                                    </td>
                                    <td>Discount</td>
                                    <td>After Discount</td>
                                    <td></td>
                                    <td></td>
                                    <td>Vat</td>
                                    <td>D.C</td>
                                    <td>S.C</td>
                                    <td>
                                      Total
                                    </td>
                                    <td>
                                      Pos Service Fee
                                    </td>
                                    <td>
                                      Order Tip
                                    </td>
                                    <td>
                                      Total Guest
                                    </td>
                                  </tr>
                                 </>
                                }
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div>*/}
                    
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect 
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Foc Summary Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              value={newSalesSummaryReport && newSalesSummaryReport.branch}
                              key={newSalesSummaryReport?.branch}
                              styles={customStyle}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-2"></div>
                          <div className="col-2">
                            <button
                            className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                            onClick={(e) => getBranchWiseSelected(e)}
                            >
                            Print Report
                            </button>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default FocReport;
