import React, { useState, useContext, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}
const StoreRequisition = () => {
    const { t } = useTranslation();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);

    const {
      branchForSearch,
    } = useContext(RestaurantContext)
  
    let {

      //opening stock
    //   getOpeningStock,
      openingStockList,
      setOpeningStockList,
      setPaginatedOpeningStock,
      openingStockForSearch,
      setOpeningStockForSearch,

      //store requisition
    //   getStoreRequisition,
      storeRequisitionList,
      setStoreRequisitionList,
      setPaginatedStoreRequisition,
      storeRequisitionForSearch,
      setStoreRequisitionForSearch,

      //pagination
      dataPaginating,
    } = useContext(UserContext);
  
    //search result
    let [searchedStoreRequisition, setSearchedStoreRequisition] = useState({
      list: null,
      searched: false,
    });
  
    //search customers here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0 || storeRequisitionForSearch === null) {
        setSearchedStoreRequisition({ ...searchedStoreRequisition, searched: false });
      } 
    else {
        let searchedList = storeRequisitionForSearch.filter((item) => {
          let lowerCaseDocumentNo = item.document_identity.toLowerCase();
          let lowerCaseDocumentDate = item.document_date.toLowerCase();
          let lowerCaseRemarks = item.remarks.toLowerCase();
          let fromBranch = item?.from_branch?.name;
          let toBranch = item?.to_branch?.name;
  
          return (
            lowerCaseDocumentNo.includes(searchInput) ||
            lowerCaseDocumentDate.includes(searchInput) ||
            fromBranch.includes(searchInput) ||
            toBranch.includes(searchInput) ||
            lowerCaseRemarks.includes(searchInput)
          );
        });
        setSearchedStoreRequisition({
          ...searchedStoreRequisition,
          list: searchedList,
          searched: true,
        });
      }
    };
  
    //delete confirmation modal of waiter
    // const handleDeleteConfirmation = (id) => {
    //   confirmAlert({
    //     customUI: ({ onClose }) => {
    //       return (
    //         <div className="card card-body">
    //           <h1>{_t(t("Are you sure?"))}</h1>
    //           <p className="text-center">{_t(t("You want to delete this?"))}</p>
    //           <div className="d-flex justify-content-center">
    //             <button
    //               className="btn btn-primary"
    //               onClick={() => {
    //                 // handleDeleteCustomer(id);
    //                 onClose();
    //               }}
    //             >
    //               {_t(t("Yes, delete it!"))}
    //             </button>
    //             <button className="btn btn-success ml-2 px-3" onClick={onClose}>
    //               {_t(t("No"))}
    //             </button>
    //           </div>
    //         </div>
    //       );
    //     },
    //   });
    // };
  
    // //delete customer here
    // const handleDeleteCustomer = (id) => {
    //   setLoading(true);
    //   const customerUrl = BASE_URL + `/settings/delete-opening-stock/${id}`;
    //   return axios
    //     .get(customerUrl, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     })
    //     .then((res) => {
    //       setStoreRequisitionList(res.data[0]);
    //       setStoreRequisitionForSearch(res.data[1]);
    //       setSearchedStoreRequisition({
    //         ...searchedStoreRequisition,
    //         list: res.data[1],
    //       });
    //       setLoading(false);
    //       toast.success(`${_t(t("Store requisition has been deleted successfully"))}`, toastObj);
    //     })
    //     .catch(() => {
    //       setLoading(false);
    //       toast.error(`${_t(t("Please try again"))}`, toastObj);
    //     });
    // };

    //print bills states and function
  // const [newRow,setNewRow]=useState([]);
  // const [purchaseDate,setPurchaseDate]=useState(new Date());
  // let [newOpeningStock, setNewOpeningStock] = useState({
  //   invoice: '',
  //   branch: '',
  //   remarks: '',
  //   total_amount:''
  // });
  // const componentRef = useRef();
  // const handleOnlyPrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const handleGetPrintData = async(id) => {
  //   const url =
  //   BASE_URL +
  //     "/settings/get-opening-stock-edit/" +
  //     parseInt(id);
  //   try {
  //     const res = await axios
  //       .get(url, {
  //         headers: { Authorization: `Bearer ${getCookie()}` },
  //       });
  //         if(res.data && res.data.stock && res.data.stockDetails){
  //           let purchaseDate = new Date(res.data.stock.document_date);
  //           setPurchaseDate(purchaseDate);
  //           let branch=branchForSearch?.find((item)=>item.id === res.data.stock.branch_id)            
  //           setNewOpeningStock({
  //             ...newOpeningStock,
  //             invoice: res.data.stock.document_identity,
  //             branch,
  //             remarks: res.data.stock.remarks,
  //             total_amount: res.data.stock.total_amount
  //           })
  //           let newArr=[];
  //           if(res.data.stockDetails && res.data.stockDetails.length>0){
  //             res.data.stockDetails.map((item)=>{
  //               newArr.push({
  //               ingredient_name:item.item_name,
  //               qty:parseFloat(item.qty).toFixed(0),
  //               rate:parseFloat(item.rate).toFixed(2),
  //               unit_name:item.unit_name})
  //             })
  //             setNewRow([...newArr])
  //           }
  //           handleOnlyPrint();
  //         }
          
  //       } catch(error) {
  //         console.log("error==>",error)
  //         toast.success(`${_t(t("Please try again"))}`, toastObj);
  //       }
  // }

  const handlePostApi = async(id) =>{
    const url = BASE_URL + "/settings/requistion-post-status";
    const formData = {
      id: id,
      is_post: 1
    }
    await axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
    .then((res) => {
      setStoreRequisitionList(res.data[0]);
      setStoreRequisitionForSearch(res.data[1]);
      setSearchedStoreRequisition({
        ...searchedStoreRequisition,
        list: res.data[1],
      });
      setLoading(false);
      toast.success(`${_t(t("Stock requisition has been post"))}`, toastObj);  
    }).catch((error) =>{
      setLoading(false);
      if(error.response.data?.message){
        toast.error(`${_t(t(error.response.data.message))}`, toastObj);
        return
      }
      toast.error(`${_t(t("Please try again"))}`, toastObj);  
    })
  }
  
  return (
    <>
      <Helmet>
        <title>{_t(t("Store Requisition"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedStoreRequisition.searched
                                    ? _t(t("Store Requisition List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/stock/store-requisition-add"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Document Number"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Document Date"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("From Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("To Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Remarks"))}
                                </th>

                                {/* <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Total"))}
                                </th> */}

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedStoreRequisition.searched
                                ? [
                                  storeRequisitionList && [
                                      storeRequisitionList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                          storeRequisitionList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (storeRequisitionList.current_page -
                                                    1) *
                                                    storeRequisitionList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.document_identity}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.document_date}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item?.from_branch?.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item?.to_branch?.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                {item.remarks}
                                              </td>

                                              {/* <td className="xsm-text align-middle text-center">
                                                {parseFloat(item.total_amount).toFixed(2)}
                                              </td> */}

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="d-flex justify-content-center">                                                    
                                                {/* <NavLink
                                                className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                to={`/dashboard/manage/stock/store-requisition-edit/${item.id}`}                                                       
                                                >
                                                <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                </NavLink>
                                                <button
                                                className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                onClick={()=>handleDeleteConfirmation(item.id)}
                                                >
                                                  <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                  // onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print"></i>
                                                </button> */}
                                                {
                                                  item?.is_post === 0 ? (
                                                    <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post requisition" 
                                                      style={{padding:"1px 0px 0px 1px"}}                                               
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button>
                                                  ) : ""
                                                }
                                                <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/store-requisition-edit/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className={`${item?.is_post ? "fa fa-eye": "fa fa-pencil"} edit-icon`} aria-hidden="true"></i>
                                                  </div>
                                                </NavLink>
                                                {/* <button
                                                className="btn btn-danger ml-2 rounded-sm del-btn"
                                                onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                >                                                    
                                                  <MdDelete className="del-icon" />                                                    
                                                </button> */}
                                                {/* <button
                                                className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print edit-icon"></i>
                                                </button> */}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedStoreRequisition && [
                                      searchedStoreRequisition.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedStoreRequisition.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (storeRequisitionList.current_page -
                                                      1) *
                                                      storeRequisitionList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.document_date}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item?.from_branch?.name}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item?.to_branch?.name}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.remarks}
                                                </td>

                                                {/* <td className="xsm-text align-middle text-center">
                                                  {parseFloat(item.total_amount).toFixed(2)}
                                                </td> */}

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="d-flex justify-content-center">                                                    
                                                {/* <NavLink
                                                className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                to={`/dashboard/manage/stock/opening-stock-edit/${item.id}`}                                                       
                                                >
                                                <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                </NavLink>
                                                <button
                                                className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                onClick={()=>handleDeleteConfirmation(item.id)}
                                                >
                                                  <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                  // onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print"></i>
                                                </button> */}
                                                {
                                                  item?.is_post === 0 ? (
                                                    <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post requisition" 
                                                      style={{padding:"1px 0px 0px 1px"}}                                               
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button>
                                                  ) : ""
                                                }
                                                <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/store-requisition-edit/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className={`${item?.is_post ? "fa fa-eye": "fa fa-pencil"} edit-icon`} aria-hidden="true"></i>
                                                  </div>
                                                </NavLink>
                                                {/* <button
                                                className="btn btn-danger ml-2 rounded-sm del-btn"
                                                onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                >                                                    
                                                  <MdDelete className="del-icon" />                                                    
                                                </button> */}
                                                {/* <button
                                                className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print edit-icon"></i>
                                                </button> */}
                                                </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedStoreRequisition.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(storeRequisitionList, setPaginatedStoreRequisition)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(storeRequisitionList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedStoreRequisition({
                                      ...searchedStoreRequisition,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedStoreRequisition,
                                    storeRequisitionForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
      {/* <StockReport
      name = "Opening Stock"
      purchaseDate={purchaseDate}
      newItem={newOpeningStock}
      newRow={newRow}
      invoiceNo = {"Document No"}
      inoviceDate = {"Document Date"}
      handleGetTotal={""}
      handleAmount={""}
      handleTaxAmount={""} 
      handleDisAmount={""}
      handleGetSubTotal={""}
      handleGetAmount={handleGetAmount}
      stockPrint={componentRef}
      />  */}
    </>
  );
}

export default StoreRequisition;