import React, { useEffect, useState } from 'react';
import { TiArrowSortedUp } from "react-icons/ti";
import { BASE_URL } from "../../../../../BaseUrl";
import { TiArrowSortedDown } from "react-icons/ti";

const parseData = (data) => {
  return data
}

const CardsWidget = ({ data, heading, previous }) => {
  const [ cards, setCards ] = useState([]);
  const [ previousCards, setPreviousCards ] = useState([])

  useEffect(() => {
    data && setCards(parseData(data?.slice(5,11)));
    previous && setPreviousCards(previous.slice(5,11))
  }, [data,previous]);
  
  return (
    <div className="col col-12 row mx-0 mt-3 px-1">
    {heading
      ? <h4 className="mb-1">{heading}</h4>
      : ''
    }
    <div className='row mx-0 px-0 flex-wrap dashboard__card-container'>
      {cards.map((card,index) => (
        <div key={card.name} className='col-2 p-0' >
         <div className='col p-0 flex-nowrap dashboard__card_view p-2' style={{margin:"0 0.4rem"}} >
          <div className='col m-0 p-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='text-capitalize print-text mb-0'>{card.name}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-block'>
              <p className='font-weight-bold xslg-text mb-0'>{card.value?.toLocaleString()}</p>
               {
                 previous && (card.name?.toLowerCase() === "net sale" || card.name?.toLowerCase() === "total") && (
                  <div className='print-text'>
                   {card.value > previousCards[index]?.value ? <span className='text-primary dashboard_percent'><TiArrowSortedUp className = "dashboard_card_up"/> {previousCards[index]?.value > 0 ? (((card.value - previousCards[index]?.value)/previousCards[index]?.value)*100).toFixed(1) : 0 }% <span>{previousCards[index]?.value}</span>  </span> : <span className='text-danger dashboard_percent'><TiArrowSortedDown className="dashboard_card_down"/>  {card.value > 0 ? (100 - ((card.value/previousCards[index]?.value)*100)).toFixed(1) : 0 }% <span>{previousCards[index]?.value}</span> </span>}
                  </div>
                  )
                }
              </div>
               <span className="ml-1 mr-0"><img style={{height:"2rem"}} src={BASE_URL + card.image} alt=""/></span>
            </div>
            {/*
              previous && (
                <>
                <div className="myBorderDashboardBottom border-1 my-1"></div>
                <p className='text-capitalize print-text mb-0'>Comparison</p>
                <p className='font-weight-bold xslg-text mb-0'>{previousCards[index]?.value?.toLocaleString()}</p>
                </>
              )
              */}
          </div>
          {card.image
            ? (
              <div className='col m-0 ml-2 p-0 flex-grow-0 d-flex justify-content-center align-items-center'>
                {<img
                  height={20}
                  className='img-fluid'
                  src={card.image}
                  alt=""
                />}
              </div>
            )
            : ''
          }
          </div>
        </div>
      ))}
     </div>
    </div>
  )
}

export default CardsWidget