import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
const StockTransferView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      branchListWeb,
      ingredientItemForSearch,
      dataPaginating,
    } = useContext(RestaurantContext);
    // let { getSupplier, supplierForSearch } = useContext(UserContext);
    let {
      //stock transfer
      authUserInfo,
    } = useContext(UserContext);
  
    let [newStockTransfer, setNewStockTransfer] = useState({
      transfer_no: "",    
      transfer_date: new Date(),
      branch: null,
      remarks: '',
    });
    //new Row
    const [newRow,setNewRow]=useState([]);
  
    //select style
    
  const customStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: "2rem",
      marginTop: "0",
      marginBottom: "0",
    }),
    placeholder:(provided) => ({
      ...provided,
      marginTop: "0",
      padding: "0 0.4rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      alignSelf: "center",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginTop: "0",
      padding: "0 0.4rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 0.4rem",
      svg: {
        height: "2rem",
      },
    }),
  };
    //print bills
    const componentRef = useRef();
  
    const [editStock, setEditStock]=useState([]);
    const getSpecificStock = async () => {
        setLoading(true);
        const url = BASE_URL + "/settings/stockTransfer/" + parseInt(params.id);
        try {
        const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            console.log("check spec==>",res.data);
            setEditStock(res.data)
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
    }
    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {
      getSpecificStock()
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
    }, []);

    useEffect(() => {
      if(editStock && editStock !== null && branchListWeb){
        let docDate=new Date(editStock?.stock?.transfer_date);
        let branch=branchForSearch?.find((item)=>editStock?.stock?.from_branch_id === item.id)
        setNewStockTransfer({
          ...newStockTransfer,
          transfer_no:editStock?.stock?.transfer_no,
          transfer_date:docDate,
          branch,
          remarks:editStock?.stock?.remarks,
        })
        
        let rowArr=[];
        if(editStock.stockDetails && editStock.stockDetails.length > 0){
          editStock.stockDetails.map((item,index)=>{
            let ing_item= ingredientItemForSearch?.find((v)=>v.id===item.item_id);
            let toBranch= branchListWeb?.find((v)=>v.id===item.to_branch_id);
            // if(ing_item){
            //   getUnits(ing_item.id,index)
            // }
            rowArr.push({
            //   id: "",
              item_name:ing_item,
              qty:item.qty,
              unit_id:item.unit_id,
              unit:item.unit,
              rate:item.price,
              amount:item.amount,
              base_unit:item.base_unit,
              base_qty:item.base_qty,
              conversion_qty:item.conversion_qty,
              stock_qty:item.stock_qty,
              branch:toBranch,
                  additional_price: item.additional_price,              
            })
            // rowId.current += 1
          })
          setNewRow([...rowArr]);
        }
      }
    }, [editStock,branchForSearch, branchListWeb]);
  
    const calBaseQty=(index)=>{
      let qty=newRow[index].qty;
      let conversion_qty=newRow[index].conversion_qty
      if(qty && conversion_qty){
        return qty * conversion_qty;
      }else{
        return "";
      }
    }
  
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    return (
      <>
        <Helmet>
          <title>{_t(t("View Stock Transfer"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                      {/* spin loading ends */}
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("View Stock Transfer"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            // onSubmit={handleSubmit}
                          >
                            <div className="col-12">
                            <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="transfer no"
                                      className="control-label"
                                    >
                                      {_t(t("Transfer no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="transfer_no"
                                      name="transfer_no"                                     
                                      placeholder="Auto no"
                                      required
                                      value={newStockTransfer.transfer_no}
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Transfer Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={newStockTransfer.transfer_date}
                                    //   onChange={(e) => handleTransferDate(e)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Transfer date"))}
                                      shouldCloseOnSelect={false}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                  
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchSearch ? branchSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newStockTransfer.branch || null}
                                      classNamePrefix="select"
                                    //   onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                      isDisabled={true}
                                    />
                                </div> 
                              </div>
  
                              <div className="row">                                                            
                                <div className="form-group mt-2 col-12">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                    //   onChange={handleRemarks}
                                      value={newStockTransfer.remarks || ""}
                                      placeholder="Remarks"
                                      style={{height:"33px"}}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>                            
                              
                                <div className={`table-responsive mt-3 pr-1 `}
                                style={{maxHeight:"23rem",overflow: "auto"}}>
                                    <table className="table table-bordered-sm table-hover">
                                      <thead className="align-middle">                                    
                                        <tr>
                                        {/* {newRow.length==0 ?<th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                            <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>
                                        </th>:<th style={{borderBottomColor:"lightgray"}}></th>} */}
                                          <th
                                            scope="col"
                                            className="d-none table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth:"60px"}}
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Code"))}
                                            </span>
                                          </th>                                     
                                          
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border table-child"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Name"))}
                                            </span>
                                          </th>
  
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("To Branch"))}
                                            </span>
                                          </th>
  
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Unit"))}
                                            </span>
                                          </th>
  
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Qty"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth:'130px'}}
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Stock Qty"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Price"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Amount"))}
                                            </span>
                                          </th>
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Add. Price"))}
                                            </span>
                                          </th>
  
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Base unit"))}
                                            </span>
                                          </th>
                                          
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Base qty"))}
                                            </span>
                                          </th>
                                          
                                          <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth:"9rem"}}
                                          >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Conversion qty"))}
                                            </span>
                                          </th>                                                                                                                                                             
                                          {/* {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>} */}
                                        </tr>
                                      </thead>
                                        {newRow && newRow.length>0 ?
                                      <tbody className="align-middle">
                                        {newRow.map(
                                          (stockItem, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                {/* <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                            {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>}&nbsp;
                                            <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                            onClick={()=>{delRow(stockItem.id)}}
                                            ></i>
                                            </td> */}
                                                <td className="d-none table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>                                                
                                                  {stockItem.code ? stockItem.code : "-"}
                                                </td>                                            
  
                                                <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"230px"}}>                                                  
                                                  <Select
                                                    options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                    components={makeAnimated()}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    classNamePrefix="select"
                                                  //   onChange={(e)=>handleSetItems(index,e)}
                                                    maxMenuHeight="12.8rem"
                                                    // menuPlacement="top"
                                                    value={stockItem.item_name}
                                                    styles={customStyle}
                                                    isMenuOpen={true}
                                                    menuPosition="fixed"
                                                    className="bank-table-input"
                                                    isDisabled={true}
                                                    placeholder={
                                                    _t(t("Please select items")) + ".."
                                                    }                                                
                                                  />
                                                </td>
  
                                                <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"240px"}}>
                                                  {/* {ingredientItem.unit} */}                                                
                                                  <Select
                                                    options={branchListWeb && branchListWeb.filter((item)=>item.id !== newStockTransfer.branch.id)}
                                                    components={makeAnimated()}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.name}
                                                    value={stockItem.branch}
                                                    className="bank-table-input"
                                                    classNamePrefix="select"
                                                    maxMenuHeight="200px"
                                                    styles={customStyle}
                                                    placeholder={
                                                    _t(t("Please select a branch")) + ".."
                                                    }
                                                    isMenuOpen={true}
                                                    menuPosition="fixed"
                                                    isDisabled={true}
                                                  />
                                                </td>
                                                <td className="table-text text-capitalize align-middle text-center">
                                                 {/* <Select
                                                    menuPosition="fixed"
                                                    options={stockItem.item_units ? stockItem.item_units : []}
                                                    components={makeAnimated()}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    value={stockItem.unit}
                                                    classNamePrefix="select"
                                                    // onChange={(e)=>handleSetUnit(index,e)}
                                                    className="bank-table-input"
                                                    maxMenuHeight="200px"
                                                    styles={customStyle}
                                                    // menuPlacement="top"
                                                    placeholder={
                                                    _t(t("please select unit")) + ".."
                                                    }      
                                                    isDisabled={true}                                             
                                                  />                                                   */}
                                                  {stockItem.unit?.name}
                                                </td>
                                                <td className="table-text align-middle text-center">
                                                  <input
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    onKeyDown={(evt) =>
                                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                    onWheel={(e) => e.target.blur()}
                                                    className={`form-control text-center table-Input table-text`}
                                                    id={stockItem.id}
                                                    name={stockItem.id}                                                    
                                                    value={                                                      
                                                      stockItem.qty || ""
                                                    }
                                                    required
                                                    placeholder="Enter qty"
                                                    readOnly
                                                  />
                                                </td>
  
                                                <td className="table-text text-capitalize align-middle text-center">                                                  
                                                  {stockItem.stock_qty}
                                                </td>
  
                                                <td className="table-text text-capitalize align-middle text-center">                                                
                                                  {stockItem.rate} 
                                                </td>
  
                                                <td className="table-text text-capitalize align-middle text-center">                                                  
                                                  {/* {
                                                  (stockItem.price && stockItem.qty)
                                                  ? !isNaN(stockItem.price * stockItem.qty) && stockItem.price * stockItem.qty
                                                  : stockItem.unit ? "0" : "-"
                                                  } */}
                                                  {stockItem.amount}
                                                </td>

                                                <td className="table-text text-capitalize align-middle text-center">                                                  
                                                  {stockItem.additional_price}
                                                </td>
  
                                                {/* hidden columns */}
                                                
                                                <td className="table-text align-middle text-center">
                                                  {stockItem.base_unit ? stockItem.base_unit : "-"}
                                                </td> 
                                                
                                                <td className="table-text align-middle text-center">
                                                  {/* {calBaseQty(index)} */}
                                                  {formatPrice(calBaseQty(index))}
                                                </td>
                                                
                                                <td className="table-text align-middle text-center">
                                                  {stockItem.conversion_qty ? stockItem.conversion_qty : "-"}
                                                </td>                                                 
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                      :null}                                
                                    </table>
                                </div>
                                
                              <div className="form-group mt-4 pb-2">
                                {/* <div className="col-lg-12">                                  
                                  <button
                                    className="btn btn-primary px-5 mx-2 rounded"
                                    onClick={() =>
                                      history.push(
                                        "/dashboard/manage/stock/stock-transfer"
                                      )
                                    }
                                  >
                                    {_t(t("Back"))}
                                  </button>
                                </div> */}
                                <div className="d-flex justify-content-end align-items-center">
                                  <div className="col-3 d-flex justify-content-end">
                                    <button
                                    type="button"
                                    className="btn rounded hov-btn mr-3"
                                    onClick={()=>(history.push("/dashboard/manage/stock/stock-transfer"))}
                                    //   data-dismiss="modal"
                                    >
                                      <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                    </button>                                                                                   
                                  </div>                                                    
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
       {/* <StockReport
       name = "Purchase Invoice"
       purchaseDate={purchaseDate}
       newItem={newItem}
       newRow={newRow}
      //  qtyOfItem={qtyOfItem}
      //  rateOfItem={rateOfItem}
       handleGetTotal={handleGetTotal}
       handleAmount={handleAmount}
       handleTaxAmount={handleTaxAmount}
       handleDisAmount={handleDisAmount}
       handleGetSubTotal={handleGetSubTotal}
       handleGetAmount={handleGetAmount}
       stockPrint={componentRef}
       /> */} 
      </>
    );
}

export default StockTransferView;