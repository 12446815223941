import React, { useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
//pages & includes
import AccountsSidebar from "../AccountsSidebar";
import AccountPrint from "./AccountPrint";
import ViewAccountSheet from "./ViewAccountSheet";
//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { MdDelete } from 'react-icons/md';

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";


//context consumer
import { FinanceContext } from "../../../../../contexts/Finance";

const CashPayment = () => {
  const { t } = useTranslation();

    //finance context
    const {
      loading,
      setLoading,    

      //CPList
      cashPaymentList,
      setCashPaymentList,
      cashPaymentListForSearch,
      setCashPaymentListForSearch,
      setPaginatedCPList,

      //pagination
      dataPaginating,
      setDataPaginating,
    } = useContext(FinanceContext);
    
    //search result
    let [searchedCPList, setSearchedCPList] = useState({
      list: null,
      searched: false,
    });
    const componentRef = useRef("");
     //print satate
     const [bankPayment,setBankPayment]=useState({
      doc_date:new Date(),
      transactionAccount:"",
      doc_no: null,
      supplierName:"",
      remarks:""
    });
    const [accountLedger,setAccountLedger]=useState({
      modal:false,
      ledger:null,
    });

    //Totals of rows
    const [totalRowAmount,setTotalRowAmount]=useState(0);
    const [totalRowWhtAmount,setTotalRowWhtAmount]=useState(0);
    const [totalRowTaxAmount,setTotalRowTaxAmount]=useState(0);
    const [totalRowNetAmount,setTotalRowNetAmount]=useState(0);
    const [newRow,setNewRow]=useState([]);
       
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
        @page {
          size: landscape;
          margin: 15px;
        }
        body {
          -webkit-print-color-adjust: exact;
        }
      `
    });

    // print api
    const handlePrint = async(item) =>{
      setDataPaginating(true);
        const url = BASE_URL + "/settings/cash-payment-print/" + item.id;
        try {
          const res = await axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          const dateFormat = new Date(res.data.document_date);
          setNewRow(res.data.Details)
          setBankPayment({
            doc_date: dateFormat,
            doc_no: res.data.document_identity,
            transactionAccount: res.data.transaction_accounts,
            remarks: res.data.remarks
          })
          setTotalRowAmount(res.data.total_amount)
          setTotalRowNetAmount(res.data.total_net_amount);
          setTotalRowTaxAmount(res.data.total_other_tax_amount)
          setTotalRowWhtAmount(res.data.total_wht_amount)
          setTimeout(() => {
            handleOnlyPrint()
          }, 200);
          setDataPaginating(false)
        //   setDataToLocal(url, res);
        } catch (error) {
          setDataPaginating(false)
            console.log("error==>",error)
        }
      
    }

    const handleViewLedger = async(id) =>{
      setDataPaginating(true);
      const url = BASE_URL + "/settings/check-financial-ledger-view/" + id + "/CP";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        if(res.data){
          setAccountLedger({
            modal: true,
            ledger: res.data
          })
        }
        setDataPaginating(false)
      } catch (error) {
        setDataPaginating(false)
      }
    }
  
    //delete account here
    const handleDeleteAccount = (slug) => {
      setDataPaginating(true);
      const bplUrl = BASE_URL + `/settings/delete-cash-payment/${slug}`;
      return axios
        .get(bplUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setCashPaymentList(res.data[0]);
          setCashPaymentListForSearch(res.data[1]);
          setSearchedCPList({
            ...searchedCPList,
            list: res.data[1],
          });
          setDataPaginating(false);
          toast.success(`${_t(t("Account has been deleted successfully"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        })
        .catch(() => {
          setDataPaginating(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    };

    //search account here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedCPList({ ...searchedCPList, searched: false });
      } else {
        let searchedList = cashPaymentListForSearch.filter((item) => {          
          let lowerCaseItemIdentity = item.document_identity.toLowerCase();
          let lowerCaseItemTotal = item.total_net_amount.toLowerCase();
          let lowerCaseItemDate = moment(item.document_date).format("LL").toLowerCase();
          return lowerCaseItemIdentity.includes(searchInput) ||
          lowerCaseItemTotal.includes(searchInput) ||
          lowerCaseItemDate.includes(searchInput);
        });
        setSearchedCPList({
          ...searchedCPList,
          list: searchedList,
          searched: true,
        });
      }
    };
  
    //delete confirmation modal of account
    const handleDeleteConfirmation = (slug) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to delete this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary rounded"
                  onClick={() => {
                    handleDeleteAccount(slug);
                    onClose();
                  }}
                >
                  {_t(t("Yes, delete it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };

    //post api
    const handlePostApi = async(id) =>{
      setLoading(true);
      const url = BASE_URL + "/settings/mark-is-post-cash-payment"
      let formData = new FormData();
      formData.append('id',id)
      formData.append('is_post',1)
      try {
        const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        if(res.data){
          toast.success(`${_t(t("Cash payment has been post"))}`,{
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        setLoading(false);
      } catch {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`,{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Cash Payments"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <AccountsSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-table-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="fragment3">
                          {/* next page data spin loading */}
                          <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          {/* spin loading ends */}
  
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!searchedCPList.searched
                                      ? _t(t("Cash Payment List"))
                                      : _t(t("Search Result"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center">
                                {/* Search group */}
                                <div className="col-md-9 t-mb-15 mb-md-0">
                                  <div className="input-group">
                                    <div className="form-file">
                                      <input
                                        type="text"
                                        className="form-control border-0 form-control--light-1 rounded-left"
                                        placeholder={_t(t("Search")) + ".."}
                                        onChange={handleSearch}
                                      />
                                    </div>
                                    <button
                                      className="btn btn-primary rounded-right"
                                      type="button"
                                    >
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
  
                                {/* Add group modal trigger button */}
                                <div className="col-md-3 text-md-right">                                  
                                  <NavLink
                                    to="/dashboard/account/transaction/cash-payment-add"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  >
                                    {_t(t("add new"))}
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Table */}
                          <div className="table-responsive">
                            <table className="table table-bordered table-hover min-table-height">
                              <thead className="align-middle">
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document No"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Date"))}
                                  </th>                                  

                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Action"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                {!searchedCPList.searched
                                  ? [
                                    cashPaymentList && [
                                      cashPaymentList.data.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="6"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          cashPaymentList.data.map((item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (cashPaymentList.current_page -
                                                      1) *
                                                      cashPaymentList.per_page}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <Moment format="LL">
                                                    {item.document_date}
                                                  </Moment>
                                                </td>
                                                  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.total_net_amount}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  <div className="d-flex justify-content-center"> 
                                                    {item.is_post !== 1 && ( 
                                                      <>                                                 
                                                      <NavLink
                                                        className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                        to={`/dashboard/account/transaction/cash-payment-edit/${item.id}`}
                                                        style={{height:"1.7rem",width:"1.7rem"}}                                                        
                                                      >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                          <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                        </div>
                                                      </NavLink>
                                                      <button
                                                        className="btn btn-danger ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                      >                                                    
                                                        <MdDelete className="del-icon" />                                                    
                                                      </button> 
                                                      </>  
                                                    )}
                                                    <button
                                                      className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                      onClick={()=>handlePrint(item)}
                                                    >
                                                      <i className="fa fa-print edit-icon"></i>
                                                    </button>
                                                    {(item.is_post !== 1 ? <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post goods" 
                                                      style={{padding:"1px 0px 0px 1px"}} 
                                                      disabled={loading}                                              
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button> : null)}
                                                    {
                                                      item.is_post === 1 && (
                                                      <button
                                                        className="btn btn-success ml-2 rounded-sm del-btn"
                                                        onClick={() => handleViewLedger(item.id)}
                                                      >
                                                        <i className="fa fa-eye"></i>
                                                      </button>
                                                      )
                                                    }
                                                  </div>
                                                </td>                                                
                                              </tr>
                                            );
                                          })
                                        ),
                                      ],
                                    ]
                                  : [
                                      /* searched data, logic === haveData*/
                                      searchedCPList && [
                                        searchedCPList.list.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="6"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          searchedCPList.list.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                  className="align-middle"
                                                  key={index}
                                                >
                                                  <th
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {index +
                                                    1 +
                                                    (cashPaymentList.current_page -
                                                      1) *
                                                      cashPaymentList.per_page}
                                                  </th>
  
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <Moment format="LL">
                                                    {item.document_date}
                                                  </Moment>
                                                </td>
                                                  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.total_net_amount}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  <div className="d-flex justify-content-center">
                                                    {item.is_post !== 1 && (
                                                      <>                                                   
                                                      <NavLink
                                                        className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                        to={`/dashboard/account/transaction/cash-payment-edit/${item.id}`}
                                                        style={{height:"1.7rem",width:"1.7rem"}}                                                        
                                                      >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                          <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                        </div>
                                                      </NavLink>
                                                      <button
                                                        className="btn btn-danger ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                      >                                                    
                                                        <MdDelete className="del-icon" />                                                    
                                                      </button> 
                                                      </>    
                                                    )
                                                   }
                                                    <button
                                                      className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                      onClick={()=>handlePrint(item)}
                                                    >
                                                      <i className="fa fa-print edit-icon"></i>
                                                    </button>
                                                    {(item.is_post !== 1 ? <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post goods" 
                                                      style={{padding:"1px 0px 0px 1px"}} 
                                                      disabled={loading}                                              
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button> : null)}
                                                    {
                                                      item.is_post === 1 && (
                                                      <button
                                                        className="btn btn-success ml-2 rounded-sm del-btn"
                                                        onClick={() => handleViewLedger(item.id)}
                                                      >
                                                        <i className="fa fa-eye"></i>
                                                      </button>
                                                      )
                                                    }
                                                  </div>
                                                </td> 
                                                </tr>
                                              );
                                            }
                                          )
                                        ),
                                      ],
                                    ]}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
  
                {/* pagination loading effect */}
                { loading === true
                  ? paginationLoading()
                  : [
                      // logic === !searched
                      !searchedCPList.searched ? (
                        <div key="fragment4">
                          <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                            <div className="row align-items-center t-pl-15 t-pr-15">
                              <div className="col-md-7 t-mb-15 mb-md-0">
                                {/* pagination function */}
                                {pagination(cashPaymentList, setPaginatedCPList)}
                              </div>
                              <div className="col-md-5">
                                <ul className="t-list d-flex justify-content-md-end align-items-center">
                                  <li className="t-list__item">
                                    <span className="d-inline-block sm-text">
                                      {showingData(cashPaymentList)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // if searched
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              <ul className="t-list d-flex">
                                <li className="t-list__item no-pagination-style">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      setSearchedCPList({
                                        ...searchedCPList,
                                        searched: false,
                                      })
                                    }
                                  >
                                    {_t(t("Clear Search"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {searchedShowingData(
                                      searchedCPList,
                                      cashPaymentListForSearch
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ),
                    ]}
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
        <AccountPrint
          name = "Cash Payment"
          newRow={newRow}
          bankPayment={bankPayment}
          totalRowAmount={totalRowAmount}
          totalRowWhtAmount={totalRowWhtAmount}
          totalRowTaxAmount={totalRowTaxAmount}
          totalRowNetAmount={totalRowNetAmount}
          AccountPrint={componentRef}
        />
        <ViewAccountSheet
          accountLedger={accountLedger}
          setAccountLedger= {setAccountLedger}
        />
      </>
  )
}

export default CashPayment