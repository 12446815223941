import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { HuePicker } from "react-color";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const KdsSettingAdd = () => {
  const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,
      ingredientItemForSearch,

      //KDS Setting
      kdsList,
      setKdsList,
      kdsListForSearch,
      setKdsListForSearch,
      // getKdsList,
      // setPaginatedKds,
    } = useContext(RestaurantContext);

    const toastObj = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    };

    const [selectedColor1, setSelectedColor1] = useState({time:"",color:'#767ddb'});
    const [selectedColor2, setSelectedColor2] = useState({time:"",color:'#767ddb'});
    const [selectedColor3, setSelectedColor3] = useState({time:"",color:'#767ddb'});

    // const [edit,setEdit] = useState(false)

    //type state
    const [alltypes,setAllTypes]=useState([
      {id:1, name:"Kitchen"},
      {id:2, name:"Dine in"},
      {id:3, name:"Takeaway"}
    ]);
    const [type,setType]=useState(null);
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight : '2rem',
        marginTop:"0",
        marginBottom:"0",
      
      }),
      
      singleValue: (provided) =>({
        ...provided,
        alignSelf: "center",
      }),
      input:(provided)=>({
        ...provided,
        margin:"0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop:"0",
        padding:"0 0.5rem",
        
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding:"0 0.4rem",
        "svg": {
          height: '2rem',
        }
      }),
       
    }
      
    const handleType = (type) => {
      setType(type)
    }
  
    //post req of food item add
    const KitchenAxios = async () => {
      setLoading(true)
      
      const url = BASE_URL + "/settings/kdssettings";
      let formData={
        name: type?.name,
        time_1: selectedColor1.time,
        time_color_1: selectedColor1.color,
        time_2: selectedColor2.time,
        time_color_2: selectedColor2.color,        
        // time_color_3: '#fc0303',
        status:1
      }      
      try {
        const res = await axios
          .post(url,formData ,{
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setKdsList(res.data)
          setKdsListForSearch(res.data)
          toast.success(`${_t(t("KDS setting has been added"))}`, toastObj);          
          setLoading(false);
          setType(null);                  
          history.push('/dashboard/manage/restaurant/kds-setting')
      } catch (error) {
        setLoading(false);
        if(error.response.status == '422'){
          toast.error(`${_t(t(error.response.data.errors.name[0]))}`, toastObj);
        }else{
          toast.error(`${_t(t("Please try again"))}`, toastObj);
        }        
      }
    };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if(type !== null && selectedColor1.time !== "" && selectedColor2.time !== ""){
        if(parseInt(selectedColor1.time) < parseInt(selectedColor2.time)){
          KitchenAxios();
        }else{
          toast.error(`${_t(t("New order time can't be greater or equal to caution order time"))}`, toastObj);  
        }
      }else{
        toast.error(`${_t(t("Please Select All Fields"))}`, toastObj);
      }
    };    
  
    return (
      <>
      <Helmet>
        <title>{_t(t("Add KDS Setting"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add KDS Setting"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <div className="row">                             
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2 ">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Display Name"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <Select
                                    options={alltypes}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={type}
                                    classNamePrefix="select table-text"
                                    onChange={handleType}
                                    //maxMenuHeight="50px"
                                    placeholder={
                                        _t(t("Please select a kitchen ")) + ".."
                                    }
                                    styles={customStyle}
                                    required
                                    isClearable
                                  />
                                </div>
                              </div>                                                                                         
                          </div>

                          <div className="row">                             
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Initial Alert Duration"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2 d-flex">                                    
                                  <input
                                    type="text"
                                    className="form-control table-text"
                                    id="time"
                                    name="time"
                                    onChange={(e)=>{                                                                                
                                      setSelectedColor1({...selectedColor1, time: e.target.value});
                                    }}
                                    value={selectedColor1.time}
                                    placeholder="Eg: 1,2,3"
                                    style={{height:"33px",width:"60%"}}
                                    step="0.1"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />

                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text ml-2"
                                    id="time"
                                    name="time"                                      
                                    value={"minutes ago"}                                      
                                    style={{height:"33px",width:'38%'}}                                       
                                  />                                     
                                </div>
                              </div>

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Initial Alert Color"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">
                                  <HuePicker 
                                    color={selectedColor1.color}
                                    onChangeComplete={(color)=>setSelectedColor1({...selectedColor1,color:color.hex})}
                                    style={{ height: '200px' }}
                                    width={'100%'}
                                  />                                    
                                </div>
                              </div>

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Selected Color"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">                                    
                                  <div style={{ backgroundColor: selectedColor1.color, width: '120px', height: '15px' }}></div>
                                </div>
                              </div>                                                                                          
                          </div>

                          <div className="row">                             
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Follow-Up Alert Duration"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2 d-flex">                                    
                                  <input
                                    type="text"
                                    className="form-control table-text"
                                    id="time"
                                    name="time"
                                    onChange={(e)=>{                                        
                                      setSelectedColor2({...selectedColor1,time:e.target.value})
                                    }}
                                    value={selectedColor2.time}
                                    placeholder="Eg: 1,2,3"
                                    style={{height:"33px",width:"60%"}}
                                    step="0.1"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />

                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text ml-2"
                                    id="time"
                                    name="time"                                      
                                    value={"minutes ago"}                                      
                                    style={{height:"33px",width:'38%'}}                                       
                                  />                                     
                                </div>
                              </div>

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Follow-Up Alert Color"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">
                                  <HuePicker 
                                    color={selectedColor2.color}
                                    onChangeComplete={(color)=>setSelectedColor2({...selectedColor2,color:color.hex})}
                                    style={{ height: '200px' }}
                                    width={'100%'}
                                  />                                    
                                </div>                                 
                              </div>

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Selected Color"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">                                    
                                  <div style={{ backgroundColor: selectedColor2.color, width: '120px', height: '15px' }}></div>
                                </div>
                              </div>                                                                                           
                          </div>

                          <div className="row">                             
                              {/* <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Time For Late Order"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2 d-flex">                                    
                                    <input
                                      type="text"
                                      className="form-control table-text"
                                      id="time"
                                      name="time"
                                      onChange={(e)=>setSelectedColor3({...selectedColor3,time:e.target.value})}
                                      value={selectedColor3.time}
                                      placeholder="Eg: 1,2,3"
                                      style={{height:"33px",width:"60%"}}
                                      step="0.1"
                                      min="0"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                        evt.preventDefault()
                                      }
                                      onWheel={(e) => e.target.blur()}
                                    />

                                    <input
                                      type="text"
                                      className="form-control table-text ml-2"
                                      id="time"
                                      name="time"                                      
                                      value={"minutes ago"}                                      
                                      style={{height:"33px",width:'38%'}}                                       
                                    />                                     
                                </div>
                              </div> */}

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Final Call Alert"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">                                    
                                  <div style={{ backgroundColor: '#fc0303', width: '100%', height: '15px' }}></div>
                                </div>
                                {/* <div className="mt-3">
                                    <HuePicker 
                                       color={selectedColor3.color}
                                       onChangeComplete={(color)=>setSelectedColor3({...selectedColor3,color:color.hex})}
                                       style={{ height: '200px' }}
                                       width={'100%'}                                       
                                    />                                    
                                </div>                                 */}
                              </div> 

                              {/* <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Selected Color"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mt-3">                                    
                                  <div style={{ backgroundColor: selectedColor3.color, width: '120px', height: '15px' }}></div>
                                </div>
                              </div>                                                                                           */}
                          </div>   

                            <div className="form-group mt-4 pb-2">
                              <div className="d-flex justify-content-end align-items-center">
                              <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/restaurant/kds-setting"))}                                  
                                >
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                              </div>                                                    
                          </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>        
    </>
    )
}

export default KdsSettingAdd;