import React, { useContext, useRef, useCallback } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../src/BaseUrl";
import { getFirebaseCookie, getFirebaseToken, getSystemSettings, getCookie, fetchResults, debouncePromise } from './Functions'
import {db} from '../Firebase';
import { toast } from "react-toastify";
import { SettingsContext } from "../contexts/Settings";
import { UserContext } from "../contexts/User";
import { FoodContext } from "../contexts/Food";
import { RestaurantContext } from "../contexts/Restaurant";
import { ref, off, onValue, query, set} from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

  const toastObj = {
    position: "bottom-center",
    backgroundColor:"#0a8d6e",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  // multiple re use functions for firabse etc.
  export const useCustomHooks = () => {
    const { generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    const { getSubmittedOrders, setOrderCounter, setBellRing, audioInstance } = useContext(RestaurantContext);
    const { getOnlineOrders,  getFoodItemsForPOS, getFoodItemsForCallCenter, getFoodPandaOrders } = useContext(FoodContext)
    const intervalRef = useRef(null);
    const onlineOrderQuery = useRef(null);
  
    // Wrap the fetchResults function with debounce
    const debouncedFetchResults = useCallback(debouncePromise((code, type, setLoading = null, id = null)=>fetchResults(code, type, setLoading, id), 1200), []);
    //firebase authentication debouncePromise
    const firebaseAuth = useCallback(async(url, callback)=>{
      const auth = getAuth();
      if(url){
        let cookie = getFirebaseCookie();
        if(!cookie){
          cookie = await getFirebaseToken(getSystemSettings(generalSettings, "firebase_collection"));
        }
        try {
          if(cookie){
            const userCredential = await signInWithCustomToken(auth, cookie);
            console.log("Abcd45",userCredential)
            if(userCredential?.user?.uid){
              onlineOrderQuery.current = query(ref(db,url));
              // Detach any previous listeners
              off(onlineOrderQuery.current)
              onValue(
                onlineOrderQuery.current,
                (snapshot) => {
                  const res = snapshot.val();
                  if(callback){
                    callback(null,res); // Resolve the promise with the result
                  }
                },
                (error) => {
                  if(callback){
                    callback(error,null); // Resolve the promise with the result
                  }
                }
              );
            }else{
              if(callback){
                callback(new Error("Authentication failed"), null);
              }
            }
          }
        } catch (error) {
          if (typeof callback === "function") callback(error, null);
        };
      }else{
        if (typeof callback === "function") callback(new Error("Url not found"), null);
      }
    },[generalSettings])

    const firebaseAuthSetData = useCallback(async(url, data)=>{
      // const auth = getAuth();
      if(url){
        // let cookie = getFirebaseCookie();
        // if(!cookie){
        //   cookie = await getFirebaseToken(getSystemSettings(generalSettings, "firebase_collection"));
        // }
        // try {
          // if(cookie){
          //   const userCredential = await signInWithCustomToken(auth, cookie);
          //   if(userCredential?.user?.uid){
              console.log("abcd5",data);
              try{
                const res = await set(ref(db, url), data);
                console.log("abcd5",res)
                return "Data written successfully!"
              }catch(err){
                return err
              }
        //     }else{
        //       return new Error("Authentication failed")
        //     }
        //   }
        // } catch (error) {
        //   return error
        // };
      }else{
        return new Error("Url not found")
      }
    },[generalSettings])
  
    //round off price
    const roundOffPrice = useCallback((price) =>{
      const roundOff = parseInt(getSystemSettings(generalSettings, "order_round_off"));
      if(roundOff > 0){
        return Number(Number(price).toFixed(roundOff))
      }else{
        return Math.round(price)
      }
    },[generalSettings])

    //round off price
    const roundFormatPrice = useCallback((price) =>{
      const roundOff = parseInt(getSystemSettings(generalSettings, "order_round_off"));
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      if (localCurrency !== null) {
        if(roundOff > 0){
          return (Number(localCurrency.rate * price).toFixed(roundOff))
        }else{
          return Math.round(localCurrency.rate * price)
        }
      }else{
        return 0
      }
    },[generalSettings]);

     //show price of each item in print
    const showPriceOfEachOrderItemPrint = useCallback((thisItem, taxType, branchTax) => {
      let price = 0;
      let tempPropertyPrice = 0;
      if (thisItem.properties !== null && thisItem.properties !== "") {
        let propertyItems = JSON.parse(thisItem.properties);
        propertyItems.forEach((propertyItem, thisIndex) => {
          let temp =
            propertyItem.quantity *
            propertyItem.price_per_qty *
            thisItem.quantity;
          tempPropertyPrice = tempPropertyPrice + temp;
        });
      }
      price = thisItem.price - tempPropertyPrice;
      if (taxType === "inclusive") {
        let finalPrice = price / (1 + branchTax / 100);
        return roundFormatPrice(finalPrice);
      }else{
        return roundFormatPrice(price);
      }
    },[]);

    // show property price
    const showPropertyPrice= useCallback((qty,propQty,price,taxType,branchTax)=>{
      let propPrice = 0;
      if (taxType === "inclusive") {
        propPrice = qty * propQty * price;
        let finalPrice = propPrice / (1 + branchTax / 100);
        return roundFormatPrice(finalPrice);
      }else{
        propPrice = qty * propQty * price;
        return roundFormatPrice(propPrice);
      }
    },[])

    const getBeforeTaxPricePayment = useCallback((price, theTax) => {
      return (Number(price) - (Number(price) / (1 + (theTax / 100)))).toFixed(2);
    },[generalSettings]);
  
    // total cash card price acc to tax
    const totalPriceWithTax = useCallback((total,subtotal,vat,discount,branch,key) =>{
      let totalPrice = parseFloat(total);
      const foundKey = branch && branch.payment_tax && Object.keys(branch.payment_tax).find(k => k.toLowerCase() === key);
      if(foundKey && branch.payment_tax[foundKey] && branch.payment_tax[foundKey] > 0){
        const calAmount = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(subtotal) - parseFloat(discount)) : parseFloat(subtotal);
        const inclusive_vat = calAmount * (parseFloat(branch.payment_tax[foundKey]) / 100);
        const previous_amount = parseFloat(total) - parseFloat(vat);
        totalPrice = previous_amount + inclusive_vat;
      }
      const finalPrice = roundFormatPrice(totalPrice);
      return [finalPrice,foundKey && branch && branch.payment_tax[foundKey]]
    },[generalSettings]);
    
    //cal net bill cash card
    const renderNetBill = useCallback((total, cartSubTotal, theVat, discount, branch, key, label) => {
      const [netBill, percent] = totalPriceWithTax(
        total,
        cartSubTotal,
        theVat,
        discount,
        branch,
        key
      );
      return (
        <div className="d-flex align-items-center justify-content-between my-1 mx-1">
          <div className="fk-print-text genAcc-select text-capitalize">
            {label}{percent > 0 && ` (${percent}%)`}
          </div>
          <div className="fk-print-text genAcc-select text-capitalize">
            {netBill}
          </div>
        </div>
      );
    },[generalSettings]);
  
    //tax percent
    const taxPercent = useCallback((total,vat,discount) => {
      if(parseFloat(vat) > 0){
        if(getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after"){
         let totalAmount = parseFloat(total) - parseFloat(discount);
         let vatPercentage = ((parseFloat(vat) / totalAmount) * 100).toFixed(0);
         return vatPercentage;
        }else{
          return ((parseFloat(vat)/parseFloat(total)) * 100).toFixed(0) 
        }
      }else{
        return 0;
      }
    },[generalSettings])
   
    //get order counter
    const getOnlineOrderCount = useCallback(async(order, text = "online") =>{
      const [online, ready, foodPanda] = order;
      console.log("Abcd49",online,order)
      if (audioInstance.current) {
        audioInstance.current.pause();
        audioInstance.current.currentTime = 0;
      }
  
      audioInstance.current = new Audio("/assets/beep/notify-new.mp3");
      
      if(online > 0){
        toast.success(`You have recieved (${online}) ${text} order`,toastObj);
      }
      if(online > 0 || ready > 0 || foodPanda > 0) {
        if(audioInstance.current){
          audioInstance.current.loop = true;
          audioInstance.current.play();
          setBellRing(true);
        }
      }
      setOrderCounter((curr) => {
        const newData = {
          ...curr,
          online: online,
          foodPanda: foodPanda,
          ready: ready,
        }
        return newData;
      });
    },[])
  
    //online order data
    const onlineOrders = useCallback(async(type = "") => {
      const pendingUrl = BASE_URL + "/website/pending-order";
      try{
        console.log("Abcd46",type)
        const res = await axios.get(pendingUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        if(res){
          console.log("Abcd47",res)
          if (res.data) {
            if(res.data?.length){
              const [online, ready, fp] = res.data;
              if(type === "online"){
                if(online > 0){
                  getOnlineOrderCount(res.data);
                  getOnlineOrders();
                }
              }else if(type === "fp"){
                if(fp > 0){
                  getOnlineOrderCount(res.data, "food panda");
                  getFoodPandaOrders();
                }
              }else{
                console.log("Abcd48",res)
                getOnlineOrderCount(res.data)  
              }
            }
          }
        }
      }catch (error){
        console.log("error order pending api",error)
      }     
    },[])
  
    //firbase online order
    const getFirbaseOnlineOrder = useCallback((type = "") =>{
      if(authUserInfo?.details){
        let url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/online_orders";
        if(authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager"){
          url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/online_orders/" + authUserInfo?.details?.branch_id
        }
       
        firebaseAuth(url, (error, res) => {
          console.log("Abcd",res,url,authUserInfo.details?.branch_id)
          if (error) {
            console.error("Firebase Error:", error);
            return;
          }
          if(res){
            let anyNotified = false;
            console.log("Abcd44",authUserInfo.details)
            if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
              if(res?.message === "Notified"){
                console.log("Abcd45",res.message)
                anyNotified = true;
              }
            }else{
              // Loop through the objects
              for (const key in res) {
                if (res[key]?.message === "Notified") {
                    anyNotified = true;
                    break; // Stop the loop once a "Notified" message is found
                }
              }
            }
            if(anyNotified){
              console.log("Abcd46",anyNotified)
              onlineOrders(type)
            }
          }
        })
      }
      
    },[generalSettings, authUserInfo]);
  
    //firbase online oder
    const getFirbaseRunningOrder = useCallback(() =>{
      if(authUserInfo?.details){
        let url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/newOrder";
        if(authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager"){
          url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/newOrder/" + authUserInfo?.details?.branch_id
        }
        
        firebaseAuth(url, (error, res) => {
          if (error) {
            console.error("Firebase Error:", error);
            return;
          }
          if(res){
            let anyNotified = false;
            if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
              if(res?.message === "Update"){
                anyNotified = true;
              }
            }else{
              // Loop through the objects
              for (const key in res) {
                if (res[key]?.message === "Update") {
                  anyNotified = true;
                  break; // Stop the loop once a "Notified" message is found
                }
              }
            }
            if(anyNotified){
              getSubmittedOrders()
            }
          }
        })
      }
      
    },[generalSettings, authUserInfo]);
  
    const checkOnlineOrders = useCallback((type = "") =>{
      if(authUserInfo?.details){
        if(getSystemSettings(generalSettings, "firebase_collection")){
          getFirbaseOnlineOrder(type);
        }else{
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          intervalRef.current = setInterval(() => {
            onlineOrders(type);
          }, 90000);
        }  
      }
    },[generalSettings, authUserInfo])
  
    // stock qty from firebase
    const fetchStock = useCallback((branch = null) => {
      if(authUserInfo?.details){
        let stockUrl;
        if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
          stockUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + '/finished_goods/' + authUserInfo.details?.branch_id;
        }else if(branch){
          stockUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + '/finished_goods/' + branch?.id;
        }
        
        firebaseAuth(stockUrl, (error, data) => {
          if (error) {
            console.error("Error in fetchDataAndProcess:", error);
            return;
          }
          if(data){
            if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
              getFoodItemsForPOS();
            }else{
              getFoodItemsForCallCenter(branch?.id)
            }
          }
        })
      }
     
    },[generalSettings, authUserInfo]);

    // set customer screen data
    const handleCustomerOrderUpdate = useCallback(async (data=null) => {
      try {
        let customerScreenUrl;
        if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
          customerScreenUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + "/" + authUserInfo.details?.branch_id + '/customer_order/' + authUserInfo.details?.id;
        }
        console.log("abcd5",data)
        const res = await firebaseAuthSetData(customerScreenUrl, data);
        if(res){
          return res;
        }
      } catch (error) {
        console.error('Error updating document:', error);
      }
    },[generalSettings, authUserInfo]);
  
    return {getFirbaseOnlineOrder, roundFormatPrice, showPriceOfEachOrderItemPrint, showPropertyPrice, getBeforeTaxPricePayment, handleCustomerOrderUpdate, debouncedFetchResults, getFirbaseRunningOrder, fetchStock, audioInstance, firebaseAuth, onlineOrderQuery, intervalRef, roundOffPrice, taxPercent, totalPriceWithTax, onlineOrders, renderNetBill, checkOnlineOrders}
  }