import React, { useEffect, useContext, useState,useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  customStyle,
  formatPrice,
  getCookie,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import moment from 'moment';
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { MdDelete } from 'react-icons/md';
// import StockReport from "./StockReport";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";


const TrialBalanceReport = () => {
    const { t } = useTranslation();
    let { loading, setLoading, generalSettings } = useContext(SettingsContext);

    //toast obj
    let common_toast={
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }

    //reportFields state
    const [reportFields,setReportFields]=useState({
      from_date:"",
      to_date:"",
      type:"trial_balance"
    });

    //report states
    const [reportData, setReportData] = useState([]);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [option,setOption] = useState("excel");
    const componentRef = useRef();
    const componentRefThermal = useRef();

    const handleFromDate=(from_date)=>{
      setReportFields({
        ...reportFields,
        from_date
      })
    }

    const thermalPrint = useReactToPrint({
      content: () => componentRefThermal.current,
      pageStyle: `@page { size: landscape; margin: 5px; }`,
    });

    const handleOption  = (e) =>{
      setOption(e.target.value)
    }

    const handleToDate=(to_date)=>{
      setReportFields({
        ...reportFields,
        to_date
      })
    }

    const handleSetType = (e)=>{
      setReportFields({
        ...reportFields,
        type: e.target.value
      })
    }

    const handleSubmit=(e)=>{
      e.preventDefault();
      let {from_date , to_date ,type} = reportFields
      if((from_date !== "" && from_date !== null) && (to_date !== "" && to_date !== null)){
        // setLoading(true);
        const url = BASE_URL + "/settings/get-trial-balance-report";
        let formData = new FormData();
        formData.append('from_date', moment(from_date).format("YYYY-MM-DD"));
        formData.append('to_date', moment(to_date).format("YYYY-MM-DD"));
        formData.append('type', type ? type : "");
        setLoading(true)
        return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data);
          setGeneratedReport(true);
          if(option==="excel"){
            componentRef.current.handleDownload();            
          }else{
            thermalPrint()
          }
          setReportFields({
            ...reportFields,
            from_date:"",
            to_date:"",
          })
          setLoading(false);
        })
        .catch((error) => {            
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`,common_toast);
        });
      }else{
        toast.error(`${_t(t("Please select from date & to date"))}`,common_toast);
      }
    }

    let currDebit = 0;
    let  currCredit = 0;
    let OpDebit = 0;
    let  OpCredit = 0;
    let totalDebit = 0;
    let  totalCredit = 0;

    const tableReturn = (accounts) => {
      return (
        <>
          {accounts.map((item, i) => {
            currDebit += item.cur_debit;
            currCredit += item.cur_credit;
            OpDebit += item.op_debit;
            OpCredit += item.op_credit;
            totalDebit += item.tot_debit;
            totalCredit += item.tot_credit;

            return (
              <tr key={i}>
                <td
                  scope="row"
                  className="sm-text text-capitalize align-middle border-1 border"
                >
                  {item.level3_display_nam}
                </td>

                {
                  reportFields.type !== "trial_balance_closing" && (
                    <>
                      <td
                        scope="row"
                        className="sm-text text-capitalize align-middle text-center border-1 border"
                      >
                        {item.op_debit}
                      </td>
                      <td
                        scope="row"
                        className="sm-text text-capitalize align-middle text-center border-1 border"
                      >
                        {item.op_credit}
                      </td>
                      <td
                        scope="row"
                        className="sm-text text-capitalize align-middle text-center border-1 border"
                      >
                        {item.cur_debit || 0}
                      </td>
                      <td
                        scope="row"
                        className="sm-text text-capitalize align-middle text-center border-1 border"
                      >
                        {item.cur_credit || 0}
                      </td>
                    </>
                  )
                }
              
                <td
                  scope="row"
                  className="sm-text text-capitalize align-middle text-center border-1 border"
                >
                  {item.tot_debit}
                </td>
                <td
                  scope="row"
                  className="sm-text text-capitalize align-middle text-center border-1 border"
                >
                  {item.tot_credit}
                </td>
              </tr>
            );
          })}
        </>
      )
    }


  return (
    <>
    <Helmet>
      <title>{_t(t("Accounts Trial Balance Report"))}</title>
    </Helmet>

    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Trial Balance Report"
      sheet="sheet 1"
      buttonText="Report"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                reportData !== null &&
                  reportData !== undefined &&
                  generatedReport === true && 
                  (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan={reportFields.type === "trial_balance_closing" ? "3" : "7" } style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {getSystemSettings(generalSettings, "siteName")}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Accounts Trial Balance Report
                                      </h3>
                                      <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                          {reportFields.from_date}
                                        </Moment>
                                        &nbsp;TO&nbsp;
                                        <Moment format="LL">{reportFields.to_date}</Moment>
                                      </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                >
                                  {_t(t("Account Name"))}
                                </th>

                                {
                                  reportFields.type !== "trial_balance_closing" && (
                                    <>
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Op. Debit"))}
                                      </th>
                                      
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Op. Credit"))}
                                      </th>
                                      
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Curr. Debit"))}
                                      </th>

                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Curr. Credir"))}
                                      </th>
                                    </>
                                  )
                                }
                                <th
                                  scope="col"
                                >
                                  {_t(t("Total Debit"))}
                                </th>
                               
                                <th
                                  scope="col"
                                >
                                  {_t(t("Total Credit"))}
                                </th>

                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here*/}
                              {reportData &&
                                Object.keys(reportData).map(
                                  (group_name, index) => { 
                                    currDebit = 0;
                                    currCredit = 0;
                                    OpDebit = 0;
                                    OpCredit = 0;
                                    totalDebit = 0;
                                    totalCredit = 0; 

                                    return(
                                    <div key={index}>
                                    <tr>
                                      <td
                                        scope="row"
                                      >
                                        <b>{group_name}</b>
                                      </td>
                                    </tr>
                                    {
                                     Array.isArray(reportData[group_name]) ? 
                                      tableReturn(reportData[group_name])
                                     :
                                     Object.keys(reportData[group_name]).map((group)=>{
                                      return(
                                        <>
                                          <tr>
                                            <td
                                              scope="row"
                                            >
                                              <b>{group}</b>
                                            </td>
                                          </tr>
                                          {tableReturn(reportData[group_name][group])}
                                        </>
                                      )
                                     })
                                    }
                                    <tr>
                                      <td
                                        scope="row"
                                      >
                                        <b>{group_name}</b>
                                      </td>
                                      {
                                        reportFields.type !== "trial_balance_closing" && (
                                          <>
                                            <td
                                              scope="row"
                                            >
                                              {OpDebit}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {OpCredit}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {currDebit}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {currCredit}
                                            </td>
                                          </>
                                        )
                                      }
                                      <td
                                        scope="row"
                                      >
                                        {totalDebit}
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                        {totalCredit}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </div>
                                );
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* thermal print */}
    <div className="d-none">
      <div ref={componentRefThermal}>
        {          
          <div className="fk-print">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/*Transaction of party wise report */}
                  <div className="col-12 mx-2">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row">
                            <h3 className="mt-3 fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                              {getSystemSettings(generalSettings, "siteName")}
                            </h3>
                            <h3 className="mt-1 fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                              Accounts Trial Balance Report
                            </h3>
                            <div>
                              <p className="mt-1 mb-0 fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                                FROM&nbsp;
                                <Moment format="LL">
                                  {reportFields.from_date}
                                </Moment>
                                &nbsp;TO&nbsp;
                                <Moment format="LL">{reportFields.to_date}</Moment>
                              </p>
                            </div>
                          </div> 
                          <table className="table table-bordered-sm w-100 mt-4">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle border-1 border "
                                  style={{minWidth:"7rem"}}
                                >
                                  {_t(t("Account Name"))}
                                </th>
                                {
                                  reportFields.type !== "trial_balance_closing" && (
                                    <>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border "
                                      >
                                        {_t(t("Op. Debit"))}
                                      </th>
                                      
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border "
                                      >
                                        {_t(t("Op. Credit"))}
                                      </th>
                                      
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border "
                                      >
                                        {_t(t("Curr. Debit"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border "
                                      >
                                        {_t(t("Curr. Credir"))}
                                      </th>
                                    </>
                                   )
                                }
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border "
                                >
                                  {_t(t("Total Debit"))}
                                </th>
                              
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border "
                                >
                                  {_t(t("Total Credit"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {reportData &&
                                Object.keys(reportData).map((group_name) => {   
                                  currDebit = 0;
                                  currCredit = 0;
                                  OpDebit = 0;
                                  OpCredit = 0;
                                  totalDebit = 0;
                                  totalCredit = 0;

                                  return(
                                  <>
                                    <tr>
                                      <td
                                        className="sm-text text-capitalize align-middle border-1 border"
                                      >
                                        <b>{group_name}</b>
                                      </td>
                                      <th className="border-1 border" colspan="7">
                                      </th>
                                    </tr>
                                    {Array.isArray(reportData[group_name]) ? 
                                      tableReturn(reportData[group_name])
                                      :
                                      Object.keys(reportData[group_name]).map((group)=>{
                                      return(
                                        <>
                                          <tr>
                                            <td
                                              className="sm-text text-capitalize align-middle border-1 border"
                                            >
                                              <b>{group}</b>
                                            </td>
                                            <th className="border-1 border" colspan="7">
                                            </th>
                                          </tr>
                                          {tableReturn(reportData[group_name][group])}
                                        </>
                                      )
                                    })
                                    }
                                    <tr>
                                      <th
                                        scope="row"
                                        className="sm-text text-capitalize align-middle border-1 border"
                                      >
                                        <b>{group_name}</b>
                                      </th>
                                      {
                                        reportFields.type !== "trial_balance_closing" && (
                                          <>
                                          <td
                                            scope="row"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {OpDebit}
                                          </td>
                                          <td
                                            scope="row"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {OpCredit}
                                          </td>
                                          <td
                                            scope="row"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {currDebit}
                                          </td>
                                          <td
                                            scope="row"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {currCredit}
                                          </td>
                                          </>
                                        )
                                      }
                                      <td
                                        scope="row"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {totalDebit}
                                      </td>
                                      <td
                                        scope="row"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {totalCredit}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </>
                                  );
                                })
                              }                                  
                            </tbody>
                          </table>
                        </>
                    )}
                  </div>
                </div> 
              </div> 
            </div> 
          </div>           
        }
      </div> 
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(loading) && "loadingBlur"}`}></div>
                  <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment-3">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 justify-content-between">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Trial Balance Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-6 d-flex justify-content-end p-0">
                          <div className="d-inline mr-3 d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input pointer-cursor"
                              id="excel_report"
                              name="excel_report"
                              value="excel"
                              onChange={(e) => {
                                handleOption(e);
                              }}
                              checked={option === "excel"}
                            />
                            <label
                              className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                              htmlFor="excel_report"
                              style={{fontSize:"16px",borderRadius:"5px"}}
                            >
                              <i className="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                            </label>
                          </div>

                          <div className="d-inline mr-2 d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input pointer-cursor"
                              id="print_report"
                              name="print_report"
                              value="print"
                              onChange={(e) => {
                                handleOption(e);
                              }}
                              checked={option === "print"}
                            />
                            <label
                              className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                              htmlFor="print_report"
                              style={{fontSize:"16px",borderRadius:"5px"}}
                            >
                              <i className="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                          <div>                            
                            <div className="row">
                              <div className="form-group col-3 text-right mt-2">
                              <div className="mb-2 text-left">
                                <label
                                htmlFor="fromDate"
                                className="control-label"
                                >
                                  {_t(t("From Date"))}
                                <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker
                                  selected={reportFields.from_date}
                                  onChange={(date) => handleFromDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control table-text"
                                  placeholderText={_t(t("From Date"))}
                                  shouldCloseOnSelect={false}                                  
                                />
                              </div>
                              </div>

                              <div className="form-group col-3 text-right mt-2">
                              <div className="mb-2 text-left">
                                <label
                                htmlFor="toDate"
                                className="control-label"
                                >
                                  {_t(t("To Date"))}
                                <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker
                                  selected={reportFields.to_date}
                                  onChange={(date) => handleToDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control table-text"
                                  placeholderText={_t(t("To Date"))}
                                  shouldCloseOnSelect={false}                                  
                                />
                              </div>
                              </div>

                              <div className="col-4 d-flex align-items-center">
                                <div className="d-inline mr-3">
                                  <input
                                    type="radio"
                                    className="form-check-input pointer-cursor"
                                    id="trial_balance"
                                    name="trial_balance"
                                    value="trial_balance"
                                    onChange={(e) => handleSetType(e)}
                                    checked={reportFields.type === "trial_balance"}
                                  />
                                  <label
                                    className="form-radio-label pointer-cursor ml-2"
                                    htmlFor="trial_balance"
                                  >
                                    {_t(t("Trial Balance"))}
                                  </label>
                                </div>

                                <div className="d-inline mr-2">
                                  <input
                                    type="radio"
                                    className="form-check-input pointer-cursor"
                                    id="trial_balance_closing"
                                    name="trial_balance_closing"
                                    value="trial_balance_closing"
                                    onChange={(e) => handleSetType(e)}
                                    checked={reportFields.type === "trial_balance_closing"}
                                  />
                                  <label
                                    className="form-radio-label pointer-cursor ml-2"
                                    htmlFor="trial_balance_closing"
                                  >
                                    {_t(t("Balance Closing"))}
                                  </label>
                                </div>
                              </div>
                              
                              <div className="form-group mt-4 col-2 text-end">                                                                                                                        
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary rounded mt-2"
                                >
                                  <i className="fa fa-table" aria-hidden="true"></i> {_t(t("Print Trial"))}
                                </button>                                                                                                                                                                                       
                              </div>
                            </div>
                          </div>                                                                        
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
        </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
      {/* main body ends */}
  </>
  )
}

export default TrialBalanceReport;
