import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

// creating context api
const FinanceContext = React.createContext();

const FinanceProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    // getFoodWeb();
    //call if authenticated
    if ((getCookie() !== undefined || offline(BASE_URL + "/auth/user")) && (window.location.pathname.includes("dashboard/account") || window.location.pathname.includes("dashboard/accounts"))) {
        // getCoaL1();
        // getCoaL2();
        getCoaL3();
        // getBPList();
        // getCPList();
        // getJVList();
        // getOAList();
    }
  }, []);

  //Coa1 list
  const [coa1, setCoa1] = useState(null);
  const [coa1ForSearch, setCoa1ForSearch] = useState(null);
  // States hook here
  const [saveData, setSaveData] = useState([
    {name:"cash_account_id",value:""},
    {name:"withholding_tax_account_id",value:""},
    {name:"other_tax_account_id",value:""},
    {name:"bank_account_id",value:""},
    {name:"inventory_account_id",value:""},
    {name:"revenue_account_id",value:""},
    {name:"cogs_id",value:""},
    {name:"purchase_discount_id",value:""},
    {name:"sales_discount_id",value:""},
    {name:"purchase_tax_id",value:""},
    {name:"sales_tax_id",value:""},
    {name:"supplier_cash_account_id",value:""},
    {name:"supplier_payable_account_id",value:""},
    {name:"suspense_account_id",value:""}
  ]);

  const [cashAcc,setCashAcc]=useState([]);
  const [whtAcc,setWhtAcc]=useState("");
  const [otherTaxAcc,setOtherTaxAcc]=useState("");
  const [bankAcc,setBankAcc]=useState([]);
  const [invAcc,setInvAcc]=useState("");
  const [revAcc,setRevAcc]=useState("");
  const [cogs,setCogs]=useState("");
  const [purchaseDis,setPurchaseDis]=useState("");
  const [salesDis,setSalesDis]=useState("");
  const [purchaseTax,setPurchaseTax]=useState("");
  const [salesTax,setSalesTax]=useState("");
  const [suppCashAcc,setSuppCashAcc]=useState("");
  const [suspenseAcc,setSuspenseAcc]=useState("");
  const [suppPayableAcc,setSuppPayableAcc]=useState("");


  const getModuleSetting= async(coa3ForSearchSettings)=>{
    setLoading(true);
    const moduleUrl = BASE_URL + "/settings/get-module-setting";
    try {
      const res = await axios.get(moduleUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // console.log("check get data==>",res.data);
      let data = [
        {name:"cash_account_id",value:""},
        {name:"withholding_tax_account_id",value:""},
        {name:"other_tax_account_id",value:""},
        {name:"bank_account_id",value:""},
        {name:"inventory_account_id",value:""},
        {name:"revenue_account_id",value:""},
        {name:"cogs_id",value:""},
        {name:"purchase_discount_id",value:""},
        {name:"sales_discount_id",value:""},
        {name:"purchase_tax_id",value:""},
        {name:"sales_tax_id",value:""},
        {name:"supplier_cash_account_id",value:""},
        {name:"supplier_payable_account_id",value:""},
        {name:"suspense_account_id",value:""}
      ]
      res.data.map((acc)=>{
        if(acc.field === "cash_account_id"){
          //set state for dropdown default values
          let cashAccId =  JSON.parse(acc.value)
          let cash =  cashAccId.map((cash)=>{
            return coa3ForSearchSettings[cash]
          });
          setCashAcc(cash);
          //set savedata values for not empty on load
          data[0].value = cashAccId
          saveData[0].value = cashAccId
          setSaveData([...saveData]);
        }
        else if(acc.field === "withholding_tax_account_id"){
          //set state for dropdown default values
          let withTax = coa3ForSearchSettings[acc.value];
          setWhtAcc(withTax)
          //set savedata values for not empty on load
          data[1].value = withTax == "" ? "" :  acc.value
        }
        else if(acc.field === "other_tax_account_id"){
          //set state for dropdown default values
          let otherTax = coa3ForSearchSettings[acc.value];
          setOtherTaxAcc(otherTax)
          //set savedata values for not empty on load
          data[2].value = otherTax == "" ? "" : acc.value;
        }else if(acc.field === "bank_account_id"){
          //set state for dropdown default values
          let bankAccId =  JSON.parse(acc.value)
          let bankIdArr =  bankAccId.map((bank)=>{
          return coa3ForSearchSettings[bank]
          });
          setBankAcc(bankIdArr);
          //set savedata values for not empty on load
          data[3].value =  bankAccId?.length == 0 ? "" : bankAccId;
        }else if(acc.field === "inventory_account_id"){
          //set state for dropdown default values
          let inventoryAcc = coa3ForSearchSettings[acc.value];
          setInvAcc(inventoryAcc);
          //set savedata values for not empty on load
          data[4].value = inventoryAcc == "" ? "" : acc.value;
        }else if(acc.field === "revenue_account_id"){
          //set state for dropdown default values
          let revenueAcc = coa3ForSearchSettings[acc.value];
          setRevAcc(revenueAcc);
          //set savedata values for not empty on load
          data[5].value = revenueAcc == "" ? "" : acc.value;          
        }else if(acc.field === "cogs_id"){
          //set state for dropdown default values
          let costOfGoods = coa3ForSearchSettings[acc.value];
          setCogs(costOfGoods);
          //set savedata values for not empty on load
          data[6].value = costOfGoods == "" ? "" : acc.value;          
        }else if(acc.field === "purchase_discount_id"){
          //set state for dropdown default values
          let purchaseDis = coa3ForSearchSettings[acc.value]
          setPurchaseDis(purchaseDis);
          //set savedata values for not empty on load
          data[7].value = purchaseDis == "" ? "" : acc.value;          
        }else if(acc.field === "sales_discount_id"){
          //set state for dropdown default values
          let salesDis = coa3ForSearchSettings[acc.value];
          setSalesDis(salesDis);
          //set savedata values for not empty on load
          data[8].value = salesDis == "" ? "" : acc.value;
        }else if(acc.field === "purchase_tax_id"){
          //set state for dropdown default values
          let purchaseTax = coa3ForSearchSettings[acc.value];
          setPurchaseTax(purchaseTax);
          //set savedata values for not empty on load
          data[9].value = purchaseTax == "" ? "" : acc.value;
        }else if(acc.field === "sales_tax_id"){
          //set state for dropdown default values
          let salesTax =  coa3ForSearchSettings[acc.value];
          setSalesTax(salesTax);
          //set savedata values for not empty on load
          data[10].value = salesTax == "" ? "" : acc.value;
        }else if(acc.field === "supplier_cash_account_id"){
          //set state for dropdown default values
          let suppCash = coa3ForSearchSettings[acc.value];
          setSuppCashAcc(suppCash);
          //set savedata values for not empty on load
          data[11].value = suppCash == "" ? "" : acc.value;
        }else if(acc.field === "supplier_payable_account_id"){
          //set state for dropdown default values
          let suppPayable = coa3ForSearchSettings[acc.value];
          setSuppPayableAcc(suppPayable);
          //set savedata values for not empty on load
          data[12].value = suppPayable == "" ? "" : acc.value;
        }else if(acc.field === "suspense_account_id"){
          let suspenseAccId = coa3ForSearchSettings[acc.value];
          setSuspenseAcc(suspenseAccId);
          //set savedata values for not empty on load
          data[13].value = suspenseAccId == "" ? "" : acc.value;
        }
        setSaveData(data)
        console.log("abcd44",data)
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
        console.log("error==>",error)
    }
  }

// Helper
  const setCoaL1 = (res) => {
    setCoa1(res.data[0]);
    setCoa1ForSearch(res.data[1]);
    setLoading(false)
  };

//get Coa level 1  // Priority ->
  const getCoaL1 = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-coa-level1";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setCoaL1(res);
    //   setDataToLocal(url, res);
    } catch (error) {
      setLoading(false)
    }
  };


  //helper
  const setPaginatedCoa1s = (res) => {
    setCoa1(res.data[0]);
    setCoa1ForSearch(res.data[1]);
    setDataPaginating(false);
  };

  //get paginated coa1
  const setPaginatedCoa1 = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-coa-level1?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedCoa1s(res);
    //   localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      setDataPaginating(false)
        console.log("error==>",error)
    //   if (!navigator.onLine && localStorage.getItem(url) != null) {
    //     let parseRes = JSON.parse(localStorage.getItem(url));
    //     setPaginatedSchemes(parseRes);
    //   }
    }
  };

  //Coa2 list
  const [coa2, setCoa2] = useState(null);
  const [coa2ForSearch, setCoa2ForSearch] = useState(null);

  // Helper
  const setCoaL2 = (res) => {
    setCoa2(res.data[0]);
    setCoa2ForSearch(res.data[1]);
    setLoading(false)
    // console.log("check in ctx==>",res)
  };

  //get Coa level 2  // Priority ->
  const getCoaL2 = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-coa-level2";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setCoaL2(res);
    //   setDataToLocal(url, res);
    } catch (error) {
      setLoading(false)
    }
  };

  //helper
  const setPaginatedCoa2s = (res) => {
    setCoa2(res.data[0]);
    setCoa2ForSearch(res.data[1]);
    setDataPaginating(false);
  };

  //get paginated coa2
  const setPaginatedCoa2 = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-coa-level2?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedCoa2s(res);
    //   localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      setDataPaginating(false)
    }
  };

    //Coa3 list
    const [coa3, setCoa3] = useState(null);
    const [coa3ForSearch, setCoa3ForSearch] = useState(null);
    const [coa3ForSearchById, setCoa3ForSearchById] = useState(null);
  
    // Helper
    const setCoaL3 = (res) => {
      setCoa3(res.data[0]);
      setCoa3ForSearch(res.data[1]);
      const obj = {};
      res.data[1]?.length && res.data[1].map((coa3) => {
        obj[parseInt(coa3.id)] = coa3;
      });
      setCoa3ForSearchById(obj)
      setLoading(false)
      if(res.data[1]){
        getModuleSetting(obj)
      }
    };
  
  //get Coa level 3  // Priority ->
    const getCoaL3 = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-coa-level3";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCoaL3(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
      }
    };
  
    //helper
    const setPaginatedCoa3s = (res) => {
      setCoa3(res.data[0]);
      setCoa3ForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated coa3
    const setPaginatedCoa3 = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-coa-level3?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedCoa3s(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
        
      }
    };

    //Bank payment list
    const [bankPaymentList, setBankPaymentList] = useState(null);
    const [bankPaymentListForSearch, setBankPaymentListForSearch] = useState(null);
  
    // Helper
    const setBPList = (res) => {
      setBankPaymentList(res.data[0]);
      setBankPaymentListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get bank payment list  // Priority ->
    const getBPList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-bank-payment";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setBPList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
      }
    };

    //helper
    const setPaginatedBPLists = (res) => {
      setBankPaymentList(res.data[0]);
      setBankPaymentListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated BPList
    const setPaginatedBPList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-bank-payment?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedBPLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
      }
    };

    //Cash payment list
    const [cashPaymentList, setCashPaymentList] = useState(null);
    const [cashPaymentListForSearch, setCashPaymentListForSearch] = useState(null);
  
    // Helper
    const setCPList = (res) => {
      setCashPaymentList(res.data[0]);
      setCashPaymentListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get cash payment list  // Priority ->
    const getCPList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-cash-payment";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCPList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
      }
    };

    //helper
    const setPaginatedCPLists = (res) => {
      setCashPaymentList(res.data[0]);
      setCashPaymentListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated CPList
    const setPaginatedCPList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-cash-payment?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedCPLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
      }
    };

    //Journal voucher list
    const [journalVoucherList, setJournalVoucherList] = useState(null);
    const [journalVoucherListForSearch, setJournalVoucherListForSearch] = useState(null);
  
    // Helper
    const setJVList = (res) => {
      setJournalVoucherList(res.data[0]);
      setJournalVoucherListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get journal voucher list  // Priority ->
    const getJVList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-journal-voucher";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setJVList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
      }
    };

    //helper
    const setPaginatedJVLists = (res) => {
      setJournalVoucherList(res.data[0]);
      setJournalVoucherListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated JVList
    const setPaginatedJVList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-journal-voucher?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedJVLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false);
      }
    };

    //Opening account list
    const [openingAccountList, setOpeningAccountList] = useState(null);
    const [openingAccountListForSearch, setOpeningAccountListForSearch] = useState(null);
  
    // Helper
    const setOAList = (res) => {
      setOpeningAccountList(res.data[0]);
      setOpeningAccountListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get opening account list  // Priority ->
    const getOAList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-opening-account";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setOAList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false);
      }
    };

    //helper
    const setPaginatedOALists = (res) => {
      setOpeningAccountList(res.data[0]);
      setOpeningAccountListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated JVList
    const setPaginatedOAList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-opening-account?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedOALists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false);
      }
    };

  return (
    <FinanceContext.Provider
      value={{
        // common
        loading,
        setLoading,

        //coa1
        coa1, 
        setCoa1,
        coa1ForSearch,
        setCoa1ForSearch,
        getCoaL1,
        setPaginatedCoa1,

        //coa2
        coa2, 
        setCoa2,
        coa2ForSearch,
        setCoa2ForSearch,
        getCoaL2,
        setPaginatedCoa2,

        //coa3
        coa3, 
        setCoa3,
        coa3ForSearch,
        setCoa3ForSearch,
        coa3ForSearchById,
        getCoaL3,
        setPaginatedCoa3,

        //BPList
        bankPaymentList,
        setBankPaymentList,
        bankPaymentListForSearch,
        setBankPaymentListForSearch,
        getBPList,
        setPaginatedBPList,

        //CPList
        cashPaymentList,
        setCashPaymentList,
        cashPaymentListForSearch,
        setCashPaymentListForSearch,
        getCPList,
        setPaginatedCPList,

        //JVList
        journalVoucherList,
        setJournalVoucherList,
        journalVoucherListForSearch,
        setJournalVoucherListForSearch,
        getJVList,
        setPaginatedJVList,

        //OAList
        openingAccountList,
        setOpeningAccountList,
        openingAccountListForSearch,
        setOpeningAccountListForSearch,
        getOAList,
        setPaginatedOAList,

        //transaction account
        // bankAccounts,
        // setBankAccounts,

        //pagination
        dataPaginating,
        setDataPaginating,

        // account module
        saveData,
        setSaveData,
        cashAcc,
        setCashAcc,
        whtAcc,
        setWhtAcc,
        otherTaxAcc,
        setOtherTaxAcc,
        bankAcc,
        setBankAcc,
        invAcc,
        setInvAcc,
        revAcc,
        setRevAcc,
        cogs,
        setCogs,
        purchaseDis,
        setPurchaseDis,
        salesDis,
        setSalesDis,
        purchaseTax,
        setPurchaseTax,
        salesTax,
        setSalesTax,
        suppCashAcc,
        setSuppCashAcc,
        suspenseAcc,
        setSuspenseAcc,
        suppPayableAcc,
        setSuppPayableAcc,
      }}
    >
      {children}
    </FinanceContext.Provider>
  );
};

export { FinanceContext, FinanceProvider };
