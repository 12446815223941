import React, { useEffect, useContext } from "react";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  managePageHrefLink,
  managePageHrefLinkMobile,
  checkPermission
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../contexts/User";


const PayrollSidebar = () => {
  const { t } = useTranslation();

  let { authUserInfo } = useContext(UserContext);

  useEffect(() => {
    handleJquery();
  }, []);

  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });
  };

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              <ul className="t-list fk-sm-nav__bar flex-row">
                {/* payroll setting */}
                {managePageHrefLinkMobile(
                  "/dashboard/payroll/deptarment",
                  _t(t("Department"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/payroll/designation",
                  _t(t("Designation"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/payroll/employee",
                  _t(t("Employee"))
                )}

                {/* Reports */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Ledger Report")
                    ? managePageHrefLinkMobile(
                      "/dashboard/manage/payroll/reports/ledger-report",
                      _t(t("Payroll Reports"))
                    )
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* GL Setup */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/payroll/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Payroll Setup"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                {managePageHrefLink(
                  "/dashboard/payroll/department",
                  _t(t("Department"))
                )
                } 

                {managePageHrefLink(
                  "/dashboard/payroll/designation",
                  _t(t("Designation"))
                )
                }

                {managePageHrefLink(
                  "/dashboard/accounts/employee",
                  _t(t("Employee"))
                )
                }
         
              </ul>
            </li>

            {/* Reports */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/reports/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

              {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/manage/reports/ledger-report",
                    _t(t("Ledger Reports"))
                  )
                : ""}                  
              </ul>
            </li>
          </ul>

        </div>
      </div>
      
      {/* Navigation for large Screen End */}
    </>
  );
};

export default PayrollSidebar;
