import React from "react";
import ReactDOM from "react-dom";
// import '../public/'
import WebFont from "webfontloader";
import * as serviceWorker from "./serviceWorker";
// import "@fontsource/roboto"
// import "@fontsource/material-icons"
// import "@fontsource/poppins"
import {
  BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import App from "./App";
import SaasApp from "./SaasApp.";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18next";

//importing context provider here
import { UserProvider } from "./contexts/User";
import { SettingsProvider } from "./contexts/Settings";
import { RestaurantProvider } from "./contexts/Restaurant";
import { FoodProvider } from "./contexts/Food";
import { FinanceProvider } from "./contexts/Finance";
import { PayrollProvider } from "./contexts/Payroll";
// ['Poppins:400,500,600,700', 'sans-serif']
// ['Material+Icons', 'sans-serif'],
// WebFont.load({
//   google: {
//     // families: ['Poppins:400,500,600,700', 'sans-serif']
//     families: ['Poppins:wght@400;500;600;700', 'Material+Icons']
//   }
// });

ReactDOM.render( 
  <React.StrictMode>
    <Router>
      <UserProvider>
        <SettingsProvider>
          <RestaurantProvider>
            <FinanceProvider>
              <FoodProvider>
                <PayrollProvider>
                  <SaasApp />
                </PayrollProvider>
              </FoodProvider>{" "}
            </FinanceProvider>{" "}
          </RestaurantProvider>{" "}
        </SettingsProvider>{" "}
      </UserProvider>{" "}
    </Router>
  </React.StrictMode>,
  document.getElementById("indolj")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();

serviceWorkerRegistration.register();
