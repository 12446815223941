import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const AdminStaffCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
    //permission group
    permissionGroupForSearch,
  } = useContext(SettingsContext);

  let {
    //adminStaff
    getAdminStaff,
    adminStaffList,
    setAdminStaffList,
    setPaginatedAdminStaff,
    adminStaffForSearch,
    setAdminStaffListforSearch,

    //pagination
    dataPaginating,
  } = useContext(UserContext);

  let {
    //branch
    branchForSearch,
    orderTypeForSearch,
  } = useContext(RestaurantContext);

  // States hook here
  const selectRef = useRef(null);
  // close btn
  const closeBtnRef = useRef(null);
    const handleInvalid = () => {
      if (!selectRef.current?.value) {
         selectRef.current.setCustomValidity('Please select an user type in the list');
      } else {
         selectRef.current.setCustomValidity('');
      }
    };
  //new adminStaff
  let [newAdminStaff, setAdminStaff] = useState({
    user_type: "",
    name: "",
    email: "",
    phn_no: "0",
    password: "",
    password_confirmation: "",
    sales_limit: 0,
    branch: null,
    user_passcode: "",
    selectedBranch: null,
    selectPermissionGroup: null,
    selectedPermissionGroup: null,
    sbr_pos_id : "",
    sbr_ntn : "",
    sbr_user_id : "",
    sbr_user_password: "",
    sbr_integration : 0,
    tillUser: null,
    orderType: null,
    image: null,
    edit: false,
    editSlug: null,
    editImage: null,
    uploading: false,
  });
  
  //search result
  let [searchedAdminStaff, setSearchedAdminStaff] = useState({
    list: null,
    searched: false,
  });

  const [tillList, setTillList] = useState([]);

  const getTillUserBranch = () =>{
    setLoading(true);
    const url = BASE_URL + "/settings/till-list";
    return axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
      .then((res) => {
        setTillList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  //useEffect == componentDidMount
  useEffect(() => {
    getTillUserBranch();
  }, []);

  // set admin staff usertype
  const handleSetNewUserType = (e) => {
    if(newAdminStaff.user_type !== e.target.value){
      setAdminStaff({ ...newAdminStaff, [e.target.name]: e.target.value ,branch: null ,selectedBranch: null});
    }else{
      setAdminStaff({ ...newAdminStaff, [e.target.name]: e.target.value });
    }
  };

  //set name, phn no hook
  const handleSetNewAdminStaff = (e) => {
    setAdminStaff({ ...newAdminStaff, [e.target.name]: e.target.value });
  };

  //set branch hook
  const handleSetBranch = (branch) => {
    setAdminStaff({ ...newAdminStaff, branch });
  };

  //set permission hook
  const handleSetPermissionGroup = (selectPermissionGroup) => {
    setAdminStaff({ ...newAdminStaff, selectPermissionGroup });
  };
 
  //till user
  const handleSetTillUser = (tillUser) => {
    if(tillUser){
      setAdminStaff({...newAdminStaff, tillUser,
        sbr_integration: tillUser?.sbr_pos_id ? 1 : 0,
        sbr_pos_id : tillUser.sbr_pos_id || "",
        sbr_ntn : tillUser.sbr_ntn || "",
        sbr_user_id : tillUser.sbr_user_id || "",
        sbr_user_password: tillUser.sbr_user_password || "",
      });
    }else{
      setAdminStaff({...newAdminStaff, tillUser,
        sbr_integration: 0,
        sbr_pos_id : "",
        sbr_ntn : "",
        sbr_user_id : "",
        sbr_user_password: "", 
      });
    }
  }
  
  //set permission hook
  const handleSetOrderTypeGroup = (orderType) => {
    setAdminStaff({ ...newAdminStaff, orderType });
  };
  //set image hook
  const handleAdminStaffImage = (e) => {
    setAdminStaff({
      ...newAdminStaff,
      [e.target.name]: e.target.files[0],
    });
  };
  
  //Save New adminStaff
  const handleSaveNewAdminStaff = (e) => {
    e.preventDefault();
    //url and form data
    const adminStaffUrl = BASE_URL + `/settings/new-admin-staff`;
    let formData = new FormData();

    let orderTypes = []
    if(newAdminStaff.user_type !== "admin" && newAdminStaff.user_type !== "superAdmin" && newAdminStaff.user_type !== "multi_manager") {
      newAdminStaff.orderType?.length > 0 && newAdminStaff.orderType.forEach((order_type)=>{
          orderTypes.push(order_type.id)
      });
    }
    formData.append("user_type", newAdminStaff.user_type);
    formData.append("name", newAdminStaff.name);
    formData.append("email", newAdminStaff.email);
    formData.append("phn_no", newAdminStaff.phn_no);
    formData.append("password", newAdminStaff.password);
    formData.append("user_passcode", newAdminStaff.user_passcode ? newAdminStaff.user_passcode : "");
    formData.append("order_type_id", JSON.stringify(orderTypes));
    formData.append(
      "password_confirmation",
      newAdminStaff.password_confirmation
    );
    formData.append(
      "sales_limit",
      newAdminStaff.sales_limit
    );
    if (newAdminStaff.selectPermissionGroup !== null) {
      formData.append(
        "permission_group_id",
        newAdminStaff.selectPermissionGroup.id
      );
    }
    if((newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") && (newAdminStaff.branch === null || newAdminStaff.branch === "")){
      toast.error(
        `${_t(t("Please select branch"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false;
    }
    if ((newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager")&& newAdminStaff.branch !== null) {
      if(newAdminStaff.user_type === "multi_manager"){
        let branches = newAdminStaff.branch.map((branch)=> branch.id);
        formData.append("branch_id", JSON.stringify(branches));
      }else{
        formData.append("branch_id", newAdminStaff.branch.id);
      }
    }else{
      formData.append("branch_id", "");
    }
    if(newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager"){
      formData.append("sbr_pos_id", newAdminStaff.sbr_pos_id);
      formData.append("sbr_ntn", newAdminStaff.sbr_ntn);
      formData.append("sbr_user_id", newAdminStaff.sbr_user_id);
      formData.append("sbr_user_password", newAdminStaff.sbr_user_password);
      formData.append("sbr_enabled", newAdminStaff.sbr_integration);
      formData.append("till_id", newAdminStaff.tillUser ? newAdminStaff.tillUser.id : "");
    }
    formData.append("image", newAdminStaff.image);

    //check user type
    if (newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") {
      //check if group || branch null
      if (
        newAdminStaff.branch !== null &&
        newAdminStaff.selectPermissionGroup !== null
      ) {
        setAdminStaff({
          ...newAdminStaff,
          uploading: true,
        });

        return axios
          .post(adminStaffUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setAdminStaff({
              user_type: "",
              name: "",
              email: "",
              phn_no: "0",
              password: "",
              password_confirmation: "",
              sales_limit: 0,
              branch: null,
              selectPermissionGroup: null,
              selectedBranch: null,
              selectedPermissionGroup: null,
              sbr_pos_id : "",
              sbr_ntn : "",
              sbr_user_id : "",
              sbr_user_password: "",
              sbr_integration : 0,
              tillUser: null,
              orderType: null,
              image: null,
              edit: false,
              editSlug: null,
              editImage: null,
              uploading: false,
            });
            setAdminStaffList(res.data[0]);
            setAdminStaffListforSearch(res.data[1]);
            setLoading(false);
            toast.success(`${_t(t("User has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            if (closeBtnRef.current) {
              closeBtnRef.current.click();
            }
          })
          .catch((error) => {
            setLoading(false);
            setAdminStaff({
              ...newAdminStaff,
              uploading: false,
            });
            if (error && error.response.data.errors) {
              if (error.response.data.errors.phn_no) {
                error.response.data.errors.phn_no.forEach((item) => {
                  if (item === "An user exists with this phone number") {
                    toast.error(
                      `${_t(t("An user exists with this phone number"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
              if (error.response.data.errors.email) {
                error.response.data.errors.email.forEach((item) => {
                  if (item === "An user exists with this email") {
                    toast.error(`${_t(t("An user exists with this email"))}`, {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    });
                  }
                });
              }
              if (error.response.data.errors.password) {
                error.response.data.errors.password.forEach((item) => {
                  if (item === "Password confirmation does not match") {
                    toast.error(
                      `${_t(t("Password confirmation does not match"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                  if (item === "The password must be at least 6 characters.") {
                    toast.error(
                      `${_t(t("The password must be at least 6 characters"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
              if (error.response.data.errors.image) {
                error.response.data.errors.image.forEach((item) => {
                  if (item === "Please select a valid image file") {
                    toast.error(
                      `${_t(t("Please select a valid image file"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                  if (item === "Please select a file less than 5MB") {
                    toast.error(
                      `${_t(t("Please select a file less than 5MB"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
            }
          });
      } else {
        if (newAdminStaff.branch === null) {
          toast.error(`${_t(t("Please select a branch"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        if (newAdminStaff.selectPermissionGroup === null) {
          toast.error(`${_t(t("Please select a permission group"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      }
    } else {
      //check if group null
      if (newAdminStaff.selectPermissionGroup !== null) {
        setAdminStaff({
          ...newAdminStaff,
          uploading: true,
        });
        return axios
          .post(adminStaffUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setAdminStaff({
              user_type: "",
              name: "",
              email: "",
              phn_no: "0",
              password: "",
              password_confirmation: "",
              sales_limit: 0,
              branch: null,
              selectPermissionGroup: null,
              selectedBranch: null,
              selectedPermissionGroup: null,
              sbr_pos_id : "",
              sbr_ntn : "",
              sbr_user_id : "",
              sbr_user_password: "",
              sbr_integration : 0,
              tillUser: null,
              orderType: null,
              image: null,
              edit: false,
              editSlug: null,
              editImage: null,
              uploading: false,
            });
            setAdminStaffList(res.data[0]);
            setAdminStaffListforSearch(res.data[1]);
            setLoading(false);
            if (closeBtnRef.current) {
              closeBtnRef.current.click();
            }
            toast.success(`${_t(t("User has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          })
          .catch((error) => {
            setLoading(false);
            setAdminStaff({
              ...newAdminStaff,
              branch: null,
              selectPermissionGroup: null,
              uploading: false,
            });
            if (error && error.response.data.errors) {
              if (error.response.data.errors.phn_no) {
                error.response.data.errors.phn_no.forEach((item) => {
                  if (item === "An user exists with this phone number") {
                    toast.error(
                      `${_t(t("An user exists with this phone number"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
              if (error.response.data.errors.email) {
                error.response.data.errors.email.forEach((item) => {
                  if (item === "An user exists with this email") {
                    toast.error(`${_t(t("An user exists with this email"))}`, {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    });
                  }
                });
              }

              if (error.response.data.errors.password) {
                error.response.data.errors.password.forEach((item) => {
                  if (item === "The password confirmation does not match.") {
                    toast.error(
                      `${_t(t("Password confirmation does not match"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                  if (item === "The password must be at least 6 characters.") {
                    toast.error(
                      `${_t(t("The password must be at least 6 characters"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
              if (error.response.data.errors.image) {
                error.response.data.errors.image.forEach((item) => {
                  if (item === "Please select a valid image file") {
                    toast.error(
                      `${_t(t("Please select a valid image file"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                  if (item === "Please select a file less than 5MB") {
                    toast.error(
                      `${_t(t("Please select a file less than 5MB"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
            }
          });
      } else {
        toast.error(`${_t(t("Please select a permission group"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  };
  console.log("abcd44",newAdminStaff)
  //set edit true & values
  const handleSetEdit = async(id) => {

    try{
      const url = BASE_URL + '/settings/edit-admin-staff/' + id;
      
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });

      if(res){
        const adminStaff = res.data;
        let selectedOptionForPermission = null;
        if (adminStaff?.permission_group_id) {
          selectedOptionForPermission =  permissionGroupForSearch && permissionGroupForSearch.find(
            (groupItem) => {
              return groupItem.id === parseInt(adminStaff.permission_group_id);
            }
          );
        }
       
        let orderType = null;
        if (adminStaff?.order_type_id && JSON.parse(adminStaff.order_type_id)?.length > 0) {
          orderType = orderTypeForSearch && orderTypeForSearch.filter(
            (order_type) => {
              return JSON.parse(adminStaff.order_type_id).includes(order_type.id);
            }
          );
        }
        let till = null;
        if(adminStaff?.till_id && adminStaff.branch_id && (adminStaff.user_type === "staff" || adminStaff.user_type === "manager")) {
           till = tillList && tillList[adminStaff.branch_id] && tillList[adminStaff.branch_id].find((item)=> item.id == adminStaff.till_id);
        }
        
        if (adminStaff.user_type === "staff" || adminStaff.user_type === "manager" || adminStaff.user_type === "multi_manager") {
          let selectedOptionForBranch = null;
          if (adminStaff.branch_id && adminStaff.user_type === "multi_manager") {
            selectedOptionForBranch =
              branchForSearch !== null &&
              branchForSearch.filter((branchItem) => {
                return adminStaff.branch_id.includes(branchItem.id);
              });
          }else if(adminStaff.branch_id){
            selectedOptionForBranch =
              branchForSearch !== null &&
              branchForSearch.find((branchItem) => {
                return branchItem.id === adminStaff.branch_id;
              });
          }
          setAdminStaff({
            ...newAdminStaff,
            user_type: adminStaff.user_type,
            name: adminStaff.name,
            email: adminStaff.email,
            phn_no: adminStaff.phn_no,
            user_passcode: adminStaff.user_passcode,
            selectedBranch: selectedOptionForBranch || null,
            branch: selectedOptionForBranch || null,
            sales_limit: adminStaff.sales_limit || 0,
            sbr_pos_id : adminStaff.sbr_pos_id || "",
            sbr_ntn : adminStaff.sbr_ntn || "",
            sbr_user_id : adminStaff.sbr_user_id || "",
            sbr_user_password: adminStaff.sbr_password || "",
            sbr_integration : adminStaff.sbr_enabled,
            tillUser: till,
            selectPermissionGroup: selectedOptionForPermission || null,
            orderType : orderType ? orderType : null,
            editSlug: adminStaff.slug,
            editImage: adminStaff.image,
            edit: true,
          });
        } else {
          setAdminStaff({
            ...newAdminStaff,
            user_type: adminStaff.user_type,
            name: adminStaff.name,
            email: adminStaff.email,
            phn_no: adminStaff.phn_no,
            user_passcode: adminStaff.user_passcode,
            sales_limit: adminStaff.sales_limit || 0,
            sbr_pos_id : adminStaff.sbr_pos_id || "",
            sbr_ntn : adminStaff.sbr_ntn || "",
            sbr_user_id : adminStaff.sbr_user_id || "",
            sbr_user_password: adminStaff.sbr_user_password || "",
            sbr_integration : adminStaff.sbr_enabled,
            tillUser: till,
            selectPermissionGroup: selectedOptionForPermission || null,
            orderType : orderType ? orderType : null,
            editSlug: adminStaff.slug,
            editImage: adminStaff.image,
            edit: true,
          });
        }
      }
    }catch {
      toast.error("Please try again", {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }   
    
  };

  //update adminStaff
  const handleUpdateAdminStaff = (e) => {
    e.preventDefault();
    //url and form data
    const adminStaffUrl = BASE_URL + `/settings/update-admin-staff`;
    let formData = new FormData();

    let orderTypes = [];
    if(newAdminStaff.user_type !== "admin" && newAdminStaff.user_type !== "superAdmin" && newAdminStaff.user_type !== "multi_manager"){
      newAdminStaff.orderType?.length > 0 && newAdminStaff.orderType.forEach((order_type)=>{
          orderTypes.push(order_type.id)
      });
    }
    
    formData.append("user_type", newAdminStaff.user_type);
    formData.append("name", newAdminStaff.name);
    formData.append("email", newAdminStaff.email);
    formData.append("phn_no", newAdminStaff.phn_no);
    formData.append("password", newAdminStaff.password);
    formData.append("user_passcode", newAdminStaff.user_passcode ? newAdminStaff.user_passcode : "");
    formData.append("order_type_id", JSON.stringify(orderTypes));
    formData.append(
      "password_confirmation",
      newAdminStaff.password_confirmation
    );
    formData.append(
      "sales_limit",
      newAdminStaff.sales_limit
    );
    
    if (newAdminStaff.selectPermissionGroup !== null) {
      formData.append(
        "permission_group_id",
        newAdminStaff.selectPermissionGroup.id
      );
    }
    if((newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") && (newAdminStaff.branch === null || newAdminStaff.branch === "")){
      toast.error(
        `${_t(t("Please select branch"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false;
    }
    if ((newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") && newAdminStaff.branch !== null) {
      if(newAdminStaff.user_type === "multi_manager"){
        let branches = newAdminStaff.branch.map((branch)=> branch.id);
        formData.append("branch_id", JSON.stringify(branches));
      }else{
        formData.append("branch_id", newAdminStaff.branch.id);
      }
    }else{
      formData.append("branch_id", "");
    }
    if(newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager"){
      formData.append("sbr_pos_id", newAdminStaff.sbr_pos_id);
      formData.append("sbr_ntn", newAdminStaff.sbr_ntn);
      formData.append("sbr_user_id", newAdminStaff.sbr_user_id);
      formData.append("sbr_user_password", newAdminStaff.sbr_user_password);
      formData.append("sbr_enabled", newAdminStaff.sbr_integration);
      formData.append("till_id", newAdminStaff.tillUser ? newAdminStaff.tillUser.id : "");
    }
    formData.append("image", newAdminStaff.image);
    formData.append("editSlug", newAdminStaff.editSlug);

    //check user type
    if (newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") {
      setAdminStaff({
        ...newAdminStaff,
        uploading: true,
      });

      return axios
        .post(adminStaffUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setAdminStaff({
            user_type: "",
            name: "",
            email: "",
            phn_no: "0",
            password: "",
            password_confirmation: "",
            sales_limit: 0,
            branch: null,
            selectPermissionGroup: null,
            selectedBranch: null,
            selectedPermissionGroup: null,
            sbr_pos_id : "",
            sbr_ntn : "",
            sbr_user_id : "",
            sbr_user_password: "",
            sbr_integration : 0,
            tillUser:null,
            orderType: null,
            image: null,
            edit: false,
            editSlug: null,
            editImage: null,
            uploading: false,
          });
          setAdminStaffList(res.data[0]);
          setAdminStaffListforSearch(res.data[1]);
          setLoading(false);
          toast.success(`${_t(t("User has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          if (closeBtnRef.current) {
            closeBtnRef.current.click();
          }
        })
        .catch((error) => {
          setLoading(false);
          setAdminStaff({
            ...newAdminStaff,
            uploading: false,
          });
          if (error && error.response.data.errors) {
            if (error.response.data.errors.email) {
              error.response.data.errors.email.forEach((item) => {
                if (item === "An user exists with this email") {
                  toast.error(`${_t(t("An user exists with this email"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  });
                }
              });
            }

            if (error.response.data.errors.phn_no) {
              error.response.data.errors.phn_no.forEach((item) => {
                if (item === "An user exists with this phone number") {
                  toast.error(
                    `${_t(t("An user exists with this phone number"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }

            if (error.response.data.errors.password) {
              error.response.data.errors.password.forEach((item) => {
                if (item === "Password confirmation does not match") {
                  toast.error(
                    `${_t(t("Password confirmation does not match"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
                if (item === "The password must be at least 6 characters.") {
                  toast.error(
                    `${_t(t("The password must be at least 6 characters"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }

            if (error.response.data.errors.image) {
              error.response.data.errors.image.forEach((item) => {
                if (item === "Please select a valid image file") {
                  toast.error(`${_t(t("Please select a valid image file"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  });
                }
                if (item === "Please select a file less than 5MB") {
                  toast.error(
                    `${_t(t("Please select a file less than 5MB"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }
          }
        });
    } else {
      setAdminStaff({
        ...newAdminStaff,
        uploading: true,
      });
      return axios
        .post(adminStaffUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setAdminStaff({
            user_type: "",
            name: "",
            email: "",
            phn_no: "0",
            password: "",
            password_confirmation: "",
            sales_limit: 0,
            branch: null,
            selectPermissionGroup: null,
            selectedBranch: null,
            selectedPermissionGroup: null,
            sbr_pos_id : "",
            sbr_ntn : "",
            sbr_user_id : "",
            sbr_user_password: "",
            sbr_integration : 0,
            tillUser:null,
            orderType: null,
            image: null,
            edit: false,
            editSlug: null,
            editImage: null,
            uploading: false,
          });
          setAdminStaffList(res.data[0]);
          setAdminStaffListforSearch(res.data[1]);
          setLoading(false);
          if (closeBtnRef.current) {
            closeBtnRef.current.click();
          }
          toast.success(`${_t(t("User has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        })
        .catch((error) => {
          setLoading(false);
          setAdminStaff({
            ...newAdminStaff,
            branch: null,
            selectPermissionGroup: null,
            uploading: false,
          });
          if (error && error.response.data.errors) {
            if (error.response.data.errors.password) {
              error.response.data.errors.password.forEach((item) => {
                if (item === "The password confirmation does not match.") {
                  toast.error(
                    `${_t(t("Password confirmation does not match"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
                if (item === "The password must be at least 6 characters.") {
                  toast.error(
                    `${_t(t("The password must be at least 6 characters"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }
            if (error.response.data.errors.phn_no) {
              error.response.data.errors.phn_no.forEach((item) => {
                if (item === "An user exists with this phone number") {
                  toast.error(
                    `${_t(t("An user exists with this phone number"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }
          }
        });
    }
  };

  //search admin staff here
  const handleSearch = (e) => {
    let searchInput = e.target.value?.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedAdminStaff({ ...searchedAdminStaff, searched: false });
    } else {
      let searchedList = adminStaffForSearch.filter((item) => {
        let lowerCaseItemName = item.name?.toLowerCase();
        let lowerCaseItemPhnNo = item.phn_no?.toLowerCase();
        let lowerCaseItemType = item.user_type?.toLowerCase();
        let lowerCaseItemBranch =
          item.branch_name !== null && item.branch_name?.toLowerCase();
        return (
          (lowerCaseItemName && lowerCaseItemName.includes(searchInput)) ||
          (lowerCaseItemPhnNo && lowerCaseItemPhnNo.includes(searchInput)) ||
          (lowerCaseItemType && lowerCaseItemType.includes(searchInput)) ||
          (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
        );
      });
      setSearchedAdminStaff({
        ...searchedAdminStaff,
        list: searchedList,
        searched: true,
      });
    }
  };

  //disable confirmation modal of adminStaff
  const handleDisableConfirmation = (slug,status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-danger text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t(`You want to ${status ? "enable" : "disable"} this user?`))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark rounded"
                onClick={() => {
                  handleDisableUser(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, please!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //disable adminStaff here
  const handleDisableUser = (slug) => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + `/settings/delete-admin-staff/${slug}`;
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setAdminStaffList(res.data[0]);
        setAdminStaffListforSearch(res.data[1]);
        setSearchedAdminStaff({
          ...searchedAdminStaff,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("User has been disabled"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //active confirmation modal of adminStaff
  const handleActiveConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-success">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to active this user?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark rounded"
                onClick={() => {
                  handleActiveUser(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, please!"))}
              </button>
              <button className="btn btn-primary ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleIntegration = (item) =>{
    let formData = new FormData();
    const url = BASE_URL + "/settings/enable-user-sbr-integration";
    formData.append("user_id", item.id);
    formData.append("sbr_enabled", !item?.sbr_enabled ? 1 : 0);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setAdminStaff({
            user_type: "",
            name: "",
            email: "",
            phn_no: "0",
            password: "",
            password_confirmation: "",
            branch: null,
            selectPermissionGroup: null,
            selectedBranch: null,
            selectedPermissionGroup: null,
            sbr_pos_id : "",
            sbr_ntn : "",
            sbr_user_id : "",
            sbr_user_password: "",
            sbr_integration : 0,
            tillUser:null,
            orderType: null,
            image: null,
            edit: false,
            editSlug: null,
            editImage: null,
            uploading: false,
          });
          setAdminStaffList(res.data[0]);
          setAdminStaffListforSearch(res.data[1]);
          setLoading(false);
          toast.success(`${_t(t("User has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          if (closeBtnRef.current) {
            closeBtnRef.current.click(); 
          };
        })
        .catch((error) => {
          setLoading(false);
       })
  } 

  //Active adminStaff here
  const handleActiveUser = (slug) => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + `/settings/delete-admin-staff/${slug}`;
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data === "noBranch") {
          setLoading(false);
          toast.error(
            `${_t(
              t(
                "The branch of this user not found, can not change active status"
              )
            )}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        } else {
          setAdminStaffList(res.data[0]);
          setAdminStaffListforSearch(res.data[1]);
          setSearchedAdminStaff({
            ...searchedAdminStaff,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("User has been activated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };


  return (
    <>
      <Helmet>
        <title>{_t(t("Users"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addWaiter" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newAdminStaff.edit
                    ? _t(t("Add new admin / staff"))
                    : _t(t("Update admin / staff"))}
                </h5>
              </div>
              <button
                type="button"
                ref={closeBtnRef}
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newAdminStaff.uploading === false ? (
                <div key="fragment-permission-1">
                  <form
                    onSubmit={
                      !newAdminStaff.edit
                        ? handleSaveNewAdminStaff
                        : handleUpdateAdminStaff
                    }
                    autoComplete="off"
                  >
                    {newAdminStaff.user_type === "superAdmin" ? (
                      <div>
                        <label htmlFor="user_type" className="form-label">
                          {_t(t("User type"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          name="user_type"
                          className="form-control"
                          onChange={handleSetNewUserType}
                          value={newAdminStaff.user_type}
                          disabled
                        >
                          <option value="superAdmin" className="text-uppercase">
                            {_t(t("Super Admin"))}
                          </option>
                        </select>
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="user_type" className="form-label">
                          {_t(t("User type"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          name="user_type"
                          className="form-control"
                          onChange={handleSetNewUserType}
                          title="Please select an user type"
                          required
                          value={newAdminStaff.user_type}
                          onInvalid={handleInvalid}
                          ref={selectRef}
                        >
                          <option value="">
                            {_t(t("Please select an user type"))}
                          </option>
                          <option value="admin" className="text-uppercase">
                            {_t(t("Admin"))}
                          </option>
                          <option value="manager" className="text-uppercase">
                            {_t(t("Manager"))}
                          </option>
                          <option value="staff" className="text-uppercase">
                            {_t(t("Staff"))}
                          </option>
                          <option value="call_center" className="text-uppercase">
                            {_t(t("Call Center Staff"))}
                          </option>
                          <option value="multi_manager" className="text-uppercase">
                            {_t(t("Multi Branch Manager"))}
                          </option>
                        </select>
                      </div>
                    )}

                    <div className="mt-3">
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Asif"
                        value={newAdminStaff.name || ""}
                        required
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="email" className="form-label">
                        {_t(t("Email"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="e.g. staff@example.com"
                        value={newAdminStaff.email || ""}
                        required
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="phn_no" className="form-label">
                        {_t(t("Phone number"))}{" "}
                      </label>
                      <input
                        type="text"
                        maxlength="12" 
                        className="form-control"
                        id="phn_no"
                        name="phn_no"
                        placeholder="e.g. 01xxx xxx xxx"
                        value={newAdminStaff.phn_no || ""}
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="password" className="form-label">
                        {_t(t("Password"))}{" "}
                        {!newAdminStaff.edit && (
                          <small className="text-danger">*</small>
                        )}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="e.g. Password"
                        value={newAdminStaff.password || ""}
                        required={!newAdminStaff.edit}
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>

                    <div className="mt-3">
                      <label
                        htmlFor="password_confirmation"
                        className="form-label"
                      >
                        {_t(t("Confirm Password"))}{" "}
                        {!newAdminStaff.edit && (
                          <small className="text-danger">*</small>
                        )}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="e.g. Confirm password"
                        value={newAdminStaff.password_confirmation || ""}
                        required={newAdminStaff.password !== ""}
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>

                    {/* {newAdminStaff.user_type === "staff" && (<div className="mt-3">
                      <label
                        htmlFor="sales_limit"
                        className="form-label"
                      >
                        {_t(t("limit"))}{" "}
                        
                      </label>
                      <input
                        min={0}
                        type="number"
                        className="form-control"
                        id="sales_limit"
                        name="sales_limit"
                        placeholder="e.g. 100000"
                        value={newAdminStaff.sales_limit}                        
                        onChange={handleSetNewAdminStaff}
                      />
                    </div>)} */}

                    {(newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "multi_manager") && (
                      <>
                      <div className="mt-3">
                        <label className="form-label">
                          {_t(t("Select a branch"))}{" "}
                          {/*newAdminStaff.edit ? (
                            <small className="text-primary">
                              {"( "}
                              {_t(
                                t(
                                  "Leave empty if you do not want to change branch"
                                )
                              )}
                              {" )"}
                            </small>
                          ) : (
                          )*/}
                          <small className="text-danger">*</small>
                        </label>
                        {/*newAdminStaff.edit &&
                          newAdminStaff.selectedBranch !== null && (
                            <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                              <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 my-1 text-center">
                                {newAdminStaff.selectedBranch.name}
                              </li>
                            </ul>
                          )*/}
                        <Select
                          options={branchForSearch}
                          //components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={newAdminStaff.branch}
                          key={newAdminStaff.branch?.length}
                          isMulti={newAdminStaff.user_type === "multi_manager" ? true : false}
                          onChange={handleSetBranch}
                          placeholder={_t(t("Please select a branch"))}
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="user_passcode" className="form-label">
                          {_t(t("Passcode"))}{" "}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="user_passcode"
                          name="user_passcode"
                          placeholder="e.g. Password"
                          value={newAdminStaff.user_passcode || ""}
                          onChange={handleSetNewAdminStaff}
                        />
                      </div>
                      </>
                    )}

                    {newAdminStaff.user_type !== "superAdmin" && (
                      <div className="mt-3">
                        <label className="form-label mb-0">
                          {_t(t("Select a permission group"))}{" "}
                          {newAdminStaff.edit ? (
                            <small className="text-primary">
                              {"( "}
                              {_t(
                                t(
                                  "Leave empty if you do not want to change permission group"
                                )
                              )}
                              {" )"}
                            </small>
                          ) : (
                            <small className="text-danger">*</small>
                          )}
                        </label>
                        {newAdminStaff.edit &&
                          newAdminStaff.selectedPermissionGroup !== null && (
                            <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                              <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 my-1 text-center">
                                {newAdminStaff.selectedPermissionGroup.name}
                              </li>
                            </ul>
                          )}
                        <Select
                          options={permissionGroupForSearch}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={newAdminStaff.selectPermissionGroup}
                          onChange={handleSetPermissionGroup}
                          placeholder={_t(t("Please select a group"))}
                        />
                      </div>
                    )}

                  {(newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager" || newAdminStaff.user_type === "call_center") && ( 
                    <div className="mt-3">
                      <label className="form-label mb-0">
                       {_t(t("Select Order Type"))}{" "}
                      </label>
                      <Select
                        value = {newAdminStaff.orderType}
                        options={orderTypeForSearch && orderTypeForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isMulti={true}
                        onChange={handleSetOrderTypeGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={_t(t("Please select order type"))}
                      />
                    </div>
                  )}

                    <div className="mt-3">
                      <div className="d-flex align-items-center mb-1">
                        <label htmlFor="image" className="form-label mb-0 mr-3">
                          {_t(t("Image"))}{" "}
                          <small className="text-secondary">
                            ({_t(t("300 x 300 Preferable"))})
                          </small>
                        </label>
                        {newAdminStaff.edit && (
                          <div
                            className="fk-language__flag"
                            style={{
                              backgroundImage: `url(${newAdminStaff.editImage})`,
                            }}
                          ></div>
                        )}
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        onChange={handleAdminStaffImage}
                      />
                    </div>

                    {
                      (newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager") && (
                        <div className="mt-3">
                          <label className="form-label mb-0">
                          {_t(t("Till User"))}{" "}
                          </label>
                          <Select
                            value = {newAdminStaff.tillUser}
                            options={tillList && newAdminStaff.branch && tillList[newAdminStaff.branch.id]}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(till)=>handleSetTillUser(till)}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder={_t(t("Please select till"))}
                            isClearable
                          />
                        </div>
                      )
                    }
             
                    {/*
                      getSystemSettings(generalSettings, "user_wise_integration") == 1 && (newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager") && (
                        <div className="mt-3 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input mr-1 ml-3 mt-0"
                            id="sbrIntegration"
                            checked={newAdminStaff.sbr_integration === 1}
                            onChange={(e)=>{
                              setAdminStaff({
                                ...newAdminStaff,
                                sbr_integration: newAdminStaff.sbr_integration === 1 ? 0 : 1
                              })
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="sbrIntegration"
                          >
                            {_t(t("SBR Integration"))}
                          </label>
    
                        </div>
                      ) 
                    */}

                    {/*getSystemSettings(generalSettings, "user_wise_integration") == 1 && (newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager") &&
                    (newAdminStaff.sbr_integration === true || newAdminStaff.sbr_integration === 1) && (
                       <div className="row">
                        <div className="mt-3 col-4 d-none">
                          <label className="form-label">
                            {_t(t("Payment Type"))}
                            {""}
                            <small className="text-danger">*</small>
                          </label>
                          <Select
                            value={newAdminStaff}
                            //options={}
                            // components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select table-text"
                            classNamePrefix="select"
                            isMulti={true}
                            //onChange={handleSetPaymentType}
                               
                            // placeholder={_t(t("Please select "))}
                          />
                        </div>
                        <div className="mt-3 col-3">
                          <label htmlFor="sbr_ntn" className="form-label">
                            {_t(t("NTN"))}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="sbr_ntn"
                            name="sbr_ntn"
                            sbr_ntn="sbr_ntn"
                            value={newAdminStaff.sbr_ntn || ""}
                            required
                            onChange={handleSetNewAdminStaff}
                          />
                        </div>
                        <div className="mt-3 col-3">
                          <label htmlFor="sbr_pos_id" className="form-label">
                            {_t(t("Pos ID"))}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="sbr_pos_id"
                            name="sbr_pos_id"
                            sbr_pos_id="sbr_pos_id"
                            value={newAdminStaff.sbr_pos_id || ""}
                            required
                            onChange={handleSetNewAdminStaff}
                          />
                        </div>  
                        <div className="mt-3 col-3">
                          <label htmlFor="sbr_user_id" className="form-label">
                            {_t(t("User Id"))}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="sbr_user_id"
                            name="sbr_user_id"
                            sbr_user_id="sbr_user_id"
                            // placeholder="e.g. 6baffg110-386a-3f83-a8re-ff84f548351"
                            value={newAdminStaff.sbr_user_id || ""}
                            required
                            onChange={handleSetNewAdminStaff}
                          />
                        </div>

                        <div className="mt-3 col-3">
                          <label
                            htmlFor="sbr_user_password"
                            className="form-label"
                          >
                            {_t(t("Password"))}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="password"
                            className="form-control "
                            id="sbr_user_password"
                            name="sbr_user_password"
                            sbr_user_password="sbr_user_password"
                            value={newAdminStaff.sbr_user_password || ""}
                            required
                            onChange={handleSetNewAdminStaff}
                          />
                        </div>                          
                       </div>                           
                    )*/}

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newAdminStaff.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newAdminStaff.edit
                            ? _t(t("Save"))
                            : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* view modal */}
      <div className="modal fade" id="viewUser" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {newAdminStaff.name}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <table className="table table-striped table-sm text-center mt-3">
                <tbody>
                  <tr>
                    <td className="text-capitalized">{_t(t("User type"))}</td>
                    <td>{newAdminStaff.user_type}</td>
                  </tr>
                  <tr>
                    <td className="text-capitalized">{_t(t("Name"))}</td>
                    <td>{newAdminStaff.name}</td>
                  </tr>
                  <tr>
                    <td className="text-capitalized">{_t(t("Email"))}</td>
                    <td>{newAdminStaff.email}</td>
                  </tr>
                  <tr>
                    <td className="text-capitalized">
                      {_t(t("Phone number"))}
                    </td>
                    <td>{newAdminStaff.phn_no}</td>
                  </tr>
                  {newAdminStaff.user_type !== "superAdmin" &&
                    newAdminStaff.selectedPermissionGroup !== null && (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Permission group"))}
                        </td>
                        <td>{newAdminStaff.selectedPermissionGroup.name}</td>
                      </tr>
                    )}

                  {(newAdminStaff.user_type === "staff" || newAdminStaff.user_type === "manager") &&
                    newAdminStaff.selectedBranch !== null && (
                      <tr>
                        <td className="text-capitalized">{_t(t("Branch"))}</td>
                        <td>{newAdminStaff.selectedBranch.name}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* view modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newAdminStaff.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedAdminStaff.searched
                                    ? _t(t("User List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addWaiter"
                                  onClick={() => {
                                    setAdminStaff({
                                      ...newAdminStaff,
                                      user_type: "",
                                      name: "",
                                      email:"",
                                      phn_no: 0,
                                      password:"",
                                      branch: null,
                                      user_type: "",
                                      selectPermissionGroup: null,
                                      selectedBranch: null,
                                      selectedPermissionGroup: null,
                                      sbr_pos_id : "",
                                      sbr_ntn : "",
                                      sbr_user_id : "",
                                      sbr_user_password: "",
                                      sbr_integration : 0,
                                      tillUser:null,
                                      edit: false,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-right text-primary xsm-text">
                          {_t(
                            t(
                              "Users marked red are disabled, they can not login"
                            )
                          )}
                          *
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Image"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Phn no"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Permission Group"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("user type"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedAdminStaff.searched
                                ? [
                                    adminStaffList && [
                                      adminStaffList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        adminStaffList.data.map(
                                          (item, index) => {
                                            let permissionGrp = permissionGroupForSearch && permissionGroupForSearch.find((group)=> group.id === item.permission_group_id);
                                            return (
                                              <tr
                                                className={`align-middle ${
                                                  parseInt(item.is_banned) &&
                                                  "text-danger"
                                                } ${parseInt(item.sbr_enabled) &&
                                                "text-primary"}`}
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (adminStaffList.current_page -
                                                      1) *
                                                      adminStaffList.per_page}
                                                </th>

                                                <td className="xsm-text">
                                                  <div
                                                    className="table-img-large mx-auto"
                                                    style={{
                                                      backgroundImage: `url(${
                                                        item.image !== null
                                                          ? item.image
                                                          : "/assets/img/admin.png"
                                                      })`,
                                                    }}
                                                  ></div>
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <a
                                                    href={`tel:${item.phn_no}`}
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.phn_no}
                                                  </a>
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.branch_name || "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {permissionGrp?.name || "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.user_type || "-"}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/*<div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          setAdminStaff({
                                                            ...newAdminStaff,
                                                            branch: null,
                                                          });
                                                          handleSetEdit(
                                                            item.slug
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#viewUser"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-eye"></i>
                                                        </span>
                                                        {_t(t("View"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          setAdminStaff({
                                                            ...newAdminStaff,
                                                            branch: null,
                                                          });
                                                          handleSetEdit(
                                                            item.slug
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addWaiter"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      {item.user_type !==
                                                        "superAdmin" && [
                                                        !parseInt(
                                                          item.is_banned
                                                        ) ? (
                                                          <button
                                                            className="dropdown-item sm-text text-capitalize"
                                                            onClick={() => {
                                                              handleDisableConfirmation(
                                                                item.slug
                                                              );
                                                            }}
                                                          >
                                                            <span className="t-mr-8">
                                                              <i className="fa fa-times"></i>
                                                            </span>
                                                            {_t(t("Disable"))}
                                                          </button>
                                                        ) : (
                                                          <button
                                                            className="dropdown-item sm-text text-capitalize"
                                                            onClick={() => {
                                                              handleActiveConfirmation(
                                                                item.slug
                                                              );
                                                            }}
                                                          >
                                                            <span className="t-mr-8">
                                                              <i className="fa fa-check"></i>
                                                            </span>
                                                            {_t(t("Active"))}
                                                          </button>
                                                        ),
                                                      ]}
                                                    </div>
                                                    </div>*/}
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <button
                                                        className="btn btn-success ml-2 rounded-sm del-btn"
                                                        onClick={() => {
                                                          setAdminStaff({
                                                            ...newAdminStaff,
                                                            branch: null,
                                                          });
                                                          handleSetEdit(
                                                            item.id
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#viewUser"
                                                        >
                                                        <i className="fa fa-eye"></i>
                                                      </button>
                                                      <button
                                                        className="btn btn-primary ml-2 rounded-sm del-btn"
                                                        onClick={() => {
                                                          setAdminStaff({
                                                            ...newAdminStaff,
                                                            branch: null,
                                                          });
                                                          handleSetEdit(
                                                            item.id
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addWaiter"
                                                        >
                                                        <i className="fa fa-pencil"></i>
                                                      </button>
                                                      {
                                                        item.user_type !==
                                                          "superAdmin" && (
                                                            <button
                                                              className={`btn ${!parseInt(item.is_banned) ? "btn-danger" : "btn-success"} ml-2 rounded-sm del-btn`}
                                                              onClick={() => {
                                                                handleDisableConfirmation(
                                                                  item.slug, parseInt(
                                                                    item.is_banned
                                                                  )
                                                                );
                                                              }}
                                                              >
                                                              {
                                                                !parseInt(
                                                                  item.is_banned
                                                                ) ? (
                                                                  <i className="fa fa-times"></i>
                                                                ): (
                                                                  <i className="fa fa-check"></i>
                                                                )
                                                              }
                                                            </button>
                                                          )
                                                      }
                                                      {/*
                                                        getSystemSettings(generalSettings, "user_wise_integration") == 1 && (
                                                          <span className="d-flex align-items-center">
                                                            <Switch
                                                                onChange={ (e) => { handleIntegration(item) } }
                                                                checked={(item?.sbr_enabled == '1') ? 1 : 0}
                                                                className="react-switch ml-2"
                                                                name="sbr_enabled"
                                                                height={19}
                                                                width={38}
                                                                offColor="#ee5253"
                                                              />
                                                             </span>  
                                                          )
                                                      */}    
                                                      
                                                    </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedAdminStaff && [
                                      searchedAdminStaff.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedAdminStaff.list.map(
                                          (item, index) => {
                                            let permissionGrp = permissionGroupForSearch && permissionGroupForSearch.find((group)=> group.id === item.permission_group_id)
                                            return (
                                              <tr
                                                className={`align-middle ${
                                                  parseInt(item.is_banned) &&
                                                  "text-danger"
                                                } ${parseInt(item.sbr_enabled) &&
                                                  "text-primary"}`}
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (adminStaffList.current_page -
                                                      1) *
                                                      adminStaffList.per_page}
                                                </th>

                                                <td className="xsm-text">
                                                  <div
                                                    className="table-img-large mx-auto"
                                                    style={{
                                                      backgroundImage: `url(${
                                                        item.image !== null
                                                          ? item.image
                                                          : "/assets/img/admin.png"
                                                      })`,
                                                    }}
                                                  ></div>
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <a
                                                    href={`tel:${item.phn_no}`}
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.phn_no}
                                                  </a>
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.branch_name || "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {permissionGrp?.name || "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.user_type || "-"}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <button
                                                      className="btn btn-success ml-2 rounded-sm del-btn"
                                                      onClick={() => {
                                                        setAdminStaff({
                                                          ...newAdminStaff,
                                                          branch: null,
                                                        });
                                                        handleSetEdit(
                                                          item.id
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#viewUser"
                                                      >
                                                      <i className="fa fa-eye"></i>
                                                    </button>
                                                    <button
                                                      className="btn btn-primary ml-2 rounded-sm del-btn"
                                                      onClick={() => {
                                                        setAdminStaff({
                                                          ...newAdminStaff,
                                                          branch: null,
                                                        });
                                                        handleSetEdit(
                                                          item.id
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#addWaiter"
                                                      >
                                                      <i className="fa fa-pencil"></i>
                                                    </button>
                                                    {
                                                      item.user_type !==
                                                        "superAdmin" && (
                                                          <button
                                                            className={`btn ${!parseInt(item.is_banned) ? "btn-danger" : "btn-primary"} ml-2 rounded-sm del-btn`}
                                                            onClick={() => {
                                                              handleDisableConfirmation(
                                                                item.slug, parseInt(
                                                                  item.is_banned
                                                                )
                                                              );
                                                            }}
                                                            >
                                                            {
                                                              !parseInt(
                                                                item.is_banned
                                                              ) ? (
                                                                <i className="fa fa-times"></i>
                                                              ): (
                                                                <i className="fa fa-check"></i>
                                                              )
                                                            }
                                                          </button>
                                                        )
                                                    }

                                                    {/*
                                                      getSystemSettings(generalSettings, "user_wise_integration") == 1 && (
                                                        <span className="d-flex align-items-center">
                                                          <Switch
                                                              onChange={ (e) => { handleIntegration(item) } }
                                                              checked={(item?.sbr_enabled == '1') ? 1 : 0}
                                                              className="react-switch ml-2"
                                                              name="sbr_enabled"
                                                              height={19}
                                                              width={38}
                                                              offColor="#ee5253"
                                                            />
                                                           </span>  
                                                      )
                                                    */}
                                                    
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newAdminStaff.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedAdminStaff.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                adminStaffList,
                                setPaginatedAdminStaff
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(adminStaffList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() =>
                                    setSearchedAdminStaff({
                                      ...searchedAdminStaff,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedAdminStaff,
                                    adminStaffForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default AdminStaffCrud;
