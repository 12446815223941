import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";

const MenuMixReport = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
    let { foodForPOS } = useContext(FoodContext);    
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [itemData, setItemData] = useState(null);
    const [branchDateWise, setBranchDateWise] = useState(null);
    const componentRef = useRef();
    const componentRefThermal = useRef();
    const componentRef1 = useRef();
    const componentRef2 = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [item, setItem] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [printReport, setPrintReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("excel");
    const [reportOption,setReportOption] = useState("menu_mix_report");

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const thermalPrint = useReactToPrint({
        content: () => componentRefThermal.current,
      });

      const handleBranch=(branch)=>{
        setBranch(branch)
      }

      const handleItem=(item)=>{
        setItem(item)
      }

      const handleOption  = (e) =>{
        setOption(e.target.value)
      }

      const handleSetOption  = (e) =>{
        setReportOption(e.target.value)
      }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      setPrintReport(false)
      if (        
        startDate !== null &&
        endDate !== null
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-item-menu-mix-category-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id ? branch.id : "");
        formData.append("item", item ? item?.name : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("ReportResult", res);
            setReportData(res.data)
            setDataPaginating(false);
            if(option==="excel"){
              setGeneratedReport(true);
              componentRef.current.handleDownload();
            }else{
              setPrintReport(true)
              thermalPrint();
            }
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date and to date"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    const getBranchItemWise = () => {
      setItemData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-item-wise-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch ? branch?.id : "");
        formData.append("item", item ? item?.name : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setGeneratedReport(true);
            setItemData(res.data[0])
            setDataPaginating(false);
            componentRef1.current.handleDownload();
            
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date and to date"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    const getBranchDateWise = () => {
      setBranchDateWise(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-menu-mix-date-wise-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch ? branch?.id : "");
        formData.append("item_name", item?.name ? item.name : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setGeneratedReport(true);
            setBranchDateWise(res.data)
            setDataPaginating(false);
            componentRef2.current.handleDownload();
            
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(t("Please select from date and to date"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    const handleCheckReport = (e) =>{
      e.preventDefault();
      if(reportOption === "menu_mix_date_wise"){
        getBranchDateWise()
      }else if(reportOption === "item_wise_report"){
        getBranchItemWise()
      }else{
        getBranchWiseSelected()
      }
    }

    let g_total_qty = 0;
    let g_total_amount = 0;
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Menu Mix Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Menu Mix Report"
          sheet="sheet 1"
          buttonText="Menu Mix Detail"
          ref={componentRef}
        />  
        
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="item-to-xls"
          filename="Menu Mix Item Wise Report"
          sheet="sheet 1"
          buttonText="Item Wise Detail"
          ref={componentRef1}
        /> 

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="branch-to-xls"
          filename="Menu Mix Date Wise Report"
          sheet="sheet 1"
          buttonText="Branch Wise Detail"
          ref={componentRef2}
        /> 
  
        {/* Excel Report*/}
        <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                    reportData !== undefined &&
                    reportData &&
                    generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="7" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Menu Mix Report
                                        </h3>
                                        <h4 className="text-center mt-0">
                                          {branch && branch.name}
                                        </h4>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Group Name"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Item Id"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Item"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Price"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>                                  
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                reportData[0] && Object.keys(reportData[0]).length>0 && Object.keys(reportData[0]).map(
                                  (branch_name, ind) => {
                                    return(
                                    <div key={ind}>
                                      <tr>
                                        <th
                                          scope="row"
                                          className="lg-text text-capitalize align-middle text-center"
                                        >
                                          {branch_name}
                                        </th>
                                      </tr>
                                        {
                                          Object.keys(reportData[0][branch_name]).length>0 && Object.keys(reportData[0][branch_name]).map(
                                          (group_name, index) => {
                                            let totalQty = 0;
                                            let totalAmount = 0;                                      
                                            return(
                                            <div key={index}>
                                          
                                            <tr>
                                              <th></th>
                                              <th
                                                scope="row"
                                              >
                                                {group_name}
                                              </th>
                                            </tr>
                                            {reportData[0][branch_name][group_name]?.length > 0 && reportData[0][branch_name][group_name].map((item, i) => {
                                              totalQty += parseFloat(item.qty);
                                              totalAmount += parseFloat(item.amount); 
                                              g_total_qty += parseFloat(item.qty);
                                              g_total_amount += parseFloat(item.amount);                               
                                              let price = item.amount > 0 && item.qty > 0 ? (parseFloat(item.amount) / parseFloat(item.qty)) : 0;
                                              
                                              return (
                                                <tr>
                                                <td></td>
                                                <td></td>
                                                  <td
                                                  scope="row"
                                                  >
                                                    {item?.item_id}                                        
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.item_name}                                        
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.qty}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {price?.toFixed(2)}
                                                  </td>                                     
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.amount}
                                                  </td>                                                                                                                
                                                </tr>
                                              );
                                            })}
                                            <tr>                                  
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <th
                                                scope="row"
                                              >
                                                Total
                                              </th>                                  
                                                <td
                                                  scope="row"
                                                >
                                                    <strong>
                                                {formatPrice(totalQty)}
                                                </strong>
                                                </td>
                                                <td></td>
                                                <td
                                                  scope="row"
                                                >
                                                    <strong>
                                                      {formatPrice(totalAmount?.toFixed(2))}
                                                    </strong>
                                                </td>                                
                                            </tr>
                                            </div>
                                            );
                                              }
                                            )
                                          }
                                      </div>
                                    )
                                  }
                                )}
                                <tr>                                  
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <th
                                    scope="row"
                                  >
                                    Grand Total
                                  </th>                                  
                                    <td
                                      scope="row"
                                    >
                                      <strong>
                                        {formatPrice(g_total_qty)}
                                      </strong>
                                    </td>
                                    <td></td>
                                    <td
                                      scope="row"
                                    >
                                      <strong>
                                        {formatPrice(g_total_amount?.toFixed(2))}
                                      </strong>
                                    </td>                                
                                </tr>
                                {
                                reportData[1] && Object.keys(reportData[1]).length > 0 && Object.keys(reportData[1]).map(
                                  (branch_name, ind) => {
                                    return(
                                    <>
                                    <tr></tr>
                                      <tr>
                                        <th colSpan={7}>{branch_name}</th>
                                      </tr>
                                    {reportData[1][branch_name] && reportData[1][branch_name]?.length > 0 && <tr>
                                      <th
                                        scope="row"
                                        colSpan={7}
                                      >
                                        Details
                                      </th>
                                    </tr>}
                                    {reportData[1][branch_name] && reportData[1][branch_name]?.length > 0 && <tr>
                                      <th
                                        scope="col"
                                        colSpan={4}
                                      >
                                        {_t(t("Item"))}
                                      </th>
                                      <th
                                        scope="col"
                                        colSpan={3}
                                      >
                                        {_t(t("Qty"))}
                                      </th>
                                    </tr>}
                                    {reportData[1][branch_name] && reportData[1][branch_name]?.length > 0 && reportData[1][branch_name].map((value, ind) => {
                                      return(
                                        <tr key={ind}>
                                          <td
                                            scope="row"
                                            colSpan={4}
                                          >
                                            {value.item_name}
                                          </td>
                                          <td
                                            scope="row"
                                            colSpan={3}
                                          >
                                            {value.qty}
                                          </td>
                                        </tr>
                                      )
                                    })}
    
                                    {reportData[2] && reportData[2][branch_name] && reportData[2][branch_name]?.length > 0 && <tr>
                                      <th
                                        scope="row"
                                        colSpan={7}
                                      >
                                        Deals Details
                                      </th>
                                    </tr>}
                                    {reportData[2] && reportData[2][branch_name] && reportData[2][branch_name]?.length > 0 && <tr>
                                      <th
                                        scope="col"
                                        colSpan={4}
                                      >
                                        {_t(t("Item"))}
                                      </th>
                                      <th
                                        scope="col"
                                        colSpan={3}
                                      >
                                        {_t(t("Qty"))}
                                      </th>
                                    </tr>}
                                    {reportData[2] && reportData[2][branch_name] && reportData[2][branch_name]?.length > 0 && reportData[2][branch_name].map((value, ind) => {
                                      return(
                                        <tr key={ind}>
                                          <td
                                            scope="row"
                                            colSpan={4}
                                          >
                                            {value.item_name}
                                          </td>
                                          <td
                                            scope="row"
                                            colSpan={3}
                                          >
                                            {value.qty}
                                          </td>
                                        </tr>
                                      )
                                    })} 
                                  </> 
                                  )}
                                )}
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* item wise Excel Report*/}
        <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {itemData !== null &&
                    itemData !== undefined &&
                    generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="item-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="6" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Item Wise Sale Report
                                        </h3>
                                        <h4 className="text-center mt-0">
                                          {branch && branch.name}
                                        </h4>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Item"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Single"))}
                                  </th> 
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Deal"))}
                                  </th>  
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Total"))}
                                  </th>                                  
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                
                                {
                                  Object.keys(itemData).map((grp,index)=>{
                                    return(
                                      <div>
                                        <tr key={index}>
                                          <th>{grp}</th>
                                        </tr>
                                    
                                      {itemData[grp].map((item, i) => {
                                        const Qty = parseFloat(item.deal) + parseFloat(item.single);
                                        
                                        return (
                                          <tr key={i}>
                                          <td></td>
                                          <td>{i +1}</td>
                                            <td
                                            scope="row"
                                            >
                                              {item.food_item}                                      
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.single}                                        
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.deal}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              <strong>
                                              {Qty}
                                              </strong>
                                            </td>                                     
                                                                                                                                                          
                                          </tr>
                                        );
                                      })}
                                      </div>
                                      )
                                    })
                                }
                                
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="4"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* date wise Excel Report*/}
        <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {branchDateWise !== null &&
                    branchDateWise !== undefined &&
                    generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="branch-to-xls"
                            >
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan={branchDateWise[0].length + 3} style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Menu Mix Date Wise Report
                                        </h3>
                                        <h4 className="text-center mt-0">
                                          {branch && branch.name}
                                        </h4>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Food Item"))}
                                  </th>
                                  {
                                    branchDateWise[0].map(item => {
                                      return(
                                        <th
                                          scope="col"
                                        >
                                          {_t(t(item))}
                                        </th>
                                      )
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                
                                {
                                  Object.keys(branchDateWise[1]).map((grp,index)=>{
                                    return(
                                      <div>
                                        <tr key={index}>
                                          <th>{grp}</th>
                                        </tr>
                                    
                                      {branchDateWise[1][grp].map((item, i) => {
                                        return (
                                          <tr key={i}>
                                          <td></td>
                                          <td>{i + 1}</td>
                                            <td
                                            scope="row"
                                            >
                                              {item.food_item}                                      
                                            </td>
                                            {
                                              branchDateWise[0].map(key => {
                                                return(
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item[key] ? item[key] : "-"}                                        
                                                  </td>
                                                )
                                              })
                                            }
                                          </tr>
                                        );
                                      })}
                                      <tr></tr>
                                      </div>
                                      )
                                    })
                                }
                                
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="4"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* print */}
        <div className="d-none">
          <div ref={componentRefThermal}>
            <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      {siteName}
                    </span>
                  </div>
                  <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    FROM&nbsp;
                    <Moment format="LL">
                        {startDate}
                      </Moment>
                      &nbsp;TO&nbsp;
                      <Moment format="LL">{endDate}</Moment>
                    </span>
                  </div>
                  <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Print Time:{" "} 
                    <Moment format="LLL">
                      {new Date()}
                    </Moment>
                  </span>
                  </div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Menu Mix Report
                  </span>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    {branch && branch.name}
                  </span>
                  {/*catg wise report */}
                  <div className="col-12">
                    {
                      reportData &&
                      reportData !== null && printReport && (
                    <div>
                      <p className="mx-1 xsm-text table-text_pos text-capitalize border border-dark py-1 d-flex justify-content-between">
                      <span className="font-weight-bold pl-1" style={{width:"40.5%"}}>
                        {
                        "Item"}
                      </span>
                      <span className="font-weight-bold" style={{width:"19%"}}>
                        {
                          "Qty"}
                        </span>

                        <span className="font-weight-bold" style={{width:"22.5%"}}>
                        {
                          "Price"}
                        </span>

                        <span className="font-weight-bold pr-1" style={{width:"18%",textAlign:"right"}}>
                        {
                          "Amount"}
                        </span>
                      </p>
                    </div>
                    )}
                    {
                      reportData &&
                      reportData !== null && printReport &&
                      Object.keys(reportData[0])?.map((branch)=>{
                      let totalMenuGroupQty = 0;
                      let totalMenuGroupPrice = 0;
                      return(
                      <>
                          <div className="w-100 col-12 mt-2">
                          <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center lg-text">{branch}</span>
                          </div> 
                          {Object.keys(reportData[0][branch])?.map((group)=>{
                              let menuGroupQty = 0;
                              let menuGroupPrice = 0;
                              return(
                              <div>
                                  <div className="w-100 col-12">
                                  <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">{group}</span>
                                  </div> 
                                  {
                                    reportData[0][branch][group]?.map((item)=>{
                                      let price = item.amount > 0 && item.qty > 0 ? (parseFloat(item.amount) / parseFloat(item.qty)) : 0;
                                      menuGroupQty += parseInt(item.qty);
                                      menuGroupPrice += parseFloat(item.amount);
                                      totalMenuGroupQty += parseInt(item.qty);
                                      totalMenuGroupPrice += parseFloat(item.amount);
                                      let lowerCaseItem = item?.item_name?.toLowerCase()
                                      let boldItem = lowerCaseItem?.includes("deal");
                                      return(
                                        <div className="text-capitalize w-100 d-flex justify-content-between py-1">
                                          <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`} style={{width:"40.2%"}}>{item?.item_name}</span>
                                          <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`} style={{width:"17%"}}>{item.qty}</span>
                                          <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`}  style={{width:"24%"}}>{price?.toFixed(2)}</span>
                                          <span className={`float-right sm-text pr-1 ${boldItem && "font-weight-bold"}`} style={{width:"18%",textAlign:"right"}}>{parseFloat(item.amount)?.toFixed(2)}</span>
                                        </div>
                                      )
                                  })
                                  }
                                  <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                                    <span style={{width:"41.5%"}}>Total</span>
                                    <span className="mx-2 ml-1"  style={{width:"17%"}}>{menuGroupQty}</span>
                                    <span style={{width:"23%"}}></span>
                                    <span className="float-right" style={{width:"18%",textAlign:"right"}}>{menuGroupPrice?.toFixed(2)}</span>
                                  </div>
                              </div>  
                              )
                          })
                          }
                          {
                            reportData !== null && reportData && (
                              <div> 
                                <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                                <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                                    <span style={{width:"41.5%"}}>Grand Total </span>
                                  <span className="mx-2 ml-1"  style={{width:"17%"}}>{totalMenuGroupQty}</span>
                                  <span style={{width:"23%"}}></span>
                                  <span className="float-right" style={{width:"18%",textAlign:"right"}}>{totalMenuGroupPrice?.toFixed(2)}</span>
                                </div>
                              </div> 
                          )
                          }
                      </>
                    )})} 
                    {reportData &&
                      reportData !== null && printReport &&
                      reportData[1] && Object.keys(reportData[1])?.map((branch)=>{
                        return(
                        <>
                            <div className="w-100 col-12 mt-3">
                            <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center lg-text">{branch}</span>
                            </div> 
                          {
                            reportData !== null && reportData[1][branch] &&
                            reportData[1][branch]?.length > 0 && (
                              <>
                                <span className="mx-2 my-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">Details</span>
                                <div>
                                    <p className="mb-1 table-text text-capitalize d-flex justify-content-between border border-dark py-1">
                                    <span className="font-weight-bold mx-2">
                                      Item
                                    </span>
                    
                                    <span className="font-weight-bold pr-1">
                                      Qty
                                    </span>
                                    </p>
                                </div>
                                {
                                  reportData[1][branch].map((item)=>{                    
                                      return (
                                        <div className="mb-1 print-text text-capitalize d-flex justify-content-between w-100">
                                          <span className="mx-2 print-text fk-print-text_pos--bold" >{item.item_name}</span>
                                          <span className="mx-2 print-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{item.qty}</span>
                                        </div>
                                      )                   
                                  })
                                }
                              </>
                            )
                          }
                          {
                            reportData !== null && reportData[2] && reportData[2][branch] &&
                            reportData[2][branch]?.length > 0 && (
                              <>
                                <span className="mx-2 my-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">Deals Details</span>
                                <div>
                                    <p className="mb-1 table-text text-capitalize d-flex justify-content-between border border-dark py-1">
                                    <span className="font-weight-bold mx-2">
                                      Item
                                    </span>
                    
                                    <span className="font-weight-bold pr-1">
                                      Qty
                                    </span>
                                    </p>
                                </div>
                                {
                                  reportData[2][branch].map((item)=>{                         
                                      return (
                                        <div className="mb-1 print-text text-capitalize d-flex justify-content-between w-100">
                                          <span className="mx-2 print-text fk-print-text_pos--bold" >{item.item_name}</span>
                                          <span className="mx-2 print-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{item.qty}</span>
                                        </div>
                                      )                   
                                  })
                                }
                              </>
                            )
                          }
                      </>
                      ) 
                    })  
                    }   
                  </div>
                </div> 
              </div> 
            </div> 
            </div> 
          </div> 
        </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Menu Mix Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_report"
                                  name="print_report"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>

                            <div className="col-2 ">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>

                            <div className="col-3">
                              <Select
                                options={foodForPOS && foodForPOS}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                value={item}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleItem}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select item")) + ".."}
                              />
                            </div> 

                          </div> 
                          <div className="mt-3 row">
                          <div className="col-3"></div>
                            <div className="d-flex justify-content-end col-7">
                              <div className="mr-4 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="menu_mix_report"
                                  name="menu_mix_report"
                                  value="menu_mix_report"
                                  onChange={(e) => {
                                    handleSetOption(e);
                                  }}
                                  checked={reportOption === "menu_mix_report"}
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1"
                                  htmlFor="menu_mix_report"
                                >
                                  {_t(t("Menu Mix Report"))}
                                </label>
                              </div>
                              <div className="mr-4 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="item_wise_report"
                                  name="item_wise_report"
                                  value="item_wise_report"
                                  onChange={(e) => {
                                    handleSetOption(e);
                                  }}
                                  checked={reportOption === "item_wise_report"}
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1"
                                  htmlFor="item_wise_report"
                                >
                                  {_t(t("Item Wise Report"))}
                                </label>
                              </div>
                              <div className="mr-4 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="menu_mix_date_wise"
                                  name="menu_mix_date_wise"
                                  value="menu_mix_date_wise"
                                  onChange={(e) => {
                                    handleSetOption(e);
                                  }}
                                  checked={reportOption === "menu_mix_date_wise"}
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1"
                                  htmlFor="menu_mix_date_wise"
                                >
                                  {_t(t("Menu Mix Date Wise"))}
                                </label>
                              </div>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => handleCheckReport(e)}
                              >
                               PRINT REPORT
                              </button>
                            </div>
                          </div>
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default MenuMixReport
