import React from 'react';
import { useTranslation } from "react-i18next";
import {
    _t,
    formatPrice,
} from "../../../../../functions/Functions";

const ViewAccountSheet = ({accountLedger, setAccountLedger}) => {
    const { t } = useTranslation();
    const handleCloseItemModal = () =>{
        setAccountLedger({
            modal: false,
            ledger: null
        })
    }

    let debit = 0;
    let credit = 0;

    return (
        <div
        className={`modal fade ${
            accountLedger.modal ? "showing" : ""
        }`}
        aria-hidden="true"
        >
            <div className="modal-dialog modal-md">
                {
                    accountLedger.modal ? (
                        <div className='modal-content'>
                            <div className="modal-header py-1 bg-primary text-white">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className = "rider-text text-white">
                                    View Account Ledger
                                </div>
                                <button
                                type="button"
                                className="btn text-white"
                                style={{fontSize:"1.6rem", padding:"0"}}
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => handleCloseItemModal()}
                                >&times;</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive mt-4 mb-2">
                                    {/* Table */}
                                    <table className="table table-bordered table-hover" style={{height:"225px"}}>
                                    {
                                        Array.isArray(accountLedger.ledger) && accountLedger.ledger?.length ? (
                                        <>
                                            <thead className="align-middle">
                                            <tr style={{backgroundColor:"#f3f3f3"}}>

                                                <th
                                                style={{minWidth:"150px"}}
                                                scope="col"
                                                className="table-text text-capitalize align-middle text-center border-1 border font-weight-md"
                                                >
                                                    {_t(t("COA Name"))}
                                                </th>

                                                <th
                                                scope="col"
                                                className="table-text text-capitalize align-middle text-center border-1 border font-weight-md"
                                                >
                                                    {_t(t("Debit"))}
                                                </th>

                                                <th
                                                scope="col"
                                                className="table-text text-capitalize align-middle text-center border-1 border font-weight-md"
                                                >
                                                    {_t(t("Credit"))}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="align-middle">
                                                {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                {accountLedger.ledger && accountLedger.ledger.map((item, index) => {
                                                    debit += Number(item.debit);
                                                    credit += Number(item.credit);
                                                    return (
                                                        <tr
                                                            className="align-middle"
                                                            key={index}
                                                        >
                                                            <td
                                                            scope="row"
                                                            className="table-text text-capitalize align-middle"
                                                            >
                                                                {item.account_name}
                                                            </td>

                                                            <td className="table-text text-capitalize align-middle text-center">
                                                                {formatPrice(Number(item.debit))}
                                                            </td>

                                                            <td className="table-text text-capitalize align-middle text-center">
                                                                {formatPrice(Number(item.credit))}
                                                            </td>
                                                        </tr>
                                                        );
                                                    })
                                                }
                                                <tr
                                                    className="align-middle"
                                                    style={{backgroundColor:"#f3f3f3"}}
                                                >
                                                    <th className='table-text text-capitalize align-middle font-weight-md border-1 border'>
                                                        Total
                                                    </th>

                                                    <td className="table-text text-capitalize align-middle font-weight-md text-center border-1 border">
                                                    {formatPrice(debit)}
                                                    </td>

                                                    <td className="table-text text-capitalize align-middle font-weight-md text-center border-1 border">
                                                    {formatPrice(credit)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        ) : ""
                                    }
                                    </table>
                                </div>
                            </div> 
                        </div>
                    ) : ""
                }
            </div> 
        </div> 
    )
}

export default ViewAccountSheet
