import React, { useEffect, useContext } from "react";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  managePageHrefLink,
  managePageHrefLinkMobile,
  checkPermission
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../contexts/User";


const AccountsSidebar = () => {
  const { t } = useTranslation();

  let { authUserInfo } = useContext(UserContext);

  useEffect(() => {
    handleJquery();
  }, []);

  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });
  };

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              <ul className="t-list fk-sm-nav__bar flex-row">
                {/* Foods */}

                {managePageHrefLinkMobile(
                  "/dashboard/accounts/gl-setting",
                  _t(t("GL Module Setting"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/accounts/level-1",
                  _t(t("Chart of Account L-1"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/accounts/level-2",
                  _t(t("Chart of Account L-2"))
                )}
     

                {managePageHrefLinkMobile(
                  "/dashboard/accounts/level-3",
                  _t(t("Chart of Account L-3"))
                )}

               {/* bank transaction */}
              {managePageHrefLinkMobile(
                "/dashboard/account/transaction/bank-payment",
                _t(t("Bank Payment"))
               )
              }

              { managePageHrefLinkMobile(
                  "/dashboard/account/transaction/cash-payment",
                  _t(t("Cash Payment"))
                )
              }

              {managePageHrefLinkMobile(
                  "/dashboard/account/transaction/journal-voucher",
                  _t(t("Journal Voucher"))
                )
              }

              { managePageHrefLinkMobile(
                  "/dashboard/account/transaction/opening-account",
                  _t(t("Opening Account"))
                )
              }        

              {/* Reports */}
              {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/account/reports/accounts-report",
                    _t(t("Accounts Reports"))
                  )
                : ""} 

              {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/account/reports/ledger-report",
                    _t(t("Ledger Reports"))
                  )
                : ""} 

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/account/reports/trial-balance-report",
                    _t(t("Trial Balance Reports"))
                  )
                : ""} 

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/account/reports/balance-sheet-report",
                    _t(t("Balance Sheet Reports"))
                  )
                : ""}

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/account/reports/profit-loss-report",
                    _t(t("Profit & Loss Reports"))
                  )
                : ""}                  

              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* GL Setup */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/accounts/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("GL Setup "))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
              {managePageHrefLink(
                    "/dashboard/accounts/gl-setting",
                    _t(t("GL Module Setting"))
                  )
                  } 

                  {managePageHrefLink(
                    "/dashboard/accounts/level-1",
                    _t(t("Chart of Account L-1"))
                  )
                  }

                  {managePageHrefLink(
                    "/dashboard/accounts/level-2",
                    _t(t("Chart of Account L-2"))
                  )
                  }

                  {managePageHrefLink(
                    "/dashboard/accounts/level-3",
                    _t(t("Chart of Account L-3"))
                  )
                  }           
                
              </ul>
            </li>
            {/* Bank Transactions */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/transaction/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Bank Transactions"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

              {managePageHrefLink(
                    "/dashboard/account/transaction/bank-payment",
                    _t(t("Bank Payment"))
                  )
                }

                { managePageHrefLink(
                    "/dashboard/account/transaction/cash-payment",
                    _t(t("Cash Payment"))
                  )
                }

                {managePageHrefLink(
                    "/dashboard/account/transaction/journal-voucher",
                    _t(t("Journal Voucher"))
                  )
                }

                { managePageHrefLink(
                    "/dashboard/account/transaction/opening-account",
                    _t(t("Opening Account"))
                  )
                }                
                
              </ul>
            </li>
            {/* Reports */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/reports/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

              {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/account/reports/accounts-report",
                    _t(t("Accounts Reports"))
                  )
                : ""}   

              {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/account/reports/ledger-report",
                    _t(t("Ledger Reports"))
                  )
                : ""} 

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/account/reports/trial-balance-report",
                    _t(t("Trial Balance Reports"))
                  )
                : ""}

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/account/reports/balance-sheet-report",
                    _t(t("Balance Sheet Reports"))
                  )
                : ""}

                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Ledger Report")
                  ? managePageHrefLink(
                    "/dashboard/account/reports/profit-loss-report",
                    _t(t("Profie & Loss Reports"))
                  )
                : ""}                 
              </ul>
            </li>
          </ul>
        </div>
      </div>
      
      {/* Navigation for large Screen End */}
    </>
  );
};

export default AccountsSidebar;
