import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const MeezanReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { partyForSearch,authUserInfo } = useContext(UserContext);
  let [newMeezanReport, setNewMeezanReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();
  //settle order
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });

  const handleBranch = (branch) => {
    setNewMeezanReport({
      ...newMeezanReport,
      branch,
    });
    // setBranch(branch);
  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    console.log("newMeezanReport ", newMeezanReport);
    if(
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
      newMeezanReport?.branch?.id === undefined
    ){
       toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }
    if (
      // newMeezanReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-meezan-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newMeezanReport?.branch?.id);
      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("MeezanReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewMeezanReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date and generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let total_order_bill = 0;
  let total_vat = 0;
  let total_discount_amount = 0;
  let total_claimable_amount = 0;
  let total_unclaimable_amount = 0;
  let total_payable = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Card Discount Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Card Discount Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="10" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Card Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Card Number"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Transaction Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount %"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Transaction Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Tax Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Merchant Share In Discount Cost"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Bank Share In Discount Cost"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {console.log("309 ", reportData)}
                                {/* loop here*/}
                                {reportData.map((item, i) => {
                                  total_order_bill += parseFloat(
                                    item.order_bill
                                  );
                                  total_vat += parseFloat(item.vat);
                                  total_discount_amount += parseFloat(
                                    item.discount_amount
                                  );
                                  total_claimable_amount += parseFloat(
                                    item.claimable_amount
                                  );
                                  total_unclaimable_amount += parseFloat(
                                    item.unclaimable_amount
                                  );
                                  total_payable += parseFloat(
                                    item.total_payable
                                  );
                                  return (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.card_number}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.order_date}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.discount}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.order_bill}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.total_payable}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.discount_amount}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.vat}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.unclaimable_amount}
                                      </td>{" "}
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.claimable_amount}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th
                                    colspan="3"
                                    style={{ border: "0px" }}
                                  ></th>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Total
                                  </th>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_order_bill)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_payable)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_discount_amount)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_vat)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_unclaimable_amount)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_claimable_amount)}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Card Discount Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-3 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-3"></div>
                          <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Print Card Report
                            </button>
                          </div>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default MeezanReport;
