import React, {useContext} from 'react';
import moment from "moment";
//functions
import {
  _t,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useCustomHooks } from '../../../../functions/Hooks';
import { SettingsContext } from "../../../../contexts/Settings";

const WorkPeriodPrint = ({
    //z report   
    zReportData,
    zReportRef,
    // cash report
    cashRegisterData,
    cashRegisterRef,

    // catg report
    zCatgData,
    catgReport,

    //order type report
    zOrderTypeData,
    orderTypeReport,

    // product mix report
    productMixReport,
    zProductMix,

    // day wise product mix report
    dayProductMix,
    branch,

    //catg wise product mix
    catgWiseReport,

    // catg day wise product mix
    dayCatgReport,
    dayCatgMix,
    dayCatgMixDetail,
    dayCatgMixDealDetail,

    //Raw material report after day end
    varianceReportRef,
    varianceData,

    //Finished Goods report after day end
    varianceReportFGRef,
    varianceDataFG

}) => {

  const {
    generalSettings,
    // setshowManageStock
  } = useContext(SettingsContext);
  const { roundOffPrice } = useCustomHooks();

    let salesDetails = [];
    for (const keys in zReportData?.sales_details) {
      salesDetails.push(
        <li>
          {keys}: {zReportData.sales_details[keys]}
        </li>
      );
    }
  
    let inSight = [];
    for (const keys in zReportData?.insights) {
      inSight.push(
        <li>
          {keys}: {zReportData.insights[keys]}
        </li>
      );
    }
  
    // catg wise sale
    let categoryWiseSales = [];
    for (const keys in zReportData?.category_wise_sales) {
      categoryWiseSales.push(
        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
          {/* {keys}: {zReportData.category_wise_sales[keys]} */}
          <span className="font-weight-bold">{keys}</span>
          <span className="float-right">
            {zReportData.category_wise_sales[keys]}
          </span>
        </p>
      );
    }
  
    // Order types
    let orderTypesSales = [];
    for (const keys in zReportData?.order_type_sales) {
      orderTypesSales.push(
        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex w-100 justify-content-between">
          {/* {keys}: {zReportData.category_wise_sales[keys]} */}
          <span className="font-weight-bold w-50">{keys}</span>
          <span className="w-25">{zReportData.order_type_sales_num[keys]}</span>
          <span className="w-25 text-right">
            {zReportData.order_type_sales[keys]}
          </span>
        </p>
      );
    }
  
    // Party Wise
    let partyWiseSales = [];
    for (const keys in zReportData?.party_wise_sales) {
      partyWiseSales.push(
        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex w-100 justify-content-between">
          {/* {keys}: {zReportData.category_wise_sales[keys]} */}
          <span className="font-weight-bold w-50">{keys}</span>
          <span className="w-25">{zReportData.party_wise_sales_no[keys]}</span>
          <span className="w-25 text-right">
            {zReportData.party_wise_sales[keys]}
          </span>
        </p>
      );
    }
  
    let departWiseSales = [];
    for (const keys in zReportData?.department_wise_sales) {
      departWiseSales.push(
        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
          {/* {keys}: {zReportData.department_wise_sales[keys]} */}
          <span className="font-weight-bold">{keys}</span>
          <span className="float-right">
            {zReportData.department_wise_sales[keys]}
          </span>
        </p>
      );
    }
  
    let departPaymentSales = [];
    for (const keys in zReportData?.payment_sales) {
      departPaymentSales.push(
        <p className="mb-0 mx-2 xsm-text fk-print-text_pos text-capitalize">
          {/* {payment} : {zReportData?.department_payment_sales[keys][payment]} */}
          <span className="font-weight-bold"> {keys}</span>
          <span className="float-right">
            {Math.round(zReportData?.payment_sales[keys], 2)}
          </span>
        </p>
      );
    }

    let totalItemPrice = 0;
    let totalRiderAmount = 0;
    let totalItemQty = 0;
    let totalGroupQty = 0;
    let totalGroupPrice = 0;
    let totalOrderTypeQty = 0;
    let totalOrderTypePrice = 0;
  
    // product mix
    let totalProductMix = 0;

    //expense part
    let total_collected_string = zReportData?.sales_details?.total_collected;
    let total_collected = parseFloat(total_collected_string?.replace(/,/g, ''));

    // day print
    let dayPrice = 0;
    let dayQty = 0;
    let dayTotalGroupQty = 0;
    let dayTotalGroupPrice = 0;

    //view cash 
    let totalPayments = 0;

  return (
    <div>

      {/*day wise catg mix report */}
      <div className="d-none">
        <div ref={dayCatgReport}>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="mt-3 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    {branch && branch.name}
                  </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                  Print Date: {moment(new Date()).format('DD-MM-YYYY')}
                  </span>
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Group Wise Product Mix Report
                  </span>
                  {/*catg wise report */}
                  <div className="col-12">
                    {
                      dayCatgMix &&
                      dayCatgMix !== null && (
                    <div>
                      <p className="mx-1 xsm-text table-text_pos text-capitalize border border-dark py-1 d-flex justify-content-between">
                      <span className="font-weight-bold pl-1" style={{width:"40.5%"}}>
                        {dayCatgMix &&
                        dayCatgMix !== null &&
                        "Item"}
                      </span>
                      <span className="font-weight-bold" style={{width:"19%"}}>
                        {dayCatgMix &&
                          dayCatgMix !== null &&
                          "Qty"}
                        </span>

                        <span className="font-weight-bold" style={{width:"22.5%"}}>
                        {dayCatgMix &&
                          dayCatgMix !== null &&
                          "Price"}
                        </span>

                        <span className="font-weight-bold pr-1" style={{width:"18%",textAlign:"right"}}>
                        {dayCatgMix &&
                          dayCatgMix !== null &&
                          "Amount"}
                        </span>
                      </p>
                    </div>
                    )}
                  {
                    dayCatgMix &&
                    dayCatgMix !== null &&
                      Object.keys(dayCatgMix)?.map((group)=>{
                          let groupQty = 0;
                          let groupPrice = 0;
                          return(
                          <div>
                              <div className="w-100 col-12">
                              <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">{group}</span>
                              </div> 
                              {
                                dayCatgMix[group]?.map((item)=>{
                                  let price = parseFloat(item.amount) / parseFloat(item.qty);
                                  groupQty += parseInt(item.qty);
                                  groupPrice += parseFloat(item.amount);
                                  dayTotalGroupQty += parseInt(item.qty);
                                  dayTotalGroupPrice += parseFloat(item.amount);
                                  let lowerCaseItem = item?.item_name?.toLowerCase()
                                  let boldItem = lowerCaseItem?.includes("deal");
                                  return(
                                    <div className="text-capitalize w-100 d-flex justify-content-between py-1">
                                      <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`} style={{width:"40.2%"}}>{item?.item_name}</span>
                                      <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`} style={{width:"17%"}}>{item.qty}</span>
                                      <span className={`mx-1 sm-text ${boldItem && "font-weight-bold"}`}  style={{width:"24%"}}>{roundOffPrice(price)}</span>
                                      <span className={`float-right sm-text pr-1 ${boldItem && "font-weight-bold"}`} style={{width:"18%",textAlign:"right"}}>{roundOffPrice(item.amount)}</span>
                                    </div>
                                  )
                              })
                              }
                              <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                                <span style={{width:"41.5%"}}>Total</span>
                                <span className="mx-2 ml-1"  style={{width:"17%"}}>{groupQty}</span>
                                <span style={{width:"23%"}}></span>
                                <span className="float-right" style={{width:"18%",textAlign:"right"}}>{roundOffPrice(groupPrice)}</span>
                              </div>
                          </div>  
                          )
                      })
                      }
                      {
                        dayCatgMix !== null && dayCatgMix && (
                          <div> 
                            <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                            <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                                <span style={{width:"41.5%"}}>Grand Total </span>
                              <span className="mx-2 ml-1"  style={{width:"17%"}}>{dayTotalGroupQty}</span>
                              <span style={{width:"23%"}}></span>
                              <span className="float-right" style={{width:"18%",textAlign:"right"}}>{roundOffPrice(dayTotalGroupPrice)}</span>
                            </div>
                          </div> 
                      )
                      }
                      {
                        dayCatgMixDetail !== null && 
                        dayCatgMixDetail?.length > 0 && (
                          <>
                            <span className="mx-2 my-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">Details</span>
                            <div>
                                <p className="mb-1 table-text text-capitalize d-flex justify-content-between border border-dark py-1">
                                <span className="font-weight-bold mx-2">
                                  Item
                                </span>
                
                                <span className="font-weight-bold pr-1">
                                  Qty
                                </span>
                                </p>
                            </div>
                            {
                              dayCatgMixDetail.map((item)=>{
                                if(!item.item_name.toLowerCase().includes("deal")){
                                  return (
                                    <div className="mb-1 print-text text-capitalize d-flex justify-content-between w-100">
                                      <span className="mx-2 print-text fk-print-text_pos--bold" >{item.item_name}</span>
                                      <span className="mx-2 print-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{item.qty}</span>
                                    </div>
                                  )
                                }
                              })
                            }
                          </>
                        )
                      }
                      {
                        dayCatgMixDealDetail !== null && 
                        dayCatgMixDealDetail?.length > 0 && (
                          <>
                            <span className="mx-2 my-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">Deals Details</span>
                            <div>
                                <p className="mb-1 table-text text-capitalize d-flex justify-content-between border border-dark py-1">
                                <span className="font-weight-bold mx-2">
                                  Item
                                </span>
                
                                <span className="font-weight-bold pr-1">
                                  Qty
                                </span>
                                </p>
                            </div>
                            {
                              dayCatgMixDealDetail.map((item)=>{
                                //  if(!item.item_name.toLowerCase().includes("deal")){
                                  return (
                                    <div className="mb-1 print-text text-capitalize d-flex justify-content-between w-100">
                                      <span className="mx-2 print-text fk-print-text_pos--bold" >{item.item_name}</span>
                                      <span className="mx-2 print-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{item.qty}</span>
                                    </div>
                                  )
                                //  }
                              })
                            }
                          </>
                        )
                      }
                  </div>
                </div> 
              </div> 
            </div> 
          </div> 
        </div> 
      </div> 

      {/* day product mix report catg wise progress*/}
      <div className="d-none">
        <div ref={catgWiseReport}>
          <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <span className="mt-3 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                  {branch && branch.name}
                </span>
                <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                Print Date: {moment(new Date()).format('DD-MM-YYYY')}
                </span>
                <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                  Category Wise Sale With Value
                </span>
                {/*product mix report */}
                <div className="col-12">
              {
                dayProductMix &&
                dayProductMix !== null && (
                  <div>
                    <p className="mb-1 table-text text-capitalize border border-dark py-1">
                      <span className="font-weight-bold mx-2 w-100">
                      {dayProductMix &&
                      dayProductMix !== null &&
                      "Item"}
                      </span>
                    <div className="d-flex justify-content-between">
                      <span style={{width:"12%"}}>
                      
                      </span>
                      <span className="font-weight-bold" style={{width:"33%"}}>
                      {dayProductMix &&
                        dayProductMix !== null &&
                        "Rate"}
                      </span>

                      <span className="font-weight-bold" style={{width:"25%"}}>
                      {dayProductMix &&
                        dayProductMix !== null &&
                        "Qty"}
                      </span>

                      <span className="font-weight-bold pr-1" style={{width:"28%",textAlign:"right"}}>
                      {dayProductMix &&
                        dayProductMix !== null &&
                        "Amount"}
                      </span>
                      </div>
                    </p>
                  </div>
                )}
              
              {
                dayProductMix && typeof(dayProductMix) === "object" && Object.keys(dayProductMix)?.map((group)=>{
                  let price = dayProductMix[group].qty * dayProductMix[group].rate
                  dayPrice += price;
                  dayQty += dayProductMix[group].qty
                  return(
                    <div className="mb-1 print-text text-capitalize w-100">
                    <span className="mx-2 table-text fk-print-text_pos--bold w-100">{group}</span>
                      <div className=" d-flex justify-content-between py-1">
                      <span className="mx-2" style={{width:"7%"}}></span>
                      <span className="mx-2 print-text" style={{width:"36%"}}>{dayProductMix[group].rate}</span>
                      <span className="mx-2 print-text " style={{width:"24%"}}>{dayProductMix[group].qty}</span>
                      <span className="mx-2 print-text pr-1" style={{width:"28%",textAlign:"right"}}>{price}</span>
                      </div>
                    </div>
                  )
                })
              }
                    
                {
                dayProductMix && dayProductMix !== null && (
                  <div> 
                    <div className = "mb-1 xsm-text print-text fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark p-1">
                      <span className="mx-1" style={{width:"47%"}}>Grand Total</span>
                      <span className="mx-1" style={{width:"25%"}}>{dayQty}</span>
                      <span className="mx-1 float-right" style={{width:"27%",textAlign:"right"}}>{dayPrice}</span>
                    </div>
                  </div> 
                )
                }
                </div>
              </div> 
            </div> 
          </div> 
          </div> 
        </div> 
      </div> 

      {/* product mix report */}
      <div className="d-none">
        <div ref={productMixReport}>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                  Product Mix Report
                  </span>
              {/*product mix report */}
              <div className="col-12">
              {
              zProductMix &&
              zProductMix && (
              <div>
                  <p className="mb-1 table-text text-capitalize d-flex justify-content-between border border-dark py-1">
                  <span className="font-weight-bold mx-2">
                  {zProductMix &&
                  zProductMix !== null &&
                  "Item"}
                  </span>

                  <span className="font-weight-bold pr-1">
                  {zProductMix &&
                      zProductMix !== null &&
                      "Qty"}
                  </span>
                  </p>
              </div>
              )}
          {
              zProductMix &&
              zProductMix !== null &&
              Object.keys(zProductMix)?.map((product)=>{
                  let productQty = 0;
                  return(
                  <div>
                      <div className="w-100 col-12 mb-2">
                      <span className="mx-2 d-flex font-weight-bold justify-content-center text-decoration-underline text-capitalize table-text">{product}</span>
                      </div>
                      {
                      Object.keys(zProductMix[product])?.map((group)=>{
                          productQty += zProductMix[product][group];
                          totalProductMix += zProductMix[product][group];
                      return(
                          <div className="mb-1 print-text text-capitalize d-flex justify-content-between w-100">
                          <span className="mx-2 print-text fk-print-text_pos--bold" >{group}</span>
                          <span className="mx-2 print-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{zProductMix[product][group]}</span>
                          </div>
                      )
                      })
                      }
                      <div className = "mb-1 table-text fk-print-text--bold text-capitalize d-flex justify-content-between border border-2 p-1">
                          <span className="mx-2 ml-1 table-text fk-print-text--bold" >Total</span>
                          <span className="float-right table-text fk-print-text--bold" style={{textAlign:"right"}}>{productQty}</span>
                      </div>
                  </div>  
                  )
              })
              }
              {
              zProductMix && zProductMix !== null && (
                  <div> 
                  <div className = "mb-1 table-text fk-print-text--bold text-capitalize d-flex justify-content-between border border-2 p-1">
                      <span className="mx-2 ml-1" >Grand Total</span>
                      <span className="float-right" style={{textAlign:"right"}}>{totalProductMix}</span>
                  </div>
                  </div> 
              )
              }
              </div>
              </div> 
            </div> 
          </div> 
        </div> 
        </div> 
      </div> 

      {/* order type wise report*/}
      <div className="d-none">
        <div ref={orderTypeReport}>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
              <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                  Order Type Wise Item Shift Report
              </span>
              {/*ordertype catg wise report */}
              <div className="col-12">
              {
              zOrderTypeData &&
              zOrderTypeData && (
              <div>
                <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                  <span className="font-weight-bold">
                    {zOrderTypeData &&
                      zOrderTypeData[1].report_date !== null &&
                      "Report Date"}
                  </span>
                  <span className="float-right">
                    {zOrderTypeData &&
                      zOrderTypeData[1].report_date !== null &&
                      zOrderTypeData[1].report_date}
                  </span>
                </p>
                <p className="mb-0 xsm-text fk-print-text_pos text-capitalize ">
                  <span className="font-weight-bold">
                    {zOrderTypeData &&
                      zOrderTypeData[1].branch_name !== null &&
                      "Branch Name"}
                  </span>
                  <span className="float-right">
                    {zOrderTypeData &&
                      zOrderTypeData[1].branch_name !== null &&
                      zOrderTypeData[1].branch_name}
                  </span>
                </p>
                <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                  <span className="font-weight-bold">
                    {zOrderTypeData &&
                      zOrderTypeData[1].workperiod_status !== null &&
                      "Open Date"}
                  </span>
                  <span className="float-right">
                    {zOrderTypeData &&
                      zOrderTypeData[1].workperiod_status !== null &&
                      zOrderTypeData[1].workperiod_status.start}
                  </span>
                </p>
                <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                  <span className="font-weight-bold">
                    {zOrderTypeData &&
                      zOrderTypeData[1].workperiod_status !== null &&
                      "Close Date"}
                  </span>
                  <span className="float-right">
                    {zOrderTypeData &&
                      zOrderTypeData[1].workperiod_status !== null &&
                      zOrderTypeData[1].workperiod_status.end}
                  </span>
                </p>
                {/* <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                  <span className="font-weight-bold">
                    {zOrderTypeData && zOrderTypeData[1].user !== null && "User"}
                  </span>
                  <span className="float-right">
                    {zOrderTypeData &&
                      zOrderTypeData[1].user !== null &&
                      zOrderTypeData[1].user.name}
                  </span>
                    </p>*/}
                <div className="myBorder my-2"></div>
              <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">Order Type Wise Item Details</span>
              <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
              <span className="font-weight-bold mx-2" style={{width:"18%"}}>
              {zOrderTypeData &&
              zOrderTypeData !== null &&
              "qty"}
              </span>
              
              <span className="font-weight-bold"  style={{width:"56%"}}>
              {zOrderTypeData &&
                  zOrderTypeData !== null &&
                  "item"}
              </span>

              <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
              {zOrderTypeData &&
                  zOrderTypeData !== null &&
                  "amount"}
              </span>
              </p>
              </div>
              )}

          {
              zOrderTypeData &&
              zOrderTypeData !== null &&
              Object.keys(zOrderTypeData[0])?.map((orderType)=>{
                  let orderTypeQty = 0;
                  let orderTypePrice = 0;
                  return(
                  <div>
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos  font-weight-bold justify-content-center border-dark border-top border-bottom border-1 lg-text">{orderType}</span>
                      </div> 
                      {
                      Object.entries(zOrderTypeData[0][orderType])?.map((group)=>{
                      return(
                          <>
                          <div className="w-100 col-12">
                          <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-center text-decoration-underline table-text">{group[0]}</span>
                          </div>
                          {
                          group[1]?.map((item)=>{
                              orderTypeQty += parseInt(item.quantity);
                              orderTypePrice += parseFloat(item.price);
                              totalOrderTypeQty += parseInt(item.quantity);
                              totalOrderTypePrice += parseFloat(item.price);
                              return (
                              <div className="mb-0 xsm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                              <span className="mx-2"  style={{width:"18.2%"}}>{item.quantity}</span>
                              <span style={{width:"54.8%"}}>{item?.item_id}</span>
                              <span className="float-right mr-1" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(item.price)}</span>
                              </div>
                              )
                          })
                          }
                          </>
                      )
                      })
                      }
                      <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                          <span className="mx-2 ml-1"  style={{width:"18%"}}>{orderTypeQty}</span>
                          <span style={{width:"55%"}}></span>
                          <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(orderTypePrice)}</span>
                      </div>
                  </div>  
                  )
              })
              }
              {
              zOrderTypeData !== null && zOrderTypeData && (
                  <div> 
                  <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                  <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                      <span className="mx-2 ml-1"  style={{width:"18%"}}>{totalOrderTypeQty}</span>
                      <span style={{width:"55%"}}></span>
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(totalOrderTypePrice)}</span>
                  </div>
                  </div> 
              )
              }
              </div>
              </div> 
            </div> 
          </div> 
        </div> 
        </div> 
      </div> 

      {/* catg wise report */}
      <div className="d-none">
        <div ref={catgReport}>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                  Group Wise Item Shift Report
                  </span>
                  {/*catg wise report */}
                  <div className="col-12">
                  {
                  zCatgData &&
                  zCatgData && (
                      <div>
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">Group Wise Item Details</span>
                  <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                  <span className="font-weight-bold mx-2" style={{width:"18%"}}>
                  {zCatgData &&
                  zCatgData !== null &&
                  "qty"}
                  </span>
                  
                  <span className="font-weight-bold"  style={{width:"56%"}}>
                  {zCatgData &&
                      zCatgData !== null &&
                      "item"}
                  </span>

                  <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
                  {zCatgData &&
                      zCatgData !== null &&
                      "amount"}
                  </span>
                  </p>
                  </div>
                  )}
                  {
                      zCatgData &&
                      zCatgData !== null &&
                      Object.keys(zCatgData)?.map((group)=>{
                          let groupQty = 0;
                          let groupPrice = 0;
                          return(
                          <div>
                              <div className="w-100 col-12">
                              <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline lg-text">{group}</span>
                              </div> 
                              {
                              zCatgData[group]?.map((item)=>{
                                  groupQty += parseInt(item.quantity);
                                  groupPrice += parseFloat(item.price);
                                  totalGroupQty += parseInt(item.quantity);
                                  totalGroupPrice += parseFloat(item.price);
                                  return(
                                  <div className="mb-0 xsm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                  <span className="mx-2"  style={{width:"18.2%"}}>{item.quantity}</span>
                                  <span style={{width:"54.8%"}}>{item?.item_id}</span>
                                  <span className="float-right mr-1" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(item.price)}</span>
                                  </div>
                                  )
                              })
                              }
                              <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                                  <span className="mx-2 ml-1"  style={{width:"18%"}}>{groupQty}</span>
                                  <span style={{width:"55%"}}></span>
                                  <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(groupPrice)}</span>
                              </div>
                          </div>  
                          )
                      })
                      }
                      {
                      zCatgData !== null && zCatgData && (
                          <div> 
                          <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                          <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                              <span className="mx-2 ml-1"  style={{width:"18%"}}>{totalGroupQty}</span>
                              <span style={{width:"55%"}}></span>
                              <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(totalGroupPrice)}</span>
                          </div>
                          </div> 
                      )
                      }
                  </div>
                </div> 
              </div> 
            </div> 
          </div> 
        </div> 
      </div> 

      {/* Print Z Report Start */}
      <div className="d-none">
        <div ref={zReportRef}>
          {zReportData && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Shift Report
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      {zReportData &&
                        zReportData.branch_name !== null &&
                        zReportData.branch_name}
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      {zReportData &&
                        zReportData.day_status !== null &&
                        zReportData.day_status}
                    </span>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.report_date !== null &&
                          "Report Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.report_date !== null &&
                          zReportData.report_date}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize ">
                      {/* {zReportData &&
                        zReportData.branch_name !== null &&
                        "Terminal: " + zReportData.branch_name} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.branch_name !== null &&
                          "Terminal"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.branch_name !== null &&
                          zReportData.branch_name}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.workperiod_status !== null &&
                        "Open Date: " + zReportData.workperiod_status.start} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          "Open Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          zReportData.workperiod_status.start}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.workperiod_status !== null &&
                        "Close Date: " + zReportData.workperiod_status.end} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          "Close Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          zReportData.workperiod_status.end}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.user !== null &&
                        "User: " + zReportData.user.name} */}
                      <span className="font-weight-bold">
                        {zReportData && zReportData.user !== null && "User"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.user !== null &&
                          zReportData.user.name}
                      </span>
                    </p>

                    {/* <ul>
                      USER
                      { zReportData &&
                        user
                      }
                    </ul> */}
                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      SALES DETAILS
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Gross Total: " +
                          zReportData?.sales_details?.gross_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Sales Variance"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.sale_variance}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Gross Total: " +
                          zReportData?.sales_details?.gross_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Gross Sales"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.gross_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Free Of Cost"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_foc}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Discount: " + zReportData?.sales_details?.discount} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Discount"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.discount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.cancelled !== null &&
                        "Discount: " + zReportData?.cancelled?.cancelled} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Cancelled"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.cancelled}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.cancelled !== null &&
                        "Discount: " + zReportData?.cancelled?.cancelled} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_refund}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Net Sales: " + zReportData?.sales_details?.net_sales} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Net Sales"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.net_sales}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Tax Amount: " + zReportData?.sales_details?.tax_amount} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Tax Amount"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.tax_amount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Taxable Total: " +
                          zReportData?.sales_details?.taxable_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Net Sales Including Tax"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.taxable_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Service Charge: " +
                          zReportData?.sales_details?.service_charge} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Delivery Charges"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.service_charge}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Service Charge: " +
                          zReportData?.sales_details?.service_charge} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Service Charges"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.service_amount}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Department Commission: " +
                          zReportData?.sales_details?.dept_commission} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Department Commission"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.dept_commission}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total Collected"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_collected}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Pos Service Fee"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.pos_fee}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData?.sales_details !== null &&
                          "Order Tip"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData?.sales_details?.order_tip &&
                          zReportData.sales_details.order_tip}
                      </span>
                    </p>

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      INSIGHT
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.insights !== null &&
                        "Number of Orders: " +
                          zReportData?.insights?.number_of_orders} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.insights !== null &&
                          "Number of Orders"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.insights !== null &&
                          zReportData?.insights?.number_of_orders}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.insights !== null &&
                        "Number of Customers: " +
                          zReportData?.insights?.number_of_customers} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.insights !== null &&
                          "Number of Customers"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.insights !== null &&
                          zReportData?.insights?.number_of_customers}
                      </span>
                    </p>
                    {/* <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      CATEGORY WISE SALES
                    </p>
                    {zReportData && categoryWiseSales} */}

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      PAYMENT WISE SALES
                    </p>
                    {zReportData && departPaymentSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.payment_type_total}
                      </span>
                    </p>

                    {/* <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Total
                    </p>
                    {zReportData && departWiseSales} */}
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      ORDER TYPE SALES
                    </p>
                    {zReportData && orderTypesSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.order_type_total}
                      </span>
                    </p>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      PARTY WISE SALES
                    </p>
                    {zReportData && partyWiseSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.party_total}
                      </span>
                    </p>
                  </div>
                  <div className="col-12">
                  {
                    zReportData && zReportData?.order_details && 
                    parseInt(getSystemSettings(generalSettings, "print_sold_items")) === 1 && (
                      <>
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Sold Item Details</span>
                  <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                    <span className="font-weight-bold" style={{width:"60%"}}>
                    {zReportData &&
                      zReportData?.order_details !== null &&
                      "item"}
                    </span>

                    <span className="font-weight-bold mx-2" style={{width:"14%"}}>
                    {zReportData &&
                      zReportData?.order_details !== null &&
                      "qty"}
                    </span>

                    <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
                    {zReportData &&
                      zReportData?.order_details !== null &&
                      "amount"}
                    </span>
                  </p>
                  
                  {
                  zReportData &&
                  zReportData.order_details !== null &&
                  zReportData.order_details && zReportData.order_details?.map((food)=>{
                    totalItemPrice += parseFloat(food.price);
                    totalItemQty += parseInt(food.quantity);
                    return(
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                        <span style={{width:"60%"}}>{food.food_item}{(food.variation && food.variation !== "") && ` - ${food.variation}`}</span>
                        <span className="mx-2"  style={{width:"13%"}}>{food.quantity}</span>
                        <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(food.price)}</span>
                        </p>
                        )
                        })
                      }
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                      <span  style={{width:"60%"}}>Total</span>
                      <span className="mx-2"  style={{width:"13%"}}>{totalItemQty}</span>
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(totalItemPrice)}</span>
                      </p>
                      </>
                      )
                    }
                  </div>
                  
                  <div className="col-12">
                  {
                    zReportData && zReportData?.expense && 
                    parseInt(getSystemSettings(generalSettings, "view_expense")) === 1 && (
                      <>
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Expense Details</span>
                  <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                    <span className="font-weight-bold" style={{width:"60%"}}>
                    {zReportData &&
                      zReportData?.expense !== null &&
                      "Type"}
                    </span>

                    <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
                    {zReportData &&
                      zReportData?.expense !== null &&
                      "amount"}
                    </span>
                  </p>
                  
                  {
                  zReportData &&
                  zReportData.expense !== null &&
                  zReportData.expense && Object.entries(zReportData.expense)?.map((food)=>{
                    totalRiderAmount += parseFloat(food[1]);                    
                    return(
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                        <span style={{width:"60%"}}>{food[0]}</span>                          
                        <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(food[1])}</span>
                        </p>
                        )
                        })
                      }                       
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                      <span  style={{width:"60%",fontWeight:"bold"}}>Total</span>                       
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice(totalRiderAmount)}</span>
                      </p>                        
                      <p className="mb-0 mt-2 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                      <span  style={{width:"60%",fontWeight:"bold"}}>Total After Expense</span>                       
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{roundOffPrice((total_collected) - totalRiderAmount)}</span>
                      </p>                       
                      </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print Cash Register Report Start */}
      <div className="d-none">
        <div ref={cashRegisterRef}>
          {cashRegisterData && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      Cash Register Report
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      {cashRegisterData &&
                        cashRegisterData.branch_name !== null &&
                        cashRegisterData.branch_name}
                    </span>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.report_date !== null &&
                          "Report Date"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.report_date !== null &&
                          cashRegisterData.report_date}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Shift Opened"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.start}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Shift Close"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.end}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.user !== null &&
                          "User"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.user !== null &&
                          cashRegisterData.user.name}
                      </span>
                    </p>
                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Cash Drawer
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Starting Cash"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.opening_cash}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    {/*<p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Cash sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.net_sales}
                      </span>
                  </p>*/}
                  
                    { cashRegisterData && cashRegisterData.payments && cashRegisterData.payments?.payment_sales ?
                    <div>
                    {Object.entries(cashRegisterData.payments?.payment_sales).length > 0 &&
                    Object.entries(cashRegisterData.payments?.payment_sales).map((value,index)=>{
                      totalPayments += value[1]
                      return(<div key={index}>
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                          <span className="font-weight-bold">
                            {value[0]}
                          </span>
                          <span className="float-right">
                            {value[1]}
                          </span>
                        </p>
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                      </div>)
                      })}
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                          <span className="font-weight-bold">
                            Total
                          </span>
                          <span className="float-right">
                            {totalPayments}
                          </span>
                      </p>
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    </div>
                      : null
                      }
                
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.total_refund}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Paid in"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.payments !== null &&
                          cashRegisterData?.payments.pay_in}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Paid out"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.payments !== null &&
                          cashRegisterData?.payments.pay_out}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Expected cash amount"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.expected_cash !== null &&
                          cashRegisterData?.expected_cash}
                      </span>
                    </p>

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Sales Summary
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Gross sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.gross_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Discount"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.discount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.total_refund}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Tax Amount"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.tax_amount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Delivery Charges"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.service_charge}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.insights !== null &&
                          "Net sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.insights !== null &&
                          cashRegisterData?.insights?.total_sales}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print Day End Raw Material Report Start */}
      <div className="d-none">
        <div ref={varianceReportRef}>
        {varianceData && (
            <div className="fk-print">
              <div style={{width:"95%",margin:"0 auto"}}>
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Inventory Variance Report
                    </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    {branch && branch.name}
                  </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Print Date: {moment(new Date()).format('DD-MM-YYYY')}
                  </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Raw Material
                  </span>                       
                  </div>
                  <div className="col-12">
                  {
                  //  zReportData && zReportData?.order_details && 
                  //  parseInt(getSystemSettings(generalSettings, "print_sold_items")) === 1 && (
                      <>
                  {/* <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Expense Details</span> */}
                    <table className="table table-bordered-sm table-hover">
                      <thead className="align-middle">
                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Item
                          </th>
                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Opening
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Purchase
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Trans In
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Prod In
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Total
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Trans Out
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Sale
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Made Void
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Discard
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Production
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Total
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Sys End Count
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Phy End Count
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Diff
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Amt
                          </th>
                      </thead>                                                           
                      {
                        varianceData &&
                        varianceData !== null &&
                        varianceData && varianceData?.map((value)=>{  
                        const totalIn = parseFloat(value?.Opening) + parseFloat(value?.Purchase) + parseFloat(value?.TrasferInn) + parseFloat(value?.ProductionInn); 
                        const totalOut =  parseFloat(value?.TrasferOut) + parseFloat(value?.Sales) + parseFloat(value?.MadeVoid) + parseFloat(value?.Discard) + parseFloat(value.Production);
                        const sysEndCount = totalIn - totalOut;
                        const diff = parseFloat(value?.PhysicalEndCount) - sysEndCount;
                        return(
                          <tbody className="align-middle">
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.name}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.Opening}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Purchase)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.TrasferInn)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.ProductionInn)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(totalIn)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.TrasferOut)}</td> 
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Sales)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.MadeVoid)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Discard)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Production)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(totalOut)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(sysEndCount)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.PhysicalEndCount}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(diff)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>0</td>
                          </tbody>
                          )
                        })
                      }
                    </table>                       
                      </>
                    //  )
                    }
                  </div>                   
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print Day End Finished Goods Report Start */}
      <div className="d-none">
        <div ref={varianceReportFGRef}>
        {varianceDataFG && (
            <div className="fk-print">
              <div style={{width:"95%",margin:"0 auto"}}>
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Inventory Variance Report
                    </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    {branch && branch.name}
                  </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Print Date: {moment(new Date()).format('DD-MM-YYYY')}
                  </span>
                  <span className="mt-2 d-block table-text font-weight-bold text-uppercase text-center mb-2">
                    Finished Goods
                  </span>                     
                  </div>
                  <div className="col-12">
                  {
                  //  zReportData && zReportData?.order_details && 
                  //  parseInt(getSystemSettings(generalSettings, "print_sold_items")) === 1 && (
                      <>
                  {/* <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Expense Details</span> */}
                      <table className="table table-bordered-sm table-hover">
                        <thead className="align-middle">
                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Item
                          </th>
                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Opening
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Finished Good
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Trans In
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Total
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Trans Out
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Sale
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Made Void
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Discard
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Total
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Sys End Count
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Phy End Count
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Diff
                          </th>

                          <th scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                            Amt
                          </th>
                        </thead>                                                           
                        {
                        varianceDataFG &&
                        varianceDataFG !== null &&
                        varianceDataFG && varianceDataFG?.map((value)=>{ 
                          const totalIn = parseFloat(value?.Opening) + parseFloat(value?.Finished_Good) + parseFloat(value?.TrasferInn); 
                          const totalOut = parseFloat(value?.TrasferOut) + parseFloat(value?.Sales) + parseFloat(value?.MadeVoid) + parseFloat(value?.Discard);
                          const sysEndCount = totalIn - totalOut;  
                          const diff = parseFloat(value?.PhysicalEndCount) - sysEndCount;                                    
                        return(
                          <tbody className="align-middle">
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.name}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.Opening}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Finished_Good)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.TrasferInn)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(totalIn)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.TrasferOut)}</td> 
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Sales)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.MadeVoid)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(value?.Discard)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(totalOut)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(sysEndCount)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.PhysicalEndCount}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{roundOffPrice(diff)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>0</td>
                          </tbody>
                            )
                          })
                        }
                      </table>                       
                      </>
                    //  )
                    }
                  </div>                   
                </div>
              </div>
            </div>
          )}
        </div>
      </div>        
    </div>
  )
}

export default WorkPeriodPrint
