import React, { useContext, useEffect, useState } from 'react'
import { currencySymbolLeft, currencySymbolRight, getSystemSettings } from "../../../../functions/Functions";
import { UserContext } from '../../../../contexts/User';
import { RestaurantContext } from '../../../../contexts/Restaurant';
import { SettingsContext } from '../../../../contexts/Settings';
import { useCustomHooks } from '../../../../functions/Hooks';
import { BASE_URL } from '../../../../BaseUrl';

const CustomerScreen = () => {
    const {authUserInfo} = useContext(UserContext);
    const { branchForListSearch } = useContext(RestaurantContext);
    const { generalSettings } = useContext(SettingsContext);
    const { firebaseAuth, roundOffPrice } = useCustomHooks();

    const [screen,setScreen] = useState("/assets/img/login-image.png");
    const [newOrder, setNewOrder] = useState([]);
    const [orderDetail, setOrderDetail] = useState({
        subTotal: 0,
        discount: 0,
        tax: 0,
        totalAmount: 0,
    });

    const isVideo = (url) => {
        const videoExtensions = ["mp4", "webm", "ogg"];
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    const setResData = (res) =>{
        setNewOrder(res?.newOrder);
        setOrderDetail({
            subTotal: res?.subtotal,
            discount: res?.discount,
            tax: res?.tax,
            totalAmount: res?.totalAmount,
        });
    }

    const getCusotmerOrder = async() =>{
        if(authUserInfo?.details?.branch_id && generalSettings){
            let customerScreenUrl;
            if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
              customerScreenUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + "/" + authUserInfo.details?.branch_id + '/customer_order/' + authUserInfo.details?.id;
            }
            firebaseAuth(customerScreenUrl, (error, res) => {
                if (error) {
                    console.error("Error in fetchDataAndProcess:",error)
                    return;
                }
                if(res){
                    setResData(res);
                }
            })
        }else{
            console.error("no branch id",)
        }
    }

    useEffect(()=>{
        if(authUserInfo?.details && branchForListSearch){
            const branch = branchForListSearch[authUserInfo.details?.branch_id];
            if(branch && branch?.customer_screen_image){
                setScreen(BASE_URL + branch.customer_screen_image)
            }
            if(branch && branch?.customer_screen_real_time){
                getCusotmerOrder()
            }else{
                const handleStorageChange = (event) => {
                    if (event.key === "order_update") {
                    const orders = JSON.parse(localStorage.getItem("order_update"));
                        if(orders){
                            setResData(orders);
                        }
                    }
                };
                window.addEventListener("storage", handleStorageChange);
                return () => window.removeEventListener("storage", handleStorageChange);
            }
        }
    },[authUserInfo, branchForListSearch])


  return (
    <div className="container-fluid fk-scroll--index t-mt-15 t-mb-15">
        <div className='row'>
            <div className='col-6 pr-0'>
                <div className='bg-white d-flex flex-column justify-content-between fk-customer-screen'>
                    <div>
                        <div className='bg-success text-center p-3 toast-body'>
                            <h5 className='text-white m-0 xxxslg-text font-weight-bold'>Your Order</h5>
                        </div>
                        <div className='my-1 mx-2 px-3'>
                            <div className="row border-bottom p-2 m-0">
                                <div className="font-weight-normal customer-font col-7 pl-0">
                                    Food Item
                                </div>

                                <div className="font-weight-normal customer-font col-2 text-center">
                                    Qty
                                </div>
                                <div className="font-weight-normal customer-font col-3 text-right">
                                    Price
                                </div>
                            </div>
                            <div className='customer-screen-wrap'>
                                {
                                    newOrder && newOrder?.length ? (newOrder.sort((a, b) =>
                                        a.seq < b.seq ? 1 : -1
                                    ).map((item,index)=>{
                                        const itemProperty = [];
                                        if (parseInt(item.item.has_property) === 1 && item.properties?.length > 0) {
                                            item.properties.forEach((item) => {
                                                const propertyName = item.item.name;
                                                itemProperty.push(
                                                    <span style={{marginTop:"-0.3rem"}} className="xslg-text d-block" key={propertyName}>
                                                        {item.quantity} - {propertyName}
                                                    </span>
                                                );
                                            });
                                        }
                                        return (
                                            <div key={index} style={{padding:"0.3rem 0.5rem"}} className="row border-bottom position-relative">
                                                <div className="customer-font col-7">
                                                    {item.item.name}
                                                    {parseInt(item.item.has_variation) === 1 ? (
                                                        <span className="item_variation">
                                                            ({item?.variation?.variation_name}){" "}
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {parseInt(item.item.has_property) === 1 && [itemProperty]}
                                                </div>
                                                <div className=" customer-font col-2 text-center">
                                                    {item.quantity}
                                                </div>
                                                <div className="customer-font col-3 text-right text-break">
                                                    <span>
                                                        {currencySymbolLeft()}
                                                        {item.after_tax_price}
                                                        {currencySymbolRight()}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }))
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="pt-2 customer-border customer-border-radius" style={{paddingLeft:"2rem", paddingRight:"2rem"}}>
                            <div className="d-flex justify-content-between">
                                <span className='customer-font' >Sub Total</span>
                                <span className='customer-font'>{currencySymbolLeft()} {roundOffPrice(orderDetail.subTotal)} {currencySymbolRight()}</span>
                            </div>
                            {
                                Number(orderDetail?.tax) > 0 && (
                                    <div className="d-flex justify-content-between">
                                        <span className='customer-font'>Tax</span>
                                        <span className='customer-font'>{currencySymbolLeft()} {roundOffPrice(orderDetail.tax)} {currencySymbolRight()}</span>
                                    </div>
                                )
                            }
                            {Number(orderDetail.discount) > 0 && (
                                    <div className="d-flex justify-content-between">
                                        <span className='customer-font'>Discount</span>
                                        <span className='customer-font'>{currencySymbolLeft()} {roundOffPrice(orderDetail.discount)} {currencySymbolRight()}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="mt-1 text-center bg-success text-white p-2 customer-border-radius">
                            <h5 className='text-white m-0 customer-font customer-font-total'>Total Payable: {currencySymbolLeft()} {roundOffPrice(orderDetail.totalAmount)} {currencySymbolRight()}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 pl-0'>
                {
                    isVideo(screen) ? (
                        <video 
                            autoPlay 
                            loop 
                            muted
                            className="customer_video"
                        >
                            <source src={screen} type="video/mp4"/>
                        </video>
                    ) : (
                        <div 
                            className='wrapped-image'
                            style={{
                                backgroundImage: `url(${screen})`,
                            }}
                        >
                        </div>
                    )
                }
            </div>
        </div>    
    </div>
  )
}

export default CustomerScreen
