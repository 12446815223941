import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import makeAnimated from "react-select/animated";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import Select from "react-select";


//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { FinanceContext } from "../../../../../contexts/Finance";

const AccountSettings = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //finance context
    const {
      loading,
      setLoading,

      // account module
      saveData,
      setSaveData,
      cashAcc,
      setCashAcc,
      whtAcc,
      setWhtAcc,
      otherTaxAcc,
      setOtherTaxAcc,
      bankAcc,
      setBankAcc,
      invAcc,
      setInvAcc,
      revAcc,
      setRevAcc,
      cogs,
      setCogs,
      purchaseDis,
      setPurchaseDis,
      salesDis,
      setSalesDis,
      purchaseTax,
      setPurchaseTax,
      salesTax,
      setSalesTax,
      suppCashAcc,
      setSuppCashAcc,
      suppPayableAcc,
      setSuppPayableAcc,
      suspenseAcc,
      setSuspenseAcc,

      //coa3
      coa3ForSearch,
      coa3ForSearchById,
      getCoaL3,

      //pagination
      dataPaginating,
    } = useContext(FinanceContext);
    
  //on load
  useEffect(()=>{
    if ((getCookie() !== undefined)) {
      getCoaL3();
    }  
  },[]);

  // handle dropdown functions
  const handleCashAcc = (account) => {
    //for onChange Values
    let cashAccId =  account
    let cashArr =  cashAccId.map((cash)=>{
      return coa3ForSearchById[cash.id]
    });
    setCashAcc(cashArr)
    //for set values in saveData
    let valueIds= account?.map((item)=>{
      return item.id;
    })
    saveData[0].value = valueIds?.length == 0 ? "" : valueIds
    setSaveData([...saveData]);
  };

  const handleWHT=(account)=>{
    //for onChange Values
    let whtAcc = coa3ForSearchById[account.value];
    setWhtAcc(whtAcc);
    //for set values in saveData
    saveData[1].value= account.id
    setSaveData([...saveData]);
  }
  const handleOtherTax=(account)=>{
    //for onChange Values
    let otherTax = coa3ForSearchById[account.value];
    setOtherTaxAcc(otherTax);
    //for set values in saveData
    saveData[2].value=account.id
    setSaveData([...saveData]);
  }
  const handleBankAcc=(account)=>{
    //for onChange Values
    let bankAccId = account
    let bankIdArr = bankAccId.map((bank)=>{
      return coa3ForSearchById[bank.id]
    });
    setBankAcc(bankIdArr)
    //for set values in saveData
    let valueIds = account?.map((item)=>{
      return item.id;
    })
    saveData[3].value = valueIds?.length == 0 ? "" : valueIds
    setSaveData([...saveData]);
  }
  const handleInventoryAcc=(account)=>{
    //for onChange Values
    let invAccId = coa3ForSearchById[account.value];;
    setInvAcc(invAccId);
    //for set values in saveData
    saveData[4].value=account.id
    setSaveData([...saveData]);
  }
  const handleRevAcc=(account)=>{
    //for onChange Values
    let revAccId = coa3ForSearchById[account.value];
    setRevAcc(revAccId);
    //for set values in saveData
    saveData[5].value=account.id
    setSaveData([...saveData]);
  }
  const handleCOGS=(account)=>{
    //for onChange Values
    let cogsAccId = coa3ForSearchById[account.value];
    setCogs(cogsAccId);
    //for set values in saveData
    saveData[6].value=account.id
    setSaveData([...saveData]);
  }
  const handlePurchaseDis=(account)=>{
    //for onChange Values
    let purchaseDisId = coa3ForSearchById[account.value];
    setPurchaseDis(purchaseDisId);
    //for set values in saveData
    saveData[7].value=account.id
    setSaveData([...saveData]);
  }
  const handleSaleDis=(account)=>{
    //for onChange Values
    let saleDisId = coa3ForSearchById[account.value];
    setSalesDis(saleDisId);
    //for set values in saveData
    saveData[8].value=account.id
    setSaveData([...saveData]);
  }
  const handlePurchaseTax=(account)=>{
    //for onChange Values
    let purchaseTaxId = coa3ForSearchById[account.value];
    setPurchaseTax(purchaseTaxId);
    //for set values in saveData
    saveData[9].value=account.id
    setSaveData([...saveData]);
  }
  const handleSaleTax=(account)=>{
    //for onChange Values
    let saleTaxId = coa3ForSearchById[account.value];
    setSalesTax(saleTaxId);
    //for set values in saveData
    saveData[10].value=account.id
    setSaveData([...saveData]);
  }
  const handleSuppCashAcc=(account)=>{
    //for onChange Values
    let suppCashAcc = coa3ForSearchById[account.value];
    setSuppCashAcc(suppCashAcc);
    //for set values in saveData
    saveData[11].value=account.id
    setSaveData([...saveData]);
  }
  const handleSuppPayableAcc=(account)=>{
    //for onChange Values
    let suppPayableAcc = coa3ForSearchById[account.value];
    setSuppPayableAcc(suppPayableAcc);
    //for set values in saveData
    saveData[12].value=account.id
    setSaveData([...saveData]);
  }

  const handleSuspenseAcc = (account)=>{
    //for onChange Values
    let suspenseAcc = coa3ForSearchById[account.value];
    setSuspenseAcc(suspenseAcc);
    //for set values in saveData
    saveData[13].value=account.id
    setSaveData([...saveData]);
  }

  const saveAccounts=(e)=>{
    e.preventDefault();
    let bodyData = {
      module_settings: saveData
    }
    const moduleUrl = BASE_URL + `/settings/create-module-setting`;
    // console.log("create data==>",bodyData);
    // validate all dropdowns
    if(saveData[0].value != "" && saveData[1].value != "" &&
      saveData[2].value != "" && saveData[3].value != "" &&
      saveData[4].value != "" && saveData[5].value != "" &&
      saveData[6].value != "" && saveData[7].value != "" &&
      saveData[8].value != "" && saveData[9].value != "" &&
      saveData[10].value != "" && saveData[11].value != "" &&
      saveData[12].value != "" && saveData[13].value != ""){
      setLoading(true);
      return axios
      .post(moduleUrl, bodyData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(`${_t(t("Module settings has been saved"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
          
      });
    }else{
      toast.error(`${_t(t("Please select all dropdowns"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }
  const customSelectStyle={
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize:"14px"
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    })
  }
  return (
    <>
    <Helmet>
      <title>{_t(t("Module Settings"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                  <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Module Settings"))}
                              </span>
                            </li>
                          </ul>
                        </div>

                      {/* Form starts here */}
                      <div className="row">
                        <form
                          className="card p-3 mx-3 sm-text my-2"
                          onSubmit={(e)=>saveAccounts(e)}
                        >
                          <div className="form-group mt-2 row">
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Cash Accounts <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) =>
                                option.level3_name
                              }
                              getOptionValue={(option) =>
                                option.id
                              }
                              value={cashAcc}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isMulti
                              isClearable
                              closeMenuOnSelect={false}
                              maxMenuHeight="200px"
                              onChange={handleCashAcc}
                              placeholder={
                                _t(
                                  t("Please select cash accounts")
                                ) + ".."
                              }
                              styles={customSelectStyle}
                            />
                          </div>
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Withholding Tax Account <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleWHT}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select withholding tax account")
                                ) + ".."
                              }
                              value={whtAcc}
                              styles={customSelectStyle}                 
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 row">
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Other Tax Account <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleOtherTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select other tax account")
                                ) + ".."
                              }
                              value={otherTaxAcc}
                              styles={customSelectStyle}
                            />
                          </div>
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Bank Accounts <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) =>
                                option.level3_name
                              }
                              getOptionValue={(option) =>
                                option.id
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isMulti
                              isClearable
                              closeMenuOnSelect={false}
                              maxMenuHeight="200px"
                              onChange={handleBankAcc}
                              placeholder={
                                _t(
                                  t("Please select bank accounts")
                                ) + ".."
                              }
                              value={bankAcc}
                              styles={customSelectStyle}
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 row">
                          <div className="col-6">
                          <div className="genAcc-select mb-1">Inventory Account<span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleInventoryAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select inventory account")
                                ) + ".."
                              }
                              value={invAcc}
                              styles={customSelectStyle}
                            />
                          </div>
                          <div className="col-6">
                          <div className="genAcc-select mb-1">Revenue Account <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleRevAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select revenue account")
                                ) + ".."
                              }
                              value={revAcc}
                              styles={customSelectStyle}                   
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 row">
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Cost of good sold <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="top"
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleCOGS}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select cost of good sold")
                                ) + ".."
                              }
                              value={cogs}    
                              styles={customSelectStyle}               
                            />
                          </div>
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Purchase Discount <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="top"
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handlePurchaseDis}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select purchase discount")
                                ) + ".."
                              }
                              value={purchaseDis}   
                              styles={customSelectStyle}                
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 row">
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Sales Discount <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="top"                              
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSaleDis}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select sales discount")
                                ) + ".."
                              }
                              value={salesDis}    
                              styles={customSelectStyle}               
                            />
                          </div>
                          <div className="col-6">
                          <div className="genAcc-select mb-1">Purchase Tax <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="top"                              
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handlePurchaseTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select purchase tax")
                                ) + ".."
                              }
                              value={purchaseTax} 
                              styles={customSelectStyle}                  
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 row">
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Sales Tax <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="top"                               
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSaleTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select sales tax")
                                ) + ".."
                              }
                              value={salesTax}   
                              styles={customSelectStyle}                
                            />
                          </div>
                          <div className="col-6">
                            <div className="genAcc-select mb-1">Supplier Cash Account <span className="text-danger">*</span></div>
                            <Select
                              menuPlacement="top"                               
                              options={coa3ForSearch ? coa3ForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSuppCashAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select cash account")
                                ) + ".."
                              }
                              value={suppCashAcc}   
                              styles={customSelectStyle}                
                            />
                          </div>                          
                          </div>

                          <div className="form-group mt-3 row">
                            <div className="col-6">
                              <div className="genAcc-select mb-1">Supplier Payable Account Id <span className="text-danger">*</span></div>
                              <Select
                                menuPlacement="top"                               
                                options={coa3ForSearch ? coa3ForSearch : []}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.level3_name}
                                getOptionValue={(option) => option.id}
                                classNamePrefix="account"
                                className="xsm-text"
                                onChange={handleSuppPayableAcc}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(
                                    t("Please select payable account")
                                  ) + ".."
                                }
                                value={suppPayableAcc}   
                                styles={customSelectStyle}                
                              />
                            </div>
                            <div className="col-6">
                              <div className="genAcc-select mb-1">Suspense Account Id <span className="text-danger">*</span></div>
                              <Select
                                menuPlacement="top"                               
                                options={coa3ForSearch ? coa3ForSearch : []}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.level3_name}
                                getOptionValue={(option) => option.id}
                                classNamePrefix="account"
                                className="xsm-text"
                                onChange={handleSuspenseAcc}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(
                                    t("Please select suspense account")
                                  ) + ".."
                                }
                                value={suspenseAcc}   
                                styles={customSelectStyle}                
                              />
                            </div>
                          </div>
                          <div className="form-group mt-4 pb-2">
                            <div className="col-12 text-right">
                              <button
                                className="btn btn-primary px-4 rounded"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default AccountSettings
