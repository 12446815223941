import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";

const SalesListing = () => {
    const location = useLocation();
    const history =  useHistory();
    const [data, setData] = useState(location.state?.data);
    
    useEffect(() => {
        if (location.state?.data) {
          setData(location.state.data);
          history.replace({
            pathname: location.pathname,
            state: {},
          });
        }
    }, [history, location.pathname, location.state]);

    return (
        <>
            {data ? (
                <pre style={{ maxHeight: '100vh', overflowY: 'auto' }}>{JSON.stringify(data, null, 2)}</pre>
            ) : (
                <p>No data available</p>
            )}
        </>
    )
}

export default SalesListing;