import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import bcrypt from "bcryptjs-react";
import * as msal from "@azure/msal-browser";
import { ReactComponent as Azure } from "../restaurant/public/azure-icon.svg"
import msalConfig from '../../MicrosoftAzure';
//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  getCookie,
  getSystemSettings,
  modalLoading,
  checkInstallOffline,
  getFirebaseToken,
  generateSHA256
} from "../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

//context consumer
import { SettingsContext } from "../../contexts/Settings";
import { UserContext } from "../../contexts/User";
import { RestaurantContext } from "../../contexts/Restaurant";
import { FoodContext } from "../../contexts/Food";

const cookies = new Cookies();

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();

  //getting context values here
  let {
    loading,
    setLoading,
    generalSettings,
    getSmtp,
    getPermissionGroups,
    getLanguages,
    getCurrency,
    getSettings,
  } = useContext(SettingsContext);
  let {
    getAuthUser,
    // getCustomer,
    getWaiter,
    getAdminStaff,
    adminStaffForSearch,
    getParty,
    getTillUser,
  } = useContext(UserContext);
  let {
    getBranch,
    GetBranchList,
    getIngredientItem,
    getBranchWeb,
    getIngredientUnit,
    getTable,
    getFloor,
    getScheme,
    getCard,
    getDeptTag,
    getOrderType,
    getPaymentType,
    getPaymentTypeTax,
    getWorkPeriod,
    getWorkPeriodForBranch,
    getDeliveryPendingOrders,
    getDeliveryDeliveredOrders,
    getKdsList,
    getZones,
    getAreas
  } = useContext(RestaurantContext);

  let {
    getFood,
    getFoodGroup,
    getFoodGroupForPOS,
    getPropertyGroup,
    getVariation,
    getFoodItemsForPOS,
    getFoodGroupWithMenu,
    getPropertyItem
  } = useContext(FoodContext);

  const queryParams = new URLSearchParams(window.location.search);

  //state hooks here

  const [credentials, setCredentials] = useState({
    email: queryParams.get("email") ?? null,
    password: queryParams.get("pass") ?? null,
    is_hash: queryParams.get("email") ?? 0,
    is_azure: 0,
    remember_me: false,
    install_check_reload: true,
    install_no: false,
  });

  
   let checkOffline = false;
   //check if offline helper
   const offline = (url) => {
    return checkOffline && localStorage.getItem(url) != null;
  }

  //const [checkSubmit, setCheckSubmit] = useState(null);

  // install check
  const checkInstall = async () => {
    const url = BASE_URL + "/check-install";
    checkOffline = await checkInstallOffline();
    if(offline(url)){
      return "YES"
    }else{

      let result = await axios.get(url);
      return result.data;
    }
  };
 
  const childSubmit = useRef();
  useEffect(() => {
    if (credentials.email && credentials.password) {
      tryLogin();
    }
    
    setLoading(false);
      const fetchData = async () => {
        let result = "YES";
        //await checkInstall();
      
          if (result === "YES" || result === "NO") {
            getSettings();
            handleJquery();
            checkAuth();
            setCredentials({
              ...credentials,
              install_no: false,
              install_check_reload: false,
            });
          } else {
            setCredentials({
              ...credentials,
              install_no: true,
              install_check_reload: false,
            });
            handleJquery();
            checkAuth();
          }
        }
      fetchData();
  
  }, []);

  //jQuery
  const handleJquery = () => {
    //obj Image Animation
    var hoverLayer = $("body");
    var objImgOne = $(".fk-global-img__obj");

    //Animation Init
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      if (objImgOne.length) {
        objImgOne.css({
          transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
        });
      }
    });
  };

  //redirect if logged in
  const checkAuth = () => {
    getCookie() !== undefined && history.replace("/dashboard");
  };

  //set credentials here on input change
  const handleCredentials = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
      remember_me: !credentials.remember_me,
    });
  };

  //offline login
  const successfulLoginOffline = (res) => {
    if(res){
      // todo:: get data if have permission here after authentication
      //common
          getAuthUser(); //
          getPermissionGroups();
          getBranch();
          getLanguages();
          getCurrency();
          getIngredientItem();
          // getProductionItem()
          getIngredientUnit()
          getZones();
          getAreas();
      //permission based -data[3] permissions of this user
          getAdminStaff();
          getWorkPeriod();
          getWorkPeriodForBranch();
          // getCustomer();
          getTable();
          getScheme();
          getCard();
          getWaiter();
          getDeptTag();
          getPaymentType();
          getPaymentTypeTax();
          // getFood();
          getFoodGroupWithMenu();
          getFoodGroup();
          getFoodGroupForPOS();
          getFoodItemsForPOS();
          getPropertyGroup();
          getVariation();
          getParty();
          getOrderType();
          // getFinishGoodsTransferList();
          // getFinishGoodsList();
          getPropertyItem()
         
          if(res.user_type === "customer"){
            history.push("/");
          }else{
              history.push("/dashboard");
          }

    } else {
      setLoading(false);
      toast.error(`${_t(t("Sorry, you do not have access!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };


  const successfulLogin = (res) => {
    
    if (res.data[2] === 0 || res.data[2] === "0") {
      let access_token = {
        _user: res.data[0].access_token.slice(0, 8),
        sbb: res.data[0].access_token.slice(8, 10),
        frr: res.data[0].access_token.slice(10, 13),
        xss: res.data[0].access_token.slice(13),
      };
      
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
   
      cookies.set("_user", access_token._user, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      cookies.set("sbb", access_token.sbb, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      cookies.set("frr", access_token.frr, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      cookies.set("xss", access_token.xss, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });

      //firbase token
      if(getSystemSettings(generalSettings, "firebase_collection")){
        getFirebaseToken(getSystemSettings(generalSettings, "firebase_collection"));
      }
      // todo:: get data if have permission here after authentication
      //common
      getAuthUser(); //
      getPermissionGroups();
      getBranch();
      GetBranchList();
      getLanguages();
      getCurrency();
      // getProductionItem();
      getAreas()
      getKdsList();
      getIngredientItem()
      //permission based -data[3] permissions of this user
      if (res.data[3] !== null && res.data[3].includes("Manage")) {
        getZones();
        getIngredientUnit();
        getBranchWeb();
      }
      if (res.data[3] !== null) {
        if (res.data[3].includes("Customer") || res.data[3].includes("POS")) {
          getAdminStaff();
          getWorkPeriod();
          getWorkPeriodForBranch();
          getTillUser();
          // getCustomer();
          getTable();
          getFloor();
          getScheme();
          getCard();
          getWaiter();
          getDeptTag();
          getPaymentType();
          getPaymentTypeTax();
          getPropertyItem()
          // getFood();
          getFoodGroupWithMenu();
          getFoodGroup();
          getFoodGroupForPOS();
          getFoodItemsForPOS();
          getPropertyGroup();
          getVariation();
          getParty();
          getOrderType();
          // getFinishGoodsTransferList();
          // getFinishGoodsList();
        }
        if (res.data[3].includes("Delivery")) {
          getDeliveryPendingOrders();
          getDeliveryDeliveredOrders();
        }
      }
      if (res.data[1].user_type === "customer") {
        history.push("/");
      } else {
        history.push("/dashboard");
      }
    } else {
      setLoading(false);
      toast.error(`${_t(t("Sorry, you do not have access!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  // Configuration
  const MAX_ATTEMPTS = 6;
  const LOCKOUT_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

  const initializeRateLimit = () => {
    // Get stored data from localStorage or initialize with defaults
    const storedData = JSON.parse(localStorage.getItem('loginRateLimit')) || {
      failedAttempts: 0,
      lockUntil: 0,
    };
    return storedData[credentials.email] || { failedAttempts: 0, lockUntil: 0 };
  }

  const saveRateLimitData = (data) => {
    const storedData = JSON.parse(localStorage.getItem('loginRateLimit')) || {};
    storedData[credentials.email] = data;
    localStorage.setItem('loginRateLimit', JSON.stringify(storedData));
  }

  const canAttemptLogin = () => {
    const data = initializeRateLimit();
    const now = Date.now();
    if (data.lockUntil > now) {
      // User is locked out
      const remainingTime = Math.ceil((data.lockUntil - now) / 1000); // in seconds
      alert(`Too many login attempts. Please try again in ${remainingTime} seconds.`);
      return false;
    }else if(data.lockUntil > 0 && data.lockUntil <= now){
      data.failedAttempts = 0;
      data.lockUntil = 0;
    }else if(!data.lockUntil){
      // Allow a login attempt
      data.failedAttempts += 1;
    }

    // If attempts exceed the limit, set lockout time
    if (data.failedAttempts >= MAX_ATTEMPTS) {
      data.lockUntil = Date.now() + LOCKOUT_TIME;
      saveRateLimitData(data);
      alert("Too many failed attempts. You are locked out for 5 minutes.");
      return false;
    }
   
    saveRateLimitData(data);
    return true;
  }

  const tryLogin = (email= null) => {
    const data = initializeRateLimit();
    if (!canAttemptLogin()) return;
    setLoading(true);
    let formData = credentials;
    if(email){
      formData = {
        email: email,
        is_azure: 1,
      }
    }
    const url = BASE_URL + "/auth/login";
    return axios
      .post(url, formData)
      .then((res) => {
        successfulLogin(res);
        localStorage.setItem(url, JSON.stringify(res));
        data.failedAttempts = 0;
        data.lockUntil = 0;
        saveRateLimitData(data)
      })
      .catch(async(error) => {
        checkOffline = await checkInstallOffline();
        if (offline(url)) {
          // offine login work
          const offlineUrl = BASE_URL + "/settings/get-admin-staff"
          const getUserOffline = JSON.parse(localStorage.getItem(offlineUrl)).data[1];
  
          const getOffline = getUserOffline.filter((staff)=>{
            return staff.email === credentials.email
          });
          if(getOffline?.length > 0){
            let hash = getOffline[0].uid;
            hash = hash.replace(/^\$2y(.+)$/i, '$2a$1');
            bcrypt.compare(credentials.password, hash, function(err, res) {
              if(res === true){
                localStorage.setItem(url, JSON.stringify(getOffline[0]));
                successfulLoginOffline(getOffline[0]);
              }else{
                setLoading(false);
                toast.error(`${_t(t("Email or password is wrong123!"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              }
             });
         
          }else{
            setLoading(false);
            toast.error(`${_t(t("Email or password is wrong!"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
          // let parseRes = JSON.parse(localStorage.getItem(url));
          // successfulLogin(parseRes);
        } else {
          setLoading(false);
          toast.error(`${_t(t("Email or password is wrong!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      });
  };

  //try for login, submit credentials to server
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  // };
  //try for login, submit credentials to server
  const handleSubmit = (e) => {
    e.preventDefault();
    tryLogin();
  };

  async function getMerchantDetail() {
    try {
      const hash = await generateSHA256(window.location.hostname);
      const url = BASE_URL + `/settings/get-merchant-details/${hash}`; 
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      toast.error(`${_t(t("Failed to fetch merchant details!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  // microsoft login
  const loginWithAzureAD = async(e) => {
    e.preventDefault();
    const res = await getMerchantDetail();
    if(!res?.azure_app_id){
      toast.error(`${_t(t("Sorry you don't have azure integration!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }
    const dynamicMsalConfig = {
      ...msalConfig,
      auth: {
        ...msalConfig.auth,
        clientId: res?.azure_app_id || msalConfig.auth.clientId,
        authority: `https://login.microsoftonline.com/${res?.md_tenant_id}`,
      },
    };
    const msalInstance = new msal.PublicClientApplication(dynamicMsalConfig);

    msalInstance.initialize().then(() => {
      msalInstance.addEventCallback(async (event) => {
        if (event.eventType === msal.EventType.LOGIN_SUCCESS ||
            ((event.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account)) {
              const account = event.payload.account;
              tryLogin(account?.username)
        }else if (event.eventType === msal.EventType.LOGIN_FAILURE) {
          if (event.error && event.error.errorCode === "user_cancelled") {
            console.log("abcd44", event.error.errorCode)
          }else if (event.error instanceof msal.AuthError) {
            console.log("abcd44", event.error)
          }
        } else if (event.eventType === msal.EventType.ACQUIRE_TOKEN_FAILURE) {
          console.log("abcd45", event.eventType)
        }
      });
      loginWithPopup(msalInstance)
     }
    );
  }

  async function loginWithPopup(msalInstance) {
    try {
      await msalInstance.loginPopup({
        scopes: msalConfig.auth.scopes,
        redirectUri: window.location.origin,
        prompt: msalConfig.auth.prompt
      })
    } catch (error) {
        if (error instanceof msal.BrowserAuthError && error.errorCode === "user_cancelled") {
          console.warn("User cancelled the login popup.");
        } else {
          console.error("Unexpected error during login:", error);
        }
    }
  }

  // if (loginPass != null && loginEmail != null) {
  //   setCredentials({
  //     ...credentials,
  //     email: loginEmail,
  //     password: loginPass,
  //   });
  //   handleSubmit();
  // }

  const style = {
    logo: {
      backgroundColor: "none",
      // generalSettings &&
      // getSystemSettings(generalSettings, "type_background"),
      // backgroundImage:
      //   generalSettings &&
      //   `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Sign In"))}</title>
      </Helmet>
      <main>
        <div className="fk-global-access-login"
        >
          <div className="d-none d-lg-block">
          {/*
            <div className="fk-global-img text-center">
              <img
                src="/assets/img/pos-2.jpg"
                alt="indolj"
                className="img-fluid mx-auto fk-global-img__is"
              />
              <img
                src="/assets/img/obj-1.png"
                alt="indolj"
                className="img-fluid fk-global-img__obj fk-global-img__obj-1"
              />
              <img
                src="/assets/img/obj-8.png"
                alt="indolj"
                className="img-fluid fk-global-img__obj fk-global-img__obj-2"
              />
              <img
                src="/assets/img/obj-7.png"
                alt="indolj"
                className="img-fluid fk-global-img__obj fk-global-img__obj-6"
              />
              <img
                src="/assets/img/obj-9.png"
                alt="indolj"
                className="img-fluid fk-global-img__obj fk-global-img__obj-8"
                </div>
              />*/}
          </div>
          <div className="container"
           style={{height:"100%"}}
          >
            {credentials.install_no ? (
              <div className="row login-logo">
                <div className="col-md-6">
                  <div className="fk-brand fk-brand--sr-lg">
                    {window.location.pathname === "/" ? (
                      <NavLink
                        to={{ pathname: "/refresh", state: "/" }}
                        exact
                        className="t-link w-100"
                      >
                        <span className="fk-brand__img fk-brand__img--fk1"></span>
                      </NavLink>
                    ) : (
                      <NavLink to="/" className="t-link w-100">
                        <span className="fk-brand__img fk-brand__img--fk1"></span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row login-logo">
                <div className="col-md-6">
                  <div className="fk-brand fk-brand--sr-lg">
                    {window.location.pathname === "/" ? (
                      <NavLink
                        to={{ pathname: "/refresh", state: "/" }}
                        exact
                        className="t-link w-100"
                      >
                        <span
                          className="fk-brand__img fk-brand__img--fk1"
                          style={style.logo}
                        ></span>
                      </NavLink>
                    ) : (
                      <NavLink to="/" className="t-link w-100">
                        <span
                          className="fk-brand__img fk-brand__img--fk1"
                          style={style.logo}
                        ></span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            )}
            {credentials.install_no ? (
              <div className="row login-form">
                <div className="col-md-6 col-lg-5 col-xl-4 t-mt-50"
                 style={{marginTop:"-7rem"}}
                >
                  <div className="fk-global-form">
                    <div key="login-form">
                      <h3 className="mt-0 text-capitalize font-weight-bold">
                        {_t(t("Not Installed"))}
                      </h3>
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div>
                          Your application is not ready to use, Install to
                          continue. Follow our documentation to get installation
                          guide.
                        </div>
                        <div className="row mt-3">
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <div className="t-mr-8">
                                <NavLink
                                  to="/installation"
                                  className="btn btn-primary sm-text text-uppercase"
                                >
                                  {_t(t("Install"))}
                                </NavLink>
                              </div>
                              <div className="t-mr-8">
                                <a
                                  href="http://docs.indolj.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-info sm-text text-uppercase"
                                >
                                  {_t(t("Online Documentation"))}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row login-form">
                <div className="col-md-6 col-lg-5 col-xl-4 t-mt-50"
                style={{marginTop:"-7rem"}}
                >
                  <div className="fk-global-form">
                    {loading ? (
                      <div key="login-form">
                        <h3 className="mt-0 text-capitalize font-weight-bold">
                          {_t(t("waiting for response"))}
                        </h3>
                        <form ref={childSubmit} onSubmit={handleSubmit} autoComplete="off">
                          <div className="row">
                            {modalLoading(3)}
                            <div className="col-12">
                              <div className="d-flex align-items-center">
                                <div className="t-mr-8">
                                  <button
                                    type="button"
                                    className="btn btn-primary sm-text text-uppercase rounded"
                                  >
                                    {_t(t("Please wait"))}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div key="loading">
                        {!credentials.install_check_reload ? (
                          <>
                            <h3 className="mt-0 text-capitalize font-weight-bold">
                              {_t(t("sign in"))}
                            </h3>
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <div className="row">
                                {/* demo login info */}
                                <div className="p-2 t-mb-15 d-none">
                                  <div className="col-12 mb-1">
                                    <div className="card p-2">
                                      <div className="row">
                                        <div className="col-12 text-center sm-text">
                                          Demo login credentials
                                          <div className="text-primary">
                                            This password section is only for
                                            demo
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* 
                                  <div
                                    className="col-12 mb-1 pointer-cursor"
                                    onClick={() => {
                                      setCredentials({
                                        ...credentials,
                                        email: "admin@mail.com",
                                        password: "indolj@123",
                                      });
                                    }}
                                  >
                                    <div className="card p-2 ">
                                      <div className="row d-flex align-items-center">
                                        <div className="col-12 col-md-5">
                                          Admin: admin@mail.com
                                        </div>
                                        <div className="col-12 col-md-4">
                                          password: indolj@123
                                        </div>
                                        <div className="col-12 col-md-3 text-right">
                                          <span className="btn btn-sm btn-primary">
                                            copy
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-12 pointer-cursor"
                                    onClick={() => {
                                      setCredentials({
                                        ...credentials,
                                        email: "staff@mail.com",
                                        password: "indolj@123",
                                      });
                                    }}
                                  >
                                    <div className="card p-2">
                                      <div className="row d-flex align-items-center">
                                        <div className="col-12 col-md-5">
                                          Staff: staff@mail.com
                                        </div>
                                        <div className="col-12 col-md-4">
                                          password: indolj@123
                                        </div>
                                        <div className="col-12 col-md-3 text-right">
                                          <span className="btn btn-sm btn-primary">
                                            copy
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-12 pointer-cursor"
                                    onClick={() => {
                                      setCredentials({
                                        ...credentials,
                                        email: "customer@mail.com",
                                        password: "indolj@123",
                                      });
                                    }}
                                  >
                                    <div className="card p-2">
                                      <div className="row d-flex align-items-center">
                                        <div className="col-12 col-md-5">
                                          Customer: customer@mail.com
                                        </div>
                                        <div className="col-12 col-md-4">
                                          password: indolj@123
                                        </div>
                                        <div className="col-12 col-md-3 text-right">
                                          <span className="btn btn-sm btn-primary">
                                            copy
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-12 pointer-cursor"
                                    onClick={() => {
                                      setCredentials({
                                        ...credentials,
                                        email: "deliverymanager@mail.com",
                                        password: "indolj@123",
                                      });
                                    }}
                                  >
                                    <div className="card p-2">
                                      <div className="row d-flex align-items-center">
                                        <div className="col-12 col-md-5">
                                          Deliveryman: deliverymanager@mail.com
                                        </div>
                                        <div className="col-12 col-md-4">
                                          password: indolj@123
                                        </div>
                                        <div className="col-12 col-md-3 text-right">
                                          <span className="btn btn-sm btn-primary">
                                            copy
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  */}
                                </div>

                                <div className="col-12 t-mb-15">
                                  <input
                                    onChange={handleCredentials}
                                    type="email"
                                    name="email"
                                    placeholder="User Email"
                                    value={credentials.email}
                                    style={{
                                      backgroundColor:"#f5f5f5"
                                    }}
                                    required
                                    autoComplete="off"
                                    className="form-control border-0 rounded-1"
                                  />
                                </div>
                                <div className="col-12 t-mb-15">
                                  <input
                                    onChange={handleCredentials}
                                    name="password"
                                    type="password"
                                    placeholder="User Password"
                                    style={{
                                      backgroundColor:"#f5f5f5"
                                    }}
                                    value={credentials.password}
                                    required
                                    autoComplete="off"
                                    className="form-control border-0 rounded-1"
                                  />
                                </div>
                                <div className="col-6 t-mb-15">
                                  <label className="mx-checkbox">
                                    <input
                                      onChange={handleCredentials}
                                      name="remember_me"
                                      type="checkbox"
                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-1-kitchen"
                                    />
                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                      {_t(t("Remember Me"))}
                                    </span>
                                  </label>
                                </div>
                                <div className="col-6 t-mb-15 text-right">
                                  <NavLink
                                    to="/reset-password"
                                    className="t-link sm-text"
                                  >
                                    {_t(t("Forgot password"))}?
                                  </NavLink>
                                </div>
                                <div className="col-12">
                                  <div className="d-flex align-items-center">
                                    <div className="t-mr-8">
                                      <button
                                        type="submit"
                                        className="btn btn-success sm-text text-uppercase rounded"
                                      >
                                        {_t(t("sign in"))}
                                      </button>
                                    </div>
                                    <div className="t-mr-8">
                                      <button
                                        type="button"
                                        className="btn btn-primary sm-text text-uppercase rounded d-flex align-items-center"
                                        onClick={(e)=> loginWithAzureAD(e)}
                                      >
                                        <Azure className="mr-2" style={{height:"1rem"}}/>
                                        {_t(t("Sign In With Microsoft"))}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </>
                        ) : (
                          modalLoading(5)
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
