import React, { useEffect, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

//pages & includes
import PayrollSidebar from "../../PayrollSidebar";

//context consumer
import { PayrollContext } from "../../../../../../contexts/Payroll";

const DesignationAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams()
    //getting context values here
    //finance context
    const {
        loading,
        setLoading,
        dataPaginating,
        setDataPaginating,
    } = useContext(PayrollContext);

    //depart state
    const [designation,setDesignation]=useState({
      name:"",
      code:"",
      id:"",
      edit:false
    });

    const handleSetEdit=async(formData)=>{
      
    }
    
    const handleSubmit = (e )=>{
        e.preventDefault();
        setLoading(true);

        const endpoint = id ?"/settings/create-bank-payment" : "/settings/create-bank-payment" 
        const url = BASE_URL + endpoint;
        const formData = {
            name:designation.name,
            code:designation.code,
        }
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
            setLoading(false);
            toast.success(`${_t(t("Department has been added"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
            history.push("/dashboard/payroll/department")
        }).catch((error) =>{
            setLoading(false);
            // console.log("check error==>",error);
            toast.error(`${_t(t("Please try again later"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        })
    }

    const handleChange=(e)=>{
        setDesignation({...designation,[e.target.name]:e.target.value})
    }

    //document date identity and net amount total in get table
    return (
      <>
      <Helmet>
        <title>{_t(t("Add Designation"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <PayrollSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add Designation"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <div className="row">                            
                              <div className="form-group col-6 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="designation"
                                    className="control-label"
                                  >
                                    {_t(t("Name"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control table-text"
                                    id="designation"
                                    name="name"
                                    onChange={handleChange}
                                    value={designation.name}
                                    placeholder="Manager .."
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group col-6 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="code"
                                    className="control-label"
                                  >
                                    {_t(t("Code"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control table-text"
                                    id="code"
                                    name="code"
                                    onChange={handleChange}
                                    value={designation.code}
                                    placeholder="MG-12 .."
                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="mt-4 row">
                                <div className="d-flex justify-content-end">
                                    <div className="col-3 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn rounded hov-btn mr-3"
                                            onClick={()=>(history.push("/dashboard/payroll/department"))}
                                        >
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                        </button>
                                        <button
                                            disabled={loading}
                                            type="submit"
                                            className="btn btn-primary rounded"
                                        >
                                            <i className="fa fa-floppy-o" aria-hidden="true"></i> {!designation.edit ? _t(t("Save")) : _t(t("Update"))}
                                        </button>                                                    
                                    </div>
                                </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
    </>
    )
}

export default DesignationAction;