import React, { useContext, useState } from "react";


//functions
import {
  _t,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";

const AccountPrint = ({
 name,
 AccountPrint,
 bankPayment,
 totalRowAmount,
 totalRowWhtAmount,
 totalRowTaxAmount,
 totalRowNetAmount,
 newRow
}) => {
    const { t } = useTranslation();
    console.log("abcd44",bankPayment,bankPayment?.transactionAccount)

    const {
        //common
        generalSettings,
    } = useContext(SettingsContext);
      const landscapePrintStyles = {
        '@media print': {
          '@page': {
            size: 'landscape',
          },
        },
      };

  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={AccountPrint} style={landscapePrintStyles}>
         {bankPayment && bankPayment.doc_no && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>

                   <div className="my-2 xslg-text xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                    {`${getSystemSettings(
                        generalSettings,
                        "siteName"
                      )}`}
                   </div>

                   <div className="my-2 xslg-text xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>

                   <div className="mt-4 my-2 d-flex print-text-stock justify-content-between text-capitalize ml-2 row">
                     <div className="print-text-stock--bold col-6">Document Date: {bankPayment.doc_date && bankPayment.doc_date?.toDateString()}</div>
                     <div className="print-text-stock--bold col-6">{bankPayment?.transactionAccount && `Transaction Account` } {bankPayment.transactionAccount && bankPayment?.transactionAccount}</div>
                   </div>
                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between ml-2 row">
                    <div className="print-text-stock--bold col-6">
                     Document No :{" "}
                     {bankPayment.doc_no && bankPayment.doc_no}
                     </div>
                     <div className="print-text-stock--bold col-6">
                       Remarks :{" "}
                     {bankPayment?.remarks}
                    </div>
                   </div>
                   
                   {totalRowAmount !== null && newRow && newRow?.length > 0 &&
                       (
                       <div className=" mt-3">
                         <table className="table table-bordered-sm table-hover ">
                           <thead className="align-middle">
                             <tr>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table-Child"
                                 style={{minWidth:"180px"}}
                               >
                                 {_t(t("Account"))}
                               </th>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Remarks"))}
                               </th>
                               {bankPayment?.transactionAccount ? (
                                  <>
                                  <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Amount"))}
                               </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Tax Amount"))}
                               </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                               {_t(t("WHT Amount"))}
                               </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                               {_t(t("Net Amount"))}
                               </th></>
                                ) : (
                                  <>
                                  <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Debit"))}
                               </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                               {_t(t("Credit"))}
                               </th>
                               </>
                                )
                               }                              
                               
                             </tr>
                           </thead>
                           {
                             newRow && newRow?.length > 0 && (
                                newRow.map((item)=>{
                                    return (
                                        <tbody className="align-middle">
                                        <tr>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.level3_name}
                                         </td>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.remarks}
                                         </td>
                                         {bankPayment?.transactionAccount ? (
                                          <>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.amount}
                                         </td>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.other_tax_amount}
                                         </td>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.wht_amount}
                                         </td>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.net_amount}
                                         </td>
                                         </>)
                                         :<>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.debit}
                                         </td>
                                         <td
                                         scope="col"
                                         className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                         >
                                           {item.credit}
                                         </td>
                                         </>}
                                        </tr>
                                       </tbody>
                                    )
                                })
                             )
                           }
                           <tbody className="align-middle">
                             <tr>
                             <th  scope="col"
                             className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             ></th>
                             <th  scope="col"
                             className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             >Grand Total</th>

                            {bankPayment?.transactionAccount ? (<>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowAmount}
                              </th>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowTaxAmount}
                              </th>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowWhtAmount}
                              </th>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowNetAmount}
                              </th></>)
                             :
                             <>
                             <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowAmount}
                              </th>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {totalRowNetAmount}
                              </th>
                             </>}
                             </tr>
                           </tbody>
                         </table>
                       </div>
                     )
                    }
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default AccountPrint
