import React, { useEffect, useContext } from "react";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  managePageHrefLink,
  managePageHrefLinkMobile,
  checkPermission,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";

const ReportSidebar = () => {
  const { t } = useTranslation();
  useEffect(() => {
    handleJquery();
  }, []);

  const { showManageStock,generalSettings } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);

  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });
  };

  console.log(
    "report",
    checkPermission(authUserInfo.permissions, "Work Period Report")
  );

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              {/* Reports */}
              <ul className="t-list fk-sm-nav__bar flex-row">
                  {/* dashboard */}
                  {managePageHrefLinkMobile(
                    "/dashboard/reports",
                    _t(t("Dashboard"))
                  )}

                  {managePageHrefLinkMobile(
                    "/dashboard/table-view",
                    _t(t("Table View"))
                  )}

                  {
                    authUserInfo && (authUserInfo.details.user_type === "superAdmin" || authUserInfo.details.user_type === "admin") && (
                      managePageHrefLinkMobile(
                        "/dashboard/admin-reports",
                        _t(t("Admin Dashboard"))
                      )
                    )
                  }
                  
                {authUserInfo && authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" ? (
                  <>
                     {/*production dashboard */}
                     {authUserInfo && (authUserInfo.details.user_type === "superAdmin" ||
                     authUserInfo.details.user_type === "admin") && managePageHrefLinkMobile(
                      "/dashboard/production-dashboard",
                      _t(t("Production Dashboard"))
                    )}

                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                     getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLinkMobile(
                      "/dashboard/consolidated-reports",
                      _t(t("Consolidate Dashboard"))
                    )}

                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                     getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLinkMobile(
                      "/dashboard/consolidate-reports",
                      _t(t("Consolidate Report"))
                    )}

                    {/* daily */}
                    {/* {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Daily Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/daily-reports",
                          _t(t("Daily"))
                        )
                      : ""} */}

                    {/* monthly */}
                    {/* {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Monthly Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/monthly-reports",
                          _t(t("Monthly"))
                        )
                      : ""} */}
                  </>
                ) : null}

                {/* monthly */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Work Period Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/work-period-reports",
                      _t(t("Work Period Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                 checkPermission(authUserInfo.permissions, "Sales Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/sales-reports",
                      _t(t("Sales Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLinkMobile(
                      "/dashboard/sales-summary-reports",
                      _t(t("Sales Summary Report"))
                    )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Foc Report"
                  )
                  ? managePageHrefLinkMobile(
                      "/dashboard/foc-reports",
                      _t(t("Foc Report"))
                    )
                  : ""}

                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLinkMobile(
                      "/dashboard/gross-sales-reports",
                      _t(t("Gross Sales Report"))
                    )
                  : ""}

                  
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLinkMobile(
                      "/dashboard/tax-sales-reports",
                      _t(t("Tax Report"))
                    )
                  : ""}

                  {/* monthly */}
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLinkMobile(
                        "/dashboard/order-tracking-reports",
                        _t(t("Order Tracking Report"))
                      )
                    : ""}
                  

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Deal's Report"
                    )
                      ? managePageHrefLinkMobile(
                          "/dashboard/deal-reports",
                          _t(t("Deal's Report"))
                        )
                      : ""}
                  
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Menu Mix Report"
                  )
                    ? managePageHrefLinkMobile(
                        "/dashboard/menu-mix-reports",
                        _t(t("Menu Mix Report"))
                      )
                    : ""}
                  

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Callcenter Report"
                    )
                    ? managePageHrefLinkMobile(
                        "/dashboard/call-center-reports",
                        _t(t("Call Center Report"))
                      )
                    : ""}

                  {/* monthly */}
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Source Wise Report")
                    ? managePageHrefLinkMobile(
                        "/dashboard/source-reports",
                        _t(t("Source Wise Report"))
                      )
                    : ""}
                  {/* monthly */}
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Customer Order History Report"
                  )
                    ? managePageHrefLinkMobile(
                        "/dashboard/customer-order-reports",
                        _t(t("Customer Order Report"))
                      )
                    : ""}
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Food Panda Report")
                    ? managePageHrefLinkMobile(
                        "/dashboard/food-panda-reports",
                        _t(t("Food Panda Report"))
                      )
                    : ""}
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Rider Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/rider-reports",
                          _t(t("Rider Report"))
                        )
                      : ""}
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Rider Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/waiter-reports",
                          _t(t("Waiter Report"))
                        )
                      : ""}    
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Income Statement Report")
                    ? managePageHrefLinkMobile(
                        "/dashboard/income-statement-reports",
                        _t(t("Income Statement Report"))
                      )
                    : ""}
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Expense Report")
                    ? managePageHrefLinkMobile(
                        "/dashboard/expense-reports",
                        _t(t("Expense Report"))
                      )
                    : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Meezan Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/meezan-reports",
                      _t(t("Card Discount Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Order Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/cancel-order-reports",
                      _t(t("Cancel Order Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Item Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/cancel-made-unmade-reports",
                      _t(t("Cancel Item Report (Made/Unmade)"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Hourly Sales Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/hourly-sales-reports",
                      _t(t("Hourly Sales Report"))
                    )
                  : ""}
               
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Daily Cash Sales Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/daily-cash-sales-reports",
                      _t(t("Daily Cash Sales Report"))
                    )
                  : ""}

                {/* yearly */}
                {/* {managePageHrefLink(
                  "/dashboard/yearly-reports",
                  _t(t("Yearly"))
                )} */}

                {/* food item wise */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Item Food Cost Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/food-items-reports",
                      _t(t("Item Food Cost"))
                    )
                  : ""}

                {/* food group wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Group Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/food-group-reports",
                  //   _t(t("Group Wise"))
                  // )):
                  // ""
                }

                {/* branch wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Branch Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/branch-reports",
                  //   _t(t("Branch Wise"))
                  // )):
                  // ""
                }

                {/* pos user wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Pos User Wise Report") ? (
                  //     managePageHrefLinkMobile(
                  //   "/dashboard/pos-user-reports",
                  //   _t(t("Pos User Wise"))
                  // )):
                  // ""
                }

                {/* food item wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Department Wise Report") ? (
                  //     managePageHrefLinkMobile(
                  //   "/dashboard/dept-tag-reports",
                  //   _t(t("Department Wise"))
                  // )):
                  // ""
                }

                {/* Service charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Service Charge Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/service-charge-reports",
                  //   _t(t("Service Charge Wise"))
                  // )):
                  // ""
                }

                {/* discount charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Discount Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/discount-reports",
                  //   _t(t("discount Wise"))
                  // )):
                  // ""
                }

                {/* {managePageHrefLinkMobile(
                  "/dashboard/food-stock",
                  _t(t("Food Stock"))
                )}
                {managePageHrefLinkMobile(
                  "/dashboard/ingredient-stock",
                  _t(t("Ingredient Stock"))
                )} */}

                {/* discount charge wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Discount Wise Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/discount-reports",
                  _t(t("Discount Report"))
                ) : ""}

                {/* discount charge wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Tender Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/sales-tender-reports",
                  _t(t("Sales Tender Report"))
                ) : ""}

                {/* fg ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/finished-goods-ledger-reports",
                  _t(t("Finished Goods Ledger"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/fg-movement-reports",
                  _t(t("FG Movement Summary Report"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/fg-reports",
                  _t(t("FG Report"))
                ) : ""}


                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/fg-transfer-reports",
                  _t(t("FG Transfer Report"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/fg-discard-reports",
                  _t(t("FG Discard Report"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/fg-packing-reports",
                  _t(t("FG Packing Report"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/gift-hamper-reports",
                  _t(t("Gift Hamper Report"))
                ) : ""}

                {/* inventory */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Inventory Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/inventory-reports",
                  _t(t("Inventory Report"))
                ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Ingredient Item Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/ingredient-item-reports",
                  _t(t("Ingredient Item"))
                ):""}

                {/* stock ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-ledger-reports",
                  _t(t("Stock Ledger"))
                ):""}

                {/* stock variance */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Variance Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-variance-reports",
                  _t(t("Stock Variance"))
                ):""}

                {/* stock consumption */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-consumption-reports",
                  _t(t("Stock Consumption"))
                ):""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-transfer-reports",
                  _t(t("Stock Transfer"))
                ):""}

                {/* stock tranfer */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-transfer-raw-reports",
                  _t(t("Raw Stock Transfer"))
                ):""}

                {/* supplier ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/supplier-ledger-reports",
                  _t(t("Supplier Ledger"))
                ):""}
                
                {/* Purchase invoice report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/purchase-invoice-reports",
                  _t(t("Purchase Invoice Report"))
                ) : ""}

                {/* loyalty report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Loyalty Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/work-period-reports",
                    _t(t("Loyalty Overview Report"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Loyalty Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/work-period-reports",
                    _t(t("Customer Loyalty Activity Report"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Loyalty Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/work-period-reports",
                    _t(t("Redemption Report"))
                  )
                  : ""}  

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Loyalty Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/work-period-reports",
                    _t(t("Loyalty Performance Report"))
                  )
                  : ""}    

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Loyalty Report")
                  ? managePageHrefLinkMobile(
                    "/dashboard/work-period-reports",
                    _t(t("Customer Activity Report"))
                  )
                  : ""}

                {/* srb report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "SRB Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/srb-report",
                  _t(t("SRB Report"))
                ):""}

                {/* Production Report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Production Report"
                  ) ?
                  managePageHrefLinkMobile(
                    "/dashboard/production-reports",
                    _t(t("Production Report"))
                  ) : ""}

                  {/* recipe Report */}
                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Production Report"
                    ) ?
                    managePageHrefLinkMobile(
                      "/dashboard/recipe-reports",
                      _t(t("Recipe Report"))
                    ) : ""}

                  {/*customer report*/}
                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Work Period Report"
                    )
                    ? managePageHrefLinkMobile(
                      "/dashboard/customer-report",
                      _t(t("Customer Report"))
                    )
                    : ""}

              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* Dashboard */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname === '/dashboard/reports' || window.location.pathname === '/dashboard/consolidated-reports' ||  window.location.pathname === "/dashboard/table-view" ||
              window.location.pathname === '/dashboard/consolidate-reports' || window.location.pathname === "/dashboard/production-dashboard" || window.location.pathname === '/dashboard/admin-reports' ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Dashboard"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                {managePageHrefLink(
                  "/dashboard/reports",
                  _t(t("Dashboard"))
                )}

                {managePageHrefLink(
                  "/dashboard/table-view",
                  _t(t("Table View"))
                )}

                {
                  authUserInfo && (authUserInfo.details.user_type === "superAdmin" || authUserInfo.details.user_type === "admin") && (
                    managePageHrefLink(
                      "/dashboard/admin-reports",
                      _t(t("Admin Dashboard"))
                    )
                  )
                }
                {authUserInfo && authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" ? (
                  <>
                    {/*production dashboard */}
                    {authUserInfo && (authUserInfo.details.user_type === "superAdmin" ||
                    authUserInfo.details.user_type === "admin") && managePageHrefLink(
                     "/dashboard/production-dashboard",
                     _t(t("Production Dashboard"))
                   )}
                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                      getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLink(
                        "/dashboard/consolidated-reports",
                        _t(t("Consolidate Dashboard"))
                      )}

                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                      getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLink(
                        "/dashboard/consolidate-reports",
                        _t(t("Consolidate Report"))
                      )}

                    {/* daily */}

                    {/* {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Daily Report")
                      ? managePageHrefLink(
                          "/dashboard/daily-reports",
                          _t(t("Daily"))
                        )
                      : ""} */}

                    {/* monthly */}
                    {/* {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Monthly Report")
                      ? managePageHrefLink(
                          "/dashboard/monthly-reports",
                          _t(t("Monthly"))
                        )
                      : ""} */}
                  </>
                ) : null}
              </ul>
            </li>

            {/* Sales Reports */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${(window.location.pathname.includes("sale") || window.location.pathname.includes("order-tracking-reports")) || window.location.pathname.includes("deal") ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Sales Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Sales Report")
                  ? managePageHrefLink(
                    "/dashboard/sales-reports",
                    _t(t("Sales Report"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/sales-summary-reports",
                    _t(t("Sales Summary Report"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Foc Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/foc-reports",
                    _t(t("Foc Report"))
                  )
                  : ""}

                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/gross-sales-reports",
                    _t(t("Gross Sales Report"))
                  )
                  : ""}
                    
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLink(
                      "/dashboard/tax-sales-reports",
                      _t(t("Tax Report"))
                    )
                  : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/order-tracking-reports",
                      _t(t("Order Tracking Report"))
                    )
                    : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Hourly Sales Report")
                  ? managePageHrefLink(
                    "/dashboard/hourly-sales-reports",
                    _t(t("Hourly Sales Report"))
                  )
                  : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Sales Summary Report"
                    )
                    ? managePageHrefLink(
                      "/dashboard/deal-reports",
                      _t(t("Deal's Report"))
                    )
                    : ""}

                {/* Tender Report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Tender Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/sales-tender-reports",
                    _t(t("Sales Tender Report"))
                  ) : ""}
              </ul>
            </li>

            {/* Finished Goods */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("fg") || window.location.pathname.includes("gift") || window.location.pathname.includes("goods") ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("FG Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {/* fg ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/finished-goods-ledger-reports",
                    _t(t("Finished Goods Ledger"))
                  ) : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Finished Goods Report"
                    ) ?
                    managePageHrefLink(
                      "/dashboard/fg-movement-reports",
                      _t(t("FG Movement Report"))
                    ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/fg-reports",
                    _t(t("FG Report"))
                  ) : ""}


                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/fg-transfer-reports",
                    _t(t("FG Transfer Report"))
                  ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/fg-discard-reports",
                    _t(t("FG Discard Report"))
                  ) : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Finished Goods Report"
                    ) ?
                    managePageHrefLink(
                      "/dashboard/fg-packing-reports",
                      _t(t("FG Packing Report"))
                    ) : ""}

                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "Finished Goods Report"
                      ) ?
                      managePageHrefLink(
                        "/dashboard/gift-hamper-reports",
                        _t(t("Gift Hamper Report"))
                      ) : ""}
              </ul>
            </li>

            {/* Stock Reports */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${(!window.location.pathname.includes("ingredient-report")) && (window.location.pathname.includes("ingredient") || window.location.pathname.includes("stock") || window.location.pathname.includes("purchase-invoice") || window.location.pathname.includes("srb") || window.location.pathname.includes("supplier-ledger") || window.location.pathname.includes("production-reports") ||  window.location.pathname.includes("recipe"))
                ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Stock Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {/* inventory */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Inventory Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/inventory-reports",
                    _t(t("Inventory Report"))
                  ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Ingredient Item Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/ingredient-item-reports",
                    _t(t("Ingredient Item"))
                  ) : ""}

                {/* stock ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/stock-ledger-reports",
                    _t(t("Stock Ledger"))
                  ) : ""}

                {/* stock variance */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Variance Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/stock-variance-reports",
                    _t(t("Stock Variance"))
                  ) : ""}

                {/* stock consumption */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/stock-consumption-reports",
                    _t(t("Stock Consumption"))
                  ) : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/stock-transfer-reports",
                    _t(t("Stock Transfer"))
                  ) : ""}

                   {/* stock transfer */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/stock-transfer-raw-reports",
                    _t(t("Raw Stock Transfer"))
                  ) : ""}

                {/* supplier ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/supplier-ledger-reports",
                    _t(t("Supplier Ledger"))
                  ) : ""}  

                    {/* Purchase invoice report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/purchase-invoice-reports",
                  _t(t("Purchase Invoice Report"))
                ) : ""}

                {/* srb report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "SRB Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/srb-reports",
                    _t(t("SRB Report"))
                  ) : ""}

                {/* Production Report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Production Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/production-reports",
                    _t(t("Production Report"))
                  ) : ""}

                {/* recipe Report */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/recipe-reports",
                    _t(t("Recipe Report"))
                  ) : ""}
              </ul>
            </li>

            {/* Discount Reports */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${(window.location.pathname.includes("meezan") || window.location.pathname.includes("discount"))
                ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Discount Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Meezan Report")
                  ? managePageHrefLink(
                    "/dashboard/meezan-reports",
                    _t(t("Card Discount Report"))
                  )
                  : ""}

                {/* discount charge wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Discount Wise Report"
                  ) ?
                  managePageHrefLink(
                    "/dashboard/discount-reports",
                    _t(t("Discount Report"))
                  ) : ""}
              </ul>
            </li>

            {/*loyality report */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("reports") && window.location.pathname !== '/dashboard/admin-reports' && window.location.pathname !== '/dashboard/consolidated-reports' && window.location.pathname !== '/dashboard/consolidate-reports' && window.location.pathname !== '/dashboard/reports' && (!window.location.pathname.includes("fg") && window.location.pathname.includes("loyality")) ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Loyalty Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Sales Report")
                  ? managePageHrefLink(
                    "/dashboard/work-period-reports",
                    _t(t("Loyalty Overview Report"))
                  )
                  : ""}
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Customer Activity Report"))
                    )
                    : ""} 
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Points Balance Report"))
                    )
                    : ""} 

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Redemption Report"))
                    )
                    : ""} 

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Customer Retention Report"))
                    )
                    : ""} 

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Customer Activity Report"))
                    )
                    : ""} 

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Sales Impact Report"))
                    )
                    : ""}  

                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Sales Report")
                    ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Loyalty Performance Report"))
                    )
                    : ""}           
                    
              </ul> 
            </li>  

            {/* Others */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("reports") && window.location.pathname !== '/dashboard/admin-reports' && window.location.pathname !== '/dashboard/consolidated-reports' && window.location.pathname !== '/dashboard/consolidate-reports' && window.location.pathname !== '/dashboard/reports' && (!window.location.pathname.includes("fg") && !window.location.pathname.includes("goods"))
                && !window.location.pathname.includes("sales") && (!window.location.pathname.includes("ingredient") && !window.location.pathname.includes("srb") && !window.location.pathname.includes("production") && !window.location.pathname.includes("inventory") && !window.location.pathname.includes("stock") && !window.location.pathname.includes("purchase-invoice"))
                && (!window.location.pathname.includes("meezan") && !window.location.pathname.includes("discount") && !window.location.pathname.includes("deal") && !window.location.pathname.includes("supplier-ledger") && !window.location.pathname.includes("gift") && !window.location.pathname.includes("recipe")) && !window.location.pathname.includes("order-tracking-reports") ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Other Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {/* monthly */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Work Period Report")
                  ? managePageHrefLink(
                    "/dashboard/work-period-reports",
                    _t(t("Work Period Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Menu Mix Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/menu-mix-reports",
                    _t(t("Menu Mix Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Callcenter Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/call-center-reports",
                    _t(t("Call Center Report"))
                  )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Source Wise Report")
                  ? managePageHrefLink(
                    "/dashboard/source-reports",
                    _t(t("Source Wise Report"))
                  )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Customer Order History Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/customer-order-reports",
                    _t(t("Customer Order Report"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Food Panda Report")
                  ? managePageHrefLink(
                    "/dashboard/food-panda-reports",
                    _t(t("Food Panda Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Rider Report")
                  ? managePageHrefLink(
                    "/dashboard/rider-reports",
                    _t(t("Rider Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Rider Report")
                  ? managePageHrefLink(
                    "/dashboard/waiter-reports",
                    _t(t("Waiter Report"))
                  )
                  : ""}  
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Income Statement Report")
                  ? managePageHrefLink(
                    "/dashboard/income-statement-reports",
                    _t(t("Income Statement Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Expense Report")
                  ? managePageHrefLink(
                    "/dashboard/expense-reports",
                    _t(t("Expense Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Cancel Order Report")
                  ? managePageHrefLink(
                    "/dashboard/cancel-order-reports",
                    _t(t("Cancel Order Report"))
                  )
                  : ""}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Cancel Item Report")
                  ? managePageHrefLink(
                    "/dashboard/cancel-made-unmade-reports",
                    _t(t("Cancel Item Report (Made/Unmade)"))
                  )
                  : ""}

                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Daily Cash Sales Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/daily-cash-sales-reports",
                    _t(t("Daily Cash Sales Report"))
                  )
                  : ""}

                {/* yearly */}
                {/* {managePageHrefLink(
                  "/dashboard/yearly-reports",
                  _t(t("Yearly"))
                )} */}

                {/* food item wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Item Food Cost Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/food-items-reports",
                    _t(t("Item Food Cost"))
                  )
                  : ""}

                {/* food group wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Group Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/food-group-reports",
                  //   _t(t("Group Wise"))
                  // )):
                  // ""
                }

                {/* branch wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Branch Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/branch-reports",
                  //   _t(t("Branch Wise"))
                  // )):
                  // ""
                }

                {/* pos user wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Pos User Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/pos-user-reports",
                  //   _t(t("Pos User Wise"))
                  // )):
                  // ""
                }

                {/* food item wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Department Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/dept-tag-reports",
                  //   _t(t("Department Wise"))
                  // )):
                  // ""
                }

                {/* Service charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Service Charge Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/service-charge-reports",
                  //   _t(t("Service Charge Wise"))
                  // )):
                  // ""
                }

              </ul>
            </li>

            {/*master report */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("item") || window.location.pathname.includes("ingredient-report") || window.location.pathname.includes("customer-report") ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Master Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">

                {/* food item */}
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Work Period Report")
                  ? managePageHrefLink(
                    "/dashboard/all-item-report",
                    _t(t("Food Item"))
                  )
                  : ""}
                  {/*ingredient item*/}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Sales Summary Report"
                  )
                  ? managePageHrefLink(
                    "/dashboard/ingredient-report",
                    _t(t("Ingredient Item Report"))
                  )
                  : ""}

                  {/*customer report*/}
                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Work Period Report"
                    )
                    ? managePageHrefLink(
                      "/dashboard/customer-report",
                      _t(t("Customer Report"))
                    )
                    : ""}

              </ul>
            </li>

          </ul>
        </div>
      </div>
      {/* Navigation for large Screen End */}
    </>
  );
};

export default ReportSidebar;
