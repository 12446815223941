import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TiArrowSortedDown } from "react-icons/ti";
//pages & includes
import ManageSidebar from "../ManageSidebar";
import { ExportToCsv } from 'export-to-csv';
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Switch from "react-switch";

import { SettingsContext } from "../../../../../contexts/Settings";

//context consumer
import { FoodContext } from "../../../../../contexts/Food";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import FoodDeal from "./FoodDeal"

const AllItemList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  let { generalSettings } = useContext(SettingsContext);
  //getting context values here
  let {
    //common
    loading,
    setLoading,

    //foodGroup
    foodGroupForSearch,

    //property group
    propertyGroupForSearch,

    //food
    foodList,
    setFoodList,
    setPaginatedFood,
    //propertyItemForSearch,
    foodForSearch,
    setFoodForSearch,
    getFoodItemsForPOS,
    //variations
    variationForSearch,

    //pagination
    dataPaginating,
  } = useContext(FoodContext);

  let {
    //dept-tag
    // getDeptTag,
    // deptTagList,
    // setDeptTagList,
    // setPaginatedDeptTag,
    deptTagForSearch,
    // setDeptTagForSearch,

    // branchForSearch,
    // orderTypeForSearch,
  } = useContext(RestaurantContext);

  // States hook here
  let [variations, setVariations] = useState({
    //common
    edit: false,
    uploading: false,

    //food item === item
    item: null,

    //variation list === list
    list: null,

    //new price of variations
    newPrice: null,

    //new price of variations
    newStatus: null,

    //new cost of variations
    newCost: null,

    //variation to delete- id of food_with_variations table
    deletedVariations: null,

    //if all variation deleted
    priceAfterAllVariationDelete: "",
  });

  const menuWisePrice = useRef(0);
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ["Id", "Name", "Cost Price", "Price", "Food Group Name", "Menu Name 1", "Price 1", "Menu Name 2", "Price 2", "Menu Name 3", "Price 3", "Menu Name 4", "Price 4", "Menu Name 5", "Price 5", "Sku", "Status"],
    filename: 'Food-item',
  };
  const csvExporter = new ExportToCsv(options);

  //new variation
  let [newVariation, setNewVariation] = useState({
    //food item === item
    item: null,
    variations: null,
  });
  let [priceForVariations, setPriceForVariations] = useState(null);
  let [deptTagForSearchObj, setDeptTagForSearchObj] = useState({});
  let [selectedMenu, setSelectedMenu] = useState([]);
  let [selectedGroup, setSelectedGroup] = useState([]);
  //pop up modal
  const [foodSort,setFoodSort] = useState(false);
  const [listItem,setListItem] = useState([]);
  const [sortGroup,setSortGroup]=useState(false);
  const groupName =  useRef("");
  //const componentRef = useRef();

  let [createDeal,setCreateDeal] = useState({
    id: null,
    name:"",
    uploading:false,
    modal: false
  });

  //edit food item
  let [foodItemEdit, setFoodItemEdit] = useState({
    //show data on modal
    editItem: null,
    propertyGroup: null,
    foodGroup: null,
    cost_price: 0,
    cost_price_percent: 0,
    //formData
    item: null,
    newPropertyGroups: null,
    newFoodGroup: null,
    removeProperty: false,

    //special
    isSpecial: false,
    isDeal: false,
    //status
    status: false,

    //image
    imageUpdate: false,
    newImage: null,
  });
  
  // csv item
  // const [generatedReport, setGeneratedReport] = useState(false);
  // const [itemCsv,setItemCsv] = useState([]);
  const [uploadCsv,setUploadCsv] = useState(null);
  const [uploadCsvModal,setUploadCsvModal] = useState(false);
  //search result
  let [searchedFoodItem, setSearchedFoodItem] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(()=>{
   
    if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
      menuWisePrice.current = 1
     }else{
      menuWisePrice.current = 0
     }
 
  },[generalSettings])

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.from &&
      history.location.state.from === 'restrictions'
    ) getFoodItemsForPOS(true)
  }, [history.location])

  useEffect(() => {
    if (deptTagForSearch && deptTagForSearch.length) {
      const obj = {}
      for(const deptTag of deptTagForSearch) {
        obj[deptTag.id] = deptTag
      }
      setDeptTagForSearchObj(obj)
    }
  }, [deptTagForSearch])

  //edit food item
  const handleSetItemGroup = (foodGroup) => {
    console.log("foodgroup", foodGroup);
    setFoodItemEdit({
      ...foodItemEdit,
      newFoodGroup: foodGroup,
    });
    setSelectedGroup(foodGroup);
  };

  //on change input fields
  const handleChange = (e) => {
    if (e.target.name === "cost_price") {
      let generate_percent =
        (parseInt(e.target.value) / parseInt(foodItemEdit.item.price)) * 100;
      setFoodItemEdit({
        ...foodItemEdit,
        item: {
          ...foodItemEdit.item,
          cost_price_percent: generate_percent,
          cost_price: e.target.value,
        },
      });
    } else if (e.target.name === "cost_price_percent") {
      let generate_amount = (parseInt(foodItemEdit.item.price) / 100) * parseInt(e.target.value);
      setFoodItemEdit({
        ...foodItemEdit,
        item: {
          ...foodItemEdit.item,
          cost_price_percent: e.target.value,
          cost_price: generate_amount,
        },
      });
    } else {
      setFoodItemEdit({
        ...foodItemEdit,
        item: { ...foodItemEdit.item, [e.target.name]: e.target.value },
      });
    }
  };

  //set image hook
  const handleItemImage = (e) => {
    setFoodItemEdit({
      ...foodItemEdit,
      newImage: e.target.files[0],
    });
  };

  //set properties hook
  const handleSetPropertes = (property) => {
    setFoodItemEdit({
      ...foodItemEdit,
      newPropertyGroups: property,
    });
  };

  const handleSelectedMenu = (menu) => {
    console.log("menu", menu);
    // setNewParty({ ...newParty, menu });
    setSelectedMenu(menu);
  };

  const handleSelectedMenuOnEdit = (itemArray) => {
    let selectOptionsMenuGroup = [];
    const menuArray = JSON.parse(itemArray);
    console.log("itemArray", itemArray);
    if (menuArray.length !== 0) {
      for (var property of menuArray) {
        for (var i = 0; i < deptTagForSearch.length; i++) {
          if (deptTagForSearch[i].id == property) {
            selectOptionsMenuGroup.push(deptTagForSearch[i]);
          }
        }
        setSelectedMenu(selectOptionsMenuGroup);
      }
    } else {
      setSelectedMenu([]);
    }
  };

  const handleSelectedGroupOnEdit = (itemArray) => {
    let selectOptionsFoodGroup = [];
    const menuArray = itemArray;
    console.log("itemArray group ", itemArray);
    if (menuArray.length !== 0) {
      for (var property of menuArray) {
        console.log("237property", property[0]?.id);
        for (var i = 0; i < foodGroupForSearch.length; i++) {
          if (foodGroupForSearch[i].id == property[0]?.id) {
            selectOptionsFoodGroup.push(foodGroupForSearch[i]);
          }
        }
        setSelectedGroup(selectOptionsFoodGroup);
      }
    } else {
      setSelectedGroup([]);
    }
  };

  const handleUpdateStatus = (item) =>{
  
    setVariations({ ...variations, uploading: true });
    let formData = {
      //item id
      itemId: item.id,
      item_status: !item.item_status == true ? 1 : 0,

    };

    const url = BASE_URL + `/settings/update-food-item-status`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        // getFoodItemsForPOS();
        setVariations({ ...variations, uploading: false });
        toast.success(`${_t(t("Food item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });

      })
      .catch(() => {
        setVariations({ ...variations, uploading: false });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  } 

  //submit edit food item request to server
  const handleSubmit = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    console.log("newFoodGroup : ", foodItemEdit);
    let formData = {
      //item id
      itemId: foodItemEdit.editItem.id,

      // //item group
      // itemNewFoodGroup:
      //   foodItemEdit.newFoodGroup !== null ? foodItemEdit.newFoodGroup : null,

      //new name
      name: foodItemEdit.item.name,
      // item desc
      item_description: foodItemEdit.item.item_description,
      //new price
      price: foodItemEdit.editItem.price ? foodItemEdit.item.price : null,
      //to delete all property, boolean
      deleteProperty: foodItemEdit.deleteProperty ? 1 : 0,
      //isSpecial
      isSpecial: foodItemEdit.isSpecial,
      isDeal: foodItemEdit.isDeal,
      //status
      //item_status: foodItemEdit.item.item_status,
      item_status: foodItemEdit.item.item_status,

      //new property groups
      cost_price: foodItemEdit.item.cost_price,
      cost_price_percent: foodItemEdit.item.cost_price_percent,
      sku: foodItemEdit.item.sku,
      newPropertyGroups:
        foodItemEdit.newPropertyGroups !== null &&
        foodItemEdit.newPropertyGroups.length > 0
          ? foodItemEdit.newPropertyGroups
          : null,
    };

    //for menu_group
    let tempArray = [];
    selectedMenu.map((Item) => {
      tempArray.push(Item.id);
    });
    formData.menu_ids = tempArray.toString();

    //foodArray
    let foodArray = [];
    selectedGroup.map((Item) => {
      foodArray.push(Item.id);
    });
    formData.itemNewFoodGroup = foodArray.toString();

    const url = BASE_URL + `/settings/update-food-item`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling item in modal after item has been updated
        let tempItem = res.data[1].find((item) => {
          return item.id === foodItemEdit.editItem.id;
        });
        let selectedGroups = [];
        if (tempItem.property_groups) {
          tempItem.property_groups.map((grpItem) => {
            propertyGroupForSearch.map((singlePropertyGroup) => {
              if (singlePropertyGroup.id === grpItem) {
                selectedGroups.push(singlePropertyGroup);
              }
            });
          });
        }
        setFoodItemEdit({
          //show data on modal
          editItem: tempItem,
          propertyGroup: tempItem.property_groups ? selectedGroups : null,
          cost_price: 0,
          cost_price_percent: 0,
          //formData
          item: tempItem,
          newPropertyGroups: null,
          newFoodGroup: null,
          removeProperty: false,
          isSpecial: false,
          isDeal: false,
        });
        setVariations({ ...variations, uploading: false });
        // getFoodItemsForPOS();
        toast.success(`${_t(t("Food item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();

      })
      .catch(() => {
        setVariations({ ...variations, uploading: false });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //submit image request to server
  const handleSubmitImage = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    let formData = new FormData();
    formData.append(
      "itemId",
      foodItemEdit.editItem && foodItemEdit.editItem.id
    );
    formData.append("image", foodItemEdit.newImage);
    const url = BASE_URL + `/settings/update-food-item`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling item in modal after item has been updated
        let tempItem = res.data[1].find((item) => {
          return item.id === foodItemEdit.editItem.id;
        });
        setFoodItemEdit({
          //show data on modal
          editItem: tempItem,
          //formData
          item: tempItem,
          imageUpdate: true,
          newImage: null,
        });
        setVariations({ ...variations, uploading: false });
        // getFoodItemsForPOS()
        toast.success(`${_t(t("Image has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();

      })
      .catch((error) => {
        setVariations({ ...variations, uploading: false });
        if (error.response.data.errors.image) {
          error.response.data.errors.image.forEach((item) => {
            if (item === "Please select a valid image file") {
              toast.error(`${_t(t("Please select a valid image file"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
            if (item === "Please select a file less than 5MB") {
              toast.error(`${_t(t("Please select a file less than 5MB"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          });
        }
      });
  };


  //set variations hook
  const handleSetVariations = (variations) => {
    setNewVariation({ ...newVariation, variations });
  };

  //set each variation price
  let tempSizeArray = []
  const handleVariationPrice = (e, index) => {
    tempSizeArray = (priceForVariations && priceForVariations[e.target.name])
      ? priceForVariations[e.target.name]
      : [];
    tempSizeArray[index] = e.target.value
    setPriceForVariations({
      ...priceForVariations,
      [e.target.name]: tempSizeArray,
    });
  };

  //update variations here
  const handleUpdateVariations = (e) => {
    e.preventDefault();
    setVariations({ ...variations, uploading: true });
    const newPriceArray = [];
    variations.list.forEach(variation => {
      const id = variation.food_with_variation_id
      console.log('id', id);
      let priceChanged = false
      let costChanged = false
      let statusChanged = false
      if (variations.newPrice && id in variations.newPrice) priceChanged = true
      if (variations.newCost && id in variations.newCost) costChanged = true
      if (variations.newStatus && id in variations.newStatus) statusChanged = true
      if (!priceChanged && !costChanged && !statusChanged) return
      const price = (priceChanged ? variations.newPrice[id] : variation.food_with_variation_price)
      const cost = (costChanged ? variations.newCost[id] : variation.food_with_variation_cost_price)
      const status = (statusChanged ? variations.newStatus[id] : variation.variation_status)
      newPriceArray.push([id, price, cost, status])
    })
    let formData = {
      foodItemId: variations.item.id,
      deletedVariationsArray: variations.deletedVariations,
      // newPriceArray: variations.newPrice
      //   ? Object.entries(variations.newPrice)
      //   : null,
      newPriceArray: newPriceArray.length
        ? newPriceArray
        : null,
      priceAfterAllVariationDelete: variations.priceAfterAllVariationDelete,
    };

    const foodVariationUpdateUrl =
      BASE_URL + `/settings/update-food-item-variation`;
    return axios
      .post(foodVariationUpdateUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        //handling variations in modal after variations updated
        let tempItem = res.data[1].find((item) => {
          return item.id === variations.item.id;
        });
        setVariations({
          ...variations,
          edit: false,
          uploading: false,
          item: tempItem,
          list: tempItem.variations,
          deletedVariations: null,
          newPrice: null,
          newCost: null,
          newStatus: null,
          priceAfterAllVariationDelete: "",
        });
        // getFoodItemsForPOS()
        toast.success(`${_t(t("Food variations has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();

      })
      .catch(() => {
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleStatus = (e) => {
    const id = e.target.name.replace(/(-status)/g, '');
    console.log('ar-log id', id);
    if (variations.list.length === 1) {
      toast.error('To turn off this one variation, you must deselect Has Variation option in item settings.', {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      })
    } else {
      const newNewStatus = {
        ...variations.newStatus,
        [id]: e.target.checked ? 1 : 0,
      }
      const howManyOff1 = variations.list.reduce(
        (acc, item) => {
          if (item.variation_status!=0)
            return acc
          if (item.food_with_variation_id in newNewStatus)
            return acc
          return acc + 1
        }, 0);
      const howManyOff = Object.values(newNewStatus)
        .reduce((acc, item) => (acc + (item==0 ? 1 : 0)), 0);
      if (!e.target.checked && (howManyOff+howManyOff1) === variations.list.length) {
        toast.error('At least one variation must be turned on.', {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        })
      } else {
        setVariations({
          ...variations,
          newStatus: newNewStatus,
        });
      }
    }
  }

  const handleEditDeal = (item) =>{

    setCreateDeal({
      ...createDeal,
      id:item.id,
      name:item.name,
      deal_item: item.deal_item,
      modal: true
    })
  }

  // set states on edit
  const handlesetStatesOnEdit = (item) =>{
    setVariations({
      ...variations,
      uploading: true,
    });

    //property groups
    let selectedGroups =
      [];
    if (
      item.property_groups
    ) {
      item.property_groups.map(
        (grpItem) => {
          propertyGroupForSearch.map(
            (
              singlePropertyGroup
            ) => {
              if (
                singlePropertyGroup.id ===
                grpItem
              ) {
                selectedGroups.push(
                  singlePropertyGroup
                );
              }
            }
          );
        }
      );
    }
    let selectedFoodGroups =
      [];
    if (item.groups) {
      item.groups.map(
        (grpItem) => {
          propertyGroupForSearch.map(
            (
              singlePropertyGroup
            ) => {
              if (
                singlePropertyGroup.id ===
                grpItem
              ) {
                selectedFoodGroups.push(
                  singlePropertyGroup
                );
              }
            }
          );
        }
      );
    }
    setFoodItemEdit({
      ...foodItemEdit,
      editItem: item,
      item:{
      ...item,
      item_status: item.item_status
    },
      propertyGroup:
        item.property_groups
          ? selectedGroups
          : null,
      foodGroup:
        item.groups
          ? selectedGroups
          : null,
      newPropertyGroups:
        null,
      newFoodGroup:
        item.groups
          ? selectedGroups
          : null,
      deleteProperty: false,

      imageUpdate: false,
      newImage: null,
      status: item.item_status,
    });

    // group work //
    item.groups
      ? handleSelectedGroupOnEdit(
          item.groups
        )
      : setSelectedGroup(
          []
        );

    // menu work //
    item.menu_ids
      ? handleSelectedMenuOnEdit(
          item.menu_ids
        )
      : setSelectedMenu(
          []
        );

    setTimeout(() => {
      setVariations({
        ...variations,
        uploading: false,
      });
    }, 500);
  }
 
  //save new variations here
  const handleSaveNewVariations = (e) => {
    e.preventDefault();
    if (newVariation.variations === null) {
      toast.error(`${_t(t("Please select variations"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      if (newVariation.variations.length > 0) {
        setVariations({ ...variations, uploading: true });
        let formData = new FormData();
        formData.append("foodItemId", newVariation.item.id);
        //converting variations and prices to array
        let slugArray = [];
        newVariation.variations.map((newVarItem) => {
          slugArray.push(newVarItem.slug);
        });
        slugArray.map((slugItem) => {
          formData.append("slugOfVariations[]", slugItem);
        });

        console.log("priceForVariations", priceForVariations);
        let tempData = Object.entries(priceForVariations);
        tempData.map((item) => {
          formData.append("variations[]", item);
        });

        const url = BASE_URL + "/settings/new-food-item-variation";
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then(() => {
            setNewVariation({
              item: null,
              variations: null,
            });
            setPriceForVariations(null);
            setVariations({ ...variations, uploading: false });
            toast.success(`${_t(t("variations has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            document.getElementById('close-btn').click();
          });
      } else {
        toast.error(`${_t(t("Please select variations"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  };

  //search food here
  const handleSearch = (e) => {
 
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedFoodItem({ ...searchedFoodItem, searched: false });
    } else {
      const searchedList = foodForSearch.filter((item) => {
        let lowerCaseItemName = item.name?.toLowerCase();
        let lowerCaseItemGroup = item?.food_group_name?.toLowerCase();
        let lowerCaseGroup = item.groups && item.groups[0] && item.groups[0][0]?.name?.toLowerCase()
        // &&
        // item.groups.map((group_item, i) => (
        //   group_item && group_item[0]?.name?.toLowerCase()
        // ));
        return (
          (lowerCaseGroup && lowerCaseGroup.includes(searchInput)) ||
          (lowerCaseItemName && lowerCaseItemName.includes(searchInput)) ||
          (lowerCaseItemGroup && lowerCaseItemGroup.includes(searchInput)) 
        );
      });
    
      setSearchedFoodItem({
        ...searchedFoodItem,
        list: searchedList,
        searched: true,
      });
    }
  };

  // desc and image add
  const handleItemConfirmation = (text, type) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h2>{_t(t("Are you sure?"))}</h2>
            <p className="text-center">{_t(t(text))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary px-3 rounded"
                onClick={() => {
                  handleItemDescImage(type);
                  onClose();
                }}
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleItemDescImage = (type) =>{
    setLoading(true);
    const url = BASE_URL + `/settings/food-item-descImage/${type}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(`${_t(t(res.data.message))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  //delete confirmation modal of food item
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteFood(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete food here
  const handleDeleteFood = (slug) => {
    setLoading(true);
    const url = BASE_URL + `/settings/delete-food-item/${slug}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Item has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleFoodCsv = () =>{
    setLoading(true);
    const url = BASE_URL + `/settings/get-food-item-for-csv`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // setItemCsv(res.data)
        // setGeneratedReport(true);
        csvExporter.generateCsv(res.data);
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  function isCSV(contents) {
    // Basic check if it's a CSV file by checking if it contains a comma
    const rows = contents.split('\n');

    // Extract the header row (assuming it's the first row)
    const headerRow = rows[0];

    // Split the header row into individual header values
    const headers = headerRow.split(',');
    return contents.includes(',') && contents.includes('\n') && (headers.length === 17);
  }

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = (e) => {
        const contents = e.target.result;
      
        let missPrice = "";
        if(isCSV(contents)){
          const startIndex = 1; // Start index
          const lines = contents.trim().split('\n').slice(startIndex);
          const parsedData = lines.map((line)=>{
            const values = line.split(',').map(value => value.replace(/["/\r\n]/g, ''));
            if(menuWisePrice.current){
              if(values[6] === null || values[6] === ""){
                missPrice += values[1] + ", "
              }
            }else{
              if(values[3] === null || values[3] === ""){
                missPrice += values[1] + ", "
              }
            }

            return {
              id : values[0],
              item_name : values[1],
              cost_price : values[2],
              price : values[3],
              food_group_name : values[4],
              menu_name_1: values[5],
              menu_price_1: values[6],
              menu_name_2: values[7],
              menu_price_2: values[8],
              menu_name_3: values[9],
              menu_price_3: values[10],
              menu_name_4: values[11],
              menu_price_4: values[12],
              menu_name_5: values[13],
              menu_price_5: values[14],
              sku : values[15],
              status : values[16],
            }
          })
          setUploadCsv(parsedData);
        }else {  
          if (document.getElementById('uploadFile')) {
            document.getElementById('uploadFile').value = '';
          }
          setUploadCsv(null)
          setUploadCsvModal(false)
          toast.error("Please upload only food item csv file", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }); 
          // const startIndex = 2; // Start index
          // const lines = contents.split('<tr>');
          // const portion = lines.slice(startIndex);
          // const parsedData = portion.map((line) => {
          //   // Extract the content from the <td> element
          //   const tdContent = line.match(/<td[^>]*>(.*?)<\/td>/g);
          //   if(tdContent){
          //   const divId = document.createElement('div');
          //   divId.innerHTML = tdContent[0];
          //   const divVarId = document.createElement('div');
          //   divVarId.innerHTML = tdContent[1]; 
          //   const divfoodGrp = document.createElement('div');
          //   divfoodGrp.innerHTML = tdContent[3]; 
          //   const divName = document.createElement('div');
          //   divName.innerHTML = tdContent[2]; 
          //   const divVar = document.createElement('div');
          //   divVar.innerHTML = tdContent[4]; 
          //   const divPrice = document.createElement('div');
          //   divPrice.innerHTML = tdContent[5];
          //   const divCostPrice = document.createElement('div');
          //   divCostPrice.innerHTML = tdContent[6]; 
          //   if(divPrice.textContent === null || divPrice.textContent === ""){
          //     missPrice += divName.textContent + ", ";
          //   } 
          //   fileUpload.push({
          //     id : divId.textContent,
          //     variation_id : divVarId.textContent,
          //     food_group_name : divfoodGrp.textContent,
          //     item_name : divName.textContent,
          //     variation_name : divVar.textContent,
          //     price : divPrice.textContent,
          //     cost_price : divCostPrice.textContent,
          //   })
          // }
          // });
        } 

        if(missPrice !== "" && missPrice !== null){
          toast.error("Please add price in " + missPrice, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      };
    }; 
  }
 
  const handleFoodCsvSubmit = (e) => {
    e.preventDefault();
    
    const url = BASE_URL +"/settings/food-item-for-csv";
    let formData = {
      items : uploadCsv
    }
    if(!uploadCsv || uploadCsv.length === 0){
      toast.error(`${_t(t("Please upload file"))}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }
    
    setLoading(true);
    return axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (document.getElementById('uploadFile')) {
          document.getElementById('uploadFile').value = '';
        }
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        setUploadCsv(null);
        setFoodSort(false);
        setUploadCsvModal(false)
        setLoading(false);
        setListItem([])
        toast.success(`${_t(t("Item file upload successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      })
      .catch(() => {
        setLoading(false);
        setUploadCsv(null);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  const handleOnDragEnd = (result) =>{
    if (!result.destination) return;

    const items = listItem;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setListItem(items);
  }

  //drag style

  const getItemStyle = (isDragging, draggableStyle) => ({
      background: isDragging ? "white" : "",
      backgroundColor: isDragging ? "white" : "",
      // styles we need to apply on draggables
      ...draggableStyle
  });
  // filter item by category
  const handleFoodSortList = (groupItem) =>{
    const url = BASE_URL + `/settings/get-food-item-by-id/${groupItem.id}`;
    groupName.current = groupItem.name;

    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setListItem(res.data[0]);
        setFoodSort(true);
        setSortGroup(false)
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  // update food item sort
  const handleUpdateFoodSort = (e) =>{
    e.preventDefault()
    let orderItem = [] 
    listItem && listItem.forEach((item,index)=>{
      orderItem.push({
        ...item,
        sort:index+1
      })
    })
    const url = BASE_URL +"/settings/update-food-item-sort";
    setLoading(true);
    let formData = {
     sortedList:orderItem
    }
    return axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodList(res.data[0]);
        setFoodForSearch(res.data[1]);
        setSearchedFoodItem({
          ...searchedFoodItem,
          list: res.data[1],
        });
        setFoodSort(false);
        setLoading(false);
        setListItem([])
        toast.success(`${_t(t("Item sorting has been updated successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Food Items"))}</title>
      </Helmet>
  
      {/*upload csv Modal*/}
      <div
        className={`modal fade ${
          uploadCsvModal !== false ? "showing" : ""
        }`}
        id="addReason"
        aria-hidden="true"
      >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header align-items-center">
            <div className="fk-sm-card__content"></div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                if (document.getElementById('uploadFile')) {
                  document.getElementById('uploadFile').value = '';
                }
                setUploadCsv(null)
                setUploadCsvModal(false)
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
              <form onSubmit={handleFoodCsvSubmit} autoComplete="off">
                <div>
                  <label htmlFor="name" className="form-label">
                    {_t(t("Upload File"))}{" "}
                    <small style={{ color: "red" }}>*</small>
                  </label>
                  <br/>
                  <input
                    type={"file"}
                    id="uploadFile"
                    name="uploadFile"
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                 </div>
                 <button
                 disabled={loading}
                 type="submit"
                 className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                 >
                   {_t(t("Submit"))}
                 </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit item modal */}
      <div className="modal fade" id="editFood" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {_t(t("Update"))}{" "}
                  <span className="text-capitalize">
                    {foodItemEdit.editItem && foodItemEdit.editItem.name}
                  </span>
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {variations.uploading === false ? (
                <div key="fragment-food-group-1">
                  {foodItemEdit.imageUpdate ? (
                    <form onSubmit={handleSubmitImage} className="mx-2 sm-text" autoComplete="off">
                      <div className="form-group mt-4">
                        <div className="d-flex align-items-center mb-2">
                          <label htmlFor="image" className="control-label mr-3">
                            {_t(t("Image"))}
                            <small className="text-secondary">
                              ({_t(t("300 x 300 Preferable"))})
                            </small>
                          </label>
                          <div
                            className="fk-language__flag"
                            style={{
                              backgroundImage: `url(${
                                foodItemEdit.editItem &&
                                foodItemEdit.editItem.image
                              })`,
                            }}
                          ></div>
                        </div>
                        <div className="mb-2">
                          <input
                            type="file"
                            className="form-control sm-text"
                            id="image"
                            name="image"
                            onChange={handleItemImage}
                            required
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                      >
                        {_t(t("Update"))}
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmit} className="mx-2 sm-text" autoComplete="off">
                      {deptTagForSearch && (
                        <div className="form-group mt-2 mb-2">
                          <div className="mb-2">
                            <label
                              htmlFor="itemGroup"
                              className="control-label"
                            >
                              {_t(t("Menu group"))}
                              <span className="text-primary mr-1">*</span>
                            </label>
                          </div>
                          <Select
                            options={deptTagForSearch}
                            components={makeAnimated()}
                            value={selectedMenu}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isMulti={true}
                            maxMenuHeight="200px"
                            onChange={handleSelectedMenu}
                            placeholder={_t(t("Please select menu")) + ".."}
                          />
                        </div>
                      )}

                      {foodGroupForSearch && (
                        <div className="form-group mt-2">
                          <div className="mb-2">
                            <label
                              htmlFor="itemGroup"
                              className="control-label"
                            >
                              {_t(t("Food group"))}
                              <span className="text-primary mr-1">
                                ({_t(t("Optional"))})
                              </span>
                            </label>
                          </div>
                          {console.log(
                            "here",
                            foodItemEdit.editItem,
                            "new",
                            foodItemEdit.propertyGroup,
                            "Food GRoup",
                            foodItemEdit.foodGroup
                          )}
                          {foodItemEdit.editItem && (
                            <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                              {foodItemEdit.editItem.groups.map(
                                (selectedItem) => {
                                  console.log("selected", selectedItem);
                                  return (
                                    <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 mx-2 my-1 text-center">
                                      {selectedItem[0]?.name}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                          <Select
                            options={foodGroupForSearch}
                            //value={foodItemEdit.groups}
                            value={selectedGroup}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isMulti
                            maxMenuHeight="200px"
                            onChange={handleSetItemGroup}
                            placeholder={
                              _t(t("Please select food group")) + ".."
                            }
                          />
                        </div>
                      )}

                      <div className="row">
                        <div className="form-group mt-3 col-md-6">
                          <div className="mb-2">
                            <label htmlFor="name" className="control-label">
                              {_t(t("Name"))}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              onChange={handleChange}
                              value={
                                foodItemEdit.item && foodItemEdit.item.name
                              }
                              placeholder="e.g. Spicy chicken burger"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <div className="mb-2">
                            <label htmlFor="sku" className="control-label">
                              {_t(t("SKU"))}
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="sku"
                              name="sku"
                              onChange={handleChange}
                              value={foodItemEdit.item && foodItemEdit.item.sku}
                              placeholder="e.g. 12345678"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group mt-3 col-md-12">
                          <div className="mb-2">
                            <label htmlFor="item_description" className="control-label">
                              {_t(t("Item Description"))}
                            </label>
                          </div>
                          <div className="mb-2">
                            <textarea
                              type="text"
                              rows="2"
                              className="form-control"
                              id="item_description"
                              name="item_description"
                              onChange={handleChange}
                              value={
                                foodItemEdit.item && foodItemEdit.item.item_description
                              }
                              placeholder="e.g. 1 beef burger, 2 drinks"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {foodItemEdit.editItem && foodItemEdit.editItem.price && (
                          <div className="form-group mt-4 col-md-6">
                            <div className="mb-2">
                              <label htmlFor="price" className="control-label">
                                {_t(t("Price"))}
                                <span className="text-primary">* </span>
                                {/* <small className="text-secondary">
                                  ({_t(t("Enter price in USD"))})
                                </small> */}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                id="price"
                                type="number"
                                step="0.01"
                                min="0"
                                className="form-control"
                                name="price"
                                value={foodItemEdit.item.price}
                                onChange={handleChange}
                                placeholder="e.g. Type price of this item in 'US dollar'"
                                required
                              />
                            </div>
                          </div>
                        )}

                        {/* {foodItemEdit.editItem && foodItemEdit.editItem.item_cost && ( */}
                        <div className="col-md-6">
                          <div className="row">
                            <div className="form-group mt-4 col-md-6">
                              <div className="mb-2">
                                <label
                                  htmlFor="cost_price_percent"
                                  className="control-label"
                                >
                                  {_t(t("Item Cost Percent"))}
                                  <span className="text-primary"></span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <input
                                  id="cost_price_percent"
                                  type="number"
                                  className="form-control"
                                  name="cost_price_percent"
                                  max="100"
                                  value={foodItemEdit.item?.cost_price_percent}
                                  onChange={handleChange}
                                  placeholder="e.g. 10%"
                                />
                              </div>
                            </div>
                            <div className="form-group mt-4 col-md-6">
                              <div className="mb-2">
                                <label
                                  htmlFor="cost_price"
                                  className="control-label"
                                >
                                  {_t(t("Item Cost"))}
                                  <span className="text-primary"> </span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <div class="input-group mb-3">
                                  <span
                                    class="input-group-text"
                                    id="basic-addon1"
                                  >
                                    Rs
                                  </span>
                                  <input
                                    id="cost_price"
                                    type="number"
                                    className="form-control"
                                    name="cost_price"
                                    max={foodItemEdit.item?.price}
                                    value={foodItemEdit.item?.cost_price}
                                    onChange={handleChange}
                                    placeholder="e.g. 100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* )} */}
                      </div>

                      <div className="form-group mt-3">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Property groups"))}
                            <span className="text-primary mr-1">
                              ({_t(t("Optional"))})
                            </span>
                          </label>
                        </div>
                        {/* delete all property chceckbox */}
                        {foodItemEdit.editItem &&
                          foodItemEdit.editItem.has_property === "1" && (
                            <div className="form-check mt-2 ml-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="propertyCheck"
                                checked={foodItemEdit.deleteProperty}
                                onChange={() => {
                                  setFoodItemEdit({
                                    ...foodItemEdit,
                                    deleteProperty:
                                      !foodItemEdit.deleteProperty,
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="propertyCheck"
                              >
                                {_t(t("Remove all properties?"))}
                              </label>
                            </div>
                          )}
                        {/* selected property group */}
                        {foodItemEdit.propertyGroup && (
                          <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                            {foodItemEdit.propertyGroup.map((selectedItem) => {
                              return (
                                <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 mx-2 my-1 text-center">
                                  {selectedItem.name}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        {!foodItemEdit.deleteProperty && (
                          <Select
                            options={propertyGroupForSearch}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isMulti
                            maxMenuHeight="200px"
                            onChange={handleSetPropertes}
                            placeholder={
                              _t(t("Please select property groups")) + ".."
                            }
                          />
                        )}
                      </div>

                      {foodItemEdit.editItem && (
                        <div className="form-check mt-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="SpecialCheck"
                            checked={foodItemEdit.isSpecial}
                            onChange={() => {
                              setFoodItemEdit({
                                ...foodItemEdit,
                                isSpecial: !foodItemEdit.isSpecial,
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="SpecialCheck"
                          >
                            {_t(t("Is Special?"))}
                          </label>
                        </div>
                      )}

                      {foodItemEdit.editItem && (
                        <div className="form-check mt-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="dealCheck"
                            checked={foodItemEdit.isDeal}
                            onChange={() => {
                              setFoodItemEdit({
                                ...foodItemEdit,
                                isDeal: !foodItemEdit.isDeal,
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="dealCheck"
                          >
                            {_t(t("Is Deal?"))}
                          </label>
                        </div>
                      )}

                      <div className="form-check mt-4 pl-0">
                        <Switch
                          checked={foodItemEdit.item?.item_status}
                          name="status"
                          onChange={(e) => {
                            setFoodItemEdit({
                              ...foodItemEdit,
                              item: {
                                ...foodItemEdit.item, 
                                item_status: !foodItemEdit.item?.item_status
                              }
                            });
                          }}
                          height={22}
                          width={44}
                          offColor="#ee5253"
                          className="float-left"
                        />
                        <label
                          className="form-check-label pointer-cursor ml-2"
                          htmlFor="status"
                        >
                          {_t(t("Status"))}
                        </label>
                      </div>
                      
                      <button
                        type="submit"
                        className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                      >
                        {_t(t("Update"))}
                      </button>
                    </form>
                  )}
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(5)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* edit item modal Ends*/}

      {/*update variations modal */}
      <div className="modal fade" id="foodVariations" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {/* food item name in variation modal */}
                  {!variations.edit
                    ? [variations.item && variations.item.name]
                    : [
                        _t(t("Update variations of")) +
                          " " +
                          [variations.item && variations.item.name],
                      ]}
                  {/* food item name in variation modal ends */}
                </h5>
              </div>
              <button
                type="button"
                id = "close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* showing this-> depending on update has been submitted or not */}
              {!variations.uploading && (
                <div className="text-right">
                  {variations.list && variations.list.length > 0 && (
                    <button
                      className={`btn btn-primary text-capitalize mb-3 sm-text px-4 rounded`}
                      onClick={() => {
                        //set variations which are selected to delete === null; if "Cancel" button is clicked,
                        //edit variation true if "Edit" button clicked
                        setVariations({
                          ...variations,
                          //items to delete
                          deletedVariations:
                            variations.edit === true
                              ? null
                              : variations.deletedVariations,

                          //items to set new price
                          newPrice:
                            variations.edit === true
                              ? null
                              : variations.newPrice,

                          //if all variation deleted
                          priceAfterAllVariationDelete:
                            variations.edit === true
                              ? null
                              : variations.priceAfterAllVariationDelete,

                          edit: !variations.edit,
                        });
                      }}
                    >
                      {!variations.edit ? _t(t("edit")) : _t(t("cancel"))}
                    </button>
                  )}
                </div>
              )}
              {/* if update has been submitted or not ends here */}

              {/* show table data / form / show modal loading */}
              {variations.edit === false ? (
                // if variation edit is false- show table data
                <div key="fragment-food-group-1 table-responsive">
                  <table className="table table-bordered-sm table-hover">
                    <thead className="align-middle">
                      <tr>
                        <th
                          scope="col"
                          className="sm-text align-middle text-center border-1 border"
                        >
                          {_t(t("S/L"))}
                        </th>

                        <th
                          scope="col"
                          className="sm-text align-middle text-center border-1 border"
                        >
                          {_t(t("Variation name"))}
                        </th>
                        <th
                          scope="col"
                          className="sm-text align-middle text-center border-1 border"
                        >
                          {_t(t("Status"))}
                        </th>
                        <th
                          scope="col"
                          className="sm-text align-middle text-center border-1 border"
                        >
                          {_t(t("Price"))}
                        </th>
                        <th
                          scope="col"
                          className="sm-text align-middle text-center border-1 border"
                        >
                          {_t(t("Cost"))}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {variations.list && variations.list.length > 0 ? (
                        [
                          variations.list.map((item, index) => {
                            return (
                              <tr
                                scope="row"
                                className="xsm-text align-middle text-center"
                              >
                                <td className="xsm-text text-capitalize align-middle text-center">
                                  {index + 1}
                                </td>
                                <td className="xsm-text text-capitalize align-middle text-center">
                                  {item.variation_name}
                                </td>
                                <td className="xsm-text text-uppercase align-middle text-center font-weight-bold">
                                  {item.variation_status == 1
                                    ? <span className="text-success">{_t(t("On"))}</span>
                                    : <span className="text-red">{_t(t("Off"))}</span>
                                  }
                                </td>
                                <td className="xsm-text text-capitalize align-middle text-center">
                                  {currencySymbolLeft()}
                                  {formatPrice(item.food_with_variation_price)}
                                  {currencySymbolRight()}
                                </td>
                                <td className="xsm-text text-capitalize align-middle text-center">
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    item.food_with_variation_cost_price ? item.food_with_variation_cost_price : 0
                                  )}
                                  {currencySymbolRight()}
                                </td>
                              </tr>
                            );
                          }),
                        ]
                      ) : (
                        <tr className="align-middle">
                          <td
                            scope="row"
                            colSpan="6"
                            className="xsm-text align-middle text-center"
                          >
                            {_t(t("No data available"))}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                //variation edit is true here- show input box for price

                <div key="fragment2">
                  {/* check if edit is true and update button clicked or not */}
                  {variations.uploading === false ? (
                    //show form if Update button not clicked
                    <form
                      className="table-responsive"
                      onSubmit={handleUpdateVariations}
                      autoComplete="off"
                    >
                      <table className="table table-bordered-sm table-hover">
                        <thead className="align-middle">
                          <tr>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("S/L"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Variation name"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Status"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Price"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("New price"))}{" "}
                              <small className="text-primary">
                                ({_t(t("optional"))})
                              </small>
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Cost"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("New cost"))}{" "}
                              <small className="text-primary">
                                ({_t(t("optional"))})
                              </small>
                            </th>
                            <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                            >
                              {_t(t("Action"))}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* loop to show each variation item with input box of price */}
                          {variations.list &&
                            variations.list.map((item, index) => {
                              return (
                                <tr
                                  scope="row"
                                  className={`xsm-text align-middle text-center ${
                                    //text deleted css if food_with_variation id exist is array of variations to delete
                                    variations.deletedVariations &&
                                    variations.deletedVariations.includes(
                                      item.food_with_variation_id
                                    )
                                      ? "text-deleted text-primary"
                                      : ""
                                  }`}
                                >
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {index + 1}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {item.variation_name}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <input
                                      type="checkbox"
                                      name={item.food_with_variation_id + '-status'}
                                      onChange={handleStatus}
                                      checked={
                                        (
                                          variations.newStatus &&
                                          (variations.newStatus[item.food_with_variation_id] || variations.newStatus[item.food_with_variation_id] == 0
                                        ))
                                        ? variations.newStatus[item.food_with_variation_id]
                                        : (
                                          item.variation_status
                                          ? parseInt(item.variation_status): 0
                                        )}
                                      className="form-check form-check-input xsm-text text-center mx-auto"
                                      disabled={
                                        //disable input field of variation if item is selected to delete
                                        variations.deletedVariations &&
                                        variations.deletedVariations.includes(
                                          item.food_with_variation_id
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      item.food_with_variation_price
                                    )}
                                    {currencySymbolRight()}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <input
                                      type="number"
                                      step="0.01"
                                      min="0"
                                      name={item.food_with_variation_id}
                                      onChange={(e) => {
                                        setVariations({
                                          //set new price for each variation
                                          ...variations,
                                          newPrice: {
                                            ...variations.newPrice,
                                            [e.target.name]: e.target.value,
                                          },
                                        });
                                      }}
                                      defaultValue={item.food_with_variation_price}
                                      className="form-control xsm-text text-center variation-min-price-input-width"
                                      placeholder="Type new price in us dollar"
                                      disabled={
                                        //disable input field of variation if item is selected to delete
                                        variations.deletedVariations &&
                                        variations.deletedVariations.includes(
                                          item.food_with_variation_id
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      item.food_with_variation_cost_price ? item.food_with_variation_cost_price : 0
                                    )}
                                    {currencySymbolRight()}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <input
                                      type="number"
                                      step="0.01"
                                      min="0"
                                      name={item.food_with_variation_id + '-cost'}
                                      onChange={(e) => {
                                        setVariations({
                                          //set new price for each variation
                                          ...variations,
                                          newCost: {
                                            ...variations.newCost,
                                            [e.target.name.replace(/(-cost)/g, '')]: e.target.value,
                                          },
                                        });
                                      }}
                                      defaultValue={item.food_with_variation_cost_price ? item.food_with_variation_cost_price : 0}
                                      className="form-control xsm-text text-center variation-min-price-input-width"
                                      placeholder="Type new cost in us dollar"
                                      disabled={
                                        //disable input field of variation if item is selected to delete
                                        variations.deletedVariations &&
                                        variations.deletedVariations.includes(
                                          item.food_with_variation_id
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {/* Delete button or Undo button to display, show undo button if item is in array of variation to delete-->variations.deletedVariations */}
                                    {variations.deletedVariations &&
                                    variations.deletedVariations.includes(
                                      item.food_with_variation_id
                                    ) ? (
                                      //Undo button
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm py-0 rounded"
                                        onClick={() => {
                                          //Remove variation item from-->(deleted variation array which will be sent to server to delete)
                                          let deletedArray = [];
                                          if (variations.deletedVariations) {
                                            //Pushing all old items to an empty array
                                            variations.deletedVariations.map(
                                              (deletedItem) => {
                                                deletedArray.push(deletedItem);
                                              }
                                            );
                                          }
                                          //removing the item from new array
                                          let tempDeletedArray =
                                            deletedArray.filter((undoItem) => {
                                              return (
                                                undoItem !==
                                                item.food_with_variation_id
                                              );
                                            });
                                          //set new array as array of variation items to delete-->(which will be sent to server to delete)
                                          setVariations({
                                            ...variations,
                                            deletedVariations: tempDeletedArray,
                                          });
                                        }}
                                      >
                                        {_t(t("Undo"))}
                                        {/* Undo button */}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm py-0 rounded"
                                        onClick={() => {
                                          //add variation item to-->(deleted variation array which will be sent to server to delete)
                                          let deletedArray = [];
                                          if (variations.deletedVariations) {
                                            //Pushing all old items to an empty array
                                            variations.deletedVariations.map(
                                              (deletedItem) => {
                                                deletedArray.push(deletedItem);
                                              }
                                            );
                                          }
                                          //adding the item to new array
                                          deletedArray.push(
                                            item.food_with_variation_id
                                          );

                                          //set new array as array of variation items to delete-->(which will be sent to server to delete)
                                          setVariations({
                                            ...variations,
                                            deletedVariations: deletedArray,
                                          });
                                        }}
                                      >
                                        {_t(t("Delete"))}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>

                      {/* input field for price if all variations has been deleted */}
                      {variations.list &&
                        variations.deletedVariations && [
                          variations.deletedVariations.length ===
                          variations.list.length ? (
                            //Check array.length to delete from food_with variations table === total variation of the items
                            <div className="mt-4">
                              <label htmlFor="price" className="form-label">
                                {_t(t("Price of the food item"))}
                                <small className="text-primary">*</small>
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                min="0"
                                className="form-control"
                                id="price"
                                name="price"
                                placeholder="Type price for this item in 'US dollar'"
                                value={
                                  variations.priceAfterAllVariationDelete || ""
                                }
                                required
                                onChange={(e) => {
                                  //set price of all variation selected to delete
                                  setVariations({
                                    ...variations,
                                    priceAfterAllVariationDelete:
                                      e.target.value,
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          ),
                        ]}
                      <button
                        type="submit"
                        className="btn btn-success sm-text px-4 mt-3 mb-2 position-absolute top-0 rounded"
                        style={{right: '8rem'}}
                      >
                        {_t(t("Update"))}
                      </button>
                    </form>
                  ) : (
                    //show loading if update has been clicked
                    <div>
                      <div className="text-center text-primary font-weight-bold text-uppercase">
                        {_t(t("Please wait"))}
                      </div>
                      {modalLoading(5)}
                    </div>
                  )}
                </div>
              )}
              {/* show table data / form / show modal loading ends here*/}
            </div>
          </div>
        </div>
      </div>
      {/*update variations modal Ends*/}

      {/* Add variation modal */}
      <div className="modal fade" id="addVariations" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                  {_t(t("Add variations for"))}{" "}
                  <span className="text-capitalize">
                    {newVariation.item && newVariation.item.name}
                  </span>
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {variations.uploading === false ? (
                <div key="fragment-food-group-1">
                  <form onSubmit={handleSaveNewVariations} className="mx-2" autoComplete="off">
                    {variationForSearch && (
                      <div className="form-group">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Add variations"))}
                          </label>
                        </div>
                        <Select
                          options={variationForSearch}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                          maxMenuHeight="200px"
                          onChange={handleSetVariations}
                          placeholder={_t(t("Please select variations")) + ".."}
                        />
                      </div>
                    )}
                    {newVariation.variations !== null && [
                      newVariation.variations.length > 0 && (
                        <div className="card mt-3 p-3 custom-bg-secondary">
                          <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                            {_t(t("Please enter price for each variation"))}
                          </div>
                          {newVariation.variations.map((variationItem) => {
                            return (
                              <div
                                className="form-group d-flex mt-4"
                                key={variationItem.id}
                              >
                                <div className="col-6">
                                  <div className="mb-2">
                                    <label
                                      htmlFor={variationItem.slug}
                                      className="control-label sm-text"
                                    >
                                      {_t(t("Total price of"))}{" "}
                                      <span className="text-primary text-bold">
                                        {variationItem.name}
                                      </span>{" "}
                                      {_t(t("variation"))}
                                      <span className="text-primary">* </span>
                                      {/* <small className="text-secondary">
                                        ({_t(t("Enter price in USD"))})
                                      </small> */}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="number"
                                      step="0.01"
                                      min="0"
                                      className="form-control"
                                      id={variationItem.slug}
                                      name={variationItem.slug}
                                      onChange={(e) => handleVariationPrice(e, 0)}
                                      placeholder="e.g. Type price of this item"
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="mb-2">
                                    <label
                                      htmlFor={variationItem.slug}
                                      className="control-label sm-text"
                                    >
                                      {_t(t("Cost price of"))}{" "}
                                      <span className="text-primary text-bold">
                                        {variationItem.name}
                                      </span>{" "}
                                      {_t(t("variation"))}
                                      <span className="text-primary">* </span>
                                      {/* <small className="text-secondary">
                                        ({_t(t("Enter price in USD"))})
                                      </small> */}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="number"
                                      step="0.01"
                                      min="0"
                                      className="form-control"
                                      id={variationItem.slug}
                                      name={variationItem.slug}
                                      onChange={(e) => handleVariationPrice(e, 1)}
                                      placeholder="e.g. Type cost price of this item"
                                      required
                                    />
                                  </div>
                                </div>
                                
                              </div>
                            );
                          })}
                        </div>
                      ),
                    ]}
                    <button
                      type="submit"
                      className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                    >
                      {_t(t("Save"))}
                    </button>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(5)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add variation modal Ends*/}
    
     {/* Add variation modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {variations.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb d-flex justify-content-between align-items-center">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedFoodItem.searched
                                    ? _t(t("Food Items List"))
                                    : _t(t("Search Result"))}
                                </span>
                                <span title="Item Sort By Groups" >
                                <TiArrowSortedDown  className="dashboard_card_down cursor-pointer ml-3" onClick={()=>setSortGroup(!sortGroup)}/>
                                </span>
                                {
                                  sortGroup === true && (
                                  <div className="dropdownSelect w-25 ml-3 mr-1 p-0">
                                    <h6 className="ml-2 px-2 mt-2">Food Groups</h6>
                                    <div className={`custom_scrollBar ${foodGroupForSearch?.length > 10 && "custom_scrollHeight"}`} >
                                      {foodGroupForSearch?.map((groupItem, groupIndex)=>{
                                      return (
                                        <span className="m-0 p-0" key={groupIndex}  onClick={() => handleFoodSortList(groupItem)}>
                                         <li className="list-unstyled dashboard_border p-1 mb-1 cursor-pointer">{groupItem.name}</li>
                                        </span>
                                      )
                                      })}
                                    </div>
                                  </div>
                                  )
                                }
                              </li>
                              <div>
                                <button title="Download food items in excel" className="btn btn-success btn-sm text-uppercase sm-text mr-2 rounded" onClick={()=>handleFoodCsv()} >Export Csv <i className="fa fa-download"></i></button>
                                <button title="Upload food items in excel" className="btn btn-primary btn-sm text-uppercase sm-text rounded" onClick={()=>setUploadCsvModal(true)}>Import Csv <i className="fa fa-upload"></i></button>
                              </div>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-1 align-items-center">
                              {/*item desc default image */}
                              <div className="col-md-4 d-flex justify-content-center">
                                <button title="Remove item description" className="btn btn-success btn-sm text-uppercase sm-text mr-2 rounded" onClick={()=>handleItemConfirmation("You want to remove item description?","desc")} >Remove Desc</button>
                                <button title="Add default item image" className="btn btn-primary btn-sm text-uppercase sm-text rounded" onClick={()=>handleItemConfirmation("You want to add default item image?","image")}>Default Img</button>
                              </div>
                              {/* Search group */}
                              <div className="col-md-5 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={
                                        _t(t("Search by name")) + ".."
                                      }
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search "
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/food/add-new"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  style={{minWidth: '100px'}}
                                >
                                  {_t(t("Item Code"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  style={{minWidth: '100px'}}
                                >
                                  {_t(t("Image"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Menu"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Group"))}
                                </th>

                                {
                                  menuWisePrice.current === 0 && (
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Price"))}
                                    </th>
                                  )
                                }

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  style={{minWidth: '80px'}}
                                >
                                  {_t(t("Status"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedFoodItem.searched
                                ? [
                                    foodList && [
                                      foodList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        foodList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={item.id}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (foodList.current_page - 1) *
                                                    foodList.per_page}
                                              </th>

                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {item.id}
                                              </td>

                                              <td className="xsm-text">
                                                <img className="table-image mx-auto" src={ item.image || "/assets/img/waiter.jpg" } alt=""/>
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {console.log('ar-log',item)}
                                                {item.menu_ids ?
                                                JSON.parse(item.menu_ids).map((menuId, i, menus) => (
                                                  <>
                                                    {deptTagForSearchObj[menuId] ? 
                                                    <span>
                                                      {deptTagForSearchObj[menuId].name}
                                                      {i!==menus.length-1 ? ', ': ''}
                                                    </span>
                                                    : ''}
                                                  </>
                                                ))
                                                : '-'}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* {item.food_group_name} */}
                                                { item.groups && item.groups.length ?
                                                    item.groups.map((group_item, i) => (
                                                      group_item ? 
                                                        <span>
                                                        {group_item[0]?.name}
                                                        { i!== item.groups.length-1 ? ', ' : ''}
                                                        </span>
                                                        : ""
                                                    ))
                                                  : "-" 
                                                }
                                              </td>

                                              {
                                                menuWisePrice.current === 0 && (
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.price ? (
                                                    [
                                                      `${currencySymbolLeft()}${formatPrice(
                                                        item.price
                                                      )}${currencySymbolRight()}`,
                                                    ]
                                                  ) : (
                                                    <button
                                                      className="btn btn-primary btn-sm py-0 rounded"
                                                      onClick={() =>
                                                        // set variations on button click
                                                        setVariations({
                                                          ...variations,
                                                          edit: false,
                                                          item: item,
                                                          list: item.variations,
                                                          deletedVariations: null,
                                                          newPrice: null,
                                                          newCost: null,
                                                          newStatus: null,
                                                          uploading: false,
                                                          priceAfterAllVariationDelete:
                                                            "",
                                                        })
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#foodVariations"
                                                    >
                                                      {_t(t("check variations"))}
                                                    </button>
                                                  )}
                                                </td>
                                                )
                                              }

                                              <td className="xsm-text text-uppercase align-middle text-center font-weight-bold">
                                                <Switch
                                                  checked={item?.item_status}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                    
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                />
                                                {/*item.item_status==1
                                                  ? <span className="text-success">{_t(t("On"))}</span>
                                                  : <span className="text-red">{_t(t("Off"))}</span>
                                                  */}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {
                                                  menuWisePrice.current ? (
                                                    <>
                                                    <NavLink
                                                    to={`/dashboard/manage/food/edit-item/${item.id}`}
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    title="Edit"
                                                    >
                                                    <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                             
                                                    </NavLink>
                                                    {
                                                      /*
                                                      <button
                                                      className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                      onClick={()=>  handleDeleteConfirmation(
                                                        item.slug
                                                      )}
                                                      >
                                                      <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                     </button>
                                                      */
                                                    }
                                                      <button
                                                      className="btn btn-success btn-sm ml-2 rounded-sm"
                                                      onClick={() => {
                                                        handleEditDeal(item)
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#createDeal"
                                                      title="Create/Edit Deal"
                                                      >
                                                         <img style={{height:"1.2rem"}} src="/assets/img/fast-food.png" />
                                                      
                                                      </button>

                                                      
                                                      {(item.has_property === '1' && item.property_groups.length) ?
                                                      <button
                                                        className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                        onClick={() => {
                                                          history.push(
                                                            '/dashboard/manage/food/addon-restriction',
                                                            {item}
                                                          )
                                                        }}
                                                        title="Addon restriction"
                                                      >
                                                        <img style={{height:"1.2rem"}} src="/assets/img/forbidden.png" />
                                                      </button>
                                                      : null
                                                    }
                                                    </>
                                                  ) : (
                                                    <div className="dropdown">
                                                      <button
                                                        className="btn t-bg-clear t-text-dark--light-40"
                                                        type="button"
                                                        data-toggle="dropdown"
                                                      >
                                                        <i className="fa fa-ellipsis-h"></i>
                                                      </button>
                                                      <div className="dropdown-menu">
                                                        <button
                                                          className="dropdown-item sm-text"
                                                          onClick={() => {
                                                            // hadle previously set form data
                                                            setPriceForVariations(
                                                              null
                                                            );
                                                            setNewVariation({
                                                              ...newVariation,
                                                              item: item,
                                                              variations: null,
                                                            });
                                                          }}
                                                          data-toggle="modal"
                                                          data-target="#addVariations"
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-plus"></i>
                                                          </span>
                                                          {_t(t("Add variation"))}
                                                        </button>
    
                                                        {(item.has_property === '1' && item.property_groups.length) ?
                                                          <button
                                                            className="dropdown-item sm-text"
                                                            onClick={() => {
                                                              history.push(
                                                                '/dashboard/manage/food/addon-restriction',
                                                                {item}
                                                              )
                                                            }}
                                                          >
                                                            <span className="t-mr-8">
                                                              <i className="fa fa-plus"></i>
                                                            </span>
                                                            {_t(t("Add properties"))}
                                                          </button>
                                                          : null
                                                        }
    
                                                        <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleEditDeal(item)
                                                        }}
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-plus"></i>
                                                          </span>
                                                          {_t(t("Create/Edit Deal"))}
                                                        </button>
    
                                                        <button
                                                          className="dropdown-item sm-text text-capitalize"
                                                          onClick={() => {
                                                            handlesetStatesOnEdit(item)
                                                          }}
                                                          data-toggle="modal"
                                                          data-target="#editFood"
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-pencil"></i>
                                                          </span>
                                                          {_t(t("Edit / View"))}
                                                        </button>
    
                                                        <NavLink
                                                        to={`/dashboard/manage/food/edit-item/${item.id}`}
                                                        className="dropdown-item sm-text text-capitalize"
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-pencil"></i>
                                                          </span>
                                                          {_t(t("View / Edit All"))}
                                                        </NavLink>
    
                                                        <button
                                                          className="dropdown-item sm-text"
                                                          onClick={() => {
                                                            //handle image upload
                                                            setVariations({
                                                              ...variations,
                                                              uploading: true,
                                                            });
                                                            setFoodItemEdit({
                                                              ...foodItemEdit,
                                                              editItem: item,
                                                              item,
                                                              imageUpdate: true,
                                                              newImage: null,
                                                            });
    
                                                            setTimeout(() => {
                                                              setVariations({
                                                                ...variations,
                                                                uploading: false,
                                                              });
                                                            }, 500);
                                                          }}
                                                          data-toggle="modal"
                                                          data-target="#editFood"
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-file"></i>
                                                          </span>
                                                          {_t(t("Image"))}
                                                        </button>
                                                        {
                                                          /*
                                                          <button
                                                            className="dropdown-item sm-text text-capitalize"
                                                            onClick={() => {
                                                              handleDeleteConfirmation(
                                                                item.slug
                                                              );
                                                            }}
                                                          >
                                                            <span className="t-mr-8">
                                                              <i className="fa fa-trash"></i>
                                                            </span>
                                                            {_t(t("Delete"))}
                                                          </button>
                                                          */
                                                        }
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedFoodItem && [
                                      searchedFoodItem.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedFoodItem.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={item.id}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (foodList.current_page -
                                                      1) *
                                                      foodList.per_page}
                                                </th>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.id}
                                                </td>
                                                <td className="xsm-text">
                                                  <img className="table-image mx-auto" src={ item.image || "/assets/img/waiter.jpg" } alt=""/>
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {console.log('ar-log',item)}
                                                  {item.menu_ids ?
                                                  JSON.parse(item.menu_ids).map((menuId, i, menus) => (
                                                    <>
                                                      {deptTagForSearchObj[menuId] ? 
                                                      <span>
                                                        {deptTagForSearchObj[menuId].name}
                                                        {i!==menus.length-1 ? ', ': ''}
                                                      </span>
                                                      : ''}
                                                    </>
                                                  ))
                                                  : '-'}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* {item.food_group_name} */}
                                                { item.groups && item.groups.length ?
                                                    item.groups.map((group_item, i) => (
                                                      group_item ? 
                                                        <span>
                                                        {group_item[0]?.name}
                                                        { i!== item.groups.length-1 ? ', ' : ''}
                                                        </span>
                                                        : ""
                                                    ))
                                                  : "-" 
                                                }
                                              </td>

                                              {
                                                menuWisePrice.current === 0 && (
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.price ? (
                                                    [
                                                      `${currencySymbolLeft()}${formatPrice(
                                                        item.price
                                                      )}${currencySymbolRight()}`,
                                                    ]
                                                  ) : (
                                                    <button
                                                      className="btn btn-primary btn-sm py-0 rounded"
                                                      onClick={() =>
                                                        // set variations on button click
                                                        setVariations({
                                                          ...variations,
                                                          edit: false,
                                                          item: item,
                                                          list: item.variations,
                                                          deletedVariations:
                                                            null,
                                                          newPrice: null,
                                                          newCost: null,
                                                          newStatus: null,
                                                          uploading: false,
                                                          priceAfterAllVariationDelete:
                                                            "",
                                                        })
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#foodVariations"
                                                    >
                                                      {_t(
                                                        t("check variations")
                                                      )}
                                                    </button>
                                                  )}
                                                </td>
                                                )
                                              }

                                                <td className="xsm-text text-uppercase align-middle text-center font-weight-bold">
                                                  <Switch
                                                   checked={item?.item_status}
                                                   name="status"
                                                   onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                    
                                                    }}
                                                    height={22}
                                                    width={44}
                                                    offColor="#ee5253"
                                                  />
                                                  {/*item.item_status==1
                                                    ? <span className="text-success">{_t(t("On"))}</span>
                                                    : <span className="text-red">{_t(t("Off"))}</span>
                                                      */}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                {
                                                  menuWisePrice.current ? (
                                                    <>
                                                    <NavLink
                                                    to={`/dashboard/manage/food/edit-item/${item.id}`}
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    title="Edit"
                                                    >
                                                    <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                             
                                                    </NavLink>
                                                    {
                                                      /*
                                                      <button
                                                        className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                        onClick={()=>  handleDeleteConfirmation(
                                                          item.slug
                                                        )}
                                                        >
                                                        <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                       </button>
                                                      */
                                                    }
                                                      <button
                                                      className="btn btn-success btn-sm ml-2 rounded-sm"
                                                      onClick={() => {
                                                        handleEditDeal(item)
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#createDeal"
                                                      title="Create/Edit Deal"
                                                      >
                                                        <img style={{height:"1.2rem"}} src="/assets/img/fast-food.png" />
                                                        {/*<i className="fa fa-plus" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>*/}
                                                      </button>

                                                      {(item.has_property === '1' && item.property_groups.length) ?
                                                      <button
                                                        className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                        onClick={() => {
                                                          history.push(
                                                            '/dashboard/manage/food/addon-restriction',
                                                            {item}
                                                          )
                                                        }}
                                                        title="Addon restriction"
                                                      >
                                                        <img style={{height:"1.2rem"}} src="/assets/img/forbidden.png" />
                                                      </button>
                                                      : null
                                                     }
                                                    </>
                                                  ) : (
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text"
                                                        onClick={() => {
                                                          // hadle previously set form data
                                                          setPriceForVariations(
                                                            null
                                                          );
                                                          setNewVariation({
                                                            ...newVariation,
                                                            item: item,
                                                            variations: null,
                                                          });
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addVariations"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-plus"></i>
                                                        </span>
                                                        {_t(t("Add variation"))}
                                                      </button>

                                                      {(item.has_property === '1' && item.property_groups.length) ?
                                                        <button
                                                          className="dropdown-item sm-text"
                                                          onClick={() => {
                                                            history.push(
                                                              '/dashboard/manage/food/addon-restriction',
                                                              {item}
                                                            )
                                                          }}
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-plus"></i>
                                                          </span>
                                                          {_t(t("Add properties"))}
                                                        </button>
                                                        : null
                                                      }

                                                      <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleEditDeal(item)
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#createDeal"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-plus"></i>
                                                        </span>
                                                        {_t(t("Create/Edit Deal"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handlesetStatesOnEdit(item)
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#editFood"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit / View"))}
                                                      </button>

                                                      <NavLink
                                                      to={`/dashboard/manage/food/edit-item/${item.id}`}
                                                      className="dropdown-item sm-text text-capitalize"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("View / Edit All"))}
                                                      </NavLink>

                                                      <button
                                                        className="dropdown-item sm-text"
                                                        onClick={() => {
                                                          //handle image upload
                                                          setVariations({
                                                            ...variations,
                                                            uploading: true,
                                                          });
                                                          setFoodItemEdit({
                                                            ...foodItemEdit,
                                                            editItem: item,
                                                            item,
                                                            imageUpdate: true,
                                                            newImage: null,
                                                          });

                                                          setTimeout(() => {
                                                            setVariations({
                                                              ...variations,
                                                              uploading: false,
                                                            });
                                                          }, 500);
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#editFood"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-file"></i>
                                                        </span>
                                                        {_t(t("Image"))}
                                                      </button>
                                                       {
                                                        /*
                                                        <button
                                                          className="dropdown-item sm-text text-capitalize"
                                                          onClick={() => {
                                                            handleDeleteConfirmation(
                                                              item.slug
                                                            );
                                                          }}
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-trash"></i>
                                                          </span>
                                                          {_t(t("Delete"))}
                                                        </button>
                                                        */
                                                       }
                                                    </div>
                                                  </div>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {variations.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedFoodItem.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(foodList, setPaginatedFood)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(foodList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedFoodItem({
                                      ...searchedFoodItem,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedFoodItem,
                                    foodForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
        <div
        className={`modal fade ${
          foodSort ? "showing" : ""
        }`}
        id="foodItem"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md mt-0 foodSortModal">
          <div className="modal-content">
            <div className="modal-header align-items-center py-1">
              <div className="fk-sm-card__content">
               <h5>{groupName?.current && groupName.current}</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={()=>setFoodSort(false)}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
             <form onSubmit={handleUpdateFoodSort} autoComplete="off">
              <div key="fragment-food-group-1">
              {/* show form or show saving loading */}
              <div className="foodSort" >
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="listItem" trpe="group">
                {(provided,snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} >
                    {listItem.map((item, index) => {
                      return (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                          {(provided,snapshot) => (
                            <div  style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                           )} className="food_sort_name" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div >
                                {item.name}
                              </div>
                            </div>
                            )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
                </Droppable>
              </DragDropContext>
              </div>
              <div className="m-1 d-flex justify-content-end mr-2 px-1">
              <button disabled={loading} type="submit" className="btn btn-sm rounded btn-primary">Submit</button>
              </div>
                 {/*
                   listItem && listItem.map((item,index)=>{
                     return(
                       item.current_id !== item.item_id &&(
                      <div
                       key={index}
                       className="cursor-pointer border-1 border rounded mb-1"
                       >
                        <input
                          type="radio"
                          className="form-check-input pointer-cursor"
                          onChange={(e) => {
                            handleGroupSort(e,item);
                          }}
                          checked={
                            sortedList && sortedList?.id === item.id ? 1 : 0
                          }
                        />
                        {item.name}
                      </div>
                      )
                     )
                   })
                  */}
              </div>
             </form>
            </div>
          </div>
        </div>
      </div>    
      </main>
      <FoodDeal
        createDeal = {createDeal}
        setCreateDeal = {setCreateDeal}
        setSearchedFoodItem = {setSearchedFoodItem}
        searchedFoodItem = {searchedFoodItem}
        loading={loading}
        setLoading = {setLoading}
      />
      {/* main body ends */}
    </>
  );
};

export default AllItemList;
