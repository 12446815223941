import React, {useContext} from 'react';
import {
    _t,
    getSystemSettings,
    modalLoading,
    currencySymbolLeft,
    currencySymbolRight,
  } from "../../../../../functions/Functions";
import { useCustomHooks } from '../../../../../functions/Hooks';  
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { RestaurantContext } from '../../../../../contexts/Restaurant';

const OrderDetailsView = ({
    checkOrderDetails,
    returnMoneyUsd,
    paidMoney,
    handleSettleOrder,
    handleSetpaymentType,
    handlePaymentTypeAmount,
    setCheckOrderDetails,
    setReturnMoneyUsd,
    setPaidMoney,
    setTotalAmount,
    redeemPoints = null,
}) => {
    const { t } = useTranslation();
    const { roundFormatPrice } = useCustomHooks();
    const { loading, generalSettings} = useContext(SettingsContext);
    const {
        //payment-type
        paymentTypeForSearch,
      } = useContext(RestaurantContext);

  return (
    <>
        {/* Settle modal */}
        <div className="modal fade" id="orderDetails" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                  <h5 className="text-capitalize fk-sm-card__title">
                    {/* show order token on modal header */}
                    {_t(t("Order details, Token"))}: #
                    {checkOrderDetails.item && checkOrderDetails.item.token?.id}
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* if loading true show loading effect */}
              {loading ? (
                <div className="modal-body">{modalLoading(5)}</div>
              ) : (
                <div className="modal-body">
                  {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                    // if this item is not settled then show settle-cancel button
                    <>
                      {checkOrderDetails.item &&
                        parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                          <div className="text-right">
                            {checkOrderDetails.settle &&
                              paidMoney >
                                parseFloat(
                                  checkOrderDetails.item.total_payable
                                ) && (
                                <span className="mr-2 text-secondary font-weight-bold">
                                  Return: {currencySymbolLeft()}
                                  {roundFormatPrice(returnMoneyUsd)}
                                  {currencySymbolRight()}{" "}
                                </span>
                              )}
                            {checkOrderDetails.settle ? (
                              <button
                                className="btn btn-primary px-3 rounded-md text-uppercase"
                                onClick={() => {
                                  setCheckOrderDetails({
                                    ...checkOrderDetails,
                                    settle: false,
                                    payment_amount: null,
                                    payment_type: null,
                                  });
                                  setReturnMoneyUsd(0);
                                  setPaidMoney(0);
                                  setTotalAmount(parseFloat(checkOrderDetails.item.total_payable));
                                }}
                              >
                                {_t(t("Cancel"))}
                              </button>
                            ) : (
                              <>
                                {getSystemSettings(
                                  generalSettings,
                                  "pos_screen"
                                ) === "0" ? (
                                  <button
                                    className="btn btn-success px-3 rounded-md text-uppercase"
                                    onClick={() => {
                                      setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        settle: true,
                                        payment_amount: null,
                                        payment_type: null,
                                      });
                                      setReturnMoneyUsd(0);
                                      setPaidMoney(0);
                                    }}
                                  >
                                    {_t(t("Settle order"))}
                                  </button>
                                ) : (
                                  <
                                    // className="btn btn-danger px-3 rounded-md text-uppercase"
                                    // data-dismiss="modal"
                                    // aria-label="Close"
                                    // onClick={() => {
                                    //   setCheckOrderDetails({
                                    //     ...checkOrderDetails,
                                    //     settle: false,
                                    //     payment_amount: null,
                                    //     payment_type: null,
                                    //   });
                                    //   setReturnMoneyUsd(0);
                                    //   setPaidMoney(0);
                                    //   setShowSettle(true);
                                    // }}
                                  >
                                    {/* {_t(t("Settle order"))} */}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                    </>
                  ) : (
                    // if this item is not settled then show settle-cancel button else, show this notification
                    <div className="text-center bg-success text-white py-2">
                      {_t(t("Order has been settled, you can close this now"))}
                    </div>
                  )}
                  {checkOrderDetails.item &&
                    //show this if order is cancelled
                    parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                      <div className="text-center bg-secondary text-white py-2">
                        {_t(t("This order has been cancelled"))}
                      </div>
                    )}
                  {/* show this if order settle is not true, if true show payment input field */}
                  {!checkOrderDetails.settle ? (
                    <div className="col-12 filtr-item">
                      <div className="fk-order-token t-bg-white">
                        <div className="fk-order-token__body">
                          <div className="fk-addons-table">
                          <div className="d-flex justify-content-between px-3 fk-addons-table__head">
                            <div className="mr-3">
                              {_t(t("order token"))}: #
                              {checkOrderDetails.item &&
                                checkOrderDetails.item.token?.id}
                              </div>
                              <div>
                              {
                              `${checkOrderDetails?.item && checkOrderDetails?.item?.order_type_name} - ${checkOrderDetails?.item && checkOrderDetails?.item?.party_name}`
                                }
                              </div>
                            </div>
                            <div className="fk-addons-table__info">
                              <div className="row g-0">
                                <div className="col-2 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("S/L"))}
                                  </span>
                                </div>
                                <div className="col-3 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("food"))}
                                  </span>
                                </div>
                                <div className="col-4 text-left pl-2 border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("Additional Info"))}
                                  </span>
                                </div>
                                <div className="col-2 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("QTY"))}
                                  </span>
                                </div>
                                <div className="col-1 text-center">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("Status"))}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {checkOrderDetails.item &&
                              checkOrderDetails.item.orderedItems.map(
                                (thisItem, indexThisItem) => {
                                  return (
                                    <div className="fk-addons-table__body-row">
                                      <div className="row g-0">
                                        <div className="col-2 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {indexThisItem + 1}
                                          </span>
                                        </div>
                                        <div className="col-3 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {thisItem.food_item} (
                                            {thisItem.food_group})
                                          </span>
                                        </div>
                                        <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                          {thisItem.variation !== null && (
                                            <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                              <span className="font-weight-bold mr-1">
                                                {_t(t("variation"))}:
                                              </span>
                                              {thisItem.variation}
                                            </span>
                                          )}

                                          {thisItem.properties !== null && (
                                            <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                              <span className="font-weight-bold mr-1">
                                                {_t(t("properties"))}:
                                              </span>
                                              {JSON.parse(
                                                thisItem.properties
                                              ).map(
                                                (propertyItem, thisIndex) => {
                                                  if (
                                                    thisIndex !==
                                                    JSON.parse(
                                                      thisItem.properties
                                                    ).length -
                                                      1
                                                  ) {
                                                    return (
                                                      propertyItem.property +
                                                      `${
                                                        propertyItem.quantity >
                                                        1
                                                          ? "(" +
                                                            propertyItem.quantity +
                                                            ")"
                                                          : ""
                                                      }` +
                                                      ", "
                                                    );
                                                  } else {
                                                    return (
                                                      propertyItem.property +
                                                      `${
                                                        propertyItem.quantity >
                                                        1
                                                          ? "(" +
                                                            propertyItem.quantity +
                                                            ")"
                                                          : ""
                                                      }`
                                                    );
                                                  }
                                                }
                                              )}
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-2 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {thisItem.quantity}
                                          </span>
                                        </div>

                                        <div className="col-1 text-center d-flex">
                                          <label className="mx-checkbox mx-checkbox--empty m-auto">
                                            <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                              {parseInt(thisItem.is_cooking) ===
                                              1 ? (
                                                [
                                                  parseInt(
                                                    thisItem.is_ready
                                                  ) === 1 ? (
                                                    <i
                                                      className="fa fa-check text-success"
                                                      title={_t(t("Ready"))}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      className="fa fa-cutlery text-secondary"
                                                      title={_t(t("Cooking"))}
                                                    ></i>
                                                  ),
                                                ]
                                              ) : (
                                                <i
                                                  className="fa fa-times text-primary"
                                                  title={_t(t("Pending"))}
                                                ></i>
                                              )}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    handleSettleOrder && (
                        <div className="my-2 payment-type-parent">
                        <Select
                            options={paymentTypeForSearch && paymentTypeForSearch}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            classNamePrefix="select"
                            className="xsm-text"
                            backspaceRemovesValue={false}
                            onChange={handleSetpaymentType}
                            maxMenuHeight="200px"
                            isMulti
                            clearIndicator={null}
                            placeholder={_t(t("Select payment methods")) + ".."}
                        />
                        {checkOrderDetails.payment_type !== null && (
                            <form
                            className="border my-2 change-background rounded-lg"
                            onSubmit={handleSettleOrder}
                            autoComplete="off"
                            >
                            <div className="sm-text text-center text-white py-2">
                                {_t(t("Amount"))}
                            </div>
                            {checkOrderDetails.payment_type.map(
                                (eachPaymentType, paymentTypeIndex) => {
                                return (
                                    <div className="addons-list__item mx-2 mb-1">
                                    <input
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        name={eachPaymentType.id}
                                        autoComplete="off"
                                        className="form-control xsm-text pl-2"
                                        onChange={handlePaymentTypeAmount}
                                        placeholder={eachPaymentType.name}
                                        value={
                                        checkOrderDetails.payment_amount &&
                                        checkOrderDetails.payment_amount[
                                            eachPaymentType.id
                                        ]
                                        }
                                    />
                                    </div>
                                );
                                }
                            )}
                            <div className="pb-2 pl-2 my-2">
                                <button
                                className="btn btn-sm btn-warning text-dark px-3 text-uppercase"
                                type="submit"
                                >
                                {_t(t("Settle order"))}
                                </button>
                            </div>
                            </form>
                        )}
                        </div>
                    )
                  )}
                  <table className="table table-striped table-sm text-center mt-3">
                    <thead className="bg-info text-white text-uppercase">
                      <tr>
                        <th scope="col" colSpan="2">
                          {_t(t("Order details"))}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Received by"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.user_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Customer"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.customer_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Contact"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.delivery_phn_no}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Alt Phone No"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item?.alt_phn_no}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Delivery Address"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.delivery_address}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Nearest Landmark"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item?.nearest_landmark || "-"}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Note to rider"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.note_to_rider || "-"}
                        </td>
                      </tr>
                    
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Ref No"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item?.ref_no || "-"}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Branch"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.branch_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Department"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.dept_tag_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Table"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.table_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Waiter"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.waiter_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Punch Time"))}
                        </td>
                        <td>
                          <Moment format="LLL">
                            {checkOrderDetails.item && checkOrderDetails.item.created_at}
                          </Moment>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Time To Deliver"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                           checkOrderDetails.item?.time_to_deliver !== null
                            ? checkOrderDetails.item.time_to_deliver + " min(s)"
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Subtotal"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {roundFormatPrice(checkOrderDetails.item.order_bill)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                      {checkOrderDetails.item &&
                      checkOrderDetails.item.vat_system === "igst" ? (
                        <tr>
                          <td className="text-capitalized">{checkOrderDetails.item.theBranch?.print_tax}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {roundFormatPrice(checkOrderDetails.item.vat)}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("CGST"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(
                                    parseFloat(checkOrderDetails.item.cgst)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("SGST"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(
                                    parseFloat(checkOrderDetails.item.sgst)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      {/* sdiscount */}
                      {getSystemSettings(generalSettings, "sDiscount") ===
                        "flat" && (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Service charge"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(
                                    checkOrderDetails.item.service_charge
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Discount"))}
                            </td>
                            <td>
                              {checkOrderDetails.item &&
                                (parseFloat(
                                  checkOrderDetails.item.discount_amount
                                ) > 0 ? (
                                  <>
                                    {currencySymbolLeft()}
                                    {roundFormatPrice(
                                      parseFloat(
                                        checkOrderDetails.item.discount_amount
                                      )
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                ) : (
                                  <>
                                    {currencySymbolLeft()}
                                    {roundFormatPrice(
                                      checkOrderDetails.item.discount
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                ))}
                            </td>
                          </tr>
                        </>
                      )}

                      {getSystemSettings(generalSettings, "sDiscount") ===
                        "percentage" && (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Service charge"))}
                              {checkOrderDetails.item &&
                                "(" +
                                  checkOrderDetails.item.service_charge +
                                  "%)"}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.service_charge /
                                        100)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Discount"))}
                              {checkOrderDetails.item &&
                                "(" + checkOrderDetails.item.discount + "%)"}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.discount / 100)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                      {/* sDiscount */}

                      {redeemPoints && Number(redeemPoints) > 0 ? (
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Redeem Points"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {roundFormatPrice(redeemPoints)}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                        ) : ""
                      }
                      
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Department Commission"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {roundFormatPrice(
                                checkOrderDetails.item.dept_commission
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Total bill"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {roundFormatPrice(
                                checkOrderDetails.item.total_payable
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Paid amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {roundFormatPrice(checkOrderDetails.item.paid_amount)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      {checkOrderDetails.item &&
                      parseFloat(
                        checkOrderDetails.item.total_payable -
                          checkOrderDetails.item.paid_amount
                      ) >= 0 ? (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Due amount"))}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {roundFormatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.total_payable -
                                      checkOrderDetails.item.paid_amount
                                  )
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Return amount"))}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {roundFormatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.paid_amount -
                                      checkOrderDetails.item.total_payable
                                  )
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Settle modal Ends*/}

    </>
  )
}

export default OrderDetailsView
