import React, { useContext, useState } from "react";


//functions
import {
  _t,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";

const ChartOfAccPrint = ({
 name,
 ChartOfAcc,
 chartofAccPrint
}) => {
    const { t } = useTranslation();

    const {
        //common
        generalSettings,
    } = useContext(SettingsContext);
      const landscapePrintStyles = {
        '@media print': {
          '@page': {
            size: 'landscape',
          },
        },
      };

  return (
    
    <>
      {/* print */}
      <div className="d-none">
        <div ref={ChartOfAcc} style={landscapePrintStyles}>
          {chartofAccPrint && Object.keys(chartofAccPrint)?.length > 0 && (
            <div className="fk-print m-4">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="mx-auto" style={{ width: "90px" }}>
                      <img
                        src={`${getSystemSettings(
                          generalSettings,
                          "type_logo"
                        )}`}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div className="my-2 xxslg-text print-text-stock text-center text-capitalize print-text-stock--bold">
                      {`${getSystemSettings(
                          generalSettings,
                          "siteName"
                        )}`}
                    </div>

                    <div className="mt-1 xxslg-text print-text-stock text-center text-capitalize print-text-stock--bold">
                      {name}
                    </div>
                    <div className="mt-5">
                      {
                        chartofAccPrint && Object.keys(chartofAccPrint)?.length > 0 && (
                          Object.keys(chartofAccPrint)?.map((chartofAcc,index)=>{
                            const result = chartofAcc.replace(/^\d+-\d+-/, "");
                            return (
                              <>
                                <div className="my-2 xxslg-text print-text-stock text-capitalize print-text-stock--bold">
                                  {`${index + 1}. ${result}`}
                                </div>
                                <div className="my-1 ml-5 xxslg-text print-text-stock text-capitalize print-text-stock--bold">
                                  <div className="border-1 border border-dark px-2">
                                    {chartofAcc}
                                  </div>
                                  {
                                    Object.keys(chartofAccPrint[chartofAcc]).map((chartofAccl2)=>{
                                      return (
                                          <div className="my-1 xslg-text print-text-stock text-capitalize print-text-stock--bold" style={{marginLeft:"3rem"}}>
                                            <div className="border-1 border border-dark px-2">
                                              {chartofAccl2}
                                            </div>
                                            {
                                              chartofAccPrint[chartofAcc][chartofAccl2].map((chartofAccl3)=>{
                                                  return (
                                                  <div className="my-1 lg-text print-text-stock text-capitalize" style={{marginLeft:"3rem"}}>
                                                    <div className="border-1 border border-dark px-2">
                                                      {chartofAccl3}
                                                    </div>
                                                  </div>
                                                  )
                                              })
                                            }
                                          </div>
                                      )
                                    })
                                  }
                                </div> 
                              </>
                            )
                          })
                        ) 
                      }
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ChartOfAccPrint
