import React, { useEffect, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import StockReport from "./StockReport";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

const StockIssuanceAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {id} = useParams()
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      branchListWeb
    } = useContext(RestaurantContext);

    let {
      authUserInfo
    } = useContext(UserContext);

    // States hook here
    //new stock issuance
    let [newStockIssuance, setNewStockIssuance] = useState({
      document_no: "",
      ref_identity:"",
      fbranch: null,
      tbranch: null, 
      remarks: "",
    });
    //new Row
    const [newRow,setNewRow]=useState([]);
    //datepicker
    const [documentDate, setDocumentDate] = useState(new Date());
  
    const [branchSearch, setBranchSearch] = useState([]);

    const [storeReqDoc, setStoreReqDoc] = useState([]);

    useEffect(()=>{
      if (authUserInfo?.details.user_type === "manager" || authUserInfo?.details.user_type === "staff") {
        setBranchSearch(
          branchForSearch.filter((branch) => branch.id === authUserInfo.details.branch_id)
        );
      } else {
        setBranchSearch(branchForSearch);
      }
    },[]);

    const handleSetEditView = (editIssuance) =>{
      let docDate=new Date(editIssuance.document_date);
  
      setNewStockIssuance({
        ...newStockIssuance,
        is_post: editIssuance.is_post,
        document_no:editIssuance.document_identity,
        fbranch: editIssuance.from_branch,
        tbranch: editIssuance.to_branch,
        ref_identity:{
          id: null,
          document_identity: editIssuance.ref_identity
        },
        remarks: editIssuance.remarks,
      })
      setDocumentDate(docDate);
      
      if(editIssuance.stock_issuance_detail && editIssuance.stock_issuance_detail.length > 0){
        let rowArr=[];
        editIssuance.stock_issuance_detail.map((item)=>{
          rowArr.push({
            item:{
              id: item.item_id,
              name: item.item_name,
            },
            unit:{
              id: item.unit_id,
              name: item.unit_name
            },
            qty: Number(item.qty),
            max_qty: Number(item.qty),
            stock_qty: item.stock_qty
          })
        })
        setNewRow([...rowArr]);
      }
    };

    //Get Specific item for edit
    const getStockIssuanceItem = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/edit-issuance/" + parseInt(id);
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          if(res){
            handleSetEditView(res.data)
          }
          setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    useEffect(()=>{
      if (id) {
        getStockIssuanceItem()
      }
    },[id]);

    // store req doc no
    const getStoreReqDoc = async(id)=>{
      const url = BASE_URL + "/settings/requisition-branches-list";
      const formData = {
        from_branch_id: newStockIssuance.fbranch.id,
        to_branch_id: id,
      }
    
      await axios
        .post(url,formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if(res.data){
            setStoreReqDoc(res.data.stock_requisitions);
          }      
        }).catch((error) =>{
          console.log("check error",error);
        })
    }

    // store req items
    const getStoreReqItems = async(ref_doc)=>{
      const url = BASE_URL + "/settings/requisition-details-list";
      const formData = {
        id: ref_doc.id,
        from_branch: newStockIssuance.fbranch.id,
      }
      await axios
        .post(url,formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        if(res.data){
          let rowArr = res.data.map((item)=>{
            return{
              ...item,
              qty: item.qty,
              max_qty: Number(item.qty)
            }
          })
          setNewRow(rowArr);
        }      
      }).catch((error) =>{
        console.log("check error",error);
      })
    }
  
    //set each item qty
    const handleItemQty = (index,e) => {
      if(parseFloat(newRow[index].stock_qty) < parseFloat(e.target.value) || (parseFloat(newRow[index].max_qty) < parseFloat(e.target.value))){
        toast.error("Your qty should not be greater than the stock/req. qty.",{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }else{
        newRow[index].qty=e.target.value;
        setNewRow([...newRow]);
      }
    };
  
    //handle Set branch hook
    const handleSetFromBranch = (fbranch) => {
      setNewStockIssuance({ ...newStockIssuance, fbranch });
    };

    const handleSetToBranch = (tbranch) => {
      setNewStockIssuance({ ...newStockIssuance, tbranch, ref_identity: "" });
      getStoreReqDoc(tbranch?.id);
      setNewRow([]);
    }; 

    const handleSetDocId = (ref_identity) => {
      // newStockIssuance.tbranch = null;
      setNewStockIssuance({ ...newStockIssuance, ref_identity });
      getStoreReqItems(ref_identity);
    };

    const handleRemarks=(e)=>{
      setNewStockIssuance({ ...newStockIssuance, remarks:e.target.value });
    }
  
    //post req of stock item update
    const stockItemAxios = async () => {
      let date =
        new Date(documentDate).getFullYear() +
        "-" +
        (new Date(documentDate).getMonth() + 1) +
        "-" +
        new Date(documentDate).getDate();

      let slugArray=[];
      newRow.map((newIssuance,index) => {
        slugArray.push({
          qty: parseInt(newIssuance.qty),
          item_id: newIssuance.item.id,
          item_name: newIssuance.item.name,          
          unit_id: newIssuance.unit.id,
          unit_name: newIssuance.unit.name,
          stock_qty: newIssuance.stock_qty,
        });
      });
        
      if(newRow.length === slugArray.length){
        setLoading(true);
        let formData = {
          ...(id && { id: id,}),
          from_branch_id: newStockIssuance.fbranch.id, 
          to_branch_id: newStockIssuance.tbranch.id,
          document_date: date,
          ref_identity : newStockIssuance.ref_identity.document_identity,
          remarks: newStockIssuance.remarks,
          issuance_details: slugArray,
        };
        const endpoint = id ? "/settings/update-issuance" : "/settings/store-issuance";
        const url = BASE_URL + endpoint;
        try {
            const res = await axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setLoading(false);
          toast.success(`${_t(t("Store Requisition has been updated"))}`, {
              position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          history.push("/dashboard/manage/stock/stock-issuance")
        } catch (error) {
          console.log("check error==>",error);
          setLoading(false);
          toast.error(`${_t(t("Please try again later"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      }
    };
    
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        newStockIssuance.fbranch !== null &&
        newStockIssuance.tbranch !== null &&
        newStockIssuance.document_no !== null &&
        documentDate !== null && newStockIssuance.ref_identity !== null
      ) {
        if(newRow.length > 0){
          stockItemAxios();
        }
        else{
          toast.error(`${_t(t("Please select doc with item"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    };
    
    return (
      <>
        <Helmet>
          <title>{id ? (newStockIssuance?.is_post ? _t(t("View Stock Issuance")) : _t(t("Edit Stock Issuance"))) : _t(t("Add Stock Issuance"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                      {/* spin loading ends */}
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {id ? (newStockIssuance?.is_post ? _t(t("View Stock Issuance")) : _t(t("Edit Stock Issuance"))) : _t(t("Add Stock Issuance"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="document no"
                                      className="control-label"
                                    >
                                      {_t(t("Document no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="document_no"
                                      name="document_no"                                      
                                      value={newStockIssuance.document_no}
                                      placeholder="Auto no"
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Document Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={documentDate}
                                      onChange={(date) => setDocumentDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Document date"))}
                                      shouldCloseOnSelect={false}
                                    />
                                  </div>
                                </div>
                                  
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("From Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchSearch}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      value={newStockIssuance.fbranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetFromBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div> 
                              </div>
  
                              <div className="row"> 

                                <div className="form-group mt-2 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("To Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchListWeb ? branchListWeb.filter((item)=>item.id!==newStockIssuance?.fbranch?.id) : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      value={newStockIssuance.tbranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetToBranch}
                                      maxMenuHeight="200px"
                                      isDisabled={!newStockIssuance.fbranch}
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select to branch")) + ".."
                                      }
                                    />
                                </div> 
                                <div className="form-group mt-2 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Select Document Ref"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={storeReqDoc}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.document_identity}
                                      getOptionValue={(option) => option.id}
                                      value={newStockIssuance.ref_identity || null}
                                      classNamePrefix="select"
                                      onChange={handleSetDocId}
                                      isDisabled={!newStockIssuance.fbranch || newStockIssuance.is_post}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select doc")) + ".."
                                      }
                                    />
                                </div>                                                            
                                <div className="form-group mt-2 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={handleRemarks}
                                      value={newStockIssuance.remarks || ""}
                                      placeholder="Remarks"
                                      style={{height:"33px"}}
                                    />
                                  </div>
                                </div>
                              </div>                            
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table_child"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Stock Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>                                    
                                      </tr>
                                    </thead>
                                    {newRow && newRow.length>0 ?
                                      <tbody className="align-middle">
                                        {newRow.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >                                            
                                                <td className="table-text text-capitalize align-middle text-center">                                                
                                                  {item.item?.name}
                                                </td>   
                                                <td className="table-text text-capitalize align-middle text-center">                                                
                                                  {item.stock_qty}
                                                </td>                                              

                                                <td className="table-text text-capitalize align-middle text-center">
                                                  <input
                                                    disabled={newStockIssuance.is_post}
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    onKeyDown={(evt) =>
                                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                    onWheel={(e) => e.target.blur()}
                                                    className="form-control text-center table-Input table-text"
                                                    id={item.id}
                                                    name={item.id}
                                                    onChange={(e)=>handleItemQty(index,e)}
                                                    value={item?.qty || ""}
                                                    placeholder="Qty"
                                                    required  
                                                  />
                                                </td>

                                                <td className="table-text text-capitalize align-middle text-center">                                                                                               
                                                 {item.unit?.name}
                                                </td>
                                              </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    :null}                                    
                                  </table>
                              </div>
  
                              <div className="d-flex justify-content-end align-items-center">
                                <div className="col-3 d-flex justify-content-end">
                                    <button
                                    type="button"
                                    className="btn rounded hov-btn mr-3"
                                    onClick={()=>(history.push("/dashboard/manage/stock/stock-issuance"))}
                                    >
                                      <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                    </button>
                                    {
                                      !newStockIssuance?.is_post &&(
                                        <button
                                        disabled={loading}
                                        type="submit"
                                        className="btn btn-primary rounded"
                                        >
                                          <i className="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                        </button>                                                
                                      )
                                    }
                                </div>                                                    
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
      </>
    );
}

export default StockIssuanceAction