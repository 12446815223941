import React, { useEffect, useContext, useState } from "react";

import "./charts/dashboard.css";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getReseredTime,
  getCookie
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

import { ReactComponent as FullTable } from "../../public/Full Table.svg";
import { ReactComponent as LeftHalfTable } from "../../public/Left Table.svg";
import { ReactComponent as RightHalfTable } from "../../public/Right Table.svg";

//3rd party packages
import { Helmet } from "react-helmet";

//pages & includes
import ReportSidebar from "./ReportSidebar";
import { SettingsContext } from "../../../../contexts/Settings";

const TableReservation = () => {
    const { t } = useTranslation();
    let { loading, setLoading } = useContext(SettingsContext);
    const [branches, setBranches] = useState();
    const [floor,setFloor] = useState();
    const [reservation, setReservation] = useState({
        branch: null,
        floor: null,
        tables: null,
        total_tables: 0,
        table_res: 0,
        table_unres: 0,
    });

    const handleSetBranch = (branch) =>{
        const floor = Object.keys(reservation.tables[branch]);
        setFloor(floor);
        setReservation({
            ...reservation,
            branch: branch,
            floor: floor ? floor[0] : null,
        })
    }

    const getTables = async() =>{
        const url = BASE_URL + "/settings/get-all-table";
        setLoading(true);
        try{
            const res = await axios.get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            });
            if(res.data){
                setLoading(false);
                const branch = Object.keys(res.data[0]);
                const floor = (branch && branch?.length) ? Object.keys(res.data[0][branch[0]]) : null;
                setBranches(branch);
                setFloor(floor);
                setReservation({
                    branch: branch ? branch[0] : null,
                    floor: floor ? floor[0] : null,
                    tables: res.data[0],
                    total_tables: res.data[1],
                    table_res: res.data[2],
                    table_unres: res.data[3],
                })
            }

        }catch(err){
            setLoading(false)
            console.error(err);
        }
    }

    useEffect(()=>{
        getTables();
        const interval = setInterval(()=>{
            getTables()
        }, 200000);
        return () => clearInterval(interval);
    },[]);

  return (
    <>
    <Helmet>
      <title>{_t(t("Table View"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                    <div key="smtp-form reports-dashboard">
                      <div className="row gx-2 mx-1 align-items-center t-pt-15 pb-2">
                        <div className="col-12 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Table Dashbord View"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* next page data spin loading  <div className={`${dataPaginating && "loading"}`}></div> */}
                     
                      <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                
                        <div className="row px-4 mt-3 mb-3">
                            <label htmlFor="name" className="d-flex align-items-center form-label mb-1 col-2">
                                Branches
                            </label>
                            {
                                branches && Array.isArray(branches) && branches?.length && branches.map((branch) => {
                                    return (
                                        <div key={branch}
                                            className="col-2 mb-2"
                                            onClick={(e) => handleSetBranch(branch)}
                                        >
                                            <div className={`table-floor text-truncate w-100 btn rounded border border-1  ${
                                                branch === reservation.branch
                                                ? "btn-primary"
                                                : ""
                                                }`}
                                            >
                                                {branch}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row px-4 mt-2 mb-3">
                            <label htmlFor="name" className="d-flex align-items-center form-label mb-1 col-2">
                                Floors
                            </label>
                            {
                                floor && Array.isArray(floor) && floor?.length && floor.map((floor) => {
                                    return (
                                        <div className="col-2 mb-2">
                                            <button key={floor}
                                                className={`btn text-truncate floorTable w-100 rounded-top
                                                ${
                                                    floor === reservation?.floor
                                                    ? "floorActive table-floor"
                                                    : ""
                                                }
                                                `}
                                                onClick={(e) => setReservation({...reservation , floor})}
                                            >
                                                {floor}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="px-4">    
                            <div className="mt-3 mb-3">
                                <div className="d-flex align-items-center mt-1">
                                    <label htmlFor="name" className="form-label mb-1 mr-3">
                                        Tables
                                    </label>
                                    <div className="d-flex align-items-center dashboard_table_head mr-2">
                                        Total: {reservation.total_tables} 
                                    </div>
                                    <div className="d-flex align-items-center dashboard_table_head mr-2">
                                        Table Reserved: {reservation.table_res} 
                                    </div>    
                                    <div className="d-flex align-items-center dashboard_table_head mr-2">
                                        Table UnReserved: {reservation.table_unres} 
                                    </div>
                                </div>
                                <div className= "custom_scrollBar" style={{height: "calc(100vh - 330px)"}}>
                                    <div className="row">
                                        {
                                            reservation?.tables && reservation?.tables[reservation.branch] && reservation?.tables[reservation.branch][reservation.floor] && Array.isArray(reservation?.tables[reservation.branch][reservation.floor]) && reservation?.tables[reservation.branch][reservation.floor]?.length && reservation?.tables[reservation.branch][reservation.floor].map((table,index)=>
                                            (
                                                <div key={index}
                                                    className="col-2 d-flex align-items-center justify-content-center my-1 mb-2 "
                                                >
                                                    <li
                                                        className={`d-flex align-items-center justify-content-center modal--table-item ${table.is_reserved === 2 && "disable_table"} ${table.is_reserved && "table-reserve"} p-3 mx-1
                                                        `}
                                                        style={{backgroundColor:table?.is_color, }}
                                                    >
                            
                                                        {
                                                            table.is_split === 1 ? (
                                                            <>
                                                                {
                                                                (table.is_split && table.is_reserved === 2) ? 
                                                                <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table split & reserved</span>
                                                                :
                                                                (table.is_split && table.is_reserved === 1) ?
                                                                <span className={`${table.is_split ? "table-tooltip" : "table-tooltip-none"}`}>Table split and half reserve</span>
                                                                :
                                                                <span className={`${table.is_split ? "table-tooltip" : "table-tooltip-none"}`}>Table split</span>
                                                                }
                                                                <span className="split d-flex justify-content-end">
                                                                <span
                                                                className={" halfTable halfTableLeft" }
                                                                >
                                                                {table.name+"A"}
                                                                </span>
                                                                <LeftHalfTable/>
                                                                </span>
                                
                                                                <span className="split d-flex justify-content-start">
                                                                <span
                                                                className={" halfTable halfTableRight"}
                                                                >
                                                                {table.name+"B"}
                                                                </span>
                                                                <RightHalfTable/>
                                                                </span>
                                                            </>
                                                            ) : (
                                                            <>
                                                                {
                                                                (table?.is_merge && table.is_reserved) ?
                                                                <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table merged & reserved</span>
                                                                :
                                                                (table?.is_merge) ?
                                                                <span className={`${table.is_merge ? "table-tooltip" : "table-tooltip-none"}`}>Table merged</span>
                                                                :
                                                                <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table reserved</span>
                                                                }
                                                                <span
                                                                className="tableName"
                                                                style={{color: table?.is_color ? table.is_color : "white"}}
                                                                >
                                                                {table.name}
                                                                </span>
                                                                <FullTable/>
                                                            </>
                                                            )
                                                        }
                                                        
                                                        {
                                                        (table.is_reserved === 2 ||  table.is_reserved === 1) ? 
                                                            <span className="reservation_time">Occ. from {getReseredTime(table && table.updated_at1)}</span>
                                                            :
                                                            ""
                                                        }
                                                    </li>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default TableReservation